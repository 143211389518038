import React from "react";
import { useState } from "react";
import nexttoprocess from "../../../assets/images/Release/intro.png";
import arrow from "../../../assets/images/arrow.svg";
import badge from "../../../assets/images/badge.svg";
import principleone from "../../../assets/images/Discovery/principle-one.png";
import principletwo from "../../../assets/images/Discovery/principle-two.png";
import principlethree from "../../../assets/images/Discovery/principle-three.png";
import guidanceone from "../../../assets/images/Discovery/guidance-one.svg";
import guidancetwo from "../../../assets/images/Discovery/guidance-two.png";
import guidancethree from "../../../assets/images/Discovery/guidance-three.png";
import guidancefour from "../../../assets/images/Discovery/guidance-four.png";
import styles from "./release.module.css";

import Intro from "./Intro";
import WhereData from "./WhereData";
import Steps from "./Steps";
import Principles from "./Principles";
import ReleaseExecution from "./ReleaseExecution";
import ClosureMeeting from "./ClosureMeeting";

function Release() {
  const WHEREDATA = [
    {
      name: "Release",
      dos: [
        { name: "Release Execution Meeting", ref: "ReleaseExecutionMeeting" },
        { name: "Closure/handover Meeting", ref: "ClosureMeeting" },
      ],
      uses: ["Release Checklist", "Release Report"],
    },
  ];

  const [activeWhere] = useState(WHEREDATA[0]);

  return (
    <div className={styles.releaseContainer}>
      <div className={"processParentContainer"}>
        <Intro nexttoprocess={nexttoprocess} />

        <WhereData
          wheredata={WHEREDATA}
          activeWhere={activeWhere}
          arrow={arrow}
          badge={badge}
        />

        <br />
        <br />
        <Steps />
        <br />

        <Principles
          principleone={principleone}
          principletwo={principletwo}
          principlethree={principlethree}
        />

        <ReleaseExecution
          guidanceone={guidanceone}
          guidancetwo={guidancetwo}
          guidancethree={guidancethree}
          guidancefour={guidancefour}
        />

        <ClosureMeeting
          guidanceone={guidanceone}
          guidancetwo={guidancetwo}
          guidancethree={guidancethree}
          guidancefour={guidancefour}
        />
      </div>
    </div>
  );
}

export default Release;
