import React from "react";
import { Collapse } from "antd";

import Guidance from "../../../../components/Guidance/Guidance";
import Tooltip from "../../../../components/Tooltip/Tooltip";
const { Panel } = Collapse;

const prepTime = "15 min";
const runTime = "60 min";
const followUpTime = "15-30 min";
const people = "3-5";

export const Dogfooding = (props) => {
  const { school, elder, victory } = props.badges;
  return (
    <>
      <br />
      <div className="guidancesContainer Dogfooding">
        <h1 className="processHeading2" id="dogFooding">
          Dogfooding Day
        </h1>
        <Guidance
          prepTime={prepTime}
          runTime={runTime}
          followUpTime={followUpTime}
          people={people}
        />
        <br />
        <br />
        <div className="guidanceCollapse">
          <Collapse expandIconPosition={"right"}>
            <Panel header="Why the requirement" key="1">
              <p className="processRunning">
                There is a big difference between bug fixing and polishing.
                Polishing is about elevating your delivery,
                <Tooltip
                  headingOne="Imtehan"
                  paragraphOne='"juice nahi hai, maza nahi aya” – what does this mean?'
                  hasQuizOne={true}
                  quizOneHeadingText="Click for jawab"
                  quizOneParagraphOne="Ask UJ ☺"
                  direction={`${screen.width <= 1455 ? "right" : "left"}`}
                >
                  <img src={school} alt="" className="badgeIcon" />
                </Tooltip>
                testing a product/feature not just for functionality but also
                UX.
                <Tooltip
                  headingOne="Buzurg kehte hein:"
                  paragraphOne="UX is heavy in the polishing phase. Small things like adding animations to a menu or making sure a button is visibly clickable make a big difference in impression."
                  direction={`${screen.width > 1540 ? "right" : "left"}`}
                >
                  <img src={elder} alt="" className="badgeIcon" />
                </Tooltip>
                It requires a dedicated effort and a shift in focus. The goal is
                here to make sure that the client does not find something that
                is obvious, and that the client is positively surprised by what
                you share with them.
                <br />
                <br />
                Polishing is best achieved when looking at things with different
                eyes. Working closely and intensely on something for hours for
                days makes one blind to the weaknesses. Dogfooding is an
                excellent way to put on new glasses.
              </p>
            </Panel>
          </Collapse>
          <br />
          <br />
          <Collapse expandIconPosition={"right"}>
            <Panel header="What is the requirement" key="1">
              <div className={"guidanceReqInnerBox"}>
                <p>
                  <b className="mandatory-participants">
                    Mandatory participants:
                  </b>{" "}
                  PM, all team members, Account Manager (optional)
                </p>
              </div>
              <p className="processRunning">
                This is a day – or part of a day depending on feature (set) –
                where the team is required to:
                <br />
                <ul>
                  <li>Implement a complete code freeze</li>
                  <li>Get together in person or online</li>
                  <li>
                    Each team member to choose a user persona
                    <Tooltip
                      headingOne="Buzurg kehte hein:"
                      paragraphOne="If user personas are not available, discuss a few users quickly at this stage, mixing gender, age, tech literacy, etc. Imagine the edge cases as well as an ‘ideal user’."
                      direction="right"
                    >
                      <img src={elder} alt="" className="badgeIcon" />
                    </Tooltip>
                  </li>
                  <li>
                    Each team member to choose a dev role that is not their own
                    (for example backend engineer chooses UX, QA chooses front
                    end and so on)
                  </li>
                  <li>
                    Ask questions in the group about the user persona if
                    something is not clear or if you are not sure you understand
                    the user fully
                  </li>
                  <li>
                    PM describes the task: Individual time to use the app as
                    your chosen user and note feedback for the role you have
                    chosen (front end, backend)
                  </li>
                  <li>
                    PM describes the rules of the exercise: no criticism, no
                    personal complaints, absolutely no blame and no discussion
                    of he did/she did, give feedback like you would like to
                    receive feedback, a spirit of collaboration, do not take
                    feedback personally, we are in this together! And challenge
                    yourselves, finding a problem now is better than the client
                    finding it.
                  </li>
                  <li>
                    Back in plenary: each member takes turns presenting their
                    feedback, brief discussion in group to take 1-2 points
                    forward, next person presents, repeat.
                  </li>
                  <li>
                    A bowl of stardust: conclude with a list of items that the
                    team will implement to polish the delivery.
                  </li>
                  <li>
                    Write down
                    <Tooltip
                      headingOne="Champions ka tareeqa:"
                      paragraphOne="I write down the list physically rather than on jeera or elsewhere online. Not writing tickets and doing it manually creates a new dynamic, a visible shift in energy. And it gives the team great satisfaction to cross off the items physically and in view of all. They get to see the list shrinking, or sometimes growing a little when I added items. In that dynamic it was easy for them to say bhai, ye nahi hoga, please nikal dein. All in all, the most exciting part of the project if you do this right."
                      paragraphTwo="I have used a large sheet of paper, the window, a white board, doors, certainly not the wall ☺"
                      direction="right"
                      yOffsetMobile="translate-Y-neg-110%"
                    >
                      <img src={victory} alt="" className="badgeIcon" />
                    </Tooltip>
                    and share the list / put it in the working area visibly
                  </li>
                  <li>
                    Take the next day / half a day implementing each item.
                  </li>
                </ul>
              </p>
            </Panel>
          </Collapse>
          <br />
        </div>
        <br />
      </div>
    </>
  );
};
