import React, { useState } from "react";
import { Field } from "formik";
import { message } from "antd";

message.config({
  duration: 1,
  maxCount: 1,
});

const numberInputChecklist = [
  "Release Number",
  "Number of Sprints in the release",
];

export const TextareaToggle = ({ label, keyIdentifier }) => {
  const [isChecked, setIsChecked] = useState(false);

  const labelCheck = () => {
    if (numberInputChecklist.includes(label)) {
      return true;
    }
    return false;
  };

  const numberValidation = (values) => {
    const reg = new RegExp("^[0-9]+$");
    if (!reg.test(values) && labelCheck()) {
      return message.error("Please enter a number only");
    }
    return undefined;
  };
  return (
    <div className={`${labelCheck() ? "checkbox-container" : ""}`}>
      <input
        id={keyIdentifier + "checkbox"}
        type="checkbox"
        onChange={(e) => setIsChecked(e.target.checked)}
      />
      <label htmlFor={keyIdentifier + "checkbox"} className="field-label">
        {label}
      </label>
      <div>
        {isChecked && (
          <Field
            className={`${labelCheck() ? "number-section" : "comment-section"}`}
            as="textarea"
            name={keyIdentifier}
            validate={numberValidation}
          />
        )}
      </div>
    </div>
  );
};
