import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import Navbar from "../../components/Navbar/Navbar";
import { RouteWithSubRoutes } from "../../routes/routeWithSubRoutes";

const AdminPanel = ({ routes }) => {
  return (
    <>
      <Navbar />
      <RouteWithSubRoutes routes={routes} />
    </>
  );
};

export default AdminPanel;
