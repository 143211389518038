import React from "react";
import { Collapse } from "antd";
import BADGES_CONTENT from "../../../../components/Processes/Discovery/BadgesContent";
import Guidance from "../../../../components/Guidance/Guidance";
import Tooltip from "../../../../components/Tooltip/Tooltip";
const { Panel } = Collapse;

const prepTime = "15 min";
const runTime = "60 min";
const followUpTime = "15-30 min";
const people = "3-5";

export const DiscoveryPlanning = (props) => {
  const { elder, victory, school, vector } = props.badges;
  return (
    <>
      <div className="guidancesContainer discoveryPlanning">
        <h1 className="processHeading2" id="internalMeeting">
          Discovery Planning Meeting
        </h1>
        <br />
        <Guidance
          prepTime={prepTime}
          runTime={runTime}
          followUpTime={followUpTime}
          people={people}
        />
        <br />
        <br />
        <div className="guidanceCollapse">
          <Collapse expandIconPosition={"right"}>
            <Panel header="Why the meeting: Purpose" key="1">
              <p className="processRunning">
                Discovery Planning Meeting is an internal meeting with a
                two-fold purpose:
                <br />
                <br />
                1. <b>Product:</b> Introduce the product, create common
                understanding of the concept and clients’ timeline.
                <br />
                2. <b>Team &amp; Task:</b>
                <span
                  style={{
                    backgroundColor: "#f0ecf2",
                    borderRadius: "10px",
                    padding: "0px 5px",
                  }}
                >
                  Onboard the team
                </span>
                <Tooltip
                  headingOne="Buzurg kehte hain"
                  paragraphOne={BADGES_CONTENT.ONBOARD}
                  direction="right"
                >
                  <img src={elder} alt="" className="badgeIcon" />
                </Tooltip>
                <br />
                <ul>
                  <li>Introduce the team and create good team dynamic</li>
                  <li>
                    Create a common understanding of the purpose and scope of
                    discovery
                  </li>
                  <li>
                    Establish ways of working (incl. roles and responsibilities,
                    SOPs for communication, tickets, and documentation)
                  </li>
                </ul>
              </p>
            </Panel>
          </Collapse>
          <br />
          <br />
          <Collapse expandIconPosition={"right"}>
            <Panel header="What is the requirement: Meeting Agenda" key="1">
              <div className="processRunning">
                The PM is responsible for drafting and sharing the agenda of the
                meeting with the entire team. It can be shared in the body of an
                email or as a separate attachment to the email. It is important
                that the PM follows their own style of communication and logical
                flow. However, the following items should be covered:
                <br />
                <br />
                <ul>
                  <li>
                    Team introductions
                    <Tooltip
                      headingOne="Champions ka tareeqa"
                      paragraphOne={BADGES_CONTENT.INTRODUCTIONS}
                      direction="right"
                    >
                      <img src={victory} alt="" className="badgeIcon" />
                    </Tooltip>
                  </li>
                  <li>
                    Brief on the product
                    <Tooltip
                      headingOne="Champions ka tareeqa"
                      paragraphOne={BADGES_CONTENT.PRODUCT}
                      direction="right"
                    >
                      <img src={victory} alt="" className="badgeIcon" />
                    </Tooltip>
                    (business concept, purpose) and the client (persons,
                    company) by the PM to the team
                    <Tooltip
                      headingOne="Imtehan"
                      paragraphOne={BADGES_CONTENT.TEAM_1}
                      hasQuizOne={true}
                      quizOneHeadingText="Click to flip"
                      quizOneParagraphOne="The founder came in towards the end of discovery and changed the way the features had been prioritised. The estimates were no longer valid. It became a painful negotiation where seniors had to get involved."
                      hasQuizTwo={true}
                      quizTwoSectionHeading="Imtehan"
                      quizTwoContent={BADGES_CONTENT.TEAM_2}
                      quizTwoHeadingText="Click to flip"
                      quizTwoParagraphOne="Champions ka tareeqa - the PM took the point of frustration, understood limitation in terms of client’s awareness and room for push back, he created space for the team to share the frustration and dissipate negative energy. Internally the client became a Pakistani bhai with his ghalat APIs. The joke was harmless, not disrespectful and created a positive point of interaction for the team."
                      direction="right"
                    >
                      <img src={school} alt="" className="badgeIcon" />
                    </Tooltip>
                  </li>
                  <li>
                    Simulation: one feature
                    <Tooltip
                      headingOne="Ye kia"
                      paragraphOne={BADGES_CONTENT.SIMULATION_1}
                      headingTwo="Lekin kion"
                      paragraphTwo={BADGES_CONTENT.SIMULATION_2}
                      hasQuizOne={true}
                      quizOneSectionHeading="Quiz"
                      quizOneContent={BADGES_CONTENT.SIMULATION_3}
                      quizOneHeadingText="Click to show answer"
                      quizOneParagraphOne="The PM’s first reaction was to see it as a territory issue as it appears to be. But took a moment to think about the person – what is bothering him – and realised that the UX team member is feeling uncertain about their place and value in the team. Addressed it by positive feedback in team meetings and in bilaterals. Boosted confidence by asking UX advice in team meetings."
                      direction="right"
                    >
                      <img src={school} alt="" className="badgeIcon" />
                    </Tooltip>
                  </li>
                  <li>
                    Scope and purpose of discovery
                    <Tooltip
                      headingOne="Buzurg kehte hain"
                      paragraphOne={BADGES_CONTENT.SCOPE_1}
                      paragraphTwo={BADGES_CONTENT.SCOPE_2}
                      paragraphThree={BADGES_CONTENT.SCOPE_3}
                      direction="right"
                    >
                      <img src={elder} alt="" className="badgeIcon" />
                    </Tooltip>
                  </li>
                  <li>
                    SOPs for communication (internal + with client) and tickets
                  </li>
                  <li>Discovery documentation and responsibilities</li>
                  <li>
                    Responsibilities: presentation at Discovery Kick off Meeting
                    <Tooltip
                      headingOne="Ye kia"
                      paragraphOne={BADGES_CONTENT.MEETING}
                      direction="left"
                    >
                      <img src={vector} alt="" className="badgeIcon" />
                    </Tooltip>
                  </li>
                </ul>
                <div className="guidanceTips">
                  <h1 className="processHeading4 italic">Before the Meeting</h1>
                  <p>
                    It is important that meetings are productive, useful to all
                    involved and provide tangible/actionable next steps. In
                    order to achieve this for the Discovery Kick off Meeting,
                    the PM should:
                  </p>
                  <ul>
                    <li>Send the agenda at least a day in advance</li>
                    <li>Share the discovery plan template with the client?</li>
                    <li>
                      Assign agenda items to individual team members as
                      applicable
                    </li>
                    <li>Assign a notetaker</li>
                  </ul>

                  <h1 className="processHeading4 italic">During the Meeting</h1>
                  <p>
                    The PM is responsible for drafting and sharing the agenda of
                    the meeting with the client and the team. It can be shared
                    in the body of an email or as a separate attachment to the
                    email. It is important that the PM follows their own style
                    of communication and logical flow. However, the following
                    items should be covered:
                  </p>
                  <ul>
                    <li>Introductions</li>
                    <li>Our understanding of the product</li>
                    <li>Brief recap: purpose and scope of discovery</li>
                    <li>Discovery Plan</li>
                    <li>SOPs for communication</li>
                  </ul>

                  <h1 className="processHeading4 italic">After the Meeting</h1>
                  <ul>
                    <li>
                      As a minimum, summarise the notes from the discussion and
                      share with clear action items
                    </li>
                    <li>Summarise SOPs clearly</li>
                    <li>
                      Give the client the necessary access as agreed in the SOPs
                    </li>
                  </ul>
                </div>
              </div>
            </Panel>
          </Collapse>
        </div>
      </div>
      <br />
    </>
  );
};
