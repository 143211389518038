import React from "react";
import PropTypes from "prop-types";
import { Collapse } from "antd";

import BADGES_CONTENT from "../../../../components/Processes/Development/BadgesContent";
import Guidance from "../../../../components/Guidance/Guidance";
import Tooltip from "../../../../components/Tooltip/Tooltip";

const { Panel } = Collapse;

const prepTime = "15 min";
const runTime = "30 min";
const followUpTime = "15 min";
const people = "3-5";
function UpdateMeetings(props) {
  const { styles, badges } = props;
  const { victory, elder } = badges;
  return (
    <div className="guidancesContainer">
      <div className={styles.guidanceMainHeadingContainer}>
        <h2 className="processHeading2" style={{ margin: 0 }}>
          Update Meetings
        </h2>
      </div>
      <br />
      <Guidance
        prepTime={prepTime}
        runTime={runTime}
        followUpTime={followUpTime}
        people={people}
      />

      <br />
      <br />
      <div className="guidanceCollapse processRunning">
        <Collapse expandIconPosition={"right"}>
          <Panel
            header="Why the requirement"
            key="1"
            className="processRunning"
          >
            <p>
              This is a meeting with the client during the Sprint/release cycle
              if needed and depending on communication frequency agreed with the
              client. The purpose of the meeting is to provide an update on
              where things are and seek clarifications if needed.
            </p>
          </Panel>
        </Collapse>
        <br />
        <br />
        <Collapse expandIconPosition={"right"}>
          <Panel
            header="What is the requirement"
            key="2"
            className="processRunning"
          >
            <div className="row">
              <div className="col-12">
                <div className="guidanceReqInnerBox">
                  <b className="mandatory-participants">
                    Mandatory participants:
                  </b>
                  &nbsp;PM, relevant team members
                </div>

                <p className="processRunning">
                  This meeting is with the client. There is no requirement for
                  minimum frequency or to have the meeting at all. The
                  requirements applies if update meetings are agreed on in the
                  communication SOPs agreed with the client or if a sudden need
                  arises. Bringing team members who are the most relevant to the
                  update/clarifications is a good practice.
                  <br />
                  <br />
                  The PM is responsible for drafting and sharing the agenda of
                  the meeting with the client. It can be shared in the body of
                  an email or as a separate attachment to the email. It is
                  important that the PM follows their own style of communication
                  and logical flow. However, the following items should be
                  covered:
                </p>
                <ul>
                  <li>Are we on track</li>
                  <li>
                    What is going well, where are the blockages
                    <Tooltip
                      headingOne="Champions ka tareeqa:"
                      paragraphOne={BADGES_CONTENT.BLOCKAGES_1}
                      paragraphTwo={BADGES_CONTENT.BLOCKAGES_2}
                    >
                      <img src={victory} alt="" className="badgeIcon" />
                    </Tooltip>
                  </li>
                  <li>Clarifications needed</li>
                </ul>

                <h1 className="processHeading4 italic">Before the Meeting</h1>
                <ul>
                  <li>Send the agenda at least a day in advance</li>
                  <li>
                    Draft talking points and discuss with a senior/another PM
                    for tips if there are challenges to be discussed.
                  </li>
                  <li>Assign a notetaker</li>
                </ul>

                <h1 className="processHeading4 italic">During the Meeting</h1>
                <ul>
                  <li>
                    Do not read from the talking points – they were only meant
                    to clarify your thoughts
                  </li>
                  <li>
                    Be open and honest. Tell the client if the team is facing
                    challenges.
                  </li>
                  <li>
                    Do not list technical details or items that have been
                    finished/in progress. Put yourself in the client’s shoe and
                    give information that is
                    <br />
                    useful and understandable
                    <Tooltip
                      headingOne="Buzurg kehte hein:"
                      paragraphOne="Remember client profiles. There is a difference between talking to an experienced CTO vs talking to a start-up owner with no tech background."
                      paragraphTwo="Also be aware of who from the client’s side are you talking to, what role do they have, what would be the most useful information for them, what would they like to know more about."
                      direction="right"
                    >
                      <img src={elder} alt="" className="badgeIcon" />
                    </Tooltip>
                    to them.
                  </li>
                </ul>

                <h1 className="processHeading4 italic">After the Meeting</h1>
                <ul>
                  <li>
                    Summarise the notes from the discussion and share with the
                    client and the team with clear action items.
                  </li>
                  <li>
                    File the minutes in the project’s document tree /
                    documentation skeleton.
                  </li>
                </ul>
              </div>
            </div>
          </Panel>
        </Collapse>
      </div>
      <br />
    </div>
  );
}

UpdateMeetings.protoTypes = {
  styles: PropTypes.any,
};

export default UpdateMeetings;
