export const DO_CONSTANTS = [
  "Discovery Planning Meeting (internal)",
  "Discovery Kickoff Meeting (with client)",
  "Review of estimates",
  "Hand over to PM meeting",
  "Conversion retro",
  "Dogfooding",
  "nangaParbat",
  "Release Execution Meeting",
  "Charter Meet",
  "Closure/handover Meeting",
  "Sprint 0.5",
  "Release Planning Meeting",
  "Dev Kickoff Meeting",
  "Daily Standup and Syncups",
  "Update Meetings (with client)",
  "Status Report",
  "Milestone Demo",
  "Discovery checklist",
  "What does this do for me",
  "Umar",
  "Fatima",
  "Saima",
  "Hamza",
  "Release Checklist",
  "Project Work-Plan",
  "Daily standups and syncups",
  "Daily updates to clients",
  "Lead conversion checklist",
];
