import React from "react";
import PropTypes from "prop-types";

import person from "../../../../assets/icons/person.svg";
import elder from "../../../../assets/icons/elderly.svg";
import school from "../../../../assets/icons/school.svg";
import BADGES_CONTENT from "../../../../components/Processes/Discovery/BadgesContent";
import Tooltip from "../../../../components/Tooltip/Tooltip";

export const ApplyingPrinciples = (props) => {
  const { principleone, principletwo, principlethree } = props;
  return (
    <div className="principlesContainer">
      <div>
        <div id="principles-row" className="row">
          <div className="col-2 whitespace"></div>
          <div className="col-6">
            <div className="row">
              <h1 className="processHeading2 ml-0">Applying the Principles</h1>
            </div>
            <div className="col-12">
              <div>
                <div className="row mt-24">
                  <div className="col-1 mobile-hidden">
                    <img
                      className="img-fluid"
                      src={principleone}
                      alt="principle"
                    />
                  </div>
                  <div className="col-10 tooltip-parent">
                    <div className="mobile-flex">
                      <img
                        className="img-fluid desktop-hidden"
                        src={principleone}
                        alt="principle"
                      />
                      <h1 className="processHeading4">
                        Delight the Customer
                        <Tooltip
                          headingOne="Client ke dil se:"
                          paragraphOne={BADGES_CONTENT.DELIGHT_CUSTOMER}
                        >
                          <img src={person} alt="" className="badgeIcon" />
                        </Tooltip>
                      </h1>
                    </div>

                    <ul className="processRunning">
                      <li>
                        You keep the client informed with meaningful updates
                      </li>
                      <li>
                        You demonstrate knowledge of the domain, an
                        understanding of the product and ask the right questions
                        at the right time
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div>
                <div className="row mt-24">
                  <div className="col-1 mobile-hidden">
                    <img
                      className="img-fluid"
                      src={principletwo}
                      alt="principle"
                    />
                  </div>
                  <div className="col-10">
                    <div className="mobile-flex">
                      <img
                        className="img-fluid desktop-hidden"
                        src={principletwo}
                        alt="principle"
                      />
                      <h1 className="processHeading4">
                        Good working environment for the team
                      </h1>
                    </div>

                    <ul className="processRunning">
                      <li>PM is engaged throughout the process</li>
                      <li>
                        The team has a common understanding of the purpose and
                        scope of discovery
                      </li>
                      <li>
                        Design and QA teams feel heard and included as equals
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div>
                <div className="row mt-24">
                  <div className="col-1 mobile-hidden">
                    <img
                      className="img-fluid"
                      src={principlethree}
                      alt="principle"
                    />
                  </div>
                  <div className="col-10 tooltip-parent">
                    <div className="mobile-flex">
                      <img
                        className="img-fluid desktop-hidden"
                        src={principlethree}
                        alt="principle"
                      />
                      <h1 className="processHeading4">
                        Profitability for the company
                        <Tooltip
                          headingOne="Buzurg kehte hain"
                          paragraphOne={BADGES_CONTENT.PROFITABILITY}
                        >
                          <img src={elder} alt="" className="badgeIcon" />
                        </Tooltip>
                      </h1>
                    </div>

                    <ul className="processRunning tooltip-parent">
                      <li>
                        You have identified the risks and Tintash’s ability to
                        address them
                      </li>
                      <li>
                        Estimates
                        <Tooltip
                          headingOne="Quiz"
                          paragraphOne={BADGES_CONTENT.ESTIMATE_1}
                          hasQuizOne={true}
                          quizOneHeadingText="Click to flip for answer"
                          quizOneParagraphOne="Sometimes mistakes will happen. When they do: communication is the solution. Clear, as soon as you know the issue, and constructive with a suggestion or two for a solution."
                          hasQuizTwo={true}
                          quizTwoSectionHeading="Quiz"
                          quizTwoContent={BADGES_CONTENT.ESTIMATE_2}
                          quizTwoHeadingText="Click for answer"
                          quizTwoParagraphOne="We assumed client had an artist but actually they were going to google, we had to spend a lot of time fixing assets to make them usable. An easy recipe for a frustrated team and a disappointed client."
                        >
                          <img src={school} alt="" className="badgeIcon" />
                        </Tooltip>
                        are done methodically and are based on the resources
                        likely to be assigned to the project
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
ApplyingPrinciples.propTypes = {
  principleone: PropTypes.string,
  principletwo: PropTypes.string,
  principlethree: PropTypes.string,
};
