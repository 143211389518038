import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getCheckList } from "../../../../../../../../redux/actions/projectDetails";
import { addUpdateEffect } from "../../../../../../../../redux/actions/dailyUpdate";
import { FormikCheckboxDynamic } from "../../../../../../../../components/formik/dynamic-form/FormikCheckboxDynamic";
import { PROCESS_CHECKLIST_MAPPER } from "../../../processConstants";
import { getFormattedInitialValues } from "../../../../../../../../utilities/utilities";
import { NextStageDialog } from "../../../../../../../../components/Dialog/next-stage-dialog/NextStageDialog";
import { unlockSubStage } from "../../../../../../../../helper/putApis";
import ClientSatisfactionMeter from "../../../ClientSatisfactionMeter/ClientSatisfactionMeter";

export const FinishingDiscoveryUpdateForm = () => {
  const dispatch = useDispatch();
  const { projectId, phaseId, processId } = useParams();
  const userRole = useSelector(
    (state) => state.clientSatisfaction.userRole.shortForm
  );
  const { data: checkList } = useSelector((state) => state.checkList);

  const [showDialog, setShowDialog] = useState(false);
  const [showCheckinForm, setShowCheckinForm] = useState(false);
  const [updateFeedback, setUpdateFeedback] = useState(null);

  useEffect(() => {
    dispatch(getCheckList(projectId, phaseId, processId));
  }, [projectId]);

  const onSubmit = async (values) => {
    let startDiscoveryCompleted = false;
    const { sendTo = [] } = values;
    const {
      checkList: checklist,
      isChecked,
      comments,
    } = values[values.syncupType];
    startDiscoveryCompleted = isChecked;
    const payload = {
      title: PROCESS_CHECKLIST_MAPPER[values.syncupType],
      syncupType: PROCESS_CHECKLIST_MAPPER[values.syncupType],
      process: processId,
      phase: phaseId,
      project: projectId,
      [values.syncupType]: isChecked,
      checklist,
      sendTo: sendTo.join(","),
      meetingPoints: comments,
    };
    dispatch(addUpdateEffect(payload));
    const isRoleValid = userRole === "PM" || userRole === "CL";
    if (values.syncupType === "clientPresentation" && isRoleValid) {
      setTimeout(() => {
        setShowCheckinForm(true);
        setUpdateFeedback(!updateFeedback);
      }, 1000);
    }
    if (startDiscoveryCompleted) {
      setShowDialog(true);
    }
    setTimeout(() => {
      dispatch(getCheckList(projectId, phaseId, processId));
    }, 1000);
  };

  const unlockNextStage = async () => {
    await unlockSubStage(projectId, phaseId, processId);
    setShowDialog(false);
  };

  return (
    <>
      <ClientSatisfactionMeter
        isVisible={showCheckinForm}
        updateFeedback={updateFeedback}
        setShowCheckinForm={setShowCheckinForm}
      />
      <NextStageDialog
        onSuccess={unlockNextStage}
        showDialog={showDialog}
        setShowDialog={setShowDialog}
      />
      <FormikCheckboxDynamic
        checkList={checkList}
        onSubmit={onSubmit}
        initialValues={getFormattedInitialValues(checkList)}
      />
    </>
  );
};
