const BADGES_CONTENT = {
  COLLABORATION:
    " I ensure my team understands and respects each other’s roles. For example, a developer’s view is always how a product should work. The ‘should’ frames their thoughts, it is natural. A QA engineer’s job is to see a product would work in the hands of live users especially the edge cases on either extreme of a user spectrum. This difference in view and collaboration ensures success. I put up a picture in our working area to illustrate the point (see picture).",
  TOGETHETR:
    "This is the time to encourage a team culture where team members put themselves in each other’s shoes and welcome feedback from each other regardless of roles, for example: engineer suggestions on design, design’s suggestions on QA etc.",
  DELIVERY:
    "This could be many things, I will ask for help when needed, will raise concerns as early as possible, will take a proactive interest in the product and understand the business problem, will take pride in learning from others’ knowledge. One Tintash tip: Ask your team to imagine three stamps – 1) Made by <team member name>, 2) Made by Tintash, 3) Made in Pakistan – ask each member to choose one that motivates them the most OR agree as a team which motivates the team the most. Have a rule – nothing will be marked done by any team member unless they are proud to put that stamp on it. You could even make one digitally! Or physically",
  MEMBER:
    "I always design the logic/write function stub before I code. Saves time when coding and reduces revisions. A bonus if I can get the stub reviewed by a peer or a senior if time/project/schedules allow.",
  RULES:
    "One rule we have in our team is that during code reviews, we will add positive comments too and not limit our feedback to where we think something should be improved. Example: Nicely solved! This inspired me to change my flow in place x! ",
  SPRINT_1: " Why 0.5?",
  SPRINT_2:
    " Well, it’s Tintash, we take our work seriously, not labels. We get to the core of an issue. Sprint 0 belongs in scrum/agile or not, we do not want to argue. This is our kickoff period",
  INFRASTRUCTURE:
    "it is essential that the staging environment is the same as the production environment. This will help you test things with the client as well as internally and reduce loops and rework after deployment to production. ",
  FLOW: " Feature flow also helps me prioritise features and break them down into small enough units that in the project environment a feature branch is as short-lived as possible. My team merges them to staging with feature flags. Helps us streamline testing and reduce errors. The longer a feature branch lives, the more complicated to merge it.",
  TICKET:
    " There should be tickets for UX. When testing, writing concise steps for reproducing an issue saves a lot of time and frustration. Code reviews – connect them to pull requests but technical debt – write tickets for those with appropriate priorities. ",
  CHARTER:
    " As the team charter, this does not need to be a formal document but a few bullet points clearly visible to the team in office or online.",
  WORKING:
    "I make sure the developers have one thing to do at a time. I know change requests will come and I know code reviews will require their time for making improvements: I budget a % for each in every sprint for example 5% for code reviews and 20% for change requests. ",
  PRESENTATION_1:
    "True or False: A good presentation will consist of a brief description of what Sprint 0.5 is, a summary of how the team planned and executed it, and a demo of what came out of it.",
  PRESENTATION_2:
    "False. Completely false. Not even a little bit true. Put yourself in the client’s shoes, think about what they want when they come to the kickoff meeting. Most clients do not want to hear your internal processes. You don’t even have to mention the term Sprint 0.5. In fact, please don’t. What will delight the client? The team knows the product, has relevant questions and seems to work like a well-oiled machine. Structure the presentation accordingly. Do not present what you have developed yet. ",
  CLIENT:
    " I really liked what they did in discovery. Excited to meet the PM/Team. I wonder how well they understand what we want. ",
  MEMBERS:
    " if I expect resistance on an item from an individual, I assign that item to them. Sometimes that is the simplest way to shift their mindset from resistance and criticism to instead try and find constructive ways forward with other team members. In other cases, when I think junior team members would learn from others on a given topic, I assign the agenda item to the team member most experienced with it.",
  SCHEDULE:
    "When developing a product, I plan in a way that we can release a minimum ‘usable’ product - a feature of feature set – to the client in the staging environment in two sprints/one month. We do not release anything for one month to make sure we make a great impression with whatever we release first. And then do continuous release of features. This is the only real way to get actionable feedback.",
  APPROPRIATE_1:
    "The best release plan is where the client gets to see and use a working part of the product earlier rather than later.But beware: as soon as the client sees a feature and uses it,their understanding will evolve and the change requests will start coming immediately and in high volume! This is natural. Prepare for it in two ways:1) think through how you want to manage client expectations and communicate clearly the implications of change requests on the rest of the project, and 2) book some time for change requests in the following sprints.The goal is not to push back on all change requests. The goal is to understand that it is natural for the client and the team’s understanding to evolve,it benefits the project but the expectations needs to be managed well through clear communication.If you have earned the client’s trust through good quality first delivery and good communication, the client will begin to understand the long-term nature of product development and the relationship",
  APPROPRIATE_2:
    "and the relationship will begin to shift towards a less timebound, longer term collaboration.",
  APPROPRIATE_3:
    "Waiting to release a product or a part of the product for a couple of months or more will not reduce client feedback, it will just make the process harder to manage after you release the build. And the product will lose the benefit of early feedback and, therefore, suffer on quality.",
  ENVIORNMENT:
    "The staging environment should be exactly the same as the production environment. This ensures a good level of readiness of your application.",
  WORKPLAN:
    "Make this a discussion rather than an announcement from your side. List your milestones and say this is based on the understanding so far. Is there something in your business plan that we need to be aware of and discuss together to finalise the milestones.",
  MINUTES:
    "Assign time keeping responsibility to a different team member each day. This keeps everyone engaged with the process and helps team members who give too much detail with focusing their own input. It also helps team members who talk to little or do not like stand-ups to feel they have some control on the process.",
  MEETINGS:
    "to keep the tone informal, I usually place a funny picture (of GIF) where the team can see it, before they arrive. It creates a little intrigue and starts the meeting on a good tone. I try and make the picture relevant to the situation or project phase that we are in. I also change it after a few days to reflect new situations and to keep the curiosity alive. Team members say they are curious to see which picture they will see when they come.",
  BLOCKAGES_1:
    "I tell the client when we are stuck. Have provided an update where I said, sorry the team has been stuck all day, have not made any progress. Please provide these clarifications.",
  BLOCKAGES_2:
    "Clients have always appreciated this communication and it has always resulted in more trust never less. Everybody knows blockages happen, nobody believe a 100% rosy picture at all times.",
};
export default BADGES_CONTENT;
