import React, { useState } from "react";
import Badge from "../../../assets/images/badge.svg";
import image1 from "../../../assets/images/Howtouse/nexttohowtouse.png";
import MainPageNav from "../../../components/MainPageNav/MainPageNav";
import { useHistory } from "react-router";
import "bootstrap/dist/css/bootstrap.min.css";
import "./howToUse.scss";
import MobileNavbarTop from "../../../components/mobile/MobileNavbarTop/MobileNavbarTop";
import { menuItems } from "../MainPage/MainPage";

export const HowToUse = () => {
  const history = useHistory();

  const [openNavigationMenu, setOpenNavigationMenu] = useState(false);

  return (
    <>
      <MainPageNav />
      <MobileNavbarTop
        openNavigationMenu={openNavigationMenu}
        setOpenNavigationMenu={setOpenNavigationMenu}
        menuItems={menuItems}
      />
      <div className="processParentContainer">
        <div className={"processIntro"}>
          <div className="flex mt-64">
            <div className="back-btn" onClick={() => history.push("/")}>
              Back
            </div>
          </div>
          <div className={"row"}>
            <div className={"whitespace col-1"}></div>
            <div className={"col-8 mobile-w-full"}>
              <h1 className={"processHeading2 mt-56"}>How to use</h1>
              <p className="lead-content">
                The playbook contains both minimum requirements that are
                mandatory for all projects, and reference material that is
                optional to use.
                <br />
                <div className="col-12">
                  <img
                    className={"desktop-hidden img-fluid"}
                    src={image1}
                    alt="process"
                  />
                </div>
                <ol type="1">
                  <li>
                    Get an overview of the requirements and reference materials.
                  </li>
                  <li>
                    Clicking on an item will take you to a description of why
                    and what it is.
                  </li>
                  <li>
                    The description is supported by standard categories of text.
                    These include:
                    <ul>
                      <li>
                        <strong>Buzurg kehte hein</strong>: advice from the
                        seniors at Tintash and experts in the field.
                      </li>
                      <li>
                        <strong>Champions ka tareeqa</strong>: examples and
                        anecdotes from Tintash on how a given requirement was
                        handled in a successful project.
                      </li>
                      <li>
                        <strong>Lekin kion</strong>: the answer to all your ‘but
                        why do we need this’ thoughts.
                      </li>
                      <li>
                        <strong>Mera project special hai</strong>: the answer to
                        why a given item also applies to your unique situation.
                      </li>
                      <li>
                        <strong>Imtehan</strong>: a quiz based on a real Tintash
                        project to help think through the item you are looking
                        at.
                      </li>
                      <li>
                        <strong>Client ke dil se</strong>: a reminder to help
                        you put yourself in the client’s show at that given
                        point.
                      </li>
                      <p>
                        Look for the badge icon&nbsp;
                        <img className="img-fluid" src={Badge} alt="badge" />
                        &nbsp;to find it.
                      </p>
                    </ul>
                  </li>
                </ol>
              </p>
            </div>
            <div className={"mobile-hidden col-3"}>
              <img
                className={"img-fluid"}
                src={image1}
                width={300}
                alt="process"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
