import React from "react";
import steps from "../../../../assets/icons/steps.svg";
export const ReleaseSteps = () => {
  return (
    <div className="row">
      <div className="col-2"></div>

      <div className="col-5">
        <div className="mobile-flex">
          <img className="waterfall-chart" src={steps} />
          <h1 className="processHeading2 develop-heading ml-24">Steps</h1>
        </div>
        <div className="lead-content col-8">
          <ul>
            <li>QA driving this sprint</li>
            <li>No feature development</li>
            <li>Edge cases</li>
            <li>QA will make a report, all edge cases are covered</li>
            <li>Green light to the dev team and the PM</li>
            <li>
              While they are doing that, the engineering team is setting up any
              additional infrastructure that is needed for this production
              readiness
            </li>
            <li>Deployment Report (incl. minutes from DPM, section on test)</li>
          </ul>
        </div>
      </div>
    </div>
  );
};
