import React, { useEffect, useState } from "react";
import UpdateDialog from "../../../../../components/Dialog/UpdateDialog";
import { getAllUpdates } from "../../../../../redux/actions/dailyUpdate";
import { useDispatch, useSelector } from "react-redux";
import { getFormattedTime } from "../../../../../utilities/dateUtilities";
import "./updateList.scss";
import { useParams } from "react-router-dom";
import Circle from "../../../../../assets/icons/hollowCircle.svg";

export const UpdateList = () => {
  const dispatch = useDispatch();
  const [updateDetailModal, setUpdateDetailModal] = useState(null);
  const [updateData, setUpdateData] = useState(null);
  const allUpdates = useSelector((state) => state.update.allUpdates);
  const { projectId, phaseId, processId } = useParams();

  useEffect(() => {
    dispatch(getAllUpdates(projectId, phaseId, processId));
  }, [projectId]);

  const handleReviewComment = (updateDetails) => {
    setUpdateDetailModal(true);
    setUpdateData(updateDetails);
  };

  return (
    <>
      <UpdateDialog
        visible={updateDetailModal}
        hide={() => setUpdateDetailModal(false)}
        updateDetails={updateData}
        onDone={() => {
          setUpdateDetailModal(false);
        }}
      />
      {allUpdates && allUpdates.length ? (
        allUpdates
          .slice(0)
          .reverse()
          .map((weeklyUpdateCardItem, index) => (
            <div className="row" key={`update-details-card-${index}`}>
              <div
                className="guideline"
                onClick={() => handleReviewComment(weeklyUpdateCardItem)}
              >
                <div
                  key={`weekly-update-card-${index}`}
                  className="flex justify-start align-center mb-24"
                >
                  <div className="guidelineDate">
                    <div>
                      <img src={Circle} />
                    </div>
                    <div className="ml-24">
                      <div>
                        {getFormattedTime(
                          weeklyUpdateCardItem.createdAt
                        ).toUpperCase()}
                      </div>
                      <div className="guideline-name">
                        {weeklyUpdateCardItem.text}
                      </div>
                    </div>
                  </div>

                  <div className="guidelineDescContainer">
                    <div className="guidelineContent">See Details</div>
                  </div>
                </div>
              </div>
            </div>
          ))
      ) : (
        <></>
      )}
    </>
  );
};
