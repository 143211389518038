import React from "react";
import PropTypes from "prop-types";

export const ApplyingPrinciples = (props) => {
  const { principleone, principletwo, principlethree } = props;
  return (
    <div className="principlesContainer">
      <div>
        <div id="principles-row" className="row">
          <div className="col-2 whitespace"></div>
          <div className="width-90 col-6">
            <div className="row">
              <h1 className="processHeading2 ml-0">Applying the Principles</h1>
            </div>
            <div className="col-12">
              <div>
                <div className="row mt-24">
                  <div className="col-1 mobile-hidden">
                    <img
                      className="img-fluid"
                      src={principleone}
                      alt="principle"
                    />
                  </div>
                  <div className="  col-10">
                    <div className="mobile-flex">
                      <img
                        className="desktop-hidden img-fluid"
                        src={principleone}
                        alt="principle"
                      />
                      <h1 className="processHeading4">Delight the Customer</h1>
                    </div>
                    <ul className="processRunning">
                      <li className="mt-0">
                        You respond and follow up with an understandable offer
                        without delays
                      </li>
                      <li className="mt-0">
                        You show interest in their product and have expert
                        advice related to their needs.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div>
                <div className="row mt-24">
                  <div className="col-1 mobile-hidden">
                    <img
                      className="img-fluid"
                      src={principletwo}
                      alt="principle"
                    />
                  </div>
                  <div className="  col-10">
                    <div className="mobile-flex">
                      <img
                        className="desktop-hidden img-fluid"
                        src={principletwo}
                        alt="principle"
                      />
                      <h1 className="processHeading4">
                        Good working environment for the team
                      </h1>
                    </div>

                    <ul className="processRunning">
                      <li className="mt-0">
                        BD knows who and how to consult and gets timely and
                        appropriate information
                      </li>
                      <li className="mt-0">
                        PMs feel heard and taken seriously
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div>
                <div className="row mt-24">
                  <div className="col-1 mobile-hidden">
                    <img
                      className="img-fluid"
                      src={principlethree}
                      alt="principle"
                    />
                  </div>
                  <div className="  col-10">
                    <div className="mobile-flex">
                      <img
                        className="desktop-hidden img-fluid"
                        src={principlethree}
                        alt="principle"
                      />

                      <h1 className="processHeading4">
                        Profitability for the company
                      </h1>
                    </div>

                    <ul className="processRunning">
                      <li className="mt-0">Lead is qualified appropriately</li>
                      <li className="mt-0">
                        Conversion effort is based on realistic possibility to
                        deliver
                      </li>
                      <li className="mt-0">
                        The high-level estimate is informed by technical experts
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
ApplyingPrinciples.propTypes = {
  principleone: PropTypes.string,
  principletwo: PropTypes.string,
  principlethree: PropTypes.string,
};
