import React from "react";
import { useState } from "react";

import Intro from "./components/Intro";
import WhereData from "./components/WhereData";
import Principles from "./components/Principles";
import Badge from "../../../assets/images/badge.svg";
import nexttolead from "../../../assets/images/Lead Conversion/nexttoleadconversion.png";

import arrow from "../../../assets/images/arrow.svg";
import principleone from "../../../assets/images/Discovery/principle-one.png";
import principletwo from "../../../assets/images/Discovery/principle-two.png";
import principlethree from "../../../assets/images/Discovery/principle-three.png";
import graph from "../../../assets/images/Lead Conversion/review.png";
import ReviewEstimates from "./components/ReviewEstimates";
import EstimateColors from "./components/EstimateColors";
import HandoverMeeting from "./components/HandoverMeeting";
import ConversionRetro from "./components/ConversionRetro";
import Checklist from "./components/CheckList";
import styles from "./leadconversion.module.css";

function LeadConversion() {
  const WHEREDATA = [
    {
      name: "Provide an Estimate",
      dos: [{ name: "Review of estimates", ref: "estimatesContainer" }],
      uses: [
        "Lead conversion checklist or Discovery checklist (if no discovery for the project)",
      ],
    },
    {
      name: "Handing over to PM",
      dos: [{ name: "Hand over to PM meeting", ref: "handoverContainer" }],
      uses: [],
    },
    {
      name: "Looking Back",
      dos: [{ name: "Conversion retro", ref: "retroContainer" }],
      uses: [],
    },
  ];
  const [activeWhere, setActiveWhere] = useState(WHEREDATA[0]);

  return (
    <div className={styles.processContainer}>
      <div className={"processParentContainer"}>
        <Intro nexttolead={nexttolead} />
        <WhereData
          wheredata={WHEREDATA}
          activeWhere={activeWhere}
          arrow={arrow}
          setActiveWhere={setActiveWhere}
        />

        <Principles
          principleone={principleone}
          principletwo={principletwo}
          principlethree={principlethree}
        />

        <ReviewEstimates badge={Badge} />
        <EstimateColors badge={Badge} graph={graph} />
        <HandoverMeeting />
        <ConversionRetro />
        <Checklist />
      </div>
    </div>
  );
}

export default LeadConversion;
