import React from "react";
import { ConfirmModal } from "./dialog";
import "./ErrorDialog.scss";
import { Button } from "../Button/Button";

export const ErrorDialog = ({
  title = "Are you sure ?",
  showDialog,
  setShowDialog,
  isDisabled = false,
  resetError,
}) => {
  const handleConfirm = async () => {
    setShowDialog(false);
    resetError();
  };
  const handleCancel = async () => {
    setShowDialog(false);
    resetError();
  };
  return (
    <ConfirmModal visible={showDialog} onCancel={() => handleCancel()}>
      <div className="error-dialog-content">
        <div className="error-dialog-title">{title}</div>
        <div className="action-buttons-wrapper">
          <Button
            className="btn-danger-custom"
            disabled={isDisabled}
            onClick={() => handleConfirm()}
          >
            Got it
          </Button>
        </div>
      </div>
    </ConfirmModal>
  );
};
