import React from "react";
import PropTypes from "prop-types";

const HamburgerDropdown = ({ menuItems }) => {
  return (
    <div className="hamburgerMenuContainerMainPage">
      {menuItems.map(({ text, url, icon }) => (
        <HamburgerDropdownItem key={text} text={text} url={url} icon={icon} />
      ))}
    </div>
  );
};

const HamburgerDropdownItem = ({ text, url, icon }) => {
  return (
    <div key={text} className="menuItemMainPageContainer">
      <a className="menuItemMainPage" href={url}>
        {text}
        <img
          src={icon}
          width={35}
          height={35}
          className="menuItemMainPage ml-16"
          alt="guidance"
        />
      </a>
    </div>
  );
};

HamburgerDropdown.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.exact({
      text: PropTypes.string,
      url: PropTypes.string,
    })
  ).isRequired,
};

export default HamburgerDropdown;
