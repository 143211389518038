import React from "react";
import Pop from "../../Popup/popup";
import Badge from "../../../assets/images/badge.svg";
import BADGES_CONTENT from "./BadgesContent";

function WhatIs(props) {
  return (
    <div className={"whatContainer"}>
      <h1 className={"processHeading2"}>What is Discovery</h1>
      <div className={"row"}>
        <div className={"col-7"}>
          <p className={"processRunning"}>
            Software development often runs into the same set of problems across
            sectors, project types and companies. Difficulty visualising a
            product before it’s seen in action, miscommunication, mismatched
            expectations, and inaccurate cost and time estimates.
            <br />
            <br />
            Agile methodologies, by themselves or combined with waterfall
            practices, help solve many of the problems. However, a key challenge
            remains. Clients and development teams are keen to get started with
            the building of the product. This is understandable, making the
            product come to life is the most exciting part.
            <br />
            <br />
            When pushed, understanding intellectually the importance of
            dedicated time for design is easy, practicing it in real life is
            difficult for both sides. There is no easy solution. Governments in
            Scandinavia, for example, have started providing funds to
            incentivise start-ups to remain in the design phase for a few months
            before beginning development or even fundraising. Start-ups with
            development plans do not qualify for these funds. Design determines
            budgets and timelines!
            <br />
            <br />
            Design thinking is the method of choice (see Annex x for a primer)
            in these times
            <br />
            <br />
            Tintash discovery is design thinking ++. It combines design thinking
            with elements of service design and adds a full-on tech component on
            top. This is the premium version of design, and we should be proud
            to present it as such.
            <Pop
              src={Badge}
              heading="Buzurg kehte hain"
              dataone={BADGES_CONTENT.DISCOVERY_1}
              datatwo={BADGES_CONTENT.DISCOVERY_2}
              datathree={BADGES_CONTENT.DISCOVERY_3}
            />
          </p>
        </div>
        <div className={"col-5"}>
          <img className={"whatImg img-fluid"} src={props.whatis} alt="what" />
        </div>
      </div>
      <div className={"whatInvContainer"}>
        <h1 className={"processHeading4"}>Who is involved</h1>
        <ul>
          <li>Project Manager to lead the effort + budgeting</li>
          <li>UX team for design thinking + service design</li>
          <li>
            Engineers for input to service design + design of system
            architecture
          </li>
          <li>UI for style guide and a sample of screens</li>
        </ul>
      </div>
    </div>
  );
}

export default WhatIs;
