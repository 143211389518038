import React, { useRef, useEffect } from "react";
import { useSelector } from "react-redux";

import "./guideline-files.scss";

import LeadConversionGuidelines from "../../../../components/Processes/LeadConversion/components/LeadConversionGuidelines";
import DiscoveryGuidelines from "../../../../components/Processes/Discovery/DiscoveryGuidelines";
import DevelopmentGuidlines from "../../../../components/Processes/Development/DevelopmentGuidlines";
import PolishingGuidelines from "../../../../components/Processes/Polishing/PolishingGuidelines";
import ReleaseGuidelines from "../../../../components/Processes/Release/ReleaseGuidelines";

function GuidelineFiles(props) {
  const currentPhaseName = useSelector((state) => state.phase.name);
  const { checkListValue, close } = props;
  const ref = useRef(null);
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      close && close();
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);
  return (
    <>
      <div
        ref={ref}
        className="guidelines-section-wrapper container pr-24 pl-24"
      >
        <div className="close-button-container flex mt-70">
          <div onClick={props.close} className="mobile-hidden closeButton">
            Close
          </div>
          <div
            onClick={props.close}
            className="desktop-hidden closeButton mt-24"
          ></div>
        </div>
        {currentPhaseName === "Lead Conversion" ? (
          <>
            <LeadConversionGuidelines checkListValue={checkListValue} />
          </>
        ) : currentPhaseName === "Discovery" ? (
          <>
            <DiscoveryGuidelines checkListValue={checkListValue} />
          </>
        ) : currentPhaseName === "Development" ? (
          <>
            <DevelopmentGuidlines checkListValue={checkListValue} />
          </>
        ) : currentPhaseName === "Polishing" ? (
          <>
            <PolishingGuidelines checkListValue={checkListValue} />
          </>
        ) : currentPhaseName === "Release" ? (
          <>
            <ReleaseGuidelines checkListValue={checkListValue} />
          </>
        ) : (
          <div>No GuideLines</div>
        )}
      </div>
    </>
  );
}

export default GuidelineFiles;
