import React from "react";
import { UpdateList } from "../../../../../UpdateSection/update-list/UpdateList";
import { ProvideEstimateUpdateForm } from "./ProvideUpdateForm/ProvideUpdateForm";
import { ProjectArtifacts } from "../../../../../ProjectArtifactsSection/ProjectArtifacts";
import "../../../DiscoveryUpdateSection.scss";

export const ProvideEstimateUpdateSection = () => {
  return (
    <div className="project-update-details-wrapper">
      <div className="update-artifacts-wrapper">
        <div className="form-update-wrapper">
          <ProvideEstimateUpdateForm />
        </div>
        <div className="project-artifacts">
          <ProjectArtifacts />
        </div>
      </div>
      <div className="update-list-wrapper">
        <div className="side-update-title mt-32 ml-24">
          Project Management Events
        </div>
        <UpdateList />
      </div>
    </div>
  );
};
