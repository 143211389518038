import axios from "axios";
import { ENV_CONSTANTS } from "../constants/globalConstants";
const baseURL = ENV_CONSTANTS.SERVER_URL;

const getConfig = () => ({
  headers: {
    Authorization: `Bearer ${
      JSON.parse(localStorage.getItem("userObj"))?.accessToken
    }`,
  },
});

export const deleteFile = async (docID) => {
  try {
    const response = await axios.delete(
      `${baseURL}/output/delete-file/${docID}`,
      getConfig()
    );
    return response.data;
  } catch (error) {
    throw new Error("Delete File Error");
  }
};
