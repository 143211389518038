import { useEffect } from "react";

const useClickOutsideTooltipDetector = (ref, setIsTooltipOpen) => {
  useEffect(() => {
    function handleClickOutside(e) {
      if (ref.current && !ref.current.contains(e.target)) {
        setIsTooltipOpen(false);
      }
    }
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [ref]);
};

export default useClickOutsideTooltipDetector;
