import "font-awesome/css/font-awesome.min.css";

import React, { useState, useEffect, useRef } from "react";

import { DO_CONSTANTS } from "../../../../constants/doConstants";
import RightArrow from "../../../RightArrow/RightArrow";

const dos_width = {
  0: { margin: 2, width: 8 },
  1: { margin: 5, width: 5 },
  2: { margin: 8, width: 2 },
};

export const scrollToClickedButton = (e, maxScreenWidth) => {
  let MAX_SCREEN_WIDTH = 450;

  if (maxScreenWidth) MAX_SCREEN_WIDTH = maxScreenWidth;

  if (screen.width <= MAX_SCREEN_WIDTH) {
    const btnId = e.target.dataset.buttonId;

    if (btnId) {
      const btnClicked = document.querySelector(
        `div[data-button-id="${btnId}"]`
      );

      btnClicked.scrollIntoView({
        behavior: "smooth",
        inline: "center",
        block: "nearest",
      });
    }
  }
};

export function WhatHappensData(props) {
  const whatHappensButtonContainerRef = useRef(null);
  const [processIndex, setProcessIndex] = useState(0);
  const [dosWidth, setDosWidth] = useState(dos_width);
  const { activeWhere, wheredata, setActiveWhere, onChangeValue, dataId } =
    props;

  useEffect(() => {
    if (wheredata.length < 2) {
      const _dosWidth = { ...dosWidth };
      _dosWidth[0] = { margin: 5, width: 2 };
      setDosWidth(_dosWidth);
    } else {
      setDosWidth(dos_width);
    }
  }, [wheredata?.length]);

  const whatHappenStateHandler = (val) => {
    const guideLineDisplay = DO_CONSTANTS.find(
      (data) => (val.name || val) === data
    );

    if (guideLineDisplay) {
      return true;
    } else {
      return false;
    }
  };

  const setActive = (whereData, index) => {
    setProcessIndex(index);
    setActiveWhere(whereData);
  };

  return (
    <>
      <div className="row" id="linkWhatHappen">
        <div className={`col-${dosWidth[0].margin}`}></div>
        <div
          id="what-happens-buttons-container"
          className={`col-${dosWidth[0].width} flex justify-center mt-48 mb-8`}
          data-id={dataId}
          ref={whatHappensButtonContainerRef}
        >
          {wheredata.map((where, index) => (
            <>
              <div
                className={
                  where["name"] === activeWhere["name"]
                    ? "button-state-active"
                    : "button-state"
                }
                data-button-id={index}
                onClick={(e) => {
                  setActive(wheredata[index], index);
                  scrollToClickedButton(e);
                }}
              >
                <span className="content" data-button-id={index}>
                  {where["name"]}
                </span>
              </div>
              {index < wheredata.length - 1 ? <RightArrow /> : ""}
            </>
          ))}
        </div>
        <div className="col-2 whitespace"></div>
      </div>
      <div className="dos mt-32 row">
        <div
          className={`whitespace col-${dosWidth[processIndex].margin}`}
        ></div>
        <div id="dos-parent-container" className={`col-2`}>
          <div id="dos-container" className="dos-container">
            <div>
              <b>Do:</b>
              <ul>
                {activeWhere["dos"].map((dos, index) => {
                  return (
                    <li
                      className={`dos-points ${
                        whatHappenStateHandler(dos, index)
                          ? "dos-points-guideline"
                          : ""
                      }`}
                      key={index}
                      onClick={onChangeValue}
                    >
                      {dos.name}
                    </li>
                  );
                })}
              </ul>
            </div>
            <div>
              <b>Use:</b>
              {activeWhere["uses"].length > 0 ? (
                <>
                  <ul>
                    {activeWhere["uses"].map((use, index) => {
                      return (
                        <li
                          key={index}
                          className={`dos-points ${
                            whatHappenStateHandler(use, index)
                              ? "dos-points-guideline"
                              : ""
                          } ${use.url ? "dos-points-guideline" : ""}`}
                          onClick={onChangeValue}
                        >
                          {use.url ? (
                            <a href={use.url} target="_blank" rel="noreferrer">
                              {use.name}
                            </a>
                          ) : null}
                          {!use.url ? use.name : null}
                        </li>
                      );
                    })}
                  </ul>
                </>
              ) : (
                <div className="empty-use">-</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
