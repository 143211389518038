import React, { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";

import DogFooding from "./DogFooding";

import prepTimeIcon from "../../../assets/icons/prepTimeIcon.svg";
import runTimeIcon from "../../../assets/icons/runTimeIcon.svg";
import followUpTimeIcon from "../../../assets/icons/followUpTimeIcon.svg";
import peopleIcon from "../../../assets/icons/peopleIcon.svg";

function PolishingGuidelines(props) {
  const location = useLocation();
  const { close } = props;
  const ref = useRef(null);
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      close && close();
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);
  return (
    <div>
      {location.pathname.includes("dog-fooding") ? (
        <DogFooding
          guidanceone={prepTimeIcon}
          guidancetwo={runTimeIcon}
          guidancethree={followUpTimeIcon}
          guidancefour={peopleIcon}
        />
      ) : null}
    </div>
  );
}

export default PolishingGuidelines;
