import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import "../../../../components/Navbar/navbar.scss";
import "../../../../components/MainPageNav/MainPageNav.scss";
import WebProducts from "./WebProducts";
import MobileProducts from "./MobileProducts";
import { scrollToClickedButton } from "../../../../components/Processes/LeadConversion/components/WhatHappensData";

const UNDERLINE_CLASS = "releaseChecklistActive";
const checkList = {
  webProducts: "webProducts",
  mobileProducts: "mobileProducts",
};

const maxScreenWidth = 9999;

function ReleaseChecklist(props) {
  const { styles } = props;
  const [selected, setSelected] = useState("");
  const onWebProductsClick = (e) => {
    setSelected(checkList.webProducts);
    scrollToClickedButton(e, maxScreenWidth);
  };

  useEffect(() => {
    setSelected(checkList.webProducts);
  }, []);
  const onMobileProductsClick = (e) => {
    setSelected(checkList.mobileProducts);
    scrollToClickedButton(e, maxScreenWidth);
  };

  return (
    <div id="release-checklist" className="guidancesContainer processRunning">
      <div className={styles.guidanceMainHeadingContainer}>
        <h1 className="processHeading2">Release Checklist</h1>
      </div>
      <br />
      <header>
        <div className="release-header-list-wrapper">
          <div className="release-header-main-items-wrapper">
            <div className="flex release-workplan-navbar-item-wrapper">
              <div
                className={`${
                  selected === checkList.webProducts
                    ? UNDERLINE_CLASS
                    : "releaseChecklist"
                }`}
                onClick={onWebProductsClick}
                data-button-id="btnOne"
              >
                Web Products
              </div>
              <div
                className={`${
                  selected === checkList.mobileProducts
                    ? UNDERLINE_CLASS
                    : "releaseChecklist"
                }`}
                onClick={onMobileProductsClick}
                data-button-id="btnTwo"
              >
                Mobile Products
              </div>
            </div>
            <div className="col-12">
              <hr />
            </div>
          </div>
        </div>
      </header>

      {selected === checkList.webProducts ? (
        <WebProducts />
      ) : (
        <MobileProducts />
      )}
    </div>
  );
}

ReleaseChecklist.protoTypes = {
  styles: PropTypes.any,
};

export default ReleaseChecklist;
