import React from "react";
import Popup from "reactjs-popup";
import "./popup.css";

function Pop(props) {
  return (
    <Popup
      trigger={<img src={props.src} className="badgeIcon" />}
      position="left center"
    >
      <div>
        {props.heading != null ? (
          <p className="Heading">{props.heading}</p>
        ) : (
          " "
        )}
        {props.dataone != null ? <p className="data">{props.dataone}</p> : ""}
        {props.datatwo != null ? <p className="data">{props.datatwo}</p> : ""}
        {props.datathree != null ? (
          <p className="data">{props.datathree}</p>
        ) : (
          ""
        )}
        {props.datafour != null ? <p className="data">{props.datafour}</p> : ""}
        {props.datafive != null ? <p className="data">{props.datafive}</p> : ""}
        {props.headingOne != null ? (
          <p className="Heading">{props.headingOne}</p>
        ) : (
          " "
        )}
        {props.headingOneData != null ? (
          <p className="data">{props.headingOneData}</p>
        ) : (
          ""
        )}
        {props.headingTwo != null ? (
          <p className="Heading">{props.headingTwo}</p>
        ) : (
          " "
        )}
        {props.headingTwoData != null ? (
          <p className="data">{props.headingTwoData}</p>
        ) : (
          ""
        )}
      </div>
    </Popup>
  );
}
export default Pop;
