import React, { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";

import TeamCharterMeet from "./Requirements/TeamCharterMeet";
import SprintZeroFive from "./Requirements/SprintZeroFive";
import ReleasePlanning from "./Requirements/ReleasePlanning";
import DevKickOff from "./Requirements/DevKickOff";
import DailyStandup from "./Requirements/DailyStandup";
import UpdateMeetings from "./Requirements/UpdateMeetings";
import MilestoneDemo from "./Requirements/MilestoneDemo";

import prepTimeIcon from "../../../assets/icons/prepTimeIcon.svg";
import runTimeIcon from "../../../assets/icons/runTimeIcon.svg";
import followUpTimeIcon from "../../../assets/icons/followUpTimeIcon.svg";
import peopleIcon from "../../../assets/icons/peopleIcon.svg";
import teamCharterImg from "../../../assets/images/Development/teamCharterImg.png";
import sprintImg from "../../../assets/images/Development/sprintImg.png";
import releasePlanningImg from "../../../assets/images/Development/releasePlanningImg.png";
import devKickOffImg from "../../../assets/images/Development/devKickOffImg.png";
import dailyStandupImg from "../../../assets/images/Development/dailyStandupImg.png";
import updateMeetingsImg from "../../../assets/images/Development/updateMeetingsImg.png";
import milestoneDemoImg from "../../../assets/images/Development/milestoneDemoImg.png";
import Dogfooding from "../Polishing/DogFooding";

import styles from "./development.module.css";

function DevelopmentGuidlines(props) {
  const location = useLocation();
  const { checkListValue, close } = props;
  const ref = useRef(null);
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      close && close();
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);
  return (
    <div>
      {location.pathname.includes("beginning-development") ? (
        <>
          {checkListValue === "teamCharterMeet" ? (
            <TeamCharterMeet
              reqImg={teamCharterImg}
              styles={styles}
              guidanceone={prepTimeIcon}
              guidancetwo={runTimeIcon}
              guidancethree={followUpTimeIcon}
              guidancefour={peopleIcon}
            />
          ) : checkListValue === "sprintZero" ? (
            <SprintZeroFive reqImg={sprintImg} styles={styles} />
          ) : null}
        </>
      ) : location.pathname.includes("during-development") ? (
        <>
          {checkListValue === "releasePlanningMeeting" ? (
            <ReleasePlanning
              reqImg={releasePlanningImg}
              styles={styles}
              guidanceone={prepTimeIcon}
              guidancetwo={runTimeIcon}
              guidancethree={followUpTimeIcon}
              guidancefour={peopleIcon}
            />
          ) : checkListValue === "devKickoffMeeting" ? (
            <DevKickOff
              reqImg={devKickOffImg}
              styles={styles}
              guidanceone={prepTimeIcon}
              guidancetwo={runTimeIcon}
              guidancethree={followUpTimeIcon}
              guidancefour={peopleIcon}
            />
          ) : checkListValue === "syncUp" ? (
            <DailyStandup
              reqImg={dailyStandupImg}
              styles={styles}
              guidanceone={prepTimeIcon}
              guidancetwo={runTimeIcon}
              guidancethree={followUpTimeIcon}
              guidancefour={peopleIcon}
            />
          ) : checkListValue === "clientUpdate" ? (
            <UpdateMeetings
              reqImg={updateMeetingsImg}
              styles={styles}
              guidanceone={prepTimeIcon}
              guidancetwo={runTimeIcon}
              guidancethree={followUpTimeIcon}
              guidancefour={peopleIcon}
            />
          ) : checkListValue === "dogFooding" ? (
            <Dogfooding
              guidanceone={prepTimeIcon}
              guidancetwo={runTimeIcon}
              guidancethree={followUpTimeIcon}
              guidancefour={peopleIcon}
            />
          ) : checkListValue === "milestoneDemo" ? (
            <MilestoneDemo
              styles={styles}
              reqImg={milestoneDemoImg}
              guidanceone={prepTimeIcon}
              guidancetwo={runTimeIcon}
              guidancethree={followUpTimeIcon}
              guidancefour={peopleIcon}
            />
          ) : null}
        </>
      ) : location.pathname.includes("completing-development") ? (
        <>
          {checkListValue === "finalDemo" ? (
            <MilestoneDemo
              styles={styles}
              reqImg={milestoneDemoImg}
              guidanceone={prepTimeIcon}
              guidancetwo={runTimeIcon}
              guidancethree={followUpTimeIcon}
              guidancefour={peopleIcon}
            />
          ) : null}
        </>
      ) : (
        <div>No GuideLines</div>
      )}
    </div>
  );
}

export default DevelopmentGuidlines;
