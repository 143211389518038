import { message } from "antd";
import axios from "axios";
import { ENV_CONSTANTS } from "../constants/globalConstants";
const baseURL = ENV_CONSTANTS.SERVER_URL;

const getConfig = () => ({
  headers: {
    Authorization: `Bearer ${
      JSON.parse(localStorage.getItem("userObj"))?.accessToken
    }`,
  },
});

export const createProject = async (values) => {
  try {
    const response = await axios.post(
      `${baseURL}/project/`,
      {
        name: values.project_name.trim(),
        PM: values.project_managers,
        reportingPM: values.reporting_manager,
        accountManager: values.account_manager,
        client: values.client,
        billable: values.billable,
        startingPhase: values.starting_phase.value,
        internal: values.internal,
        startDate: values.start_date,
        endDate: values.end_date,
        comment: null,
        projectType: values.project_type,
        projectCategory: values.category.value,
      },
      getConfig()
    );
    return response.data;
  } catch (error) {
    const { response: { data: { message } = {} } = {} } = error;
    throw new Error(message || "Error creating project");
  }
};
export const updateProject = async (values, _id) => {
  try {
    const response = await axios.put(
      `${baseURL}/project/${_id}`,
      {
        name: values.project_name.trim(),
        PM: values.project_managers,
        reportingPM: values.reporting_manager,
        accountManager: values.account_manager,
        client: values.client,
        billable: values.billable,
        internal: values.internal,
        startDate: values.start_date,
        endDate: values.end_date,
        comment: null,
        projectType: values.project_type,
      },
      getConfig()
    );
    return response.data;
  } catch (error) {
    const { response: { data: { message } = {} } = {} } = error;
    throw new Error(message || "Error creating project");
  }
};

export const deleteProj = async (values) => {
  const { _id } = values;
  try {
    const response = await axios.delete(
      `${baseURL}/project/${_id}`,
      getConfig()
    );
    console.log("response--", response);
    return response;
  } catch (error) {
    const { response: { data: { message } = {} } = {} } = error;
    throw new Error(message || "Error creating project");
  }
};
export const userLogin = async (userEmail, userPassword) => {
  try {
    console.log("email, pass", userEmail, " ", userPassword);
    axios
      .post(`${baseURL}/user/login/`, {
        email: userEmail,
        password: userPassword,
      })
      .then((response) => {
        console.log("response.data inside", response.data);
        localStorage.setItem("userObj", JSON.stringify(response.data));
        window.location.reload();
      })
      .catch((error) => {
        localStorage.setItem("userObj", JSON.stringify(error.response.data));
        window.location.reload();
      });
    // return response.data;
  } catch (error) {
    //throw new Error("Error Get Output files");
    console.log("error Login");
  }
};
export const addOutputFile = async (
  projectID,
  stageID,
  subStageID,
  fileName,
  fileUrl
) => {
  try {
    const response = await axios.post(
      `${baseURL}/output/`,

      {
        file: {
          name: fileName,
          url: fileUrl,
        },
        project: projectID,
        phase: stageID,
        process: subStageID,
      },
      getConfig()
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
message.config({
  duration: 2,
  maxCount: 1,
});
export const AddFeedback = async (
  projectId,
  userRanking,
  userRole,
  comments
) => {
  try {
    const response = await axios.post(
      `${baseURL}/feedback/`,
      {
        ranking: userRanking,
        project: projectId,
        role: userRole,
        comments,
      },
      getConfig()
    );
    message.success("Feedback Added Successfully");
    return response.data;
  } catch (error) {
    message.error("Error Adding Feedback");
    console.log("AddFeedBack error");
  }
};
message.config({
  duration: 2,
  maxCount: 1,
});
export const signUp = async ({ name, email, role }) => {
  try {
    const response = await axios.post(`${baseURL}/user/signup/`, {
      name,
      email,
      role,
    });
    message.success("User Added Successfully");
    return response.data;
  } catch (error) {
    message.error("User Already Exists");
    throw new Error("Error Sign Up");
  }
};

export const GoogleSignIn = async (loginToken) => {
  try {
    return axios
      .post(`${baseURL}/google-authentication/`, {
        token: loginToken,
      })
      .then((response) => {
        localStorage.setItem("userObj", JSON.stringify(response.data));
        // window.location.reload();
        console.log("token : ", response.data.accessToken);
        return response;
      })
      .catch((error) => {
        localStorage.setItem("userObj", JSON.stringify(error.response.data));
      });
  } catch (error) {
    throw new Error("Google Sign In");
  }
};
