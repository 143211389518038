import React from "react";

const Icon = (props) => {
  const { processState } = props;
  return (
    <div>
      <img src={processState} className="h-30 w-30" alt="spinner" />
    </div>
  );
};
export default Icon;
