import {
  GET_PROJECT_DETAIL,
  GET_PROJECT_STATUS,
  GET_PROJECT_STATUS_SUCCESS,
  GET_PROJECT_DETAIL_SUCCESS,
  GET_PROJECT_STATUS_ERROR,
  GET_PROJECT_DETAIL_ERROR,
  SET_SUBSTAGE,
  SET_PHASE,
} from "../actions/types";

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: null,
  //   pagination: {
  //     count: 0,
  //     next: null,
  //     prev: null,
  //   },
};

export const projectReducer = (
  state = INITIAL_STATE,
  action = { type: "" }
) => {
  switch (action.type) {
    case GET_PROJECT_DETAIL:
      return { ...state, loading: true };
    case GET_PROJECT_DETAIL_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: action.loading,
      };
    case GET_PROJECT_DETAIL_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
export const projectDetailReducer = (
  state = INITIAL_STATE,
  action = { type: "" }
) => {
  switch (action.type) {
    case GET_PROJECT_STATUS:
      return { ...state, loading: true };
    case GET_PROJECT_STATUS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: action.loading,
        phases: action.phases,
        projectId: action.currProjectID,
        projectStage: action.currProjectStage,
        startDate: action.startDate,
        endDate: action.endDate,
        status: action.status,
      };
    case GET_PROJECT_STATUS_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
export const subStageReducer = (
  state = INITIAL_STATE,
  action = { type: "" }
) => {
  switch (action.type) {
    case SET_SUBSTAGE:
      return {
        ...state,
        id: action.payload,
        name: action.name,
      };
    default:
      return state;
  }
};

export const phaseReducer = (state = INITIAL_STATE, action = { type: "" }) => {
  switch (action.type) {
    case SET_PHASE:
      return {
        ...state,
        id: action.payload,
        name: action.name,
      };
    default:
      return state;
  }
};
