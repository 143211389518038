import React from "react";
import LinkWithEllipse from "../../../components/LinkWithEllipse/LinkWithEllipse";

function WhatDoesThisDoForMe() {
  return (
    <div className="guidancesContainer">
      <div className="mobile-hidden">
        <LinkWithEllipse title={"What does this do for me"} />
      </div>
      <p id="what-does-this-do-heading" className="desktop-hidden">
        What does this do for me?
      </p>
      <br />
      <br />
      <p className={"processRunning"}>
        <strong>Clients:</strong> <br className="desktop-hidden" /> receive a
        consistent experience from Tintash over time and across individuals /
        functions. Tintash is able to demonstrate high value for money.
        <br />
        <br />
        <strong>Team members:</strong> <br className="desktop-hidden" /> have
        ownership of own role in a project and clear expectations from the
        organisation.
        <br />
        <br />
        <strong>Project managers:</strong>
        <br className="desktop-hidden" /> have clarity on their roles and
        expectations from the organisation and easy access to resources that
        they need.
        <br />
        <br />
        <strong> Tintash:</strong> <br className="desktop-hidden" /> increases
        team efficiency and, therefore, profit margins.
      </p>
    </div>
  );
}

export default WhatDoesThisDoForMe;
