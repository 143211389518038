import React from "react";
function Principles(props) {
  return (
    <div className="principlesContainer">
      <h1 className="processHeading2">Applying the Principles</h1>
      <div className="principles">
        <div className="row">
          <div className="col-12">
            <div className="principle">
              <div className="row">
                <div className="col-1">
                  <img
                    className="img-fluid"
                    src={props.principleone}
                    alt="principle"
                  />
                </div>
                <div className="col-11">
                  <h1 className="processHeading4">Delight the Customer</h1>
                  <ul className="processRunning">
                    <li>
                      You respond and follow up with an understandable offer
                      without delays
                    </li>
                    <li>
                      You show interest in their product and have expert advice
                      related to their needs.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="principle">
              <div className="row">
                <div className="col-1">
                  <img
                    className="img-fluid"
                    src={props.principletwo}
                    alt="principle"
                  />
                </div>
                <div className="col-11">
                  <h1 className="processHeading4">
                    Good working environment for the team
                  </h1>
                  <ul className="processRunning">
                    <li>
                      BD knows who and how to consult and gets timely and
                      appropriate information
                    </li>
                    <li>PMs feel heard and taken seriously</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="principle" style={{ borderBottom: "none" }}>
              <div className="row">
                <div className="col-1">
                  <img
                    className="img-fluid"
                    src={props.principlethree}
                    alt="principle"
                  />
                </div>
                <div className="col-11">
                  <h1 className="processHeading4">
                    Profitability for the company
                  </h1>
                  <ul className="processRunning">
                    <li>Lead is qualified appropriately</li>
                    <li>
                      Conversion effort is based on realistic possibility to
                      deliver
                    </li>
                    <li>
                      The high-level estimate is informed by technical experts
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Principles;
