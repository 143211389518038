import React from "react";
import { Collapse } from "antd";
import PropTypes from "prop-types";

import BADGES_CONTENT from "../../../../components/Processes/Development/BadgesContent";
import Guidance from "../../../../components/Guidance/Guidance";
import victory from "../../../../assets/icons/victory.svg";
import Tooltip from "../../../../components/Tooltip/Tooltip";

const { Panel } = Collapse;

const prepTime = "5mins";
const runTime = "15 mins";
const followUpTime = "0mins";
const people = "5-7";
function DailyStandup(props) {
  const { styles } = props;

  return (
    <div className="guidancesContainer">
      <div className={styles.guidanceMainHeadingContainer}>
        <h2 className="processHeading2" style={{ margin: 0 }}>
          Daily Standups and Syncups
        </h2>
      </div>

      <br />
      <Guidance
        prepTime={prepTime}
        runTime={runTime}
        followUpTime={followUpTime}
        people={people}
      />

      <br />
      <br />
      <div className="guidanceCollapse processRunning">
        <Collapse expandIconPosition={"right"}>
          <Panel
            header="Why the requirement"
            key="1"
            className="processRunning"
          >
            <p>
              Standups and syncups are well known, well used practices for dev
              teams. They are meant to:
            </p>

            <ul>
              <li>Keep everyone on the same page.</li>
              <li>Keep the team informed of important developments.</li>
              <li>Help each other solve challenges.</li>
            </ul>

            <p>
              But are they well understood? For these meetings to be useful,
              they must avoid the standard issues that turn them irrelevant for
              most if not all team members.
            </p>

            <ul>
              <li>Turned into status meetings.</li>
              <li>Turned into problem solving meetings.</li>
              <li>Take too long (an hour sometimes!).</li>
            </ul>

            <p>
              Tintash requirement exists to keep them consistent and help avoid
              these standard challenges.
            </p>
          </Panel>
        </Collapse>
        <br />
        <br />
        <Collapse expandIconPosition={"right"}>
          <Panel
            header="What is the requirement"
            key="2"
            className="processRunning"
          >
            <div className="row">
              <div className="col-12">
                <div className="guidanceReqInnerBox">
                  <b className="mandatory-participants">
                    Mandatory participants:
                  </b>
                  &nbsp;PM, all team members
                </div>
                <p className="processRunning">
                  There is no strict requirement at Tintash regarding the format
                  of these. However, some key characteristics that a PM and the
                  team should follow:
                </p>

                <ul className="processRunning">
                  <li>
                    Brief: ideally between 15 and 20 minutes
                    <Tooltip
                      headingOne="Champions ka tareeqa:"
                      paragraphOne={BADGES_CONTENT.MINUTES}
                    >
                      <img src={victory} alt="" className="badgeIcon" />
                    </Tooltip>
                  </li>
                  <li>
                    What people present should never be a list of things each
                    member has worked on or is working on. Rather, each member
                    must present what they are working on in connection to where
                    they need collaboration/input from others, or challenges to
                    seek advice. To help encourage this, and to tease out the
                    blockages, the recommended questions are:
                    <ul>
                      <li>
                        What made you unhappy yesterday &amp; what will make you
                        unhappy today
                      </li>
                      <li>Thank a team member who made your day</li>
                    </ul>
                  </li>
                  <li>
                    Informal/casual: the tone of the meetings
                    <Tooltip
                      headingOne="Champions ka tareeqa:"
                      paragraphOne={BADGES_CONTENT.MEETINGS}
                      yOffsetMobile="translate-Y-neg-110%"
                    >
                      <img src={victory} alt="" className="badgeIcon" />
                    </Tooltip>
                    should remain informal. A recommended question for the end
                    of the meeting IF there is time:
                    <ul>
                      <li>
                        If you were a support ticket, what would you be:
                        Service, Problem, Incident
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </Panel>
        </Collapse>
      </div>
      <br />
    </div>
  );
}

DailyStandup.protoTypes = {
  styles: PropTypes.any,
};

export default DailyStandup;
