import React from "react";
import { Collapse } from "antd";
import PropTypes from "prop-types";

import BADGES_CONTENT from "../../../../components/Processes/Development/BadgesContent";
import Guidance from "../../../../components/Guidance/Guidance";
import Tooltip from "../../../../components/Tooltip/Tooltip";

const { Panel } = Collapse;

const prepTime = "15 min";
const runTime = "60 min";
const followUpTime = "15-30 min";
const people = "3-5";

function ReleasePlanning(props) {
  const { styles, badges } = props;
  const { victory, elder } = badges;
  return (
    <div className="guidancesContainer Release_Plan">
      <div className={styles.guidanceMainHeadingContainer}>
        <h2
          className="processHeading2"
          id="releaseMeeting"
          style={{ margin: 0 }}
        >
          Release Planning Meeting
        </h2>
      </div>
      <br />

      <Guidance
        prepTime={prepTime}
        runTime={runTime}
        followUpTime={followUpTime}
        people={people}
      />

      <br />
      <br />
      <div className="guidanceCollapse">
        <Collapse expandIconPosition={"right"}>
          <Panel header="Why the requirement" key="1">
            <p className="processRunning">
              This is an internal meeting. Simply put: the earlier you plan the
              release the better the process – both for the team and the client.
              If you wait to plan and prepare for release until the time for
              actual release, you will find that many steps require days, if not
              weeks, of advance prep, that there are issues that should have
              been dealt with differently during development and that you need
              the team to redo things, thus causing frustrations internally and
              disappointments at the client’s end.
            </p>
          </Panel>
        </Collapse>
        <br />
        <br />
        <Collapse expandIconPosition={"right"}>
          <Panel header="What is the requirement" key="2">
            <div className="row">
              <div className="col-12 processRunning">
                <div className="guidanceReqInnerBox">
                  <b>Mandatory participants:</b>&nbsp;PM, team members with a
                  release role, QA
                </div>

                <p>
                  This is an internal meeting. The PM is responsible for
                  drafting and sharing the agenda of the meeting. It can be
                  shared in the body of an email or as a separate attachment to
                  the email. It is important that the PM follows their own style
                  of communication and logical flow. However, the following
                  items should be covered:
                </p>
                <ul>
                  <li>Product and project goals: brief summary</li>
                  <li>
                    Release schedule
                    <Tooltip
                      headingOne="Champions ka tareeqa:"
                      paragraphOne={BADGES_CONTENT.SCHEDULE}
                      direction="right"
                    >
                      <img src={victory} alt="" className="badgeIcon" />
                    </Tooltip>
                    appropriate
                    <Tooltip
                      headingOne="Buzurg kehte hein:"
                      paragraphOne={
                        BADGES_CONTENT.APPROPRIATE_1 +
                        BADGES_CONTENT.APPROPRIATE_2
                      }
                      paragraphTwo={BADGES_CONTENT.APPROPRIATE_3}
                      direction="right"
                    >
                      <img src={elder} alt="" className="badgeIcon" />
                    </Tooltip>
                    for the goals
                  </li>
                  <li>
                    Release preparation: what is needed to make the
                    product/feature/feature set release ready and to deploy it
                    to production, when will it be released, in what increments,
                    who is responsible for what
                  </li>
                  <li>
                    What is the total cost associated with release/deployment?
                    Are there decisions needed from the client.
                  </li>
                </ul>

                <h1 className="processHeading4 italic">Before the Meeting</h1>
                <ul>
                  <li>Send the agenda at least a day in advance</li>
                  <li>
                    Share the Release Checklist with the team for reflection
                    before the meeting
                  </li>
                  <li>Assign a notetaker</li>
                </ul>

                <h1 className="processHeading4 italic">During the Meeting</h1>
                <ul>
                  <li>
                    Refer to the checklist to make sure all items that have
                    implications for the release plan are discussed.
                  </li>
                  <li>Make space for different points of view.</li>
                  <li>
                    Be prepared to prevent the discussion from spiralling out of
                    scope or go in circles without dismissing what people are
                    saying. Extract the main point people are making and repeat
                    that. Phrases: let’s park this and come back to it at the
                    end if we have time, let’s address that at point x, this is
                    an important discussion, I will book time for this during...
                  </li>
                  <li>
                    Show interest when a team member shares a thought or an idea
                    by asking questions about it.
                  </li>
                  <li>
                    If a team member is quiet, encourage them to contribute
                    without putting them on the spot. You can do this by raising
                    something that related to their area of
                    responsibility/expertise.
                  </li>
                </ul>

                <h1 className="processHeading4 italic">After the Meeting</h1>
                <ul>
                  <li>
                    Summarise the notes from the discussion and share with the
                    whole team with clear action items.
                  </li>
                  <li>Update the key milestones in the project workplan</li>
                  <li>
                    Use the meeting conclusion to inform the kick-off meeting
                    prep and presentation to the client.
                  </li>
                </ul>
              </div>
            </div>
          </Panel>
        </Collapse>
      </div>
      <br />
      <br />
    </div>
  );
}

ReleasePlanning.protoTypes = {
  styles: PropTypes.any,
};

export default ReleasePlanning;
