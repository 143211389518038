import { GET_ROLES } from "./GetAllRolesTypes";
const INITIAL_STATE = {
  data: null,
};

const getAllRolesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ROLES:
      return { ...state, data: action.payload };
    default:
      return state;
  }
};

export default getAllRolesReducer;
