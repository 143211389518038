import React from "react";
import PersonAvatarWithName from "../../../components/GuideLines/PersonAvatarWithName/PersonAvatarWithName";

const personName = "Fatima";
function Fatima() {
  return (
    <div className="guidancesContainer">
      <PersonAvatarWithName key={personName} name={personName} />
      <br />
      <br />
      <div className="guidanceReqInnerBox">
        <p className={"processRunning"}>
          <b>Use case</b>: The playbook streamlines the process more uniformly
          across the organisation while being selective in the requirements. It
          keeps humans (both clients and teams) at the centre and helps Fatima
          train her team and mentor her juniors in the fine balance between
          following a process and adapting to the needs of the project, client
          and team.
        </p>
      </div>
      <p className={"processRunning"}>
        Fatima, also an engineer by background, an old timer at Tintash, manages
        a large portfolio of different kinds of projects. She is a 33-year-old
        single woman with a huge appetite for personal and professional growth
        and spends a lot of time learning and reflecting on the human element of
        service delivery. She is structured and highly organised, known in the
        company for her streamlined processes and firm but fair communication.
        <br />
        <br />
        Her <b>pains</b> relate to a growing organisation that is focusing on
        many different changes in processes, sometimes at the risk of losing
        focus on the human element of successful projects and the Tintash
        identity. Her <b>gains </b> come from a natural ability and experience
        with understanding the core needs of a client and choosing the right
        elements of a process for each project.
      </p>
    </div>
  );
}

export default Fatima;
