import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Modal,
  Input,
  Select,
  DatePicker,
  message,
  Empty,
} from "antd";
import styles from "./clientSatisfaction.module.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Table as AntTable, Dropdown, Menu } from "antd";
import { googleLogout } from "@react-oauth/google";
import { UserOutlined, DownOutlined } from "@ant-design/icons";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  getProjectCategory,
  getProjectTypes,
  getUsersByRole,
  getProjectDetails,
  getAccountManagers,
  getProjectClient,
  getAllPhases,
  allTintashMembers,
  getProjectStatus,
} from "../../../helper/getApis";

import {
  createProject,
  deleteProj,
  signUp,
  updateProject,
} from "../../../helper/postApis";

import Graph from "../../../components/Graph/graph";
import { ConfirmationDialog } from "../../../components/Dialog/dialog";

import {
  getProjectsList,
  getStatusProject,
  setCurrentSubStage,
  setCurrentPhase,
} from "../../../redux/actions/projectDetails";
import { setProjectDetail } from "../../../redux/ClientSatisfaction/ClientSatisfactionActions";

import { ROUTES, SUB_ROUTE_KEY } from "../../../routes/routeConstants";
import "./client-satisfaction.scss";
import {
  getPmDetails,
  handleRole,
  getAllRoles,
} from "./clientSatisfactionHelper";
import { logoutUser } from "../../../redux/actions/logout";

const ClientSatisfaction = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const accessToken = useSelector((state) => state.login.accessToken);
  const projectData = useSelector((state) => state.projects.data);
  const loading = useSelector((state) => state.projects.loading);
  const userRole = useSelector((state) => state.login.data.role[0]);
  const userRoleId = userRole?._id;
  const roles = useSelector((state) => state.allRoles.data);
  const projectDetail = useSelector(
    (state) => state.clientSatisfaction.projectDetail
  );

  const currentUserObj = JSON.parse(localStorage.getItem("userObj"));
  const userId = currentUserObj?._id;
  const userRoles = currentUserObj?.role;
  const userCurrentRole = useSelector(
    (state) => state.clientSatisfaction.userRole
  );

  const { Option } = Select;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [projectCategory, setProjectCategory] = useState(null);
  const [projectManagers, setProjectManagers] = useState(null);
  const [superAdmins, setSuperAdmins] = useState(null);
  const [reportingManagers, setReportingManagers] = useState(null);
  const [projectTypes, setProjectTypes] = useState(null);
  const [accountManager, setAccountManager] = useState(null);
  const [projectClient, setProjectClient] = useState(null);
  const [isUserModalVisible, setIsUserModalVisible] = useState(false);
  const [allMembers, setAllMembers] = useState([]);
  const [pmId, setPmId] = useState("");
  const [phases, setPhases] = useState(null);
  const [form] = Form.useForm();
  const [addUserForm] = Form.useForm();
  const [isEditing, setIsEditing] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };

  useEffect(() => {
    dispatch(getProjectsList(config, userRoleId));
    getAllRoles();
    getCategory();
    getTypes();
    getAllTintashMembers();
  }, []);

  useEffect(() => {
    if (!roles) return;
    getSuperAdmins();
    getManagers();
    getAccManagers();
    getClient();
  }, [roles]);

  useEffect(() => {
    if (userCurrentRole) {
      handleRole(userCurrentRole);
      getDetails(userId, userCurrentRole._id);
    } else {
      handleRole(userRoles[0]);
      getDetails("", userRoles[0]._id);
    }
  }, []);

  useEffect(() => {
    if (isModalVisible === false) {
      getDetails("", userRoleId);
    }
  }, [isModalVisible]);

  useEffect(() => {
    const delaySearch = setTimeout(() => {}, 2000);
    return () => clearTimeout(delaySearch);
  }, [projectDetail, pmId]);

  useEffect(() => {
    dispatch(setProjectDetail(projectData));
  }, [projectData]);

  useEffect(() => {
    setReportingManagers(projectManagers);
  }, [projectManagers, superAdmins]);

  const goToProjectDetails = ({
    _id: projectId,
    phase: { _id: phaseId, name: phaseName },
    process: { _id: processId, name: processName },
  }) => {
    dispatch(setCurrentPhase(phaseId, phaseName));
    dispatch(getStatusProject(config, projectId, "1", phaseName));
    dispatch(setCurrentSubStage(processId, processName));
    history.push(
      ROUTES[SUB_ROUTE_KEY[processName]](projectId, phaseId, processId)
    );
  };
  const columns = [
    {
      title: "Project Name",
      render: (value) => (
        <a href="javascript:void(0)" onClick={() => goToProjectDetails(value)}>
          {value?.name}
        </a>
      ),
    },
    {
      title: "PM Name",
      dataIndex: "PM",
      key: "PM",
      render: (value) => value?.name,
    },
    {
      title: "Client",
      dataIndex: "client",
      key: "client",
      render: (value) => value?.name,
    },
    {
      title: "Project Status",
      dataIndex: "phase",
      key: "phase",
      render: (value) => value?.name,
    },
    {
      title: "Billable",
      dataIndex: "billable",
      key: "billable",
      render: (value) => (value ? "Yes" : "No"),
    },
    {
      title: "Internal",
      dataIndex: "internal",
      key: "internal",
      render: (value) => (value ? "Yes" : "No"),
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      render: (value) => moment(value).format("YYYY-MM-DD"),
    },
    {
      title: "Last Updated",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (value) => moment(value).format("YYYY-MM-DD"),
    },
    {
      title: "Action",
      key: "action",
      width: 160,
      fixed: "right",
      hidden: userCurrentRole?.shortForm !== "AD",
      render: (value) => (
        <span className="table-action-buttons">
          <Button
            className="text-button mr-10"
            onClick={() => onEditButtonClick(value)}
          >
            Edit
          </Button>
          <Button
            className="delete-btn mr-10"
            onClick={() => handleDeleteClick(value)}
          >
            Delete
          </Button>
        </span>
      ),
    },
  ].filter((column) => {
    if (column.hidden) {
      return false;
    }
    return true;
  });

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const disableStartDates = (current) => {
    return current && current > moment(endDate).endOf("day");
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };
  const disableEndDates = (current) => {
    return current && current < moment(startDate).startOf("day");
  };

  const handleSeletedPm = (user) => {
    if (user.key === "tmp_key-0") {
      dispatch(setProjectDetail(projectData));
      setPmId("");
    } else {
      getPmDetails(user.key, userRoleId);
      setPmId(user.key);
    }
  };

  const handleProjectCategory = (obj) => {
    getPhases(obj.value);
    form.resetFields(["starting_phase"]);
  };

  const handleConfirmDelete = async (values) => {
    await deleteProj(values)
      .then(() => {
        dispatch(getProjectsList(config, userRoleId));
        message.error("Project Deleted!");
      })
      .catch((e) => {
        console.log("error", e);
      });
  };

  const handleDeleteClick = (values) => {
    ConfirmationDialog({
      title: "Are you sure you want to delete?",
      onConfirm: () => {
        handleConfirmDelete(values);
      },
    });
  };
  const isOpen = async (projectId) => {
    const response = await getProjectStatus(projectId);
    const status = response.status;
    if (status === "closed") {
      return false;
    }
    return true;
  };
  const onEditButtonClick = async (values) => {
    setIsEditing(true);
    const {
      name: project_name,
      PM: { _id: project_managers },
      reportingPM: reporting_manager,
      projectCategory: { name: categoryLabel, _id: categoryValue },
      projectType: { _id: project_type },
      accountManager: { _id: account_manager },
      client: { _id: client },
      billable,
      internal,
      startDate: start_date,
      endDate: end_date,
      startingPhase,
    } = values;
    await getPhases(categoryValue);
    const obj = {
      _id: values._id,
      project_name,
      project_managers,
      reporting_manager,
      category: {
        label: categoryLabel,
        value: categoryValue,
      },
      project_type,
      account_manager,
      client,
      billable,
      internal,
      start_date: moment(start_date),
      end_date: moment(end_date),
      starting_phase: {
        value: startingPhase,
      },
    };
    if (await isOpen(obj._id)) {
      form.setFieldsValue(obj);
      setIsModalVisible(true);
    } else {
      message.error("Project is Closed!");
    }
  };
  const pmData = (
    <Menu onClick={handleSeletedPm}>
      <Menu.Item icon={<UserOutlined />}>All</Menu.Item>
      {projectManagers?.map((obj) => {
        return (
          <Menu.Item key={obj._id} icon={<UserOutlined />}>
            {obj.name}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const projectStatus = (
    <Menu>
      {/*projectDetail?.map((obj) => {
        return (
          <Menu.Item
            key={obj.stage_id}
            icon={<UserOutlined />}
          >
            {obj.stage}
          </Menu.Item>
        );
      })*/}
      <Menu.Item key="menu-item" icon={<UserOutlined />}>
        All
      </Menu.Item>
      {/*<Menu.Item key={"623079836c2cb5f6a309bd13"} icon={<UserOutlined />}>
        Lead Conversion
    </Menu.Item>*/}
    </Menu>
  );

  const getCategory = async () => {
    try {
      const response = await getProjectCategory();
      setProjectCategory(response);
    } catch (error) {
      console.log("Error Fetching Category");
    }
  };
  const getTypes = async () => {
    try {
      const response = await getProjectTypes();
      setProjectTypes(response);
    } catch (error) {
      console.log("Error Fetching Types");
    }
  };

  const getProjectManagerRoleId = async () => {
    return roles?.find((obj) => obj.name === "Project Manager")?._id;
  };
  const getSuperAdminRoleId = async () => {
    return roles?.find((obj) => obj.name === "Super Admin")?._id;
  };

  const getManagers = async () => {
    try {
      const projectManagerRoleId = await getProjectManagerRoleId();
      const response = await getUsersByRole(projectManagerRoleId);
      setProjectManagers(response);
    } catch (error) {
      console.log("Error Fetching Managers");
    }
  };

  const getSuperAdmins = async () => {
    try {
      const superAdminRoleId = await getSuperAdminRoleId();
      const response = await getUsersByRole(superAdminRoleId);
      setSuperAdmins(response);
    } catch (error) {
      console.log("Error Fetching Super Admins");
    }
  };

  const getPhases = async (projectCategoryId) => {
    try {
      const response = await getAllPhases(projectCategoryId);
      setPhases(response);
    } catch (error) {
      console.log("Error Fetching Phases");
    }
  };
  const compareDates = (values) => {
    return moment(values.start_date).isAfter(moment(values.end_date));
  };
  message.config({
    duration: 2,
    maxCount: 1,
  });
  const setStatesNull = () => {
    setStartDate(null);
    setEndDate(null);
    setPhases([]);
    setIsModalVisible(false);
  };
  const callCreateProject = async (values) => {
    try {
      if (isEditing) {
        if (compareDates(values)) {
          return message.error("Start date should be less than end date");
        } else {
          await updateProject(values, values._id);
          message.success("Project Updated!");
        }
      } else {
        await createProject(values);
        message.success("Project Created!");
      }
      dispatch(getProjectsList(config, userRoleId));
      setStatesNull();
      form.resetFields();
    } catch (error) {
      message.error("Project with same name already exists");
      console.log("Error creating Project");
    }
  };

  const AddUser = async (values) => {
    try {
      await signUp(values);
      setIsUserModalVisible(false);
      addUserForm.resetFields();
    } catch (error) {
      console.log("Error Add User");
    }
  };

  const logOutUser = () => {
    history.push(ROUTES.HOME_PAGE);
    googleLogout();
    dispatch(logoutUser());
    localStorage.clear();
  };

  const getDetails = async (userId, userRoleId) => {
    try {
      const response = await getProjectDetails(userId, userRoleId);
      dispatch(setProjectDetail(response));
    } catch (error) {
      if (error.response.data.message === "jwt expired") {
        logOutUser();
      }
      console.log("Error Fetching Project Detail");
    }
  };

  const getAccountManagerRoleId = async () => {
    return roles?.find((obj) => obj.name === "Business Developer")?._id;
  };

  const getAccManagers = async () => {
    try {
      const accountManagerRoleId = await getAccountManagerRoleId();
      const response = await getAccountManagers(accountManagerRoleId);
      setAccountManager(response);
    } catch (error) {
      console.log("Error Fetching Managers Detail");
    }
  };
  const getAllTintashMembers = async () => {
    try {
      const response = await allTintashMembers();
      setAllMembers(response.data);
    } catch (error) {
      console.log("Error Fetching tintash members Detail");
    }
  };

  const getClientRoleId = async () => {
    return roles?.find((obj) => obj.name === "Client")?._id;
  };

  const getClient = async () => {
    try {
      const clientRoleId = await getClientRoleId();
      const response = await getProjectClient(clientRoleId);
      setProjectClient(response);
    } catch (error) {
      console.log("Error Fetching Clients");
    }
  };

  const onSubmit = (values) => {
    callCreateProject(values);
  };
  const getUpdatedUsers = () => {
    getSuperAdmins();
    getManagers();
    getAccManagers();
    getClient();
  };
  const addUserSubmit = async (values) => {
    setIsButtonDisabled(true);
    await AddUser(values);
    setIsButtonDisabled(false);
    getUpdatedUsers();
  };

  const handleCreateProject = () => {
    setIsEditing(false);
    form.resetFields();
    setIsModalVisible(true);
  };
  const handleUserSelect = (value) => {
    allMembers?.forEach((obj) => {
      if (obj._id === value) {
        addUserForm.setFieldsValue({ name: obj.name, email: obj.email });
      }
    });
  };

  return (
    <>
      <div className={styles.projectsAndClientsDashboard}>
        <div className={styles.projects}>
          <div className={styles.project_heading}>Projects</div>
          <div>
            <Dropdown overlay={projectStatus}>
              <button className={styles.project_status}>
                Project Status <DownOutlined />
              </button>
            </Dropdown>
          </div>
        </div>
        <div className={styles.client}>
          <div className={styles.heading}></div>
          {userCurrentRole?.shortForm === "AD" ? (
            <div className="ph-32">
              <Dropdown overlay={pmData}>
                <button className={styles.pm_button}>
                  PM <DownOutlined />
                </button>
              </Dropdown>

              <button
                className={styles.create_button}
                onClick={handleCreateProject}
              >
                + Create
              </button>
              <button
                className={styles.user_button}
                onClick={() => setIsUserModalVisible(true)}
              >
                + Add User
              </button>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className={styles.projects_listing_container}>
        <AntTable
          title={null}
          columns={columns}
          dataSource={projectDetail}
          showHeader
          className="projectsListing"
          size="default"
          bordered={false}
          loading={loading}
        />
      </div>

      <div className="ph-100">
        <Graph projectId="" pmId={pmId} />
      </div>

      <div>
        <Modal
          title={isEditing ? "Update Project" : "Create Project"}
          open={isModalVisible}
          footer={null}
          centered
          onCancel={setStatesNull}
        >
          <Form
            className="tag-form"
            form={form}
            onFinish={onSubmit}
            layout="vertical"
          >
            <Form.Item name="_id" hidden={true}>
              <Input placeholder="ID" type="text" />
            </Form.Item>
            <Form.Item
              name="project_name"
              label="Project Name"
              rules={[
                {
                  required: true,
                  message: "Please enter project name",
                },
              ]}
            >
              <Input placeholder="Enter Project Name" type="text" />
            </Form.Item>
            <Form.Item
              name="project_managers"
              label="Project Manager"
              rules={[
                {
                  required: true,
                  message: "Please select project manager",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select Project Manager"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                {projectManagers?.map((obj, index) => {
                  return (
                    <Option key={`project-manager-${index}`} value={obj._id}>
                      {obj.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name="reporting_manager"
              label="Reporting Manager"
              rules={[
                {
                  required: true,
                  message: "Please select reporting manager",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select Reproting Manager"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                {reportingManagers?.map((obj, index) => {
                  return (
                    <Option key={`reporting-manager-${index}`} value={obj._id}>
                      {obj.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item
              name="category"
              label="Category"
              rules={[
                {
                  required: true,
                  message: "Please select project category",
                },
              ]}
            >
              <Select
                showSearch
                suffixIcon={isEditing ? null : <DownOutlined />}
                disabled={isEditing}
                labelInValue
                placeholder="Select Project Category"
                onChange={handleProjectCategory}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                {projectCategory?.map((obj, index) => {
                  return (
                    <Select.Option
                      key={`project-category-${index}`}
                      value={obj._id}
                    >
                      {obj.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name="starting_phase"
              label="Starting Phase"
              rules={[
                {
                  required: true,
                  message: "Please select phase",
                },
              ]}
            >
              <Select
                showSearch
                suffixIcon={isEditing ? null : <DownOutlined />}
                disabled={isEditing}
                labelInValue
                placeholder="Select Phase"
                notFoundContent={
                  <Empty
                    description="Select Project Category First"
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                  />
                }
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                {phases?.map((obj, index) => {
                  return (
                    <Select.Option
                      key={`project-phase-${index}`}
                      value={obj._id}
                    >
                      {obj.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name="project_type"
              label="Project Type"
              rules={[
                {
                  required: true,
                  message: "Please select project type",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select Project Type"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                {projectTypes?.map((obj, index) => {
                  return (
                    <Option key={`project-types-${index}`} value={obj._id}>
                      {obj.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item
              name="account_manager"
              label="Account Manager"
              rules={[
                {
                  required: true,
                  message: "Please select account manager",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select Account Manager"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                {accountManager?.map((obj, index) => {
                  return (
                    <Option key={`account-manager-${index}`} value={obj._id}>
                      {obj.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name="client"
              label="Client"
              rules={[
                {
                  required: true,
                  message: "Please enter client name",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Client"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                {projectClient?.map((obj, index) => {
                  return (
                    <Option key={`project-client-${index}`} value={obj._id}>
                      {obj.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item
              name="billable"
              label="Billable"
              rules={[
                {
                  required: true,
                  message: "Please choose an option",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Billable?"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                <Option value={true}>Yes</Option>;
                <Option value={false}>No</Option>;
              </Select>
            </Form.Item>

            <Form.Item
              name="internal"
              label="Internal"
              rules={[
                {
                  required: true,
                  message: "Please choose an option",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Internal Project?"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                <Option value={true}>Yes</Option>;
                <Option value={false}>No</Option>;
              </Select>
            </Form.Item>

            <Form.Item
              name="start_date"
              label="Start Date"
              rules={[
                {
                  required: true,
                  message: "Please enter project start date",
                },
              ]}
            >
              <DatePicker
                className="project-date-picker"
                placeholder="Enter Start Date"
                type="date"
                onChange={handleStartDateChange}
                disabledDate={disableStartDates}
                getPopupContainer={(trigger) => trigger.parentNode}
              />
            </Form.Item>
            <Form.Item
              name="end_date"
              label="End Date"
              rules={[
                {
                  required: true,
                  message: "Please enter project end date",
                },
              ]}
            >
              <DatePicker
                className="project-date-picker"
                placeholder="Enter End Date"
                type="date"
                onChange={handleEndDateChange}
                disabledDate={disableEndDates}
                getPopupContainer={(trigger) => trigger.parentNode}
              />
            </Form.Item>
            {
              <div className="create-project-btn">
                <Button type="primary" htmlType="submit">
                  {isEditing ? "Update Project" : "Create Project"}
                </Button>
              </div>
            }
          </Form>
        </Modal>
      </div>
      <div>
        <Modal
          title="Add User"
          open={isUserModalVisible}
          footer={null}
          centered
          onCancel={() => {
            setIsUserModalVisible(false);
            addUserForm.resetFields();
          }}
        >
          <Form
            className="tag-form"
            form={addUserForm}
            onFinish={addUserSubmit}
            layout="vertical"
          >
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please enter name of user",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
                onSelect={handleUserSelect}
              >
                {allMembers?.map((obj, index) => {
                  return (
                    <Select.Option
                      key={`project-category-${index}`}
                      value={obj._id}
                    >
                      {obj.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item name="email">
              <Input placeholder="Enter Email ID" type="text" readOnly />
            </Form.Item>
            <Form.Item
              name="role"
              rules={[
                {
                  required: true,
                  message: "Please select role of user",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Role"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                {roles?.map((obj, index) => {
                  return (
                    <Option key={`roles-${index}`} value={obj._id}>
                      {obj.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            {
              <div className={styles.save_button}>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  className="wide"
                  disabled={isButtonDisabled}
                >
                  Save User
                </Button>
              </div>
            }
          </Form>
        </Modal>
      </div>
    </>
  );
};

export default ClientSatisfaction;
