import React, { useState } from "react";
import { Button } from "antd";
import { useHistory } from "react-router-dom";

import LeadConversion from "../../components/Processes/LeadConversion/LeadConversion";
import Discovery from "../../components/Processes/Discovery/Discovery";
import Development from "../../components/Processes/Development/Development";
import Polishing from "../../components/Processes/Polishing/Polishing";
import Release from "../../components/Processes/Release/Release";

import styles from "./landingPage.module.css";
import logo from "../../assets/images/logo.png";
import stages from "../../assets/images/stages.png";
import "bootstrap/dist/css/bootstrap.min.css";

function LandingPage() {
  const history = useHistory();
  const PROCESSES = [
    {
      name: "Lead Conversion",
      subprocesses: [
        {
          name: "What Happens",
          ref: "whatHappensContainer",
        },
        {
          name: "Applying the principles",
          ref: "principlesContainer",
        },
        {
          name: "Review Estimates",
          ref: "estimatesContainer",
        },
        {
          name: "Hand Over",
          ref: "handoverContainer",
        },
        {
          name: "Conversion Retro",
          ref: "retroContainer",
        },
        {
          name: "Checklist",
          ref: "processChecklist",
        },
        {
          name: "Nanga Parbat",
          ref: "parbatContainer",
        },
      ],
    },
    {
      name: "Discovery",
      subprocesses: [
        {
          name: "What Happens",
          ref: "whatHappensContainer",
        },
        {
          name: "Applying the principles",
          ref: "principlesContainer",
        },
        {
          name: "What is Discovery",
          ref: "whatContainer",
        },
        {
          name: "Planning Meeting",
          ref: "discoveryPlanning",
        },
        {
          name: "Kick Off Meeting",
          ref: "discoveryKickOff",
        },
        {
          name: "Checklist",
          ref: "processChecklist",
        },
      ],
    },
    {
      name: "Development",
      subprocesses: [
        {
          name: "What Happens",
          ref: "whatHappensContainer",
        },
        {
          name: "Applying the principles",
          ref: "principlesContainer",
        },
        {
          name: "Team Charter Meet",
          ref: "TeamChartMeet",
        },
        {
          name: "Sprint 0.5",
          ref: "Sprintzerofive",
        },
        {
          name: "Release Plan Meeting",
          ref: "Release_Plan",
        },
        {
          name: "Release Checklist",
          ref: "Release_Checklist",
        },
      ],
    },

    {
      name: "Polishing",
      subprocesses: [
        {
          name: "What Happens",
          ref: "whatHappensContainer",
        },
        {
          name: "Applying the principles",
          ref: "principlesContainer",
        },
        {
          name: "Dogfooding Day",
          ref: "Dogfooding",
        },
      ],
    },
    {
      name: "Release",
      subprocesses: [
        {
          name: "What Happens",
          ref: "whatHappensContainer",
        },
        {
          name: "Steps",
          ref: "stepsContainer",
        },
        {
          name: "Applying the principles",
          ref: "principlesContainer",
        },
        {
          name: "Release Execution Meeting",
          ref: "ReleaseExecutionMeeting",
        },
        {
          name: "Closure/Handover Meeting",
          ref: "ClosureMeeting",
        },
      ],
    },
    // { name: "Maintenance" },
  ];
  const [activeProcess, setActiveProcess] = useState("");

  function getComponent(compName) {
    switch (compName) {
      case "Lead Conversion":
        return <LeadConversion />;
      case "Discovery":
        return <Discovery />;
      case "Development":
        return <Development />;
      case "Polishing":
        return <Polishing />;
      case "Release":
        return <Release />;
      // case "Maintenance":
      //   return <Maintenance />;
      default:
        return "";
    }
  }

  return (
    <>
      <div className={styles.hero + " px-4 text-center"}>
        <img src={logo} alt="logo" className="img-fluid" width="150" />
        <div className={styles.heading}>
          PLAYBOOK FOR <br /> MANAGERS
        </div>

        <div className={styles.buttonContainer}>
          <Button
            type="primary"
            shape="round"
            size="large"
            className={styles.button + " " + styles.button__blue}
            onClick={() => history.push("/introduction")}
          >
            Why the Playbook
          </Button>
          <Button
            type="primary"
            shape="round"
            size="large"
            className={styles.button + " " + styles.button__green}
            onClick={() => console.log("bdashdas")}
          >
            How to Use
          </Button>
          <Button
            type="primary"
            shape="round"
            size="large"
            className={styles.button + " " + styles.button__blue}
            onClick={() => history.push("/sign-in")}
          >
            Dashboard
          </Button>
        </div>
        <div className={styles.heroProcessContainer}>
          <img className="img-fluid" src={stages} alt="process" />
        </div>
        <div className={styles.processBar}>
          {PROCESSES.map((process, index) => {
            return (
              <div
                key={index}
                className={
                  activeProcess.name === process.name
                    ? styles.processBarLabelActive
                    : styles.processBarLabel
                }
                onClick={() => setActiveProcess(process)}
              >
                {process.name}
              </div>
            );
          })}
        </div>
        {/* <div className={styles.processContainer}>*/}
        <div>{getComponent(activeProcess.name)}</div>
      </div>
    </>
  );
}

export default LandingPage;
