import React from "react";
import "./aLittleStoryFirstButton.scss";
import littleStoryDefault from "../../assets/icons/general/littleStoryDefault.svg";

function ALittleStoryFirstButton({ onClick }) {
  return (
    <div className="little-story-container">
      <div className="story" onClick={() => onClick("nangaParbat")}>
        <img className="crown" src={littleStoryDefault} />
      </div>
    </div>
  );
}

export default ALittleStoryFirstButton;
