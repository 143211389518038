import {
  setProjectDetail,
  setUserRole,
} from "../../../redux/ClientSatisfaction/ClientSatisfactionActions";
import { setAllRoles } from "../../../redux/GetAllRoles/GetAllRolesActions";
import { getUserRoles } from "../../../helper/getApis";
import { getProjectDetails } from "../../../helper/getApis";
import { store } from "../../../redux/store";

const currentUserObj = JSON.parse(localStorage.getItem("userObj"));
const userId = currentUserObj?._id;

export const getPmDetails = async (userId, currentUserRoleId) => {
  try {
    const response = await getProjectDetails(userId, currentUserRoleId);
    store.dispatch(setProjectDetail(response));
  } catch (error) {
    console.log("Error Fetching PM Detail");
  }
};

export const handleRole = (roleObj) => {
  if (roleObj.shortForm === "AD") {
    getPmDetails("", roleObj._id);
    store.dispatch(setUserRole(roleObj));
  } else {
    store.dispatch(setUserRole(roleObj));
    getPmDetails(userId, roleObj._id);
  }
};

export const getAllRoles = async () => {
  try {
    const roles = await getUserRoles();
    store.dispatch(setAllRoles(roles));
  } catch (error) {
    throw new Error("Error Fetching roles");
  }
};
