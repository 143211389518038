import React, { useState, useRef, useEffect } from "react";

import MainPageNav from "../../../components/MainPageNav/MainPageNav";
import { GuideLines } from "../../../components/GuideLines/GuideLines";
import { WhatHappensData } from "../../../components/Processes/LeadConversion/components/WhatHappensData";
import { ApplyingPrinciples } from "./components/ApplyingPrinciples";
import { DO_CONSTANTS } from "../../../constants/doConstants";
import arrow from "../../../assets/images/arrow.svg";
import leadConversionIcon from "../../../assets/icons/leadConversion.svg";
import dollar from "../../../assets/icons/dollar.svg";
import thumbsUp from "../../../assets/icons/thumbsUp.svg";
import heartIcon from "../../../assets/icons/heartIcon.svg";
import "./polishing.scss";
import ScrollToTopButton from "../../../components/ScrollToTopButton/ScrollToTopButton";
import WhatHappensButton from "../../../components/WhatHappensButton/WhatHappensButton";

import { MOBILE_NAVIGATION_MENU_ITEMS } from "../../../constants/navigationMenuConstants";
import MobileNavbarTop from "../../../components/mobile/MobileNavbarTop/MobileNavbarTop";
import MobileNavbarBottom from "../../../components/mobile/MobileNavbarBottom/MobileNavbarBottom";
import useElementInView from "../../../utilities/useElementInView";
import { whatHappensObserverOptions } from "../LeadConversion/LeadConversion";

const WHEREDATA = [
  {
    name: "Polishing",
    dos: [{ name: "Dogfooding", ref: "Dogfooding" }],
    uses: [],
  },
];

export const Polishing = () => {
  const pageTopRef = useRef(null);
  const whatHappensSectionRef = useRef(null);

  const [activeWhere, setActiveWhere] = useState(WHEREDATA[0]);
  const [showGuideline, setShowGuideLine] = useState(false);
  const [selectedGuideline, setSelectedGuideline] = useState("");
  const [openNavigationMenu, setOpenNavigationMenu] = useState(false);

  const [whatHappensRef, whatHappensRefInView] = useElementInView(
    whatHappensObserverOptions
  );

  const [whatHappensEndRef, whatHappensEndRefInView] = useElementInView(
    whatHappensObserverOptions
  );

  const whatHappenStateHandler = (val) => {
    const guideLineDisplay = DO_CONSTANTS.find(
      (data) => val.target.innerText === data
    );
    if (guideLineDisplay) {
      setShowGuideLine(true);
      setSelectedGuideline(val.target.innerText);
    }
  };
  const mainDivFunction = () => {
    if (showGuideline) {
      setShowGuideLine(false);
    }
  };

  useEffect(() => {
    pageTopRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);
  return (
    <>
      <div ref={pageTopRef} />
      <MobileNavbarTop
        menuItems={MOBILE_NAVIGATION_MENU_ITEMS}
        openNavigationMenu={openNavigationMenu}
        setOpenNavigationMenu={setOpenNavigationMenu}
      />
      <MainPageNav />
      {showGuideline && (
        <GuideLines
          selectedGuideline={selectedGuideline}
          close={() => setShowGuideLine(false)}
        />
      )}
      <div
        onClick={mainDivFunction}
        className={showGuideline ? "row opacity" : "row"}
      >
        <div className="row mt-88">
          <div className="whitespace col-2"></div>
          <div className="col-5">
            <div className="mobile-flex">
              <img className="waterfall-chart" src={leadConversionIcon} />
              <h1 className="processHeading2 develop-heading ml-24">
                Polishing
              </h1>
            </div>
            <div className="lead-content">
              <p>
                Polishing begins when a feature (set) is complete. It ends when
                the feature (set) is considered ready for release (deployment to
                production).
              </p>
            </div>
          </div>
          <div className="mobile-hidden col-5">
            <WhatHappensButton buttonRef={whatHappensSectionRef} />
          </div>
        </div>
        <div className="whitespace" style={{ height: "50px" }}></div>
        <ApplyingPrinciples
          principleone={heartIcon}
          principletwo={thumbsUp}
          principlethree={dollar}
        />

        <div className="row">
          <div
            className="main-content-align"
            id="linkWhatHappen"
            ref={whatHappensSectionRef}
          >
            <div className="happens" ref={whatHappensRef}>
              What Happens?
            </div>
          </div>
        </div>
        <WhatHappensData
          wheredata={WHEREDATA}
          activeWhere={activeWhere}
          arrow={arrow}
          setActiveWhere={setActiveWhere}
          onChangeValue={whatHappenStateHandler}
          dataId="polishing"
        />
        <div ref={whatHappensEndRef}></div>
        {!(whatHappensRefInView || whatHappensEndRefInView) ? (
          <MobileNavbarBottom>
            <WhatHappensButton buttonRef={whatHappensSectionRef} />
          </MobileNavbarBottom>
        ) : null}
        <ScrollToTopButton pageTopRef={pageTopRef} />
      </div>
    </>
  );
};
