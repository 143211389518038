import React from "react";
import { useState } from "react";

import nexttoprocess from "../../../assets/images/Polishing/intro.png";
import arrow from "../../../assets/images/arrow.svg";
import badge from "../../../assets/images/badge.svg";
import principleone from "../../../assets/images/Discovery/principle-one.png";
import principletwo from "../../../assets/images/Discovery/principle-two.png";
import principlethree from "../../../assets/images/Discovery/principle-three.png";
import guidanceone from "../../../assets/images/Discovery/guidance-one.svg";
import guidancetwo from "../../../assets/images/Discovery/guidance-two.png";
import guidancethree from "../../../assets/images/Discovery/guidance-three.png";
import guidancefour from "../../../assets/images/Discovery/guidance-four.png";
import styles from "./polishing.module.css";

import Intro from "./Intro";
import WhereData from "./WhereData";
import Principles from "./Principles";
import DogFooding from "./DogFooding";

function Polishing() {
  const WHEREDATA = [
    {
      name: "Polishing",
      dos: [{ name: "Dogfooding", ref: "Dogfooding" }],
      uses: [],
    },
  ];

  const [activeWhere] = useState(WHEREDATA[0]);

  return (
    <div className={styles.polishingContainer}>
      <div className={"processParentContainer"}>
        <Intro nexttoprocess={nexttoprocess} />

        <WhereData
          wheredata={WHEREDATA}
          activeWhere={activeWhere}
          arrow={arrow}
          badge={badge}
        />

        <Principles
          principleone={principleone}
          principletwo={principletwo}
          principlethree={principlethree}
        />

        <DogFooding
          guidanceone={guidanceone}
          guidancetwo={guidancetwo}
          guidancethree={guidancethree}
          guidancefour={guidancefour}
        />
      </div>
    </div>
  );
}

export default Polishing;
