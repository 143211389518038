import React from "react";
import { Collapse } from "antd";
import Pop from "../../../Popup/popup";
import Badge from "../../../../assets/images/badge.svg";
import BADGES_CONTENT from "../BadgesContent";
const { Panel } = Collapse;

function TeamCharterMeet(props) {
  const { styles } = props;
  return (
    <div className="guidancesContainer TeamChartMeet">
      <div className={styles.guidanceMainHeadingContainer}>
        <h2 className="processHeading2" id="charterMeet" style={{ margin: 0 }}>
          Team Charter Meet
        </h2>
        <br />
        <br />
        <img
          className="img-fluid pr-24"
          src={props.reqImg}
          alt="req main img"
        />
      </div>
      <div className="row guidanceReqs">
        <div className="col-3">
          <div className="guidance guidance__brown">
            <img
              src={props.guidanceone}
              width={40}
              className="img-fluid"
              alt="guidance"
            />
            <div>
              <div>Prep Time:</div>
              <div>15 mins</div>
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="guidance guidance__purple">
            <img
              src={props.guidancetwo}
              width={40}
              className="img-fluid"
              alt="guidance"
            />
            <div>
              <div>Run Time:</div>
              <div>60 mins</div>
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="guidance guidance__red">
            <img
              src={props.guidancefour}
              width={40}
              className="img-fluid"
              alt="guidance"
            />
            <div>
              <div>Follow Up:</div>
              <div style={{ whiteSpace: "nowrap" }}>15-30 mins</div>
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="guidance guidance__green">
            <img
              src={props.guidancethree}
              width={40}
              className="img-fluid"
              alt="guidance"
            />
            <span style={{ fontWeight: 600 }}>People:</span>&nbsp;4-8
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="guidanceCollapse">
        <Collapse expandIconPosition={"right"}>
          <Panel header="Why the requirement" key="1">
            <p className="processRunning">
              At this point in a project’s life, a team at Tintash could be
              starting where Discovery concluded and taking on additional and/or
              replacement team members to continue the project, or a team could
              be starting fresh on a project based on a SOW without a discovery.
              This is an internal team meeting.
            </p>
            <div className={styles.reqsInnerBox}>
              <p className="processRunning">
                The purpose of this meet is for the team to:
                <ol>
                  <li>
                    Get to know each other in these roles for this project (even
                    if everyone knows each other from before)
                  </li>
                  <li>
                    Establish some rules for how to work together on this
                    particular project
                  </li>
                </ol>
              </p>
            </div>
          </Panel>
        </Collapse>
        <br />
        <br />
        <Collapse expandIconPosition={"right"}>
          <Panel header="What is the requirement" key="2">
            <div className="row">
              <div className="col-12">
                <div className="guidanceReqInnerBox">
                  <b>Mandatory participants:</b>&nbsp;PM, all team members,
                  Account Manager (optional)
                </div>
                <p className="processRunning">
                  This is an internal meeting. It is a 2-hour session organised
                  by the PM in their own style but that, at a minimum, covers
                  the following:
                </p>
                <ul className="processRunning">
                  <li>Team introductions</li>
                  <li>
                    “Rules of collaboration”
                    <Pop
                      src={Badge}
                      heading="Champions ka tareeqa"
                      dataone={BADGES_CONTENT.COLLABORATION}
                    />
                    - how will the team work together
                    <Pop
                      src={Badge}
                      heading="Buzurg kehte hein"
                      dataone={BADGES_CONTENT.TOGETHETR}
                    />
                  </li>
                  <li>
                    “Rules of communication” - how will the team communicate
                    with each other and the client
                  </li>
                  <li>
                    “Rules of delivery”
                    <Pop
                      src={Badge}
                      heading="Buzurg kehte hain"
                      dataone={BADGES_CONTENT.DELIVERY}
                    />
                    – how will each team member
                    <Pop
                      src={Badge}
                      heading="Champions ka tareeqa"
                      dataone={BADGES_CONTENT.MEMBER}
                    />
                    approach their work
                  </li>
                  <li>Tools and frequency of communication</li>
                </ul>
                <p className="processRunning">
                  Tintash does not require a formal charter document. The rules
                  <Pop
                    src={Badge}
                    heading="Champions ka tareeqa"
                    dataone={BADGES_CONTENT.RULES}
                  />
                  can be written down in a google document, on a whiteboard, on
                  a large sheet placed on a wall, any other medium of the team’s
                  choice. The format is not important, it should not be a long
                  document or an essay, just a few bullet points (around 7-10)
                  clearly visible and accessible to all team members where they
                  sit (or where they collaborate online).
                </p>
                <h1 className="processHeading4 italic">Before the Meeting</h1>
                <ul className="processRunning">
                  <li>Send the agenda at least a day in advance</li>
                  <li>
                    Share any materials you would like the team to be familiar
                    with
                  </li>
                  <li>
                    Prepare how you will conduct introductions and the
                    discussions on rules (ice breaker, exercises etc. depending
                    on the team)
                  </li>
                  <li>Get familiarised with all team members’ backgrounds</li>
                  <li>Assign a notetaker</li>
                </ul>
                <h1 className="processHeading4 italic">During the Meeting</h1>
                <ul className="processRunning">
                  <li>Make space for different points of view</li>
                  <li>
                    Be prepared to prevent the discussion from spiralling out of
                    scope or go in circles without dismissing what people are
                    saying. Extract the main point people are making and repeat
                    that
                  </li>
                  <li>
                    Show interest when a team member shares a thought or an idea
                    by asking questions about it
                  </li>
                  <li>
                    If a team member is quiet, encourage them to contribute
                    without putting them on the spot. You can do this by raising
                    something that related to their area of
                    responsibility/expertise
                  </li>
                </ul>
                <h1 className="processHeading4 italic">After the Meeting</h1>
                <ul className="processRunning">
                  <li>
                    Write the bullet points down and share with the team by
                    email as well as put in a visible place in the office or
                    online
                  </li>
                </ul>
              </div>
            </div>
          </Panel>
        </Collapse>
      </div>
      <br />
    </div>
  );
}

export default TeamCharterMeet;
