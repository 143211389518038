import axios from "axios";
import { ENV_CONSTANTS } from "../../constants/globalConstants";
import {
  ADD_SYNCUP_UPDATE,
  ADD_CLIENT_UPDATE,
  ADD_SYNCUP_UPDATE_SUCCESS,
  ADD_CLIENT_UPDATE_SUCCESS,
  ADD_SYNCUP_UPDATE_ERROR,
  ADD_CLIENT_UPDATE_ERROR,
  GET_ALL_UPDATES,
} from "./types";
import { getUpdate } from "../../helper/getApis";
import { addUpdateService } from "../../helper/processHelpers";

const baseURL = ENV_CONSTANTS.SERVER_URL;

export const getAllUpdates =
  (
    projectID = "6294c32457075db1d6926cb2",
    stageID,
    subStageID = "627bfa822b38f59591e23a73"
  ) =>
  async (dispatch) => {
    try {
      const response = await getUpdate(projectID, stageID, subStageID);
      dispatch({
        type: GET_ALL_UPDATES,
        payload: response,
      });
    } catch (e) {
      dispatch({
        type: GET_ALL_UPDATES,
        payload: [],
      });
    }
  };
export const addUpdateEffect = (payload) => async (dispatch) => {
  const response = await addUpdateService(payload);
  dispatch({
    type: ADD_SYNCUP_UPDATE_SUCCESS,
    payload: response.data,
    loading: false,
  });
};
export const addSyncUp =
  (config, values, projectId, stageId, subStageId) => async (dispatch) => {
    dispatch({ type: ADD_SYNCUP_UPDATE });
    try {
      const response = await axios.post(
        `${baseURL}/update/`,
        {
          title: values.title,
          syncupType: values.syncupType,
          dailySyncup: {
            sufficientAttendance:
              values.dailySyncup.sufficientAttendance.toString(),
            scrumTime: values.dailySyncup.scrumTime.toString(),
            roadBlocks: values.dailySyncup.roadBlocks.toString(),
            teamInformed: values.dailySyncup.teamInformed.toString(),
            projectOnTrack: values.dailySyncup.projectOnTrack.toString(),
          },
          update: {
            sufficientAttendance: values.update.sufficientAttendance.toString(),
            meetingAgenda: values.update.meetingAgenda.toString(),
            demoGiven: values.update.demoGiven.toString(),
            changesByClient: values.update.changesByClient.toString(),
          },
          comments: values.comments,
          subStage: subStageId,
          projectStage: stageId,
          project: projectId,
        },
        config
      );

      dispatch({
        type: ADD_SYNCUP_UPDATE_SUCCESS,
        payload: response.data,
        loading: false,
      });
    } catch (error) {
      dispatch({
        type: ADD_SYNCUP_UPDATE_ERROR,
        payload: {
          message: "Error Adding Sync up",
        },
      });
    }
  };

export const addClientUpdate =
  (config, values, projectId, stageId, subStageId) => async (dispatch) => {
    dispatch({ type: ADD_CLIENT_UPDATE });
    try {
      const response = axios.post(
        `${baseURL}/update/`,
        {
          title: values.title,
          syncupType: values.syncupType,
          update: {
            sufficientAttendance: String(values.sufficientAttendance),
            meetingAgenda: values.meetingAgenda,
            demoGiven: String(values.demoGiven),
            changesByClient: values.changesByClient,
          },
          comments: values.comments,
          subStage: subStageId,
          projectStage: stageId,
          project: projectId,
        },
        config
      );

      dispatch({
        type: ADD_CLIENT_UPDATE_SUCCESS,
        payload: response.data,
        loading: false,
      });
    } catch (error) {
      dispatch({
        type: ADD_CLIENT_UPDATE_ERROR,
        payload: {
          message: "Error Client Update ",
        },
      });
    }
  };
