import axios from "axios";
import { ENV_CONSTANTS } from "../constants/globalConstants";

const baseURL = ENV_CONSTANTS.SERVER_URL;

const getConfig = () => ({
  headers: {
    Authorization: `Bearer ${
      JSON.parse(localStorage.getItem("userObj"))?.accessToken
    }`,
  },
});

export const getProjectTypes = async () => {
  try {
    const response = await axios.get(`${baseURL}/project-type`, getConfig());
    return response.data;
  } catch (error) {
    throw new Error("Error Fetching Project type");
  }
};

export const getProjectCategory = async () => {
  try {
    const response = await axios.get(
      `${baseURL}/project-category`,
      getConfig()
    );
    return response.data;
  } catch (error) {
    throw new Error("Error Fetching Project Category");
  }
};

export const getUsersByRole = async (roleId) => {
  try {
    const response = await axios.get(
      `${baseURL}/user/users-by-role/${roleId}`,
      getConfig()
    );
    return response.data;
  } catch (error) {
    throw new Error("Error Fetching Project Managers");
  }
};

export const getAllProjects = async () => {
  try {
    const response = await axios.get(`${baseURL}/project/`, getConfig());
    return response.data;
  } catch (error) {
    throw new Error("Error Fetching projects");
  }
};

export const getAllPhases = async (projectCategoryID) => {
  try {
    const response = await axios.get(
      `${baseURL}/phase/${projectCategoryID}`,
      getConfig()
    );
    return response.data;
  } catch (error) {
    throw new Error("Error Fetching projects");
  }
};

export const getProjectStatus = async (projectID) => {
  try {
    const response = await axios.get(
      `${baseURL}/project/${projectID}`,
      getConfig()
    );
    return response.data;
  } catch (error) {
    throw new Error("Error Fetching projects Status");
  }
};

export const getProjectDetails = async (pmId, role) => {
  try {
    const response = await axios.get(
      `${baseURL}/project?pm=${pmId}&role=${role}`,
      getConfig()
    );
    const sortByLastCreatedData = response.data.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );
    return sortByLastCreatedData;
  } catch (error) {
    console.log("Error Fetching Projects");
    throw error;
  }
};

export const getAccountManagers = async (businessDeveloperRoleId) => {
  try {
    const response = await axios.get(
      `${baseURL}/user/users-by-role/${businessDeveloperRoleId}`,
      getConfig()
    );
    return response.data;
  } catch (error) {
    throw new Error("Error  fetching Get Account Managers");
  }
};

export const getProjectClient = async (clientRoleId) => {
  try {
    const response = await axios.get(
      `${baseURL}/user/users-by-role/${clientRoleId}`,
      getConfig()
    );
    return response.data;
  } catch (error) {
    throw new Error("Error Get Project Client");
  }
};

export const getInputFiles = async (projectID, stageID) => {
  try {
    const response = await axios.get(
      `${baseURL}/input/${projectID}/${stageID}`,
      getConfig()
    );
    return response.data;
  } catch (error) {
    throw new Error("Error Get Input Files");
  }
};

export const getActivityFiles = async (projectID, stageID) => {
  try {
    const response = await axios.get(
      `${baseURL}/activity/${projectID}/${stageID}`,
      getConfig()
    );
    return response.data;
  } catch (error) {
    throw new Error("Error Get Activity Files");
  }
};

export const getOutputFiles = async (projectID, stageID, subStageID) => {
  try {
    const response = await axios.get(
      `${baseURL}/output/${projectID}/${stageID}/${subStageID}`,
      getConfig()
    );
    return response.data;
  } catch (error) {
    throw new Error("Error Get Output files");
  }
};

export const getFeedback = async (userRoleId, pmId, projectId) => {
  try {
    const response = await axios.get(
      `${baseURL}/feedback/feedback-average?role=${userRoleId}&PM=${pmId}&projectId=${projectId}`,
      getConfig()
    );
    return response.data;
  } catch (error) {
    throw new Error("Error Get Output files");
  }
};

export const getUpdate = async (projectId, stageId, subStageId) => {
  try {
    const response = await axios.get(
      `${baseURL}/update/${projectId}/${stageId}/${subStageId}`,
      getConfig()
    );
    return response.data;
  } catch (error) {
    throw new Error("Error Get Update");
  }
};

export const subStages = async (projectId, stageId) => {
  try {
    return await axios.get(
      `${baseURL}/process-status/process/${projectId}/${stageId}`,
      getConfig()
    );
  } catch (error) {
    throw new Error("Error Getting Sub Stages");
  }
};

export const allTintashMembers = async (status = false) => {
  try {
    return await axios.get(
      `${baseURL}/tintash-member?status=${status}`,
      getConfig()
    );
  } catch (error) {
    throw new Error("Error Getting All members");
  }
};

export const getProcessStatus = async (projectID, stageID, subStageID) => {
  try {
    const response = await axios.get(
      `${baseURL}/process-status/${projectID}/${stageID}/${subStageID}`,
      getConfig()
    );
    return response.data;
  } catch (error) {
    throw new Error("Error putting unlock Sub Stage");
  }
};

export const getUserRoles = async () => {
  try {
    const response = await axios.get(`${baseURL}/role`, getConfig());
    return response.data;
  } catch (error) {
    throw new Error("Error Fetching User Roles");
  }
};
