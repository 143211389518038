import React from "react";

import Badge from "../../../../assets/images/badge.svg";
import BADGES_CONTENT from "../../../../components/Processes/Development/BadgesContent";
import Tooltip from "../../../../components/Tooltip/Tooltip";

function WebProducts() {
  return (
    <>
      <h1 className="processRunningHeading">Pointers</h1>
      <ul className="processRunning">
        <li>Code freeze</li>
        <li>Thorough Testing of the application</li>
        <li>Document changes &amp; publishing Release Notes</li>
        <li>
          Security considerations:
          <ul>
            <li>
              Proper authorization and authentication techniques, resistance to
              common malicious activity.
            </li>
            <li>
              Secrets/keys are secured properly in a vault or secret store.
              Tools like git-secrets scan code to identify potential secrets.
            </li>
            <li>
              Data encryption (both at rest &amp; in flight — if needed). SSL
              configuration on production environment both backend and frontend.
            </li>
          </ul>
        </li>
        <li>
          Compliance: If there are any prevailing compliance requirements --
          like data encryption, HIPPA Guidelines, GDPR etc, they should be met
          before the release.
        </li>
        <li>
          Deployment:
          <ul>
            <li>
              Prepare a good staging environment
              <Tooltip
                headingOne="Buzurg kehte hein:"
                paragraphOne={BADGES_CONTENT.ENVIORNMENT}
                direction="right"
              >
                <img src={Badge} alt="" className="badgeIcon" />
              </Tooltip>
              for your software.
            </li>
            <li>
              Deployment strategy documented, and CI/CD pipelines configured for
              staging and production environments.
            </li>
            <li>
              Check possibilities for soft launch first or rollout in phases.
            </li>
          </ul>
        </li>
        <li>
          Scalability: Test for application scalability, enable auto scaling or
          configure Load balancers for application to scale well.
        </li>
        <li>
          Application Performance: Load / Stress testing of the application
          using tools like Jmeter and Loader.io
        </li>
        <li>
          Application Monitoring Tools should be configured (Logging, tracing
          and general performance metrics)
        </li>
        <li>
          Fault Tolerance and Disaster Recovery mechanism should be outlined
        </li>
      </ul>
    </>
  );
}

export default WebProducts;
