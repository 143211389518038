import axios from "axios";
import { ENV_CONSTANTS } from "../../constants/globalConstants";
import {
  GET_PROJECT_DETAIL,
  GET_PROJECT_STATUS,
  GET_PROJECT_STATUS_SUCCESS,
  GET_PROJECT_DETAIL_SUCCESS,
  GET_PROJECT_STATUS_ERROR,
  GET_PROJECT_DETAIL_ERROR,
  SET_SUBSTAGE,
  GET_CHECKLIST,
  SET_PHASE,
} from "./types";
import { getCheckListService } from "../../helper/processHelpers";
import { getProjectStatus } from "../../helper/getApis";
const baseURL = ENV_CONSTANTS.SERVER_URL;

export const getCheckList =
  (projectId, phaseId, processId) => async (dispatch) => {
    dispatch({
      type: GET_CHECKLIST,
      payload: [],
      loading: true,
    });
    try {
      const { data } = await getCheckListService(projectId, phaseId, processId);
      dispatch({
        type: GET_CHECKLIST,
        payload: data,
        loading: false,
      });
    } catch (e) {
      dispatch({
        type: GET_CHECKLIST,
        payload: [],
        loading: false,
      });
    }
  };
export const getProjectsList = (config, userRoleId) => async (dispatch) => {
  dispatch({
    payload: [],
    type: GET_PROJECT_DETAIL,
    loading: true,
  });
  try {
    const response = await axios.get(
      `${baseURL}/project?role=${userRoleId}`,
      config
    );
    const projectDetail = response.data.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );
    dispatch({
      type: GET_PROJECT_DETAIL_SUCCESS,
      payload: projectDetail ? projectDetail : [],
      loading: false,
    });
  } catch (error) {
    dispatch({
      type: GET_PROJECT_DETAIL_ERROR,
      payload: {
        message: "Error fetching students.",
      },
      loading: false,
    });
  }
};

export const getStatusProject =
  (config, projectID, status = "1", projectPhaseName) =>
  async (dispatch) => {
    dispatch({ type: GET_PROJECT_STATUS });
    try {
      const response = await axios.get(
        `${baseURL}/project/status/${projectID}`,
        config
      );
      const { data } = response;
      const phaseIndex = data.findIndex(
        ({ phase }) => phase.name === projectPhaseName
      );
      const index = phaseIndex > -1 ? phaseIndex : status;
      const projectStatus = await getProjectStatus(projectID);
      dispatch({
        type: GET_PROJECT_STATUS_SUCCESS,
        payload: response.data[index].processes,
        phases: response.data,
        loading: false,
        currProjectID: response.data[0].project,
        currProjectStage: response.data[index].phase._id,
        startDate: projectStatus.startDate,
        endDate: projectStatus.endDate,
        status: response.data[index].status,
      });
    } catch (error) {
      dispatch({
        type: GET_PROJECT_STATUS_ERROR,
        payload: {
          message: "Error fetching project detail ",
        },
      });
    }
  };

export const setCurrentSubStage = (id, name) => ({
  type: SET_SUBSTAGE,
  payload: id,
  name: name,
});

export const setCurrentPhase = (id, name) => ({
  type: SET_PHASE,
  payload: id,
  name: name,
});
