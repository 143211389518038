import {
  GET_LOGIN_DETAIL,
  GET_LOGIN_DETAIL_SUCCESS,
  GET_LOGIN_DETAIL_ERROR,
} from "../actions/types";

const INITIAL_STATE = {
  accessToken: null,
  loading: false,
  isRegisteredWithGoogle: null,
};

const loginReducer = (state = INITIAL_STATE, action = { type: "" }) => {
  switch (action.type) {
    case GET_LOGIN_DETAIL:
      return { ...state, loading: true };
    case GET_LOGIN_DETAIL_SUCCESS:
      return {
        ...state,
        data: action.data,
        accessToken: action.payload,
        loading: action.loading,
        isRegisteredWithGoogle: action.isRegisteredWithGoogle,
      };
    case GET_LOGIN_DETAIL_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default loginReducer;
