import React from "react";
import { Collapse } from "antd";
import PropTypes from "prop-types";

import BADGES_CONTENT from "../../../../components/Processes/Development/BadgesContent";
import Guidance from "../../../../components/Guidance/Guidance";
import Tooltip from "../../../../components/Tooltip/Tooltip";
const { Panel } = Collapse;

const prepTime = "15 min";
const runTime = "60 min";
const followUpTime = "15-30 min";
const people = "3-5";

function SprintZeroFive(props) {
  const { styles, badges } = props;
  const { school, victory, elder, person } = badges;

  return (
    <div className="guidancesContainer Sprintzerofive">
      <div className={styles.guidanceMainHeadingContainer}>
        <h2 className="processHeading2" id="sprint0.5" style={{ margin: 0 }}>
          Sprint 0.5
        </h2>
      </div>
      <br />
      <Guidance
        prepTime={prepTime}
        runTime={runTime}
        followUpTime={followUpTime}
        people={people}
      />

      <div className="guidanceCollapse">
        <Collapse expandIconPosition={"right"}>
          <Panel header="Why the requirement" key="1">
            <p className="processRunning">
              This is an internal exercise. While the charter meet is focused on
              rules for the team, Sprint 0.5
              <Tooltip
                headingOne="Imtehan:"
                paragraphOne={BADGES_CONTENT.SPRINT_1}
                paragraphTwo={BADGES_CONTENT.SPRINT_2}
              >
                <img src={school} alt="" className="badgeIcon" />
              </Tooltip>
              is more of a charter for the project++. The mapping of PM
              challenges at Tintash highlights the importance of a kick-off
              period where the team comes together to think through the
              product/feature set, explores the business problem together,
              understands use stories and feature flow and tests working
              together on the smallest of increments and uses that to set up the
              project infrastructure. Projects where teams took the time/were
              given the time continue to be successful in delighting the client,
              providing a good working environment for the team and generate
              profit for the company.
            </p>
          </Panel>
        </Collapse>
        <br />
        <br />
        <Collapse expandIconPosition={"right"}>
          <Panel header="What is the requirement" key="2">
            <div className="row">
              <div className="col-12 processRunning">
                <p className="processRunning">
                  This is an internal exercise. It should immediately follow the
                  team charter meet. The duration is 3-5 days depending on the
                  project. Sprint 0.5, like any sprint/release period is planned
                  and has daily standups and syncups. The difference is content:
                </p>
                <ul>
                  <li>
                    Plan: Sprint 0.5 is heavy on planning – think through the
                    product together, understand the client and their goals
                  </li>
                  <li>
                    Development: Sprint 0.5 is light on development. Choose a
                    very small increment to develop (design, code, QA). Jump
                    right into it and use this to:
                    <ul>
                      <li>
                        Set up the project infrastructure
                        <Tooltip
                          headingOne="Champions ka tareeqa"
                          paragraphOne={BADGES_CONTENT.INFRASTRUCTURE}
                          direction="right"
                        >
                          <img src={victory} alt="" className="badgeIcon" />
                        </Tooltip>
                      </li>
                      <li>
                        Create a feature flow
                        <Tooltip
                          headingOne="Champions ka tareeqa"
                          paragraphOne={BADGES_CONTENT.FLOW}
                          direction="right"
                        >
                          <img src={victory} alt="" className="badgeIcon" />
                        </Tooltip>
                      </li>
                      <li>Discuss user stories</li>
                      <li>
                        Discuss ticket writing
                        <Tooltip
                          headingOne="Buzurg kehte hain"
                          paragraphOne={BADGES_CONTENT.TICKET}
                          direction="right"
                        >
                          <img src={elder} alt="" className="badgeIcon" />
                        </Tooltip>
                        for the project
                      </li>
                      <li>Establish a code review practice</li>
                      <li>Discuss technical debt and it’s handling</li>
                      <li>
                        ‘Charter’
                        <Tooltip
                          headingOne="Buzurg kehte hain"
                          paragraphOne={BADGES_CONTENT.CHARTER}
                          direction="right"
                        >
                          <img src={elder} alt="" className="badgeIcon" />
                        </Tooltip>
                        ways of working
                        <Tooltip
                          headingOne="Champions ka tareeqa"
                          paragraphOne={BADGES_CONTENT.WORKING}
                          direction="right"
                        >
                          <img src={victory} alt="" className="badgeIcon" />
                        </Tooltip>
                      </li>
                    </ul>
                  </li>
                  <li>
                    Presentation: Present the outcome
                    <Tooltip
                      headingOne="Imtehan"
                      paragraphOne={BADGES_CONTENT.PRESENTATION_1}
                      hasQuizOne={true}
                      quizOneHeadingText={"Click for jawab"}
                      quizOneParagraphOne={BADGES_CONTENT.PRESENTATION_2}
                      direction="right"
                    >
                      <img src={school} alt="" className="badgeIcon" />
                    </Tooltip>
                    of Sprint 0.5 to the client.
                    <Tooltip
                      headingOne="Client ke dil se"
                      paragraphOne={BADGES_CONTENT.CLIENT}
                      direction="right"
                    >
                      <img src={person} alt="" className="badgeIcon" />
                    </Tooltip>
                  </li>
                </ul>

                <h1 className="processHeading4 italic">Before the meeting</h1>
                <ul>
                  <li>Send the agenda at least a day in advance</li>
                  <li>
                    Share any project related materials that you have with the
                    agenda
                  </li>
                  <li>
                    Assigning different agenda items to individual team members
                    <Tooltip
                      headingOne="Champions ka tareeqa"
                      paragraphOne={BADGES_CONTENT.MEMBERS}
                      direction="right"
                    >
                      <img src={victory} alt="" className="badgeIcon" />
                    </Tooltip>
                    is a good way to get ownership and to have them engage
                    during the meeting
                  </li>
                  <li>Assign a notetaker</li>
                </ul>

                <h1 className="processHeading4 italic">During the Meeting</h1>
                <ul>
                  <li>Make space for different points of view</li>
                  <li>
                    Be prepared to prevent the discussion from spiralling out of
                    scope or go in circles without dismissing what people are
                    saying. Extract the main point people are making and repeat
                    that. Phrases: let’s park this and come back to it at the
                    end if we have time, let’s address that at point x, this is
                    an important discussion, I will book time for this during...
                  </li>
                  <li>
                    Show interest when a team member shares a thought or an idea
                    by asking questions about it
                  </li>
                  <li>
                    If a team member is quiet, encourage them to contribute
                    without putting them on the spot. You can do this by raising
                    something that related to their area of
                    responsibility/expertise
                  </li>
                </ul>

                <h1 className="processHeading4 italic">After the Meeting</h1>
                <ul>
                  <li>
                    Write down the project’ charter’ points and share with the
                    team by email + place in office or online visibly
                  </li>
                  <li>
                    Add the feature flow to the project workplan and update the
                    key milestones as relevant
                  </li>
                </ul>
              </div>
            </div>
          </Panel>
        </Collapse>
        <br />
      </div>
    </div>
  );
}

SprintZeroFive.protoTypes = {
  styles: PropTypes.any,
  reqImg: PropTypes.string,
};

export default SprintZeroFive;
