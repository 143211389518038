/// PROJECT DETAILS
export const GET_PROJECT_DETAIL = "GET_PROJECT_DETAIL";
export const GET_PROJECT_DETAIL_SUCCESS = "GET_PROJECT_DETAIL_SUCCESS";
export const GET_PROJECT_DETAIL_ERROR = "GET_PROJECT_DETAIL_ERROR";

/// LOGIN
export const GET_LOGIN_DETAIL = "GET_LOGIN_DETAIL";
export const GET_LOGIN_DETAIL_SUCCESS = "GET_LOGIN_DETAIL_SUCCESS";
export const GET_LOGIN_DETAIL_ERROR = "GET_LOGIN_DETAIL_ERROR";

/// UPDATE FORM
export const GET_UPDATE_DETAIL = "GET_UPDATE_DETAIL";
export const GET_UPDATE_DETAIL_SUCCESS = "GET_UPDATE_DETAIL_SUCCESS";
export const GET_UPDATE_DETAIL_ERROR = "GET_UPDATE_DETAIL_ERROR";

/// PROJECT STATUS
export const GET_PROJECT_STATUS = "GET_PROJECT_STATUS";
export const GET_PROJECT_STATUS_SUCCESS = "GET_PROJECT_STATUS_SUCCESS";
export const GET_PROJECT_STATUS_ERROR = "GET_PROJECT_STATUS_ERROR";

/// SYNCUP UPDATE
export const ADD_SYNCUP_UPDATE = "ADD_SYNCUP_UPDATE";
export const ADD_SYNCUP_UPDATE_SUCCESS = "ADD_SYNCUP_UPDATE_SUCCESS";
export const ADD_SYNCUP_UPDATE_ERROR = "ADD_SYNCUP_UPDATE_ERROR";

/// CLIENT UPDATE
export const ADD_CLIENT_UPDATE = "ADD_CLIENT_UPDATE";
export const ADD_CLIENT_UPDATE_SUCCESS = "ADD_CLIENT_UPDATE_SUCCESS";
export const ADD_CLIENT_UPDATE_ERROR = "ADD_CLIENT_UPDATE_ERROR";

// GET UPDATE
export const GET_ALL_UPDATES = "GET_ALL_UPDATES";

///SET SUBSTAGE
export const SET_SUBSTAGE = "SET_SUBSTAGE";

///SET SUBPHASE
export const SET_PHASE = "SET_PHASE";

export const GET_CHECKLIST = "GET_CHECKLIST";

export const USER_LOGOUT = "USER_LOGOUT";

export const PROJECT_STATUSES = {
  COMPLETED: "completed",
  PENDING_FOR_APPROVAL: "pending for approval",
  IN_PROGRESS: "in progress",
  LOCKED: "locked",
  DISABLE: "disable",
};
