import React from "react";

function Principles(props) {
  return (
    <div className="principlesContainer">
      <h1 className="processHeading2">Applying the Principles</h1>
      <div className="principles">
        <div className="row">
          <div className="col-12">
            <div className="principle">
              <div className="row">
                <div className="col-1">
                  <img
                    className="img-fluid"
                    src={props.principleone}
                    alt="principle"
                  />
                </div>
                <div className="col-11">
                  <h1 className="processHeading4">Delight the Customer</h1>
                  <ul className="processRunning">
                    <li>The product works as intended</li>
                    <li>
                      You provide high quality documentation (client facing +
                      technical)
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="principle">
              <div className="row">
                <div className="col-1">
                  <img
                    className="img-fluid"
                    src={props.principletwo}
                    alt="principle"
                  />
                </div>
                <div className="col-11">
                  <h1 className="processHeading4">
                    Good working environment for the team
                  </h1>
                  <ul className="processRunning">
                    <li>Feedback from the PM (positive and negative)</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="principle" style={{ borderBottom: "none" }}>
              <div className="row">
                <div className="col-1">
                  <img
                    className="img-fluid"
                    src={props.principlethree}
                    alt="principle"
                  />
                </div>
                <div className="col-11">
                  <h1 className="processHeading4">
                    Profitability for the company
                  </h1>
                  <ul className="processRunning">
                    <li>
                      Clear roles: QA driving the sprint, engineering team
                      setting up additional infrastructure as needed
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Principles;
