import React from "react";
import PropTypes from "prop-types";

import guidanceone from "../../assets/images/Discovery/guidance-one.svg";
import guidancetwo from "../../assets/images/Discovery/guidance-two.png";
import guidancethree from "../../assets/images/Discovery/guidance-three.png";
import guidancefour from "../../assets/images/Discovery/guidance-four.png";
import releasePlanningImg from "../../assets/images/Development/releasePlanningImg.png";
import devKickOffImg from "../../assets/images/Development/devKickOffImg.png";
import dailyStandupImg from "../../assets/images/Development/dailyStandupImg.png";
import updateMeetingsImg from "../../assets/images/Development/updateMeetingsImg.png";
import milestoneDemoImg from "../../assets/images/Development/milestoneDemoImg.png";
import sprintImg from "../../assets/images/Development/sprintImg.png";
import teamCharterImg from "../../assets/images/Development/teamCharterImg.png";

import { CheckList } from "../../pages/LandingSite/Discovery/components/CheckList";
import { ReviewEstimates } from "../../pages/LandingSite/LeadConversion/components/ReviewEstimates";
import { HandOver } from "../../pages/LandingSite/LeadConversion/components/HandOver";
import { ConversionRetro } from "../../pages/LandingSite/LeadConversion/components/ConversionRetro";
import { DiscoveryPlanning } from "../../pages/LandingSite/Discovery/components/DiscoveryPlanning";
import { DiscoveryKickOff } from "../../pages/LandingSite/Discovery/components/DiscoveryKickOff";
import { Dogfooding } from "../../pages/LandingSite/Polishing/components/DogFooding";
import { ExecutionMeeting } from "../../pages/LandingSite/Release/components/ExecutionMeeting";
import { HandoverMeeting } from "../../pages/LandingSite/Release/components/HandoverMeeting";
import { NangaParbat } from "../../pages/LandingSite/LeadConversion/components/NangaParbat";
import CharterMeet from "../../pages/LandingSite/Development/components/CharterMeet";
import SprintZeroFive from "../../pages/LandingSite/Development/components/SprintZeroFive";
import ReleasePlanning from "../../pages/LandingSite/Development/components/RelealsePlanning";
import DevelopmentKickOff from "../../pages/LandingSite/Development/components/DevelopmentKickOff";
import DailyStandup from "../../pages/LandingSite/Development/components/DailyStandup";
import UpdateMeetings from "../../pages/LandingSite/Development/components/UpdateMeetings";
import MilestoneDemo from "../../pages/LandingSite/Development/components/MilestoneDemo";
import StatusReport from "../../pages/LandingSite/Development/components/StatusReport";
import ReleaseChecklist from "../../pages/LandingSite/Development/components/ReleaseChecklist";
import styles from "../../components/Processes/Development/development.module.css";
import WhatDoesThisDoForMe from "../../pages/Introduction/whyThePlaybook/WhatDoesThisDoForMe";
import { ProjectWorkplan } from "../../pages/LandingSite/Discovery/components/ProjectWorkplan";
import Umar from "../../pages/Introduction/whyThePlaybook/Umar";
import Fatima from "../../pages/Introduction/whyThePlaybook/Fatima";
import Saima from "../../pages/Introduction/whyThePlaybook/Saima";
import Hamza from "../../pages/Introduction/whyThePlaybook/Hamza";

import elder from "../../assets/icons/elderly.svg";
import person from "../../assets/icons/person.svg";
import help from "../../assets/icons/help.svg";
import school from "../../assets/icons/school.svg";
import victory from "../../assets/icons/victory.svg";
import vector from "../../assets/icons/vector.svg";
import crossIcon from "../../assets/icons/crossIcon.svg";
import "./guideLines.scss";
import LeadConversionChecklist from "../../pages/LandingSite/LeadConversion/components/LeadConversionChecklist";

export function GuideLines(props) {
  const { selectedGuideline } = props;
  const badges = { elder, person, help, school, victory, vector };
  return (
    <>
      <div className="guidelines-section-wrapper container pr-24 pl-24">
        <div className="close-button-container flex">
          <div onClick={props.close} className="mobile-hidden closeButton">
            Close
          </div>
          <div
            onClick={props.close}
            className="desktop-hidden closeButton mt-24"
          >
            <img src={crossIcon} alt="cross-icon" />
          </div>
        </div>
        {selectedGuideline === "Review of estimates" ? (
          <ReviewEstimates badges={badges} />
        ) : selectedGuideline === "Hand over to PM meeting" ? (
          <HandOver badges={badges} />
        ) : selectedGuideline === "Conversion retro" ? (
          <ConversionRetro badges={badges} />
        ) : selectedGuideline === "Discovery Planning Meeting (internal)" ? (
          <DiscoveryPlanning badges={badges} />
        ) : selectedGuideline === "Discovery Kickoff Meeting (with client)" ? (
          <DiscoveryKickOff badges={badges} />
        ) : selectedGuideline === "Dogfooding" ? (
          <Dogfooding badges={badges} />
        ) : selectedGuideline === "nangaParbat" ? (
          <NangaParbat badges={badges} />
        ) : selectedGuideline === "Release Execution Meeting" ? (
          <ExecutionMeeting badges={badges} />
        ) : selectedGuideline === "Charter Meet" ? (
          <CharterMeet
            reqImg={teamCharterImg}
            styles={styles}
            badges={badges}
          />
        ) : selectedGuideline === "Closure/handover Meeting" ? (
          <HandoverMeeting
            guidanceone={guidanceone}
            guidancetwo={guidancetwo}
            guidancethree={guidancethree}
            guidancefour={guidancefour}
            badges={badges}
          />
        ) : selectedGuideline === "Sprint 0.5" ? (
          <SprintZeroFive
            guidanceone={guidanceone}
            guidancetwo={guidancetwo}
            guidancethree={guidancethree}
            guidancefour={guidancefour}
            reqImg={sprintImg}
            styles={styles}
            badges={badges}
          />
        ) : selectedGuideline === "Project Work-Plan" ? (
          <ProjectWorkplan />
        ) : selectedGuideline === "Release Planning Meeting" ? (
          <ReleasePlanning
            reqImg={releasePlanningImg}
            styles={styles}
            guidanceone={guidanceone}
            guidancetwo={guidancetwo}
            guidancethree={guidancethree}
            guidancefour={guidancefour}
            badges={badges}
          />
        ) : selectedGuideline === "Dev Kickoff Meeting" ? (
          <DevelopmentKickOff
            reqImg={devKickOffImg}
            styles={styles}
            guidanceone={guidanceone}
            guidancetwo={guidancetwo}
            guidancethree={guidancethree}
            guidancefour={guidancefour}
          />
        ) : selectedGuideline === "Daily Standup and Syncups" ||
          selectedGuideline === "Daily standups and syncups" ? (
          <DailyStandup
            reqImg={dailyStandupImg}
            styles={styles}
            guidanceone={guidanceone}
            guidancetwo={guidancetwo}
            guidancethree={guidancethree}
            guidancefour={guidancefour}
          />
        ) : selectedGuideline === "Update Meetings (with client)" ||
          selectedGuideline === "Daily updates to clients" ? (
          <UpdateMeetings
            reqImg={updateMeetingsImg}
            styles={styles}
            guidanceone={guidanceone}
            guidancetwo={guidancetwo}
            guidancethree={guidancethree}
            guidancefour={guidancefour}
            badges={badges}
          />
        ) : selectedGuideline === "Status Report" ? (
          <StatusReport styles={styles} />
        ) : selectedGuideline === "Discovery checklist" ? (
          <CheckList />
        ) : selectedGuideline === "Lead conversion checklist" ? (
          <LeadConversionChecklist />
        ) : selectedGuideline === "Milestone Demo" ? (
          <MilestoneDemo
            styles={styles}
            guidanceone={guidanceone}
            guidancetwo={guidancetwo}
            guidancethree={guidancethree}
            guidancefour={guidancefour}
            reqImg={milestoneDemoImg}
          />
        ) : selectedGuideline === "Release Checklist" ? (
          <ReleaseChecklist styles={styles} />
        ) : selectedGuideline === "What does this do for me" ? (
          <WhatDoesThisDoForMe />
        ) : selectedGuideline === "Umar" ? (
          <Umar />
        ) : selectedGuideline === "Fatima" ? (
          <Fatima />
        ) : selectedGuideline === "Saima" ? (
          <Saima />
        ) : selectedGuideline === "Hamza" ? (
          <Hamza />
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

GuideLines.protoTypes = {
  selectedGuideline: PropTypes.string,
};
