import React from "react";
import { Collapse } from "antd";
import PropTypes from "prop-types";

import BADGES_CONTENT from "../../../../components/Processes/Development/BadgesContent";
import Guidance from "../../../../components/Guidance/Guidance";
import Tooltip from "../../../../components/Tooltip/Tooltip";
const { Panel } = Collapse;

const prepTime = "15mins";
const runTime = "60mins";
const followUpTime = "15-30mins";
const people = "4-8";
function CharterMeet(props) {
  const { styles, badges } = props;
  const { victory, elder } = badges;
  return (
    <div className="guidancesContainer TeamChartMeet">
      <div className={styles.guidanceMainHeadingContainer}>
        <h2 className="processHeading2" id="charterMeet" style={{ margin: 0 }}>
          Team Charter Meet
        </h2>
        <br />
        <br />
      </div>
      <Guidance
        prepTime={prepTime}
        runTime={runTime}
        followUpTime={followUpTime}
        people={people}
      />

      <br />
      <br />
      <div className="guidanceCollapse">
        <Collapse expandIconPosition={"right"}>
          <Panel header="Why the requirement" key="1">
            <p className="processRunning">
              At this point in a project’s life, a team at Tintash could be
              starting where Discovery concluded and taking on additional and/or
              replacement team members to continue the project, or a team could
              be starting fresh on a project based on a SOW without a discovery.
              This is an internal team meeting.
            </p>
            <div className={styles.reqsInnerBox}>
              <p className="processRunning">
                The purpose of this meet is for the team to:
                <ol>
                  <li>
                    Get to know each other in these roles for this project (even
                    if everyone knows each other from before)
                  </li>
                  <li>
                    Establish some rules for how to work together on this
                    particular project
                  </li>
                </ol>
              </p>
            </div>
          </Panel>
        </Collapse>
        <br />
        <br />
        <Collapse expandIconPosition={"right"}>
          <Panel header="What is the requirement" key="2">
            <div className="row">
              <div className="col-12">
                <div className="guidanceReqInnerBox">
                  <b className="mandatory-participants">
                    Mandatory participants:
                  </b>
                  &nbsp;PM, all team members, Account Manager (optional)
                </div>
                <p className="processRunning">
                  This is an internal meeting. It is a 2-hour session organised
                  by the PM in their own style but that, at a minimum, covers
                  the following:
                </p>
                <ul className="processRunning">
                  <li>Team introductions</li>
                  <li>
                    “Rules of collaboration”
                    <Tooltip
                      headingOne="Champions ka tareeqa"
                      paragraphOne={BADGES_CONTENT.COLLABORATION}
                      direction="right"
                    >
                      <img src={victory} alt="" className="badgeIcon" />
                    </Tooltip>
                    - how will the team work together
                    <Tooltip
                      headingOne="Buzurg kehte hein"
                      paragraphOne={BADGES_CONTENT.TOGETHETR}
                      direction={`${screen.width <= 1600 ? "left" : "right"}`}
                    >
                      <img src={elder} alt="" className="badgeIcon" />
                    </Tooltip>
                  </li>
                  <li>
                    “Rules of communication” - how will the team communicate
                    with each other and the client
                  </li>
                  <li>
                    “Rules of delivery”
                    <Tooltip
                      headingOne="Buzurg kehte hein"
                      paragraphOne={BADGES_CONTENT.DELIVERY}
                      direction="right"
                    >
                      <img src={elder} alt="" className="badgeIcon" />
                    </Tooltip>
                    – how will each team member
                    <Tooltip
                      headingOne="Champions ka tareeqa"
                      paragraphOne={BADGES_CONTENT.MEMBER}
                      direction={`${screen.width <= 1500 ? "left" : "right"}`}
                    >
                      <img src={victory} alt="" className="badgeIcon" />
                    </Tooltip>
                    approach their work
                  </li>
                  <li>Tools and frequency of communication</li>
                </ul>
                <p className="processRunning">
                  Tintash does not require a formal charter document. The rules
                  <Tooltip
                    headingOne="Champions ka tareeqa"
                    paragraphOne={BADGES_CONTENT.RULES}
                    direction={`${screen.width <= 1500 ? "left" : "right"}`}
                  >
                    <img src={victory} alt="" className="badgeIcon" />
                  </Tooltip>
                  can be written down in a google document, on a whiteboard, on
                  a large sheet placed on a wall, any other medium of the team’s
                  choice. The format is not important, it should not be a long
                  document or an essay, just a few bullet points (around 7-10)
                  clearly visible and accessible to all team members where they
                  sit (or where they collaborate online).
                </p>
                <h1 className="processHeading4 italic">Before the Meeting</h1>
                <ul className="processRunning">
                  <li>Send the agenda at least a day in advance</li>
                  <li>
                    Share any materials you would like the team to be familiar
                    with
                  </li>
                  <li>
                    Prepare how you will conduct introductions and the
                    discussions on rules (ice breaker, exercises etc. depending
                    on the team)
                  </li>
                  <li>Get familiarised with all team members’ backgrounds</li>
                  <li>Assign a notetaker</li>
                </ul>
                <h1 className="processHeading4 italic">During the Meeting</h1>
                <ul className="processRunning">
                  <li>Make space for different points of view</li>
                  <li>
                    Be prepared to prevent the discussion from spiralling out of
                    scope or go in circles without dismissing what people are
                    saying. Extract the main point people are making and repeat
                    that
                  </li>
                  <li>
                    Show interest when a team member shares a thought or an idea
                    by asking questions about it
                  </li>
                  <li>
                    If a team member is quiet, encourage them to contribute
                    without putting them on the spot. You can do this by raising
                    something that related to their area of
                    responsibility/expertise
                  </li>
                </ul>
                <h1 className="processHeading4 italic">After the Meeting</h1>
                <ul className="processRunning">
                  <li>
                    Write the bullet points down and share with the team by
                    email as well as put in a visible place in the office or
                    online
                  </li>
                </ul>
              </div>
            </div>
          </Panel>
        </Collapse>
      </div>
      <br />
    </div>
  );
}

CharterMeet.protoTypes = {
  styles: PropTypes.any,
};
export default CharterMeet;
