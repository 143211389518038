import "../Navbar/navbar.scss";
import "./MainPageNav.scss";

import React from "react";
import { Link } from "react-router-dom";

import logo from "../../assets/images/logo.png";
import { ROUTES } from "../../routes/routeConstants";

function MainPageNav() {
  const isActiveRoute = (route) =>
    location.pathname.includes(route) ? "main-active-navbar-item" : "";

  return (
    <>
      <div className="row main-page-header">
        <div className="logo-container-main-page col-1">
          <Link to={ROUTES.HOME_PAGE}>
            <img src={logo} alt="logo" className="img-fluid" width="150" />
          </Link>
        </div>

        <div className="col-1"></div>
        <div className="header-main-items-wrapper col-8">
          <ul className="flex flex-center navbar-items-wrapper">
            <li
              className={`main-navbar-item ${isActiveRoute(
                ROUTES.LEAD_CONVERSION
              )}`}
            >
              <a
                className="main-navbar-link no-wrap"
                aria-current="page"
                href={ROUTES.LEAD_CONVERSION}
              >
                Lead Conversion
              </a>
            </li>
            <li
              className={`main-navbar-item ${isActiveRoute(ROUTES.DISCOVERY)}`}
            >
              <a className="main-navbar-link active" href={ROUTES.DISCOVERY}>
                Discovery
              </a>
            </li>
            <li
              className={`main-navbar-item ${isActiveRoute(
                ROUTES.DEVELOPMENT
              )}`}
            >
              <a className="main-navbar-link active" href={ROUTES.DEVELOPMENT}>
                Development
              </a>
            </li>
            <li
              className={`main-navbar-item ${isActiveRoute(ROUTES.POLISHING)}`}
            >
              <a className="main-navbar-link active" href={ROUTES.POLISHING}>
                Polishing
              </a>
            </li>
            <li className={`main-navbar-item ${isActiveRoute(ROUTES.RELEASE)}`}>
              <a className="main-navbar-link active" href={ROUTES.RELEASE}>
                Release
              </a>
            </li>
          </ul>
        </div>
        <div className="col-2"></div>
      </div>
    </>
  );
}

export default MainPageNav;
