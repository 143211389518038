import React, { useEffect, useState } from "react";
import { GoogleLogin } from "@react-oauth/google";
import { useHistory } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleSignIn } from "../../helper/postApis";
import styles from "./googlesignin.module.css";
import { useDispatch, useSelector } from "react-redux";
import { loginAuth } from "../../redux/actions/login";
import { message, Spin } from "antd";
import { ROUTES } from "../../routes/routeConstants";
import { ENV_CONSTANTS } from "../../constants/globalConstants";
import backgroundImage from "../../assets/images/mask-group.png";
import logo from "../../assets/images/logo.png";

const SignInGoogle = () => {
  message.config({
    duration: 3,
    maxCount: 1,
  });
  var accessToken = null;
  const history = useHistory();
  const dispatch = useDispatch();
  const isRegistered = useSelector(
    (state) => state.login.isRegisteredWithGoogle
  );
  const loading = useSelector((state) => state.login.loading);

  const [loginResponse, setLoginResponse] = useState(null);
  const [token, setToken] = useState(null);
  const [currentUserObj, setCurrentUserObj] = useState(null);

  const isLogin = async (token) => {
    try {
      const res = await GoogleSignIn(token);
      setLoginResponse(res);
      const jsonRes = JSON.parse(localStorage.getItem("userObj"));
      setCurrentUserObj(jsonRes);
      return res;
    } catch (error) {
      const jsonRes = JSON.parse(localStorage.getItem("userObj"));
      setCurrentUserObj(jsonRes);
      console.log("Error Logging through goolge");
    }
  };
  useEffect(() => {
    if (token !== null) {
      if (isRegistered === true) {
        history.push(ROUTES.DASHBOARD);
      } else if (currentUserObj?.message) {
        message.error(currentUserObj?.message);
      } else {
        console.log("Error occured while logging in");
      }
    }
  }, [loginResponse, token, isRegistered, currentUserObj]);

  const useGoogleAuthentication = () => {
    const handleSuccess = async (response) => {
      if ("credential" in response) {
        accessToken = response.credential;
        setToken(accessToken);
        await isLogin(accessToken);
        dispatch(loginAuth(accessToken));
      }
    };
    return {
      handleSuccess,
    };
  };

  const { handleSuccess } = useGoogleAuthentication();
  return (
    <>
      <div className={`flex ${styles.loginPageContainer} `}>
        <img className={styles.loginPageImage} src={backgroundImage} />
        <a className={styles.logo} href="/">
          <img className={styles.logoImage} src={logo} />
        </a>
        <div className={styles.pageContent}>
          <div className={styles.heading}>Welcome</div>

          <p className={styles.welcomeContent}>
            Welcome to playbook for managers <br /> Login with your email
          </p>

          <br />

          <div className={styles.buttonContainer}>
            <header className="App-header">
              <div className={styles.login}>
                <GoogleOAuthProvider clientId={ENV_CONSTANTS.GCP_CLIENT_ID}>
                  <GoogleLogin
                    buttonText="Log in with Google"
                    onSuccess={handleSuccess}
                    onFailure={() => {
                      console.log("login failed");
                    }}
                  />
                </GoogleOAuthProvider>
              </div>
            </header>
          </div>
          {loading && (
            <div>
              <Spin className="flex content-center" size="large" />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default SignInGoogle;
