import "./scrollToTopButton.scss";

import React, { useState } from "react";
import PropTypes from "prop-types";

import ScrollTopButton from "../../assets/icons/scroll-to-top.svg";

import ScrollTopButtonHover from "../../assets/icons/scroll-to-top-hover.svg";

const ScrollToTopButton = ({ pageTopRef }) => {
  const [hovered, setHovered] = useState(false);

  const handleOnButtonClick = () => {
    pageTopRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const mouseEnter = () => {
    setHovered(true);
  };

  const mouseLeave = () => {
    setHovered(false);
  };

  return (
    <div className="row">
      <div className="col-2"></div>

      <div className="col-8 scroll-to-top-container">
        <button
          onClick={handleOnButtonClick}
          className="scroll-to-top-button mb-100"
        >
          <img
            onMouseEnter={mouseEnter}
            onMouseLeave={mouseLeave}
            src={hovered ? ScrollTopButtonHover : ScrollTopButton}
          ></img>
        </button>
      </div>
      <div className="col-2"></div>
    </div>
  );
};

ScrollToTopButton.propTypes = {
  pageTopRef: PropTypes.any.isRequired,
};
export default ScrollToTopButton;
