import React from "react";
function Principles(props) {
  return (
    <div className="principlesContainer">
      <h1 className="processHeading2">Applying the Principles</h1>
      <div className="principles">
        <div className="row">
          <div className="col-12">
            <div className="principle">
              <div className="row">
                <div className="col-1">
                  <img
                    className="img-fluid"
                    src={props.principleone}
                    alt="principle"
                  />
                </div>
                <div className="col-11">
                  <h1 className="processHeading4">Delight the Customer</h1>
                  <ul>
                    <li>
                      The team provides meaningful updates including visual
                      demos
                    </li>
                    <li>
                      The team raises concerns/issues as soon as they arise and
                      have suggestions for addressing them
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="principle">
              <div className="row">
                <div className="col-1">
                  <img
                    className="img-fluid"
                    src={props.principletwo}
                    alt="principle"
                  />
                </div>
                <div className="col-11">
                  <h1 className="processHeading4">
                    Good working environment for the team
                  </h1>
                  <ul>
                    <li>
                      PM is engaged and aware of progress, concerns and team
                      dynamics; gets support (e.g., from team managers) as
                      needed.
                    </li>
                    <li>PM gives frequent and clear feedback</li>
                    <li>
                      Design and QA teams feel heard and included as equals
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="principle" style={{ borderBottom: "none" }}>
              <div className="row">
                <div className="col-1">
                  <img
                    className="img-fluid"
                    src={props.principlethree}
                    alt="principle"
                  />
                </div>
                <div className="col-11">
                  <h1 className="processHeading4">
                    Profitability for the company
                  </h1>
                  <ul>
                    <li>
                      Designers, engineers and QA engineers work well together
                      in a smooth flow
                    </li>
                    <li>PM has ownership of the budget</li>
                    <li>Code quality is high (reputation &amp; loyalty)</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Principles;
