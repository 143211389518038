import React from "react";
import { Tooltip } from "antd";
import complete from "../../assets/icons/check.svg";
import warningOrange from "../../assets/icons/warningOrange.svg";
import warningRed from "../../assets/icons/warningRed.svg";
import "./sidebarItem.scss";
import { useEffect } from "react";

const PROCESSES_STAGE = {
  completed: complete,
  rejected: warningRed,
  "pending for approval": warningOrange,
};
const PROCESSES_MESSAGE = {
  completed: "Documents Approved",
  rejected: "Documents Rejected",
  "pending for approval": "Documents Pending for Approval",
};
export const SidebarItem = ({ isActive, projectDetails, onClick }) => {
  const { process, name, status } = projectDetails;
  useEffect(() => {
    isActive ? scrollToActive() : null;
  }, [isActive]);
  const scrollToActive = () => {
    const sidebarItem = document.getElementsByClassName(`active-sidebar-item`);
    sidebarItem[0].scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  };
  return (
    <div
      className={`${isActive ? "active-sidebar-item" : ""} sidebar-item`}
      onClick={onClick}
    >
      <div className="flex">
        <div className="project-title">{name} </div>
        <Tooltip
          placement="topLeft"
          color="white"
          overlayInnerStyle={{
            color: "black",
          }}
          title={PROCESSES_MESSAGE[process.status]}
        >
          {PROCESSES_STAGE[process.status] && (
            <img
              src={PROCESSES_STAGE[process.status]}
              className="img-fluid w-12 mb-16"
            />
          )}
        </Tooltip>
      </div>
      <div className={status === "closed" ? "project-status" : "project-info"}>
        {status === "closed" ? status : process.name}
      </div>
    </div>
  );
};
