import React, { useState } from "react";

import { scrollToClickedButton } from "../../../../components/Processes/LeadConversion/components/WhatHappensData";

export const ProjectWorkplan = () => {
  const [checklist, setChecklist] = useState("lead");
  const maxScreenWidth = 9999;

  return (
    <div id="project-workplan" className="processChecklist">
      <h1 className="processHeading2">Project Work Plan</h1>

      <div className="row">
        <div className="col-4">
          <div
            className={
              checklist === "lead"
                ? "discoveryChecklistActive"
                : "discoveryChecklist"
            }
            onClick={(e) => {
              setChecklist("lead");
              scrollToClickedButton(e, maxScreenWidth);
            }}
            data-button-id="btnOne"
          >
            During Lead Conversion
          </div>
        </div>
        <div className="col-4">
          <div
            className={
              checklist === "discovery"
                ? "discoveryChecklistActive"
                : "discoveryChecklist"
            }
            onClick={(e) => {
              setChecklist("discovery");
              scrollToClickedButton(e, maxScreenWidth);
            }}
            data-button-id="btnTwo"
          >
            During Discovery
          </div>
        </div>
        <div className="col-4">
          <div
            className={
              checklist === "development"
                ? "discoveryChecklistActive"
                : "discoveryChecklist"
            }
            onClick={(e) => {
              setChecklist("development");
              scrollToClickedButton(e, maxScreenWidth);
            }}
            data-button-id="btnThree"
          >
            During Development
          </div>
        </div>
      </div>
      <div className="col-12 horizontal-rule mobile-hidden">
        <hr />
      </div>

      <div className="checklistPointers">
        <h1 className="processHeading4">Pointers</h1>
        <ul>
          {checklist === "lead" ? (
            <>
              <li>
                A project workplan is started with very few, biggest milestones.
              </li>
              <li>It helps confirm feasibility, scope, and time estimates.</li>
              <li>
                A project folder is created in the filing tree/intranet and the
                project workplan is filed there for easy reference.
              </li>
            </>
          ) : checklist === "discovery" ? (
            <>
              <li>
                The workplan is updated – both in terms of an adjustment of the
                timeline as needed and addition of some detail/some smaller
                milestones as things become clearer.
              </li>
            </>
          ) : checklist === "development" ? (
            <>
              <li>
                At kick-off, the workplan is updated to include a release
                schedule and feature flow for easy reference.
              </li>
              <li>
                It is used to guide discussions with the client and check
                feasibility and impact of change requests or other requests.
              </li>
            </>
          ) : (
            <></>
          )}
        </ul>
      </div>
    </div>
  );
};
