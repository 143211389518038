import React from "react";
import { Collapse } from "antd";
import BADGES_CONTENT from "../../../../components/Processes/LeadConversion/BadgesContent";

import Guidance from "../../../../components/Guidance/Guidance";
import Tooltip from "../../../../components/Tooltip/Tooltip";
const { Panel } = Collapse;

const prepTime = "15 min";
const runTime = "60 min";
const followUpTime = "15-30 min";
const people = "3-5";

export function ConversionRetro(props) {
  const { victory } = props.badges;
  return (
    <div className="guidancesContainer retroContainer">
      <h1 className="processHeading2" id="conversionRetro">
        Conversion Retro
      </h1>
      <br />
      <Guidance
        prepTime={prepTime}
        runTime={runTime}
        followUpTime={followUpTime}
        people={people}
      />

      <br />
      <br />
      <Collapse expandIconPosition={"right"}>
        <Panel header="Why the requirement: Learn &amp; Grow" key="1">
          <p className="processRunning">
            The best of intentions do not guarantee a perfect process. It is
            important to learn from both the mistakes and the successes. So we
            build on what we do well and share it with others. And so we do not
            repeat our mistakes, as individuals, teams and the organisation.
          </p>
        </Panel>
      </Collapse>
      <br />
      <br />
      <Collapse expandIconPosition={"right"}>
        <Panel header="What is the requirement" key="1">
          <div className={"guidanceReqInnerBox"}>
            <p>
              <b className="mandatory-participants">Mandatory participants:</b>{" "}
              Deal Driver, Experts who provided and reviewed the estimates, PM,
              Account Manager (if assigned)
            </p>
          </div>
          <div className="processRunning">
            The Deal Driver is responsible for sharing the agenda and conducting
            the meeting. You should draft and communicate it in your own style.
            However, it must include:
            <br />
            <ul>
              <li>
                Brief overview of the lead conversion process (5 minutes or
                less)
              </li>
              <li>What went well</li>
              <li> What did not go well </li>
              <li>
                Takeaways
                <Tooltip
                  headingOne="Champions ka tareeqa"
                  paragraphOne={BADGES_CONTENT.TAKEAWAYS}
                  direction="right"
                >
                  <img src={victory} alt="" className="badgeIcon" />
                </Tooltip>
              </li>
            </ul>
            Make the meeting interactive. Do not use a PowerPoint after the
            first 5 minutes of providing an overview of the lead conversion
            process.
            <br />
            <br /> For each of the next three agenda items, ask people to work
            during the meeting. This can be in different forms including, but
            not limited to:
            <ul>
              <li>A discussion in pairs and then discussion in plenary</li>
              <li>
                Use of post-its that then go under the relevant column on a wall
                / white board
              </li>
              <li> Use of miro / trello</li>
            </ul>
            <p className="processRunning">
              Choose your method and prepare accordingly before the meeting.
              <br />
              No prep required from the participants.
            </p>
          </div>
        </Panel>
      </Collapse>
    </div>
  );
}
