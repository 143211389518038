import React, { useState } from "react";

import { scrollToClickedButton } from "../../../../components/Processes/LeadConversion/components/WhatHappensData";

const LeadConversionChecklist = () => {
  const [checklist, setChecklist] = useState("product");

  const maxScreenWidth = 9999;

  return (
    <div id="lead-conversion-checklist" className="processChecklist">
      <h1 className="processHeading2">Lead Conversion Checklist</h1>

      <div className="row">
        <div className="col-4">
          <div
            className={
              checklist === "product"
                ? "leadConversionChecklistActive"
                : "leadConversionChecklist"
            }
            onClick={(e) => {
              setChecklist("product");
              scrollToClickedButton(e, maxScreenWidth);
            }}
            data-button-id="btnOne"
          >
            Product/Business Concept
          </div>
        </div>
        <div className="col-4">
          <div
            className={
              checklist === "tech"
                ? "leadConversionChecklistActive"
                : "leadConversionChecklist"
            }
            onClick={(e) => {
              setChecklist("tech");
              scrollToClickedButton(e, maxScreenWidth);
            }}
            data-button-id="btnTwo"
          >
            Tech
          </div>
        </div>
        <div className="col-4">
          <div
            className={
              checklist === "law"
                ? "leadConversionChecklistActive"
                : "leadConversionChecklist"
            }
            onClick={(e) => {
              setChecklist("law");
              scrollToClickedButton(e, maxScreenWidth);
            }}
            data-button-id="btnThree"
          >
            Laws &amp; Regulations
          </div>
        </div>
      </div>
      <div className="col-12 horizontal-rule mobile-hidden">
        <hr />
      </div>

      <div className="checklistPointers">
        <h1 className="processRunningHeading">
          {checklist === "tech" ? "Pointer" : "Pointers"}
        </h1>
        <ul className="processRunning">
          {checklist === "product" ? (
            <>
              <li>
                Would you like to share your business plan with us? Or other
                plans – launch, marketing, fundraising?
              </li>
              <li>
                Did you go through a design thinking exercise in making your
                business plan?
              </li>
            </>
          ) : checklist === "tech" ? (
            <>
              <li>
                Do you have any preference for technology to use while building
                out the product?
              </li>
            </>
          ) : checklist === "law" ? (
            <>
              <li>
                Are there any laws related to the domain (e.g. health, use by
                minors) that are applicable to your product?
              </li>
              <li>
                Are there any privacy and data protection regulations to
                consider in the area you are trying to launch it in? At what
                stage?
              </li>
              <li>
                Are there universal access / accessibility of ICT solutions
                requirements that you need to comply with? At what stage?
              </li>
            </>
          ) : (
            <></>
          )}
        </ul>
      </div>
    </div>
  );
};

export default LeadConversionChecklist;
