import React from "react";
import { UpdateList } from "../../../../../UpdateSection/update-list/UpdateList";
import { HandingOverUpdateForm } from "./HandingOverUpdateForm/HandingOverUpdateForm";
export const HandingOverUpdateSection = () => {
  return (
    <div className="project-update-details-wrapper">
      <div className="update-form-wrapper">
        <HandingOverUpdateForm />
      </div>
      <div className="update-list-wrapper">
        <div className="side-update-title mt-32 ml-24">
          Project Management Events
        </div>
        <UpdateList />
      </div>
    </div>
  );
};
