import React from "react";

function Intro(props) {
  return (
    <div className={"processIntro"}>
      <div className={"row"}>
        <div className={"col-6"}>
          <h1 className={"processHeading"}>Lead Conversion</h1>
          <p className={"processRunning"}>
            The phase begins after lead qualification by BD. It ends in one of
            two different ways depending on the type of project: 1) with a
            decision to proceed with discovery and a contract signed for the
            purpose, or) with an agreed Statement of Work (SOW), for example,
            for team augmentation projects.
            <br />
            <br />
            <br />
            This phase is in the Tintash Project Cycle is led by BD who are
            responsible for getting input from relevant technical functions. The
            end of the phase involves a handover to the assigned PM.
          </p>
        </div>
        <div className={"col-6"}>
          <img className={"img-fluid"} src={props.nexttolead} alt="process" />
        </div>
      </div>
    </div>
  );
}

export default Intro;
