import "./linkWithEllipse.scss";
import React from "react";
import PropTypes from "prop-types";

import Ellipse from "../../assets/icons/Ellipse 23.svg";

function LinkWithEllipse(props) {
  const { title, onClick = () => {} } = props;
  return (
    <div>
      <img className="ellipse" src={Ellipse} />
      <div
        className="link-with-ellipse"
        onClick={() => {
          onClick(title);
        }}
      >
        {title}
      </div>
    </div>
  );
}
LinkWithEllipse.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default LinkWithEllipse;
