import React from "react";
import { useState } from "react";
import BADGES_CONTENT from "../BadgesContent";
import Pop from "../../../Popup/popup";

function EstimateColors(props) {
  const [estimate, setEstimate] = useState("greys");
  return (
    <>
      <img className={"whatImg img-fluid"} src={props.graph} alt="what" />
      <div className={"reviewColorsContainer"}>
        <div className={"row"}>
          <div className={"col-3"}>
            <div
              onClick={() => setEstimate("greys")}
              className={"reviewColorBox1 img-fluid"}
            >
              The Greys
            </div>
          </div>
          <div className={"col-3"}>
            <div
              onClick={() => setEstimate("greens")}
              className={"reviewColorBox2 img-fluid"}
            >
              The Greens
            </div>
          </div>
          <div className={"col-3"}>
            <div
              onClick={() => setEstimate("blues")}
              className={"reviewColorBox3 img-fluid"}
            >
              <span style={{ fontWeight: 600 }}>The Blues</span>
            </div>
          </div>
        </div>
        <br />
        {estimate === "greys" ? (
          <>
            <b>The Greys</b>
            <ul className="processRunning">
              <li>
                Typically, these projects are at an investor MVP stage. The
                client
                <Pop
                  src={props.badge}
                  heading="Client ke dil se"
                  dataone={BADGES_CONTENT.GREYS_CLIENT}
                />
                may have a different description, but the term is not important.
                What is important is that you are estimating based on a concept
                and very little specifications.
              </li>
              <li>
                Estimate
                <Pop
                  src={props.badge}
                  heading="Buzurg kehte hain"
                  dataone={BADGES_CONTENT.GREYS_ESTIMATE}
                />
                to be provided by: Technical Domain Expert, Estimate review by:
                UX
              </li>
            </ul>
          </>
        ) : estimate === "greens" ? (
          <>
            <b>
              The Greens
              <Pop
                src={props.badge}
                heading="Client ke dil se"
                dataone={BADGES_CONTENT.GREENS}
              />
            </b>
            <ul className="processRunning">
              <li>
                These projects are likely at a Go to Market MVP stage or meant
                to improve the product-market fit. They could be Investor MVPs
                in the hands of very technical clients who are able to describe
                the features somewhat clearly. Or they could be growth or
                improvement of existing products/systems, but the client is a
                manager without a technical background. Essentially, you receive
                some actionable detail on requirements.
              </li>
              <li>
                Estimate
                <Pop
                  src={props.badge}
                  heading="Buzurg kehte hain"
                  dataone={BADGES_CONTENT.GREENS_ESTIMATE}
                />
                to be provided by: Team Manager, Estimate Review by: a senior PM
              </li>
            </ul>
          </>
        ) : estimate === "blues" ? (
          <>
            <b>
              The Blues
              <Pop
                src={props.badge}
                heading="Client ke dil se"
                dataone={BADGES_CONTENT.BLUES}
              />
            </b>
            <ul className="processRunning">
              <li>
                These projects are mostly about long term growth of a product
                and would lean towards a team augmentation arrangement. The
                product was born before it came to you so it’s easier for both
                you and the client to discuss the requirements even for
                completely new features.
              </li>
              <li>
                Estimate to be provided by: TDE or Team Manager. No review
                needed, this is mainly about the number of people needed (and
                resultant burn rate).
              </li>
            </ul>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
export default EstimateColors;
