import React, { useState } from "react";
import { Button, Modal } from "antd";
import { useSelector } from "react-redux";

import "../ClientSatisfactionMeter/client-satsifaction-meter.scss";
import { AddFeedback } from "../../../../../../helper/postApis";
import sadImg from "../../../../../../assets/emojis/sad.svg";
import smileImg from "../../../../../../assets/emojis/smile.svg";
import happyImg from "../../../../../../assets/emojis/happy.svg";
import cryingImg from "../../../../../../assets/emojis/angry.svg";
import speechlessImg from "../../../../../../assets/emojis/speechless.svg";
import { ErrorDialog } from "../../../../../../components/Dialog/dialog";
import Graph from "../../../../../../components/Graph/graph";

const images = [
  { active: false, src: cryingImg, id: "1" },
  { active: false, src: sadImg, id: "2" },
  { active: false, src: speechlessImg, id: "3" },
  { active: false, src: smileImg, id: "4" },
  { active: false, src: happyImg, id: "5" },
];

function ClientSatisfactionMeter({
  isVisible,
  updateFeedback,
  setShowCheckinForm,
}) {
  const currentUserRole = useSelector(
    (state) => state.clientSatisfaction.userRole
  );
  const currentUserRoleId = currentUserRole?._id;
  const currentProjectId = useSelector((state) => state?.current.projectId);
  const [moods, setMoods] = useState(images);
  const [comments, setComments] = useState("");

  const handleClick = async (event) => {
    const currentId = event.target.id;
    const updated = moods.map((mood) => {
      if (mood.id === currentId) {
        mood.active = true;
      } else {
        mood.active = false;
      }
      return mood;
    });
    setMoods(updated);
  };

  const resetFields = () => {
    setComments("");
    moods.forEach((mood) => (mood.active = false));
    setShowCheckinForm(false);
  };

  const handleSubmit = async () => {
    const activeMood = moods.find(({ active }) => active === true);
    if (!activeMood) {
      return ErrorDialog({
        title: "Please choose a mood",
      });
    }
    await AddFeedback(
      currentProjectId,
      activeMood.id,
      currentUserRoleId,
      comments
    );
    resetFields();
  };
  return (
    <Modal
      title="Check-in"
      className="client-satisfaction-meter"
      visible={isVisible}
      footer={null}
      centered
      onCancel={() => {
        resetFields();
      }}
    >
      <p className="checkin-heading">How have you been feeling?</p>

      {moods.map(({ src, id, active }) => (
        <img
          key={id}
          className={`mood-emoji ${active ? "active-mood" : ""}`}
          id={id}
          src={src}
          onClick={handleClick}
        />
      ))}
      <br />
      <br />

      <Graph
        updateFeedback={updateFeedback}
        projectId={currentProjectId}
        pmId=""
      />
      <br />
      <br />

      <div className="submit-btn">
        <Button onClick={handleSubmit}>Submit</Button>
      </div>
    </Modal>
  );
}

export default ClientSatisfactionMeter;
