import React from "react";
import { Button } from "antd";
import { Collapse } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { CaretRightOutlined } from "@ant-design/icons";
import { BrowserRouter as Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Pop from "../../components/Popup/popup";
import Badge from "../../assets/images/badge.svg";
import styles from "./introduction.module.css";
import logo from "../../assets/images/logo.png";
import image1 from "../../assets/images/Introduction/nexttointro.png";
import "bootstrap/dist/css/bootstrap.min.css";

const { Panel } = Collapse;

const Index = () => {
  const history = useHistory();

  return (
    <div className={styles.hero + " px-4 text-left"}>
      <div>
        <img src={logo} alt="logo" width={100} />
        <div className={"row"}>
          <div
            className={"col-8"}
            onClick={() => history.push("/")}
            style={{ cursor: "pointer" }}
          >
            <div className={styles.heading}>
              <Link to="/">
                <LeftOutlined className="logo" />
              </Link>
              &nbsp; PLAYBOOK FOR MANAGERS
            </div>
          </div>
          <div className={"col-4"}>
            <Button
              type="primary"
              shape="round"
              size="large"
              className={styles.button + " " + styles.button__blue}
              onClick={() => history.push("/introduction")}
            >
              Why The Playbook
            </Button>
            <Button
              type="primary"
              shape="round"
              size="large"
              className={styles.button + " " + styles.button__green}
              onClick={() => history.push("/howtouse")}
            >
              How to Use
            </Button>
          </div>
        </div>
      </div>
      <div className={styles.processParentContainer}>
        <div className={"processIntro"}>
          <div className={"row"}>
            <div className={"col-8"}>
              <h1 className={"processHeading"}>Introduction</h1>
              <p className={"processRunning"}>
                There exist in this world many a project management guide –
                different methodologies all evolving, various institutions and
                changing trends. Tintash’s PM playbook is not another method, we
                are not in the business of reinventing wheels.
                <br />
                <br />
                All companies apply existing methodologies to their work as
                relevant. This translation to a company’s reality implies
                difference in terminology used for different stages of a
                project. It also means a variation in obligatory requirements
                based on the needs and realities of the company. Project
                Management at Tintash – like at any growing company – has and
                continues to evolve. Engineers have risen through ranks to
                become successful Project Managers, PMs coming from outside have
                brought their expertise and experiences with them, the company
                has groomed and mentored individuals to deliver projects the
                Tintash way- with people at the centre, always.
                <br />
                <br />
                The PM Playbook is a documentation and further development of
                this - Project Management the Tintash way. It is meant to ensure
                consistency
                <Pop
                  src={Badge}
                  heading="Buzurg kehte hain"
                  dataone="In achieving consistency, some at Tintash may experience the playbook as close to existing practice and for some it might require changing, removing, or adding to existing practices to varying degrees. This is part of our journey together as we grow."
                />
                in identity and quality in the way the organisation delivers
                projects to its clients as it grows rapidly and achieves its
                strategic ambitions. It lays out the minimum requirements for
                all projects, offers optional tools and provides expert tips.
              </p>
            </div>
            <div className={"col-4"}>
              <img
                className={"img-fluid"}
                src={image1}
                width={500}
                alt="process"
              />
            </div>
          </div>
        </div>

        <Collapse
          bordered={true}
          style={{ backgroundColor: "rgba(40, 160, 255, 0.06)" }}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
          className="site-collapse-custom-collapse"
        >
          <Panel
            header="What does this do for me?"
            key="1"
            className="site-collapse-custom-panel"
          >
            <p className={"processRunning"}>
              Clients: receive a consistent experience from Tintash over time
              and across individuals / functions. Tintash is able to demonstrate
              high value for money.
              <br />
              <br />
              Team members: have ownership of own role in a project and clear
              expectations from the organisation.
              <br />
              <br />
              Project managers: have clarity on their roles and expectations
              from the organisation and easy access to resources that they need.
              <br />
              <br />
              Tintash: increases team efficiency and, therefore, profit margins.
            </p>
          </Panel>
        </Collapse>
        <div className={styles.termsContainer + " whatHappensContainer"}>
          <h1 className={"processHeading2"}>
            Terms &amp; a note on Methodology
          </h1>
          <p className={"processRunning"}>
            <b>Project</b>: A project at Tintash takes many forms. We develop
            Minimum Viable Products (MVP) for start-ups, we continue to be a
            development partner for companies building on their products, we
            provide engineers to a vast variety of companies looking to augment
            their existing development teams, and we create games. The playbook
            is meant for use by PMs for all types of projects with two caveats.
            Team augmentation projects imply project management responsibility
            at the client’s end and, therefore, have a subset of requirements
            apply to them internally at Tintash. Games, especially Hyper Casual
            Games, have different needs and a different lifecycle.
            <br />
            <br />
            <b>Project Management Methodology</b>: is not strictly prescribed at
            Tintash. This is for two main reasons: vast variety of projects and
            different types of clients. On the client architype spectrum, we
            have technical clients who have a strong preference for a specific
            method on one end, while on the other end there are non-technical
            clients who prefer not to be inundated with terminology or
            methodological requirements for client engagement – and many
            variations in between. Tintash uses an agile approach and a
            combination of agile methods and tools to fit the needs of the
            clients and the project types. This playbook provides tips and
            references for resources for PMs to be familiar with and explore
            different methods as needed.
            <br />
            <br />
            <b>Project lifecycle:</b> The phases of a project’s life at Tintash
            are: Lead Conversion, Discovery, Development (in iterative cycles of
            design, coding and quality assurance each of which are also cyclical
            in nature), Polishing and Deployment (also cyclical in larger
            iterations). Maintenance and support to finished and deployed
            products is handled through standalone contracts and mechanisms.
            <br />
            Note: This diagram is on the main page.
          </p>
        </div>
        <div className={styles.termsContainer + " whatHappensContainer"}>
          <h1 className={"processHeading2"}>Principles</h1>
          <p className={"processRunning"}>
            A brief note on the principles that guide the content of the
            playbook– in translating project management to Tintash’s reality,
            the playbook takes guidance from the company ethos. These reflect
            the human focus at the organisation and, as described uniformly by
            Tintash leadership, are:
            <br />
            <br />
            <ul>
              <li>Delight the client</li>
              <li>Create a good work environment for the team</li>
              <li>Ensure profitability for the company</li>
            </ul>
            In this playbook, you will find what each of these mean specifically
            at different stages of a project’s life cycle
          </p>
        </div>
        <div className={styles.termsContainer + " whatHappensContainer"}>
          <h1 className={"processHeading2"}>Use Cases</h1>
          <p className={"processRunning"}>
            The uses cases you are about to read are based on fictional
            characters in that the names, gender, and age are imaginary. Any
            similarity to Project Managers at Tintash is most definitely not
            coincidental
            <span role="img" aria-label="smile">
              😊
            </span>
          </p>
        </div>
        <div className={styles.collapse}>
          <Collapse
            bordered={true}
            style={{ backgroundColor: "rgba(40, 160, 255, 0.06)" }}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
            className="site-collapse-custom-collapse"
          >
            <Panel header="Umar" key="1" className="site-collapse-custom-panel">
              <div className="guidanceReqInnerBox">
                <p className={"processRunning"}>
                  <b>Use case</b> : The playbook captures his good practices,
                  tips and tricks that other can learn from. It is a book that
                  he could use to train his team. The set of obligatory
                  activities and tools would make it easy for him to monitor the
                  health of his projects as he takes on a larger portfolio.
                </p>
              </div>
              <p className={"processRunning"}>
                Umar is an engineer by background and started at Tintash as
                such. He is a 35-year-old father of three young children. He
                works hard, thinks fast, and likes to solve problem creatively.
                He has been managing several large and medium projects at
                Tintash in the past 5 years. He is known at the company for the
                friendly atmosphere that he has created for his team and high
                level of trust that he enjoys with his clients. He manages by
                instinct which lets him adapt quickly to the needs of his
                clients while keeping his team happy.
                <br />
                <br />
                His <b>pains</b> mainly come from a busy life, having many hats
                to wear at work and at home. A can-do attitude sometimes works
                against Umar who immediately thinks of how to make something
                happen when a client has new asks and, therefore, can take too
                much on. He knows that he can grow his portfolio with the same
                level of success but will need to delegate to someone that can
                maintain the client relationship and the team dynamic. It is not
                easy to find someone who can do what Umar does. His{" "}
                <b>gains </b>
                relate to the respect he enjoys in the organisation which allows
                him great leeway in the tools and tricks that he can use to get
                things done. He is on the path to taking on more leadership
                responsibility at Tintash.
              </p>
            </Panel>
          </Collapse>
        </div>
        <div className={styles.collapse}>
          <Collapse
            bordered={true}
            style={{ backgroundColor: "rgba(40, 160, 255, 0.06)" }}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
            className="site-collapse-custom-collapse"
          >
            <Panel
              header="Fatima"
              key="1"
              className={" site-collapse-custom-panel"}
            >
              <div className="guidanceReqInnerBox">
                <p className={"processRunning"}>
                  <b>Use case</b>: The playbook streamlines the process more
                  uniformly across the organisation while being selective in the
                  requirements. It keeps humans (both clients and teams) at the
                  centre and helps Fatima train her team and mentor her juniors
                  in the fine balance between following a process and adapting
                  to the needs of the project, client and team.
                </p>
              </div>
              <p className={"processRunning"}>
                Fatima, also an engineer by background, an old timer at Tintash,
                manages a large portfolio of different kinds of projects. She is
                a 33-year-old single woman with a huge appetite for personal and
                professional growth and spends a lot of time learning and
                reflecting on the human element of service delivery. She is
                structured and highly organised, known in the company for her
                streamlined processes and firm but fair communication.
                <br />
                <br />
                Her <b>pains</b> relate to a growing organisation that is
                focusing on many different changes in processes, sometimes at
                the risk of losing focus on the human element of successful
                projects and the Tintash identity. Her <b>gains </b> come from a
                natural ability and experience with understanding the core needs
                of a client and choosing the right elements of a process for
                each project.
              </p>
            </Panel>
          </Collapse>
        </div>
        <div className={styles.collapse}>
          <Collapse
            bordered={true}
            style={{ backgroundColor: "rgba(40, 160, 255, 0.06)" }}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
            className="site-collapse-custom-collapse"
          >
            <Panel
              header="Saima"
              key="1"
              className={" site-collapse-custom-panel"}
            >
              <div className="guidanceReqInnerBox">
                <p className={"processRunning"}>
                  <b>Use case</b>: The playbook is an easy to access overview
                  and collection of project management tools that make Saima’s
                  day to day life easy, so she does not have to come up with
                  templates and think through the process herself. It also
                  reflects some of her good practices and gives her an
                  opportunity to continue to learn where and how to create
                  organisational changes through continuous contribution to this
                  living playbook.
                </p>
              </div>
              <p className={"processRunning"}>
                Saima is trained as an engineer but has worked with project
                management from the beginning of her career. She is a
                27-year-old mother of one with many responsibilities at home.
                She is finding her voice at work and at home but can still doubt
                herself at times. She is extremely organised in her thoughts and
                her way of working. Her ability to think through problems
                thoroughly and her good planning has earned her respect and
                rapidly increasing responsibility at Tintash.
                <br />
                <br />
                Her <b>pains</b> come from not always having the time to spend
                on planning a project before jumping into development on a tight
                schedule. She does not enjoy small talk, prefers to be to the
                point and efficient in her conversation and needs some guidance
                in managing difficult conversations with clients. Her
                <b> gains</b> relate to the potential for her thorough and
                structured approach to benefit Tintash at large as well as her
                own career path in the organisation.
              </p>
            </Panel>
          </Collapse>
        </div>
        <div className={styles.collapse}>
          <Collapse
            bordered={true}
            style={{ backgroundColor: "rgba(40, 160, 255, 0.06)" }}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
            className="site-collapse-custom-collapse"
          >
            <Panel header="Hamza" key="1">
              <div className="guidanceReqInnerBox">
                <p className={"processRunning"}>
                  <b>Use case</b>: The playbook gives him clarity on what
                  Tintash expects of him as project manager. It helps him
                  enforce expectations and requirements with his team members
                  regardless of seniority. At the same time, it provides useful
                  tips and resources on different methods and tools, enough for
                  him to be able to have an informed conversation with clients.
                </p>
              </div>
              <p className={"processRunning"}>
                Hamza is a mid-level project manager by education and by
                professional experience including from outside of Tintash. He is
                a 29-year-old single man who likes to spend his time
                experimenting with solutions to different organisational
                problems that he would like to solve. He has brought good ideas
                to management and has helped develop and streamline some
                internal systems. His management style is a combination of
                traditional and modern practices. He prefers to deal with
                clients formally and have a casual atmosphere in the team with
                bilateral connection with each team member.
                <br />
                <br />
                His <b>pains</b> relate to not always being able to connect with
                senior team members who are more experienced and under different
                pressures due to different life situation than his own. He tries
                to solve that by asking them questions on their experiences but
                needs mentoring in establishing his leadership role as project
                manager. He also needs time to fully internalise the Tintash way
                of working. At the same time, his <b>pains</b> come from having
                experienced project management at a large organisation and with
                experienced clients who trained him on methods being used by
                modern tech companies in the US.
              </p>
            </Panel>
          </Collapse>
        </div>
      </div>
    </div>
  );
};

export default Index;
