import React, { useState, useRef } from "react";
import { GuideLines } from "../../../components/GuideLines/GuideLines";
import { WhatHappensData } from "../../../components/Processes/LeadConversion/components/WhatHappensData";
import { ApplyingPrinciples } from "./components/ApplyingPrinciples";
import arrow from "../../../assets/images/arrow.svg";
import { DO_CONSTANTS } from "../../../constants/doConstants";
import "./leadConversion.scss";
import MainPageNav from "../../../components/MainPageNav/MainPageNav";
import ALittleStoryFirstButton from "../../../components/ALittleStoryFirstButton/ALittleStoryFirstButton";
import dollar from "../../../assets/icons/dollar.svg";
import leadConversionIcon from "../../../assets/icons/leadConversion.svg";
import thumbsUp from "../../../assets/icons/thumbsUp.svg";
import heartIcon from "../../../assets/icons/heartIcon.svg";
import ScrollToTopButton from "../../../components/ScrollToTopButton/ScrollToTopButton";
import WhatHappensButton from "../../../components/WhatHappensButton/WhatHappensButton";
import { useEffect } from "react";

import { MOBILE_NAVIGATION_MENU_ITEMS } from "../../../constants/navigationMenuConstants";
import MobileNavbarTop from "../../../components/mobile/MobileNavbarTop/MobileNavbarTop";
import MobileNavbarBottom from "../../../components/mobile/MobileNavbarBottom/MobileNavbarBottom";
import useElementInView from "../../../utilities/useElementInView";

const WHEREDATA = [
  {
    name: "Provide an Estimate",
    dos: [{ name: "Review of estimates", ref: "estimatesContainer" }],
    uses: [
      {
        name: "Lead conversion checklist",
        url: "",
      },
      {
        name: "Discovery checklist",
        url: "",
      },
    ],
  },
  {
    name: "Handing over to PM",
    dos: [{ name: "Hand over to PM meeting", ref: "handoverContainer" }],
    uses: [],
  },
  {
    name: "Looking Back",
    dos: [{ name: "Conversion retro", ref: "retroContainer" }],
    uses: [],
  },
];

export const whatHappensObserverOptions = {
  root: null,
  rootMargin: "0px",
  threshold: 1.0,
};

export const LeadConversion = () => {
  const pageTopRef = useRef(null);
  const whatHappensSectionRef = useRef(null);

  const [activeWhere, setActiveWhere] = useState(WHEREDATA[0]);
  const [showGuideline, setShowGuideLine] = useState(false);
  const [selectedGuideline, setSelectedGuideline] = useState("");
  const [openNavigationMenu, setOpenNavigationMenu] = useState(false);

  const [whatHappensRef, whatHappensRefInView] = useElementInView(
    whatHappensObserverOptions
  );

  const [whatHappensEndRef, whatHappensEndRefInView] = useElementInView(
    whatHappensObserverOptions
  );

  const whatHappenStateHandler = (val) => {
    const guideLineDisplay = DO_CONSTANTS.find(
      (data) => val.target.innerText === data
    );
    if (guideLineDisplay) {
      setShowGuideLine(true);
      setSelectedGuideline(val.target.innerText);
    }
  };

  const mainDivFunction = () => {
    if (showGuideline) {
      setShowGuideLine(false);
    }
  };
  const nangaParbatHandler = (val) => {
    setSelectedGuideline(val);
    setShowGuideLine(true);
  };

  useEffect(() => {
    pageTopRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);
  return (
    <>
      <div ref={pageTopRef} />
      <MobileNavbarTop
        openNavigationMenu={openNavigationMenu}
        setOpenNavigationMenu={setOpenNavigationMenu}
        menuItems={MOBILE_NAVIGATION_MENU_ITEMS}
      />
      <MainPageNav />
      {showGuideline && (
        <GuideLines
          selectedGuideline={selectedGuideline}
          close={() => setShowGuideLine(false)}
        />
      )}
      <div
        onClick={mainDivFunction}
        className={showGuideline ? "row opacity" : "row"}
      >
        <div className="mt-88 whitespace"></div>
        <div id="intro-row" className="row">
          <div className="col-2 whitespace"></div>
          <div id="intro-container" className="col-5">
            <div className="main-heading-container">
              <img className="waterfall-chart" src={leadConversionIcon} />
              <h1 className="processHeading2 develop-heading ml-24">
                Lead Conversion
              </h1>
            </div>
            <div className="lead-content">
              <p>
                The phase begins after lead qualification by BD. It ends in one
                of two different ways depending on the type of project: 1) with
                a decision to proceed with discovery and a contract signed for
                the purpose, or) with an agreed Statement of Work (SOW), for
                example, for team augmentation projects.
              </p>

              <p>
                This phase is in the Tintash Project Cycle is led by BD who are
                responsible for getting input from relevant technical functions.
                The end of the phase involves a handover to the assigned PM.
              </p>
            </div>
          </div>
          <div className="col-5 whitespace">
            <WhatHappensButton buttonRef={whatHappensSectionRef} />
            <ALittleStoryFirstButton onClick={nangaParbatHandler} />
          </div>
        </div>
        <div className="mt-24 whitespace"></div>

        <ApplyingPrinciples
          principleone={heartIcon}
          principletwo={thumbsUp}
          principlethree={dollar}
        />
        <div className="row">
          <div
            className="main-content-align"
            id="linkWhatHappen"
            ref={whatHappensSectionRef}
          >
            <div className="happens">What happens?</div>

            <p className="what-happens-description" ref={whatHappensRef}>
              Client shares their concept/requirements. Tintash provides a{" "}
              <br />
              high-level estimate/burn rate
            </p>
          </div>
        </div>
        <WhatHappensData
          wheredata={WHEREDATA}
          activeWhere={activeWhere}
          arrow={arrow}
          setActiveWhere={setActiveWhere}
          onChangeValue={whatHappenStateHandler}
        />
        <div ref={whatHappensEndRef}></div>
      </div>
      <ScrollToTopButton pageTopRef={pageTopRef} />
      {!(whatHappensRefInView || whatHappensEndRefInView) ? (
        <MobileNavbarBottom>
          <WhatHappensButton buttonRef={whatHappensSectionRef} />
          <ALittleStoryFirstButton onClick={nangaParbatHandler} />
        </MobileNavbarBottom>
      ) : null}
    </>
  );
};
