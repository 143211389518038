import React from "react";
import { useState } from "react";

import Intro from "./Intro";
import WhereData from "./WhereData";
import Principles from "./Principles";
import TeamCharterMeet from "./Requirements/TeamCharterMeet";
import SprintZeroFive from "./Requirements/SprintZeroFive";
import ReleasePlanning from "./Requirements/ReleasePlanning";
import ReleaseChecklist from "./Requirements/ReleaseChecklist";
import DevKickOff from "./Requirements/DevKickOff";
import DailyStandup from "./Requirements/DailyStandup";
import UpdateMeetings from "./Requirements/UpdateMeetings";
import StatusReport from "./Requirements/StatusReport";
import MilestoneDemo from "./Requirements/MilestoneDemo";

import styles from "./development.module.css";
import mainImg from "../../../assets/images/Development/main.png";
import principleone from "../../../assets/images/Discovery/principle-one.png";
import principletwo from "../../../assets/images/Discovery/principle-two.png";
import principlethree from "../../../assets/images/Discovery/principle-three.png";
import guidanceone from "../../../assets/images/Discovery/guidance-one.svg";
import guidancetwo from "../../../assets/images/Discovery/guidance-two.png";
import guidancethree from "../../../assets/images/Discovery/guidance-three.png";
import guidancefour from "../../../assets/images/Discovery/guidance-four.png";
import arrow from "../../../assets/images/arrow.svg";
import badge from "../../../assets/images/badge.svg";
import teamCharterImg from "../../../assets/images/Development/teamCharterImg.png";
import sprintImg from "../../../assets/images/Development/sprintImg.png";
import releasePlanningImg from "../../../assets/images/Development/releasePlanningImg.png";
import devKickOffImg from "../../../assets/images/Development/devKickOffImg.png";
import dailyStandupImg from "../../../assets/images/Development/dailyStandupImg.png";
import updateMeetingsImg from "../../../assets/images/Development/updateMeetingsImg.png";
import milestoneDemoImg from "../../../assets/images/Development/milestoneDemoImg.png";

function Development() {
  const WHEREDATA = [
    {
      name: "Beginning Development",
      dos: [
        { name: "Charter Meet", ref: "TeamChartMeet" },
        { name: "Sprint 0.5", ref: "Sprintzerofive" },
        { name: "Release Planning Meeting", ref: "Release_Plan" },
      ],
      uses: [
        "Project Workplan",
        "Project SOPs (tickets, comms, documentation)",
        "Feature Flow",
        "Project Documentation Template",
        "Release Checklist",
      ],
    },
    {
      name: "During Development",
      dos: [
        { name: "Dev Kickoff Meeting" },
        { name: "Daily Standup and Syncups" },
        { name: "Update Meetings (with client)" },
      ],
      uses: [
        "Project Work-Plan",
        "Project Documentation Template",
        "Status Report",
      ],
    },
    {
      name: "Completing feature or product",
      dos: [{ name: "Milestone Demo" }],
      uses: [],
    },
  ];

  const [activeWhere, setActiveWhere] = useState(WHEREDATA[0]);

  return (
    <div className={styles.processContainer}>
      <div className={"processParentContainer"}>
        <Intro mainImg={mainImg} />
        <WhereData
          wheredata={WHEREDATA}
          activeWhere={activeWhere}
          arrow={arrow}
          badge={badge}
          setActiveWhere={setActiveWhere}
        />
        <Principles
          principleone={principleone}
          principletwo={principletwo}
          principlethree={principlethree}
        />
        <TeamCharterMeet
          reqImg={teamCharterImg}
          styles={styles}
          guidanceone={guidanceone}
          guidancetwo={guidancetwo}
          guidancethree={guidancethree}
          guidancefour={guidancefour}
        />
        <SprintZeroFive reqImg={sprintImg} styles={styles} />
        <ReleasePlanning
          reqImg={releasePlanningImg}
          styles={styles}
          guidanceone={guidanceone}
          guidancetwo={guidancetwo}
          guidancethree={guidancethree}
          guidancefour={guidancefour}
        />
        <ReleaseChecklist styles={styles} />
        <DevKickOff
          reqImg={devKickOffImg}
          styles={styles}
          guidanceone={guidanceone}
          guidancetwo={guidancetwo}
          guidancethree={guidancethree}
          guidancefour={guidancefour}
        />
        <DailyStandup
          reqImg={dailyStandupImg}
          styles={styles}
          guidanceone={guidanceone}
          guidancetwo={guidancetwo}
          guidancethree={guidancethree}
          guidancefour={guidancefour}
        />
        <UpdateMeetings
          reqImg={updateMeetingsImg}
          styles={styles}
          guidanceone={guidanceone}
          guidancetwo={guidancetwo}
          guidancethree={guidancethree}
          guidancefour={guidancefour}
        />
        <StatusReport styles={styles} />
        <MilestoneDemo
          styles={styles}
          reqImg={milestoneDemoImg}
          guidanceone={guidanceone}
          guidancetwo={guidancetwo}
          guidancethree={guidancethree}
          guidancefour={guidancefour}
        />
      </div>
    </div>
  );
}

export default Development;
