import React from "react";
import { Collapse } from "antd";

import Guidance from "../../../../components/Guidance/Guidance";
import Tooltip from "../../../../components/Tooltip/Tooltip";
const { Panel } = Collapse;
const prepTime = "15 min";
const runTime = "60 min";
const followUpTime = "15-30 min";
const people = "3-5";
export const HandoverMeeting = (props) => {
  const { elder } = props.badges;
  return (
    <div className="guidancesContainer ClosureMeeting">
      <h1 className="processHeading2">Closure/Handover Meeting</h1>
      <br />
      <Guidance
        prepTime={prepTime}
        runTime={runTime}
        followUpTime={followUpTime}
        people={people}
      />
      <br />
      <br />
      <div className="guidanceCollapse">
        <Collapse expandIconPosition={"right"}>
          <Panel
            header="Why the requirement"
            key="1"
            className="processRunning"
          >
            <p>
              This is a meeting with the client that should take place right
              after or at the same time as a product/feature (set) is released.
              The purpose of this meeting is to:
            </p>
            <ul>
              <li>Communicate what has been released</li>
              <li>
                Explain risks and planned mitigation measures &amp; contingency
              </li>
            </ul>
          </Panel>
        </Collapse>
        <br />
        <br />
        <Collapse expandIconPosition={"right"}>
          <Panel header="What is the requirement" key="2">
            <div className="row">
              <div className="col-12 processRunning">
                <div className="guidanceReqInnerBox">
                  <b className="mandatory-participants">
                    Mandatory participants:
                  </b>
                  &nbsp;PM, relevant team members
                </div>

                <p>
                  The PM is responsible for drafting and sharing the agenda of
                  the meeting with the client and the team. It can be shared in
                  the body of an email or as a separate attachment to the email.
                  It is important that the PM follows their own style of
                  communication and logical flow. However, the following items
                  should be covered:
                </p>
                <ul>
                  <li>
                    What is being released
                    <Tooltip
                      headingOne="Buzurg kehte hein:"
                      paragraphOne="Visual is better than verbal only."
                      direction="right"
                    >
                      <img src={elder} className="badgeIcon" alt="" />
                    </Tooltip>
                  </li>
                  <li>Risks and mitigation</li>
                  <li>
                    Successes and failures
                    <Tooltip
                      headingOne="Buzurg kehte hein:"
                      paragraphOne="A lot of times when we try to make our updates like facebook posts i.e. we share something great that happens in our lives, we try to impress people. That does not win us trust. To win the trust of a client, be transparent, where they can see your flaws as well. And also say the lessons that you have learnt."
                      direction="right"
                    >
                      <img src={elder} className="badgeIcon" alt="" />
                    </Tooltip>
                  </li>
                </ul>

                <h1 className="processHeading4 italic">Before the Meeting</h1>
                <ul>
                  <li>Send the agenda at least a day in advance</li>
                  <li>
                    Draft talking points and discuss with a senior/another PM
                    for tips if there are challenges to be discussed.
                  </li>
                  <li>Assign a notetaker</li>
                </ul>

                <h1 className="processHeading4 italic">During the Meeting</h1>
                <ul>
                  <li>
                    Do not read from the talking points – they were only meant
                    to clarify your thoughts
                  </li>
                  <li>
                    Do not prolong the introduction, the client is keen to see
                    the release, get right to it.
                  </li>
                </ul>

                <h1 className="processHeading4 italic">After the Meeting</h1>
                <ul>
                  <li>
                    Summarise the notes from the discussion and share with the
                    client and the team with clear action items if any.
                  </li>
                  <li>
                    File the minutes in the project’s document tree /
                    documentation skeleton.
                  </li>
                </ul>
              </div>
            </div>
          </Panel>
        </Collapse>
      </div>
      <br />
    </div>
  );
};
