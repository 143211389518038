import React from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { ErrorDialog } from "../Dialog/dialog";
import { ERROR_MESSAGE } from "../../constants/errorMessages";

export const RichTextEditor = ({ field, form, defaultValue }) => {
  const handleChange = (content) => form.setFieldValue(field.name, content);
  const handleImageUploadBefore = () => {
    ErrorDialog({ title: ERROR_MESSAGE.IMAGE_INPUT });
  };
  return (
    <>
      <SunEditor
        defaultValue={defaultValue}
        onImageUploadBefore={handleImageUploadBefore}
        onChange={handleChange}
        setAllPlugins={true}
        setOptions={{
          height: "200",
          mode: "inline",
          buttonList: [
            ["font", "fontSize", "formatBlock"],
            ["paragraphStyle", "blockquote"],
            ["bold", "underline", "italic", "strike", "list"],
          ],
        }}
      />
    </>
  );
};
