const BADGES_CONTENT = {
  ESTIMATE_REVIEW:
    "Estimates for software projects are notoriously difficult to make. It is documented that most tech projects go over time and budget and deliver fewer features than planned. But cost, time and functionality are not the only measures of a project’s success. Quality and customer satisfaction are equally, if not more, important and lead to good project outcomes and lasting relationships. This is Tintash’s goal – lasting relationships based on trust which is gained through quality of our delivery at all stages and open communication.",
  REQUIREMENT_REVIEW:
    "I have reached out to a professional design and dev studio, they will hear what I say and know what it will cost approximately. They might even tell me during the call! The high level estimate effort at this stage has to be measured in hours or days, it cannot be longer.",
  GREYS_CLIENT:
    "Can I afford this project? I cannot commit without knowing that I can",
  GREYS_ESTIMATE:
    "Do not delay the estimate, this should be hours or within a day, not longer. The longer the time you take, the higher the client expectation on what they will receive. You will not be able to provide a justified estimate. Set up the deal driver for success here, Tintash will make a bad impression by going back with a very broad range. The client should not have to wonder ‘why couldn’t they just say this when we talked first",
  MEETING_SUCCESS_1:
    "They have assigned a good PM to my project. She has an excellent understanding of what has happened so far. I trust her and I trust Tintash, they clearly have a high functioning internal environment.",
  MEETING_SUCCESS_2:
    " Conversely: This was a very basic point that she has missed completely. Either they didn’t brief her properly or she isn’t that switched on.",
  MEETING_SUCCESS_3:
    "(Your colleague will struggle to balance this impression for a long time if not the entire duration of the project.)",
  REQUIREMENT_ESTIMATE:
    "Make space for reviewing the estimate at this stage. The people who are actually going to work on the project should be able to comment here. This is a quick way of verifying/adjusting the estimates AND getting ownership from the team. Remember the goal: you are setting up your colleagues and the client for success here.",
  REQUIREMENT_PMS:
    "As a PM, I found that it was an easier journey when I was involved in lead conversion as a listener. But where I understandably couldn’t be involved, handover became critical. I see it as my responsibility to take ownership. That begins by proactive preparation. I begin my product thinking here. Put myself in the client’s shoes and start to understand the business, why do they want this product. I do not act merely as a passive recipient of information and instruction",
  TAKEAWAYS:
    "Discuss in the meeting how the takeaways will be documented and where they will be shared. Regardless of people involved and the context, make the finding anonymous and make that clear in the beginning of the meeting. Do explain to the people involved that it is not that this is a highly classified activity, anonymization of findings is important because who said x is neither relevant, nor important but can become a distraction for those reading. We want to keep the focus on what has been shared, not who shared it",
  DESIGN_EXERCISE:
    "design thinking is not important if they are not familiar with the concept. You ask this to get an idea of how thought through the product concept is. Do they have user personas, user journeys, use cases etc. regardless of the terminology they use or methodology that they followed. This will help you suggest a scope of discovery that is relevant and delights the client",
  VOILA_1:
    " In all seriousness, your experience with groceries is important. But how confident are you of your assumptions about people + you have not been north. Does that mean minor adjustments to your estimate or are there factors that will fundamentally change the estimate. 40,000 – 80,000 PKR is not the type of range that Mannan is looking for. In fact, that is not a range, that is an estimate and it’s double. He is a bit annoyed, but you explain, and he approves it. You hand this over to your most reliable team member for procurement. She talks to everyone going, she checks different shops and talks to people who go to fairy meadows regularly. She makes a thorough list which tells her this will be 120,000 PKR. She immediately raises it. Imagine the reaction now",
  VOILA_2:
    "The trip was approved based on your estimate. Something has to change fundamentally about the trip now. No one is happy.",
  INFORMED_ESTIMATE_1:
    "You asked an Expert. You got a much more precise estimate, an actual range: 80,000 – 100,000. Oh but Wasay went to Hunza (drive on KKH and normal roads to hotel + day hikes + local shops). A trip to fairy meadows is a different game (drive on KKH + drive on the local dirt road where only drivers and jeeps from the village are allowed and that need to be booked in advance, the team might have to wait half a day for road to clear + 3-hour hike to camp site with possible landslides along the hiking path + no shops at a driving distance + luggage will be carried by horses). But his experience helped him see some challenges. The estimate is a bit high, but you explain it well and get approval. You hand this over to your most reliable team member for procurement. She talks to everyone going, she checks different shops and talks to people who go to fairy meadows regularly. She makes a thorough list which tells her this will be 120,000 PKR. She immediately raises it.",
  INFORMED_ESTIMATE_2:
    "A bit frustrating but can be managed with some adjustments to the trip or, ideally, the purchases without altering the trip.",
  ESTIMATES_1:
    " Possibly the most accurate estimate: 100,000-120,000. But you are not going to get it tomorrow. This took 4 days.",
  ESTIMATES_2:
    "Soon after receiving the task, you were keen to impress Mannan and be even faster than asked but you took some time to reflect on the complexity of the task. You asked Wasay to join you in a quick follow up with Mannan. Wasay asked him if Tintash would cover the cost of all extra gear as well or if there are criteria for what will be covered. He also asked about the assumption behind the 6-day margin and about quality vs cost balance of the purchases. You asked if the gear should be visibility items with Tintash logos. Mannan felt he had assigned the job to the right person and gave you more time to discover the needs of the people and market options and come up with a reliable estimate",
  ESTIMATES_3:
    "The impression stayed with him. The next time, planning the management retreat, he suggested you to Murad as the ideal person for the job",
  LAWSREGULATIONS_1:
    "You don’t have to implement any or all of it during the project – for example for an MVP, if the client chooses it that way. However, you need to know what might come your way down the line and how it may fundamentally affect design, front or backend choices, and therefore your estimate.",
  LAWSREGULATIONS_2:
    "tell the clients that companies consider these at different stages depending on their own risk analysis. Do not make a recommendation that will implicate Tintash. If advised by you to wait, clients will appreciate the time and cost saving when all goes well. But if fined or in trouble otherwise, they will say Tintash told us to wait.",
  GREENS:
    " What can I achieve in the budget that I have? They seem to know what they are talking about, I want their advice. I feel working with them will give me the best results.",
  GREENS_ESTIMATE:
    "If the requirements are somewhat detailed, ask for more time for a qualified estimate. This should be days, not weeks.",
  BLUES:
    "I need people who can hit the ground running. They seem competent and engaged, it would be good having them on board.",
  UNPREPARED_1: "What did you miss here?",
  UNPREPARED_2:
    "You are presenting to two business leaders. In their positions, they are both interested in the financial health of the company and the wellbeing of their team, but they are two different people with their own styles and how they approach an issue. You need to cater to their styles AND not lose sight of the issue which is important to both of them.",
  UNPREPARED_3:
    "Client is not one entity. There are also multiple people involved on their end. Their differences and dynamic between them is important for you to understand and convey to the PM during handover.",
  UNPREPARED_4:
    "It is essential to know who you are going to meet and what they want to know (often based on their roles, not just personalities). What you are going to say must be focused on what your audience needs to know – throughout the project, at each meeting point.",
};
export default BADGES_CONTENT;
