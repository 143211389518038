import React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import BADGES_CONTENT from "../../../../components/Processes/LeadConversion/BadgesContent";

import Tooltip from "../../../../components/Tooltip/Tooltip";
import { scrollToClickedButton } from "../../../../components/Processes/LeadConversion/components/WhatHappensData";

export function EstimateColor(props) {
  const { graph, person, elder } = props;
  const [estimate, setEstimate] = useState("greys");
  const maxScreenWidth = 9999;

  return (
    <>
      <img
        className={"mobile-hidden whatImg img-fluid"}
        src={graph}
        alt="what"
      />
      <div className={"reviewColorsContainer"}>
        <div className={"row"}>
          <div className="boxes-container">
            <div className={"col-3"}>
              <div
                onClick={(e) => {
                  setEstimate("greys");
                  scrollToClickedButton(e, maxScreenWidth);
                }}
                className={`reviewColorBox1 img-fluid ${
                  estimate === "greys" ? "active" : ""
                }`}
                data-button-id="btnOne"
              >
                The Greys
              </div>
            </div>
            <div className={"col-3"}>
              <div
                onClick={(e) => {
                  setEstimate("greens");
                  scrollToClickedButton(e, maxScreenWidth);
                }}
                className={`reviewColorBox2 img-fluid ${
                  estimate === "greens" ? "active" : ""
                }`}
                data-button-id="btnTwo"
              >
                The Greens
              </div>
            </div>
            <div className={"col-3"}>
              <div
                onClick={(e) => {
                  setEstimate("blues");
                  scrollToClickedButton(e, maxScreenWidth);
                }}
                className={`reviewColorBox3 img-fluid ${
                  estimate === "blues" ? "active" : ""
                }`}
                data-button-id="btnThree"
              >
                <span style={{ fontWeight: 600 }}>The Blues</span>
              </div>
            </div>
          </div>
          <hr />
        </div>
        <br />
        {estimate === "greys" ? (
          <>
            <b className="processRunningHeading">The Greys</b>
            <ul className="processRunning">
              <li>
                Typically, these projects are at an investor MVP stage. The
                client
                <Tooltip
                  headingOne="Client ke dil se"
                  paragraphOne={BADGES_CONTENT.GREYS_CLIENT}
                >
                  <img src={person} alt="" className="badgeIcon" />
                </Tooltip>
                may have a different description, but the term is not important.
                What is important is that you are estimating based on a concept
                and very little specifications.
              </li>
              <li>
                Estimate
                <Tooltip
                  headingOne="Buzurg kehte hain"
                  paragraphOne={BADGES_CONTENT.GREYS_ESTIMATE}
                  direction="right"
                  yOffsetMobile="translate-Y-neg-110%"
                >
                  <img src={elder} alt="" className="badgeIcon" />
                </Tooltip>
                to be provided by: Technical Domain Expert, Estimate review by:
                UX
              </li>
            </ul>
          </>
        ) : estimate === "greens" ? (
          <>
            <b className="processRunningHeading">
              The Greens
              <Tooltip
                headingOne="Client ke dil se"
                paragraphOne={BADGES_CONTENT.GREENS}
                direction="right"
              >
                <img src={person} alt="" className="badgeIcon" />
              </Tooltip>
            </b>
            <ul className="processRunning">
              <li>
                These projects are likely at a Go to Market MVP stage or meant
                to improve the product-market fit. They could be Investor MVPs
                in the hands of very technical clients who are able to describe
                the features somewhat clearly. Or they could be growth or
                improvement of existing products/systems, but the client is a
                manager without a technical background. Essentially, you receive
                some actionable detail on requirements.
              </li>
              <li>
                Estimate
                <Tooltip
                  headingOne="Buzurg kehte hain"
                  paragraphOne={BADGES_CONTENT.GREENS_ESTIMATE}
                  direction="right"
                  yOffsetMobile="translate-Y-neg-110%"
                >
                  <img src={elder} alt="" className="badgeIcon" />
                </Tooltip>
                to be provided by: Team Manager, Estimate Review by: a senior PM
              </li>
            </ul>
          </>
        ) : estimate === "blues" ? (
          <>
            <b className="processRunningHeading">
              The Blues
              <Tooltip
                headingOne="Client ke dil se"
                paragraphOne={BADGES_CONTENT.BLUES}
                direction="right"
              >
                <img src={person} alt="" className="badgeIcon" />
              </Tooltip>
            </b>
            <ul className="processRunning">
              <li>
                These projects are mostly about long term growth of a product
                and would lean towards a team augmentation arrangement. The
                product was born before it came to you so it’s easier for both
                you and the client to discuss the requirements even for
                completely new features.
              </li>
              <li>
                Estimate to be provided by: TDE or Team Manager. No review
                needed, this is mainly about the number of people needed (and
                resultant burn rate).
              </li>
            </ul>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
EstimateColor.protoTypes = {
  graph: PropTypes.string,
  badge: PropTypes.string,
};
