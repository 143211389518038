import { GET_CHECKLIST } from "../actions/types";

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: null,
};
export const checkListReducer = (
  state = INITIAL_STATE,
  action = { type: "" }
) => {
  switch (action.type) {
    case GET_CHECKLIST:
      return {
        ...state,
        data: action.payload,
        loading: action.loading,
      };
    default:
      return state;
  }
};
