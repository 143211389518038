import React from "react";
import { useState } from "react";

import Badge from "../../../../assets/images/badge.svg";
import BADGES_CONTENT from "../../../../components/Processes/Discovery/BadgesContent";
import Tooltip from "../../../../components/Tooltip/Tooltip";
import { scrollToClickedButton } from "../../../../components/Processes/LeadConversion/components/WhatHappensData";

export const CheckList = () => {
  const [checklist, setChecklist] = useState("product");
  const maxScreenWidth = 9999;

  return (
    <div id="discovery-checklist" className="processChecklist">
      <h1 className="processHeading2">Discovery Checklist</h1>

      <div className="row">
        <div className="col-4">
          <div
            className={
              checklist === "product"
                ? "discoveryChecklistActive"
                : "discoveryChecklist"
            }
            onClick={(e) => {
              setChecklist("product");
              scrollToClickedButton(e, maxScreenWidth);
            }}
            data-button-id="btnOne"
          >
            Product/Business Concept
          </div>
        </div>
        <div className="col-4">
          <div
            className={
              checklist === "tech"
                ? "discoveryChecklistActive"
                : "discoveryChecklist"
            }
            onClick={(e) => {
              setChecklist("tech");
              scrollToClickedButton(e, maxScreenWidth);
            }}
            data-button-id="btnTwo"
          >
            Tech
          </div>
        </div>
        <div className="col-4">
          <div
            className={
              checklist === "law"
                ? "discoveryChecklistActive"
                : "discoveryChecklist"
            }
            onClick={(e) => {
              setChecklist("law");
              scrollToClickedButton(e, maxScreenWidth);
            }}
            data-button-id="btnThree"
          >
            Laws &amp; Regulations
          </div>
        </div>
      </div>
      <div className="col-12 horizontal-rule mobile-hidden">
        <hr />
      </div>

      <div className="checklistPointers">
        <h1 className="processRunningHeading">Pointers</h1>
        <ul className="processRunning">
          {checklist === "product" ? (
            <>
              <li>
                What is your target demographic, and can you share some sample
                personas
                <Tooltip
                  headingOne="Buzurg kehte hain"
                  paragraphOne={BADGES_CONTENT.PERSONAS_1}
                  paragraphTwo={BADGES_CONTENT.PERSONAS_2}
                >
                  <img src={Badge} alt="" className="badgeIcon" />
                </Tooltip>
                of users who would be using your product?
              </li>
              <li>
                Do you currently have a style/theme guide for your product?
              </li>
              <li>
                What features are the foundation of your product and are
                primarily derived from your business model?
              </li>
              <li>
                Do you currently have any competitors
                <Tooltip
                  headingOne="Buzurg kehte hain"
                  paragraphOne={BADGES_CONTENT.COMPETITORS}
                  direction="right"
                >
                  <img src={Badge} alt="" className="badgeIcon" />
                </Tooltip>
                for your idea?
              </li>
              <li>Is this an investor or a market MVP?</li>
              <li>
                If features are already shared: How would you rank and
                prioritise your features based on the value they would deliver
                to your users?
              </li>
              <li>
                Would you like to share your business plan with us? Or other
                plans – launch, marketing, fundraising? How can we work together
                to make sure that our timelines remain connected?
              </li>
            </>
          ) : checklist === "tech" ? (
            <>
              <li>
                Do you have any preference for technology to use while building
                it out?
              </li>
              <li>
                What are the features that can be natively developed vs that can
                be used from third-party off the shelf components?
              </li>
              <li>What third party tools to use to build out the system</li>
            </>
          ) : checklist === "law" ? (
            <>
              <li>
                Are there any laws related to the domain (e.g. health, use by
                minors) that are applicable to your product?
              </li>
              <li>
                Are there any privacy and data protection regulations to
                consider in the area you are trying to launch it in? At what
                stage?
                <Tooltip
                  headingOne="Lekin kion"
                  paragraphOne={BADGES_CONTENT.LAWS_1}
                  headingTwo="Buzurg kehte hain"
                  paragraphTwo={BADGES_CONTENT.LAWS_2}
                  yOffsetMobile="translate-Y-neg-110%"
                  direction={`${screen.width <= 1690 ? "right" : "left"}`}
                >
                  <img src={Badge} alt="" className="badgeIcon" />
                </Tooltip>
              </li>
              <li>
                Are there universal access / accessibility of ICT solutions
                requirements that you need to comply with? At what stage?
              </li>
            </>
          ) : (
            <></>
          )}
        </ul>
      </div>
    </div>
  );
};
