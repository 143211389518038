import React from "react";
function Principles() {
  return (
    <div className="stepsContainer" style={{ textAlign: "left" }}>
      <h1 className="processHeading2">Steps</h1>
      <div className="steps processRunning">
        <ul>
          <li>QA driving this sprint</li>
          <li>No feature development</li>
          <li>Edge cases</li>
          <li>QA will make a report, all edge cases are covered</li>
          <li>Green light to the dev team and the PM</li>
          <li>
            While they are doing that, the engineering team is setting up any
            additional infrastructure that is needed for this production
            readiness
          </li>
          <li>Deployment Report (incl. minutes from DPM, section on test)</li>
        </ul>
      </div>
    </div>
  );
}

export default Principles;
