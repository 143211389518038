import React from "react";
import PersonAvatarWithName from "../../../components/GuideLines/PersonAvatarWithName/PersonAvatarWithName";

const personName = "Saima";
function Saima() {
  return (
    <div className="guidancesContainer">
      <PersonAvatarWithName key={personName} name={personName} />
      <br />
      <br />
      <div className="guidanceReqInnerBox">
        <p className={"processRunning"}>
          <b>Use case</b>: The playbook is an easy to access overview and
          collection of project management tools that make Saima’s day to day
          life easy, so she does not have to come up with templates and think
          through the process herself. It also reflects some of her good
          practices and gives her an opportunity to continue to learn where and
          how to create organisational changes through continuous contribution
          to this living playbook.
        </p>
      </div>
      <p className={"processRunning"}>
        Saima is trained as an engineer but has worked with project management
        from the beginning of her career. She is a 27-year-old mother of one
        with many responsibilities at home. She is finding her voice at work and
        at home but can still doubt herself at times. She is extremely organised
        in her thoughts and her way of working. Her ability to think through
        problems thoroughly and her good planning has earned her respect and
        rapidly increasing responsibility at Tintash.
        <br />
        <br />
        Her <b>pains</b> come from not always having the time to spend on
        planning a project before jumping into development on a tight schedule.
        She does not enjoy small talk, prefers to be to the point and efficient
        in her conversation and needs some guidance in managing difficult
        conversations with clients. Her
        <b> gains</b> relate to the potential for her thorough and structured
        approach to benefit Tintash at large as well as her own career path in
        the organisation.
      </p>
    </div>
  );
}

export default Saima;
