import React from "react";
import styles from "./../introduction.module.css";

function ProjectMethodology() {
  return (
    <div>
      {" "}
      <div className="main-content-align mt-88 mb-64">
        <h4 className={"processHeading2 mb-none"}>{"Terms &"}</h4>
        <h4 className={"processHeading2"}>A note on Methodology</h4>
      </div>
      <div className="row lead-content">
        <div className="col-2"></div>

        <div className="col-4 mobile-w-90%">
          <div data-id="projectLifecycle">
            <br />
            <b>Project</b>:<br className="desktop-hidden" /> A project at
            Tintash takes many forms. We develop Minimum Viable Products (MVP)
            for start-ups, we continue to be a development partner for companies
            building on their products, we provide engineers to a vast variety
            of companies looking to augment their existing development teams,
            and we create games. The playbook is meant for use by PMs for all
            types of projects with two caveats. Team augmentation projects imply
            project management responsibility at the client’s end and,
            therefore, have a subset of requirements apply to them internally at
            Tintash. Games, especially Hyper Casual Games, have different needs
            and a different lifecycle.
          </div>
          <div className={styles.projectLifecycle}>
            <b>Project lifecycle:</b>
            <br className="desktop-hidden" /> The phases of a project’s life at
            Tintash are: Lead Conversion, Discovery, Development (in iterative
            cycles of design, coding and quality assurance each of which are
            also cyclical in nature), Polishing and Deployment (also cyclical in
            larger iterations). Maintenance and support to finished and deployed
            products is handled through standalone contracts and mechanisms.
            <br />
            <br className="desktop-hidden" />
            <div className="mobile-hidden">
              Note: This diagram is on the main page.
            </div>
            <div className="desktop-hidden guidanceReqInnerBox">
              <strong>Note: </strong>This diagram is on the main page.
            </div>
          </div>
        </div>
        <div className={`col-4 mobile-w-90% ${styles.projectLifecycle}`}>
          <b>Project Management Methodology</b>:
          <br className="desktop-hidden" /> is not strictly prescribed at
          Tintash. This is for two main reasons: vast variety of projects and
          different types of clients. On the client architype spectrum, we have
          technical clients who have a strong preference for a specific method
          on one end, while on the other end there are non-technical clients who
          prefer not to be inundated with terminology or methodological
          requirements for client engagement – and many variations in between.
          Tintash uses an agile approach and a combination of agile methods and
          tools to fit the needs of the clients and the project types. This
          playbook provides tips and references for resources for PMs to be
          familiar with and explore different methods as needed.
        </div>
      </div>
    </div>
  );
}

export default ProjectMethodology;
