import React from "react";
import { Spin } from "antd";

const Loader = () => (
  <div>
    <Spin className="flex content-center" size="large" />
  </div>
);

export default Loader;
