import React from "react";

import BADGES_CONTENT from "../../../../components/Processes/Discovery/BadgesContent";
import elder from "../../../../assets/icons/elderly.svg";
import whatIsDiscovery from "../../../../assets/icons/whatIsDiscovery.svg";
import Tooltip from "../../../../components/Tooltip/Tooltip";

export const DiscoveryContent = () => (
  <div id="discovery-content-container" className="row mt-88">
    <div className="row">
      <div className="col-2 whitespace"></div>
      <div id="discovery-content" className="col-5">
        <div className="mobile-flex">
          <img className="waterfall-chart" src={whatIsDiscovery} />
          <h1 className="processHeading2 develop-heading ml-24">
            What is Discovery
          </h1>
        </div>
        <div className="lead-content">
          <p className="tooltip-parent">
            Software development often runs into the same set of problems across
            sectors, project types and companies. Difficulty visualising a
            product before it’s seen in action, miscommunication, mismatched
            expectations, and inaccurate cost and time estimates.
            <br />
            <br />
            Agile methodologies, by themselves or combined with waterfall
            practices, help solve many of the problems. However, a key challenge
            remains. Clients and development teams are keen to get started with
            the building of the product. This is understandable, making the
            product come to life is the most exciting part.
            <br />
            <br />
            When pushed, understanding intellectually the importance of
            dedicated time for design is easy, practicing it in real life is
            difficult for both sides. There is no easy solution. Governments in
            Scandinavia, for example, have started providing funds to
            incentivise start-ups to remain in the design phase for a few months
            before beginning development or even fundraising. Start-ups with
            development plans do not qualify for these funds. Design determines
            budgets and timelines!
            <br />
            <br />
            Design thinking is the method of choice (see Annex x for a primer)
            in these times
            <br />
            <br />
            Tintash discovery is design thinking ++. It combines design thinking
            with elements of service design and adds a full-on tech component on
            top. This is the premium version of design, and we should be proud
            to present it as such.
            <Tooltip
              headingOne="Buzurg kehte hain"
              paragraphOne={BADGES_CONTENT.DISCOVERY_1}
              paragraphTwo={BADGES_CONTENT.DISCOVERY_2}
              paragraphThree={BADGES_CONTENT.DISCOVERY_3}
              direction="right"
            >
              <img src={elder} alt="" className="badgeIcon" />
            </Tooltip>
          </p>
        </div>
        <div className="whatInvContainer mt-64">
          <h1 className={"processHeading4"}>Who is involved</h1>
          <ul className="lead-content">
            <li>Project Manager to lead the effort + budgeting</li>
            <li>UX team for design thinking + service design</li>
            <li>
              Engineers for input to service design + design of system
              architecture
            </li>
            <li>UI for style guide and a sample of screens</li>
          </ul>
        </div>
      </div>

      <div className="col-5"></div>
    </div>
  </div>
);
