import "./mainPage.scss";

import React, { useState } from "react";
import { Button } from "antd";

import logo from "../../../assets/images/logo.png";
import HamburgerDropdown from "../../../components/HamburgerDropdown/HamburgerDropdown";
import RightArrow from "../../../components/RightArrow/RightArrow";
import dasboard from "../../../assets/icons/dashboard.svg";
import whyThePlayBook from "../../../assets/icons/whyThePlaybook.svg";
import howToUse from "../../../assets/icons/howToUse.svg";
import arrowTop from "../../../assets/icons/arrowTop.svg";
import arrowBottom from "../../../assets/icons/arrowBottom.svg";
import mainPageImage from "../../../assets/images/mainPageImage.png";
import arrowRightCylic from "../../../assets/icons/arrowRightCyclic.svg";

import MobileNavbarTop from "../../../components/mobile/MobileNavbarTop/MobileNavbarTop";

export const menuItems = [
  { text: "How to use?", url: `/how-to-use`, icon: howToUse },
  {
    text: "Why the Playbook?",
    url: `/why-the-playbook`,
    icon: whyThePlayBook,
  },
  { text: "Dashboard", url: `/sign-in`, icon: dasboard },
];

export const MainPage = (props) => {
  const { history } = props;

  const [openNavigationMenu, setOpenNavigationMenu] = useState(false);

  return (
    <>
      <MobileNavbarTop
        menuItems={menuItems}
        openNavigationMenu={openNavigationMenu}
        setOpenNavigationMenu={setOpenNavigationMenu}
      />
      <div className={`main-page-container`}>
        <div className="outerContainerMainPage">
          <div className="logoContainerMainPage">
            <a href="/">
              <img src={logo} alt="logo" className="img-fluid" width="150" />
            </a>
            <HamburgerDropdown menuItems={menuItems} />
          </div>

          <div className="headingContainerMainPage mb-64">
            <div className="headingMainPage">PLAYBOOK FOR </div>
            <div className="headingMainPage mt-16">MANAGERS</div>
          </div>

          <div className="main-page-mobile-illustration">
            <img
              src={mainPageImage}
              width={"93%"}
              className="img-fluid ml-16"
              alt="guidance"
            />
          </div>

          <div className="row">
            <div className="col-5"></div>
            <div id="arrowTop" className="col-4 arrowTop mt-3-vh">
              <img
                src={arrowTop}
                width={"93%"}
                className="img-fluid ml-16"
                alt="guidance"
              />
            </div>
          </div>
          <div>
            <div className="row">
              <div className="col-1"></div>
              <div className="col-10 button-container-main-page">
                <div id="cyclic-arrows-container">
                  <div id="arrow-right">
                    <img src={arrowRightCylic} alt="guidance" />
                  </div>
                  <div id="arrow-left">
                    <img src={arrowRightCylic} alt="guidance" />
                  </div>
                </div>

                <Button
                  type="primary"
                  shape="round"
                  size="large"
                  className="button"
                  onClick={() => history.push("/lead-conversion")}
                >
                  Lead Conversion
                </Button>
                <RightArrow width={100} className="arrow" />
                <Button
                  type="primary"
                  shape="round"
                  size="large"
                  className="button"
                  onClick={() => history.push("/discovery")}
                >
                  Discovery
                </Button>
                <RightArrow width={100} />
                <Button
                  type="primary"
                  shape="round"
                  size="large"
                  className="button"
                  onClick={() => history.push("/development")}
                >
                  Development
                </Button>
                <RightArrow width={100} />
                <Button
                  type="primary"
                  shape="round"
                  size="large"
                  className="button"
                  onClick={() => history.push("/polishing")}
                >
                  Polishing
                </Button>
                <RightArrow width={100} />
                <Button
                  type="primary"
                  shape="round"
                  size="large"
                  className="button"
                  onClick={() => history.push("/release")}
                >
                  Release
                </Button>
                <RightArrow width={100} />
                <Button
                  type="primary"
                  shape="round"
                  size="large"
                  className="button"
                  disabled
                  onClick={() => history.push("/maintenance")}
                >
                  Maintenance
                </Button>
              </div>
              <div className="col-1"></div>
            </div>
          </div>
          <div className="row">
            <div className="col-5"></div>
            <div id="arrowBottom" className="col-4 arrowBottom">
              <img
                src={arrowBottom}
                width={"93%"}
                className="img-fluid ml-8"
                alt="guidance"
              />
            </div>
          </div>
        </div>
        <div className="col-4 illustrationContainer mt-3-vh">
          <img
            src={mainPageImage}
            width={"93%"}
            className="img-fluid ml-16"
            alt="guidance"
          />
        </div>
      </div>
    </>
  );
};
