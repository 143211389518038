import React from "react";
import PropTypes from "prop-types";

import "./mobileNavigationMenu.scss";

import logo from "../../../assets/images/logo.png";
import crossIcon from "../../../assets/icons/crossIcon.svg";

const MobileNavigationMenu = ({
  menuItems,
  openNavigationMenu,
  setOpenNavigationMenu,
}) => {
  const closeNavigationMenu = () => {
    setOpenNavigationMenu(false);
  };

  return (
    <div
      className={`mobile-menu-container ${
        openNavigationMenu ? "visible" : "hidden"
      }`}
    >
      <div className="main-page-mobile-navbar">
        <nav>
          <div className="main-page-mobile-logo-container">
            <a href="/">
              <img src={logo} alt="logo" />
            </a>
          </div>
          <div className="main-page-mobile-hamburger-menu">
            <img
              src={crossIcon}
              alt="close navbar icon"
              onClick={closeNavigationMenu}
            />
          </div>
        </nav>
        <ul id="links-container">
          {menuItems
            ? menuItems.map((item) => {
                return (
                  <li key={item.text}>
                    {item.icon ? (
                      <img
                        src={item.icon}
                        width={20}
                        height={20}
                        className="menuItemMainPage ml-16"
                        alt="guidance"
                      />
                    ) : null}

                    <a
                      href={`${item.url}`}
                      className={`nav-link ${item.icon ? "" : "ml-16"}`}
                    >
                      {item.text}
                    </a>
                  </li>
                );
              })
            : null}
        </ul>
      </div>
    </div>
  );
};

MobileNavigationMenu.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      url: PropTypes.string,
      icon: PropTypes.string,
    })
  ).isRequired,
  openNavigationMenu: PropTypes.bool.isRequired,
  setOpenNavigationMenu: PropTypes.func.isRequired,
};

export default MobileNavigationMenu;
