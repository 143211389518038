import React, { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";

import ReleaseExecution from "./ReleaseExecution";
import ClosureMeeting from "./ClosureMeeting";

import ReleaseChecklist from "../Development/Requirements/ReleaseChecklist";
import styles from "../Development/development.module.css";

import prepTimeIcon from "../../../assets/icons/prepTimeIcon.svg";
import runTimeIcon from "../../../assets/icons/runTimeIcon.svg";
import followUpTimeIcon from "../../../assets/icons/followUpTimeIcon.svg";
import peopleIcon from "../../../assets/icons/peopleIcon.svg";

function ReleaseGuidelines(props) {
  const location = useLocation();
  const { checkListValue, close } = props;
  const ref = useRef(null);
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      close && close();
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);
  return (
    <div>
      {location.pathname.includes("release-update") ? (
        <>
          {checkListValue === "releaseChecklist" ? (
            <ReleaseChecklist styles={styles} />
          ) : checkListValue === "releaseExecutionMeeting" ? (
            <ReleaseExecution
              guidanceone={prepTimeIcon}
              guidancetwo={runTimeIcon}
              guidancethree={followUpTimeIcon}
              guidancefour={peopleIcon}
            />
          ) : checkListValue === "closureHandoverMeeting" ? (
            <ClosureMeeting
              guidanceone={prepTimeIcon}
              guidancetwo={runTimeIcon}
              guidancethree={followUpTimeIcon}
              guidancefour={peopleIcon}
            />
          ) : null}
        </>
      ) : null}
    </div>
  );
}
export default ReleaseGuidelines;
