import GetProjectType from "./getProjectType";

const INITIAL_STATE = {
  data: null,
  loading: false,
};

const GetProjectReducer = (state = INITIAL_STATE, action = { type: "" }) => {
  switch (action.type) {
    case GetProjectType.SET_GET_PROJECT:
      return { ...state, loading: true };
    case GetProjectType.SET_GET_PROJECT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: action.loading,
      };
    default:
      return state;
  }
};

export default GetProjectReducer;
