import "./navbar.scss";

import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { googleLogout } from "@react-oauth/google";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { ROUTES, SUB_ROUTE_KEY } from "../../routes/routeConstants";
import { logoutUser } from "../../redux/actions/logout";
import logo from "../../assets/images/logo.png";
import avatar from "../../assets/icons/logout.svg";

import {
  setCurrentSubStage,
  getStatusProject,
  setCurrentPhase,
} from "../../redux/actions/projectDetails";
import { handleRole } from "../../pages/AdminPanel/ClientSatisfaction/clientSatisfactionHelper";

const Navbar = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const currentUserObj = JSON.parse(localStorage.getItem("userObj"));
  const currentUserRole = currentUserObj?.role;
  const allProjects = useSelector((state) => state?.projects.data);
  const accessToken = useSelector((state) => state.login.accessToken);
  const userRole = useSelector((state) => state.clientSatisfaction.userRole);

  const [showDropdown, setShowDropdown] = useState(false);

  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };

  const logoutHandler = () => {
    googleLogout();
    dispatch(logoutUser());
    localStorage.clear();
  };

  const changeRoute = (route) => {
    history.push(route);
  };

  const dropDownClickHandler = (e) => {
    if (showDropdown) setShowDropdown(false);
    else {
      if (e.target.classList.contains("avatar-icon")) setShowDropdown(true);
    }
    e.stopPropagation();
  };

  const getDisplayName = () => {
    const MAX_DISPLAY_NAME_LENGTH = 16;

    let displayName;

    const fullName = currentUserObj?.name;

    if (fullName.length <= MAX_DISPLAY_NAME_LENGTH + 2) return fullName;

    const names = fullName.split(" ");
    const firstName = names[0];
    const lastName = names[names.length - 1];

    if (firstName.length < MAX_DISPLAY_NAME_LENGTH) {
      displayName = `${firstName} ${lastName.charAt(0)}.`;
    } else if (firstName.length === MAX_DISPLAY_NAME_LENGTH) {
      displayName = firstName;
    } else {
      displayName = firstName.substring(0, MAX_DISPLAY_NAME_LENGTH + 3);
    }
    return displayName;
  };

  const isActiveRoute = (route) =>
    location.pathname.includes(route) ? "active-navbar-item" : "";

  const getProjectsDashboardUrl = () => {
    if (allProjects && allProjects.length > 0) {
      const firstProject = allProjects[0];
      return ROUTES[SUB_ROUTE_KEY[firstProject?.process?.name]](
        firstProject._id,
        firstProject.phase._id,
        firstProject.process._id
      );
    } else {
      return ROUTES.DASHBOARD;
    }
  };

  const updateProjectStates = () => {
    if (!allProjects || allProjects.length < 1) {
      return;
    }
    const firstProject = allProjects[0];

    const {
      _id: projectId,
      phase: { _id: phaseId, name: phaseName },
      process: { _id: processId, name: processName },
    } = firstProject;

    dispatch(setCurrentPhase(phaseId, phaseName));
    dispatch(setCurrentSubStage(processId, processName));
    dispatch(getStatusProject(config, projectId, "1", phaseName));
  };

  const changeRole = (role) => {
    handleRole(role);
    changeRoute(ROUTES.DASHBOARD);
  };

  window.addEventListener("click", (e) => {
    dropDownClickHandler(e);
    e.stopPropagation();
  });

  return (
    <header className="header dashboard-navbar">
      <div className="logo-dashboard-nav">
        <a href="/admin-panel/client-satisfaction">
          <img src={logo} alt="logo" className="img-fluid" />
        </a>
      </div>
      <div className="header-list-wrapper">
        <div className="header-main-items-wrapper">
          <ul className="flex flex-center navbar-items-wrapper">
            <li className={`navbar-item ${isActiveRoute(ROUTES.DASHBOARD)}`}>
              <Link
                className="navbar-link"
                aria-current="page"
                to={ROUTES.DASHBOARD}
              >
                Dashboard
              </Link>
            </li>
            <li className={`navbar-item ${isActiveRoute(ROUTES.PROJECTS(""))}`}>
              {currentUserRole !== "BD" ? (
                <Link
                  className="navbar-link active"
                  to={getProjectsDashboardUrl()}
                  onClick={updateProjectStates}
                >
                  Project
                </Link>
              ) : (
                <Link
                  className="navbar-link active"
                  to="/projects/client-satisfaction-meter"
                >
                  Projects
                </Link>
              )}
            </li>
          </ul>
        </div>

        <div className={`user-account-actions`}>
          <div className="avatar">
            <img
              src={avatar}
              alt=""
              className="avatar-icon"
              onClick={dropDownClickHandler}
            />
          </div>
          {showDropdown ? (
            <ul className="dropdown">
              <div className="introduction-container">
                <img src={avatar} alt="" className="profile-photo" />
                <div className="name-container">
                  <h4 className="name">{getDisplayName()}</h4>
                  <p className="role">{userRole.name}</p>
                </div>
              </div>
              <hr className="section-separator" />
              <div className="roles">
                {currentUserRole
                  ? currentUserRole.map((role) => (
                      <li
                        key={role._id}
                        data-id={role._id}
                        onClick={() => {
                          changeRole(role);
                        }}
                      >
                        {role.name}
                      </li>
                    ))
                  : null}
              </div>
              <hr className="section-separator" />
              <div className="logout-link">
                <li>
                  <Link to="/sign-in" onClick={logoutHandler}>
                    Log out
                  </Link>
                </li>
              </div>
            </ul>
          ) : null}
        </div>
      </div>
    </header>
  );
};

export default Navbar;
