import React from "react";
import PersonAvatarWithName from "../../../components/GuideLines/PersonAvatarWithName/PersonAvatarWithName";

const personName = "Umar";
function Umar() {
  return (
    <div className="guidancesContainer">
      <PersonAvatarWithName key={personName} name={personName} />
      <br />
      <br />
      <div className="guidanceReqInnerBox">
        <p className={"processRunning"}>
          <b>Use case</b> : The playbook captures his good practices, tips and
          tricks that other can learn from. It is a book that he could use to
          train his team. The set of obligatory activities and tools would make
          it easy for him to monitor the health of his projects as he takes on a
          larger portfolio.
        </p>
      </div>
      <p className={"processRunning"}>
        Umar is an engineer by background and started at Tintash as such. He is
        a 35-year-old father of three young children. He works hard, thinks
        fast, and likes to solve problem creatively. He has been managing
        several large and medium projects at Tintash in the past 5 years. He is
        known at the company for the friendly atmosphere that he has created for
        his team and high level of trust that he enjoys with his clients. He
        manages by instinct which lets him adapt quickly to the needs of his
        clients while keeping his team happy.
        <br />
        <br />
        His <b>pains</b> mainly come from a busy life, having many hats to wear
        at work and at home. A can-do attitude sometimes works against Umar who
        immediately thinks of how to make something happen when a client has new
        asks and, therefore, can take too much on. He knows that he can grow his
        portfolio with the same level of success but will need to delegate to
        someone that can maintain the client relationship and the team dynamic.
        It is not easy to find someone who can do what Umar does. His{" "}
        <b>gains </b>
        relate to the respect he enjoys in the organisation which allows him
        great leeway in the tools and tricks that he can use to get things done.
        He is on the path to taking on more leadership responsibility at
        Tintash.
      </p>
    </div>
  );
}

export default Umar;
