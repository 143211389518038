import React, { useState, useRef, useEffect } from "react";

import MainPageNav from "../../../components/MainPageNav/MainPageNav";
import arrow from "../../../assets/images/arrow.svg";
import { WhatHappensData } from "../../../components/Processes/LeadConversion/components/WhatHappensData";
import { GuideLines } from "../../../components/GuideLines/GuideLines";
import ApplyingPrinciples from "./components/ApplyingPrinciples";
import { DO_CONSTANTS } from "../../../constants/doConstants";
import leadConversionIcon from "../../../assets/icons/leadConversion.svg";
import dollar from "../../../assets/icons/dollar.svg";
import thumbsUp from "../../../assets/icons/thumbsUp.svg";
import heartIcon from "../../../assets/icons/heartIcon.svg";
import "./development.scss";
import ScrollToTopButton from "../../../components/ScrollToTopButton/ScrollToTopButton";
import WhatHappensButton from "../../../components/WhatHappensButton/WhatHappensButton";

import { MOBILE_NAVIGATION_MENU_ITEMS } from "../../../constants/navigationMenuConstants";
import MobileNavbarTop from "../../../components/mobile/MobileNavbarTop/MobileNavbarTop";
import MobileNavbarBottom from "../../../components/mobile/MobileNavbarBottom/MobileNavbarBottom";
import useElementInView from "../../../utilities/useElementInView";
import { whatHappensObserverOptions } from "../LeadConversion/LeadConversion";

const WHEREDATA = [
  {
    name: "Beginning Development",
    dos: [
      { name: "Charter Meet", ref: "TeamChartMeet" },
      { name: "Sprint 0.5", ref: "Sprintzerofive" },
    ],
    uses: [
      { name: "Project Work-Plan", url: "" },
      { name: "Project SOPs (tickets, comms, documentation)", url: "" },
      { name: "Feature Flow", url: "" },
      { name: "Project Documentation Template", url: "" },
      { name: "Release Checklist", url: "" },
    ],
  },
  {
    name: "During Development",
    dos: [
      { name: "Release Planning Meeting", ref: "Release_Plan" },
      { name: "Dev Kickoff Meeting" },
      { name: "Daily Standup and Syncups" },
      { name: "Update Meetings (with client)" },
      { name: "Dogfooding", ref: "Dogfooding" },
    ],
    uses: [
      { name: "Project Work-Plan", url: "" },
      { name: "Project Documentation Template", url: "" },
      { name: "Status Report", url: "" },
    ],
  },
  {
    name: "Completing product/feature",
    dos: [{ name: "Milestone Demo" }],
    uses: [],
  },
];

export const Development = () => {
  const pageTopRef = useRef(null);
  const whatHappensSectionRef = useRef(null);

  const [activeWhere, setActiveWhere] = useState(WHEREDATA[0]);
  const [showGuideline, setShowGuideLine] = useState(false);
  const [selectedGuideline, setSelectedGuideline] = useState("");
  const [openNavigationMenu, setOpenNavigationMenu] = useState(false);

  const [whatHappensRef, whatHappensRefInView] = useElementInView(
    whatHappensObserverOptions
  );

  const [whatHappensEndRef, whatHappensEndRefInView] = useElementInView(
    whatHappensObserverOptions
  );

  const whatHappenStateHandler = (val) => {
    const guideLineDisplay = DO_CONSTANTS.find(
      (data) => val.target.innerText === data
    );
    if (guideLineDisplay) {
      setShowGuideLine(true);
      setSelectedGuideline(val.target.innerText);
    }
  };
  const mainDivFunction = () => {
    if (showGuideline) {
      setShowGuideLine(false);
    }
  };

  useEffect(() => {
    pageTopRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  return (
    <>
      <div ref={pageTopRef} />
      <MobileNavbarTop
        openNavigationMenu={openNavigationMenu}
        setOpenNavigationMenu={setOpenNavigationMenu}
        menuItems={MOBILE_NAVIGATION_MENU_ITEMS}
      />

      <MainPageNav />
      {showGuideline && (
        <GuideLines
          selectedGuideline={selectedGuideline}
          close={() => setShowGuideLine(false)}
        />
      )}
      <div
        onClick={mainDivFunction}
        className={showGuideline ? "row opacity" : "row"}
      >
        <div id="intro-row" className={"row mt-88"}>
          <div className="col-2 whitespace"></div>
          <div className="col-5">
            <div className="mobile-flex">
              <img className="waterfall-chart" src={leadConversionIcon} />
              <h1 className="processHeading2 develop-heading ml-24">
                Development
              </h1>
            </div>
            <div className="lead-content">
              <p className={"lead-content col-8"}>
                The development phase begins after discovery is concluded and/or
                a Statement of Work (SOW) for the project is agreed upon with
                the client. It ends when a feature (set) is developed and ready
                for polishing. Tintash follows an agile approach to development
                which means it takes place in iterative cycles of design,
                coding, and QA.
              </p>
            </div>
          </div>
          <div className="mobile-hidden col-5">
            <WhatHappensButton buttonRef={whatHappensSectionRef} />
          </div>
        </div>
        <ApplyingPrinciples
          principleone={heartIcon}
          principletwo={thumbsUp}
          principlethree={dollar}
        />
        <div className="row">
          <div
            className="main-content-align"
            id="linkWhatHappen"
            ref={whatHappensSectionRef}
          >
            <div className="happens" ref={whatHappensRef}>
              What Happens?
            </div>
          </div>
        </div>
        <div className="whitespace mt-40"></div>
        <WhatHappensData
          wheredata={WHEREDATA}
          activeWhere={activeWhere}
          arrow={arrow}
          setActiveWhere={setActiveWhere}
          onChangeValue={whatHappenStateHandler}
        />
        <div ref={whatHappensEndRef}></div>

        {!(whatHappensRefInView || whatHappensEndRefInView) ? (
          <MobileNavbarBottom>
            <WhatHappensButton buttonRef={whatHappensSectionRef} />
          </MobileNavbarBottom>
        ) : null}

        <ScrollToTopButton pageTopRef={pageTopRef} />
      </div>
    </>
  );
};
