import "../FormikDyanmic.scss";

import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Button } from "antd";
import { Field, FieldArray, useFormikContext } from "formik";
import { getCurrentTime } from "../../../utilities/dateUtilities";
import { TextareaToggle } from "../checbox-toggle/TextareaToggle";
import { RichTextEditor } from "../../rich-text-editor/RichTextEditor";
import styles from "../../../pages/AdminPanel/adminPanel.module.css";
import GuidelineFiles from "../../../pages/AdminPanel/Projects/GuidelineSection/GuidelineFiles";
import completedIcon from "../../../assets/icons/check.svg";
import { FieldArrayComponent } from "../dynamic-form/FieldArrayComponent";
import { NextStageDialog as ConfirmModal } from "../../Dialog/next-stage-dialog/NextStageDialog";
import { ErrorDialog } from "../../Dialog/dialog";

export const FormIkRadioDynamicContent = ({
  errors,
  handleSubmit,
  checkList,
  setFieldValue,
  resetForm,
  status,
}) => {
  const [currentChecklist, setCurrentChecklist] = useState({});
  const [showGuidelines, setShowGuidelines] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [checkListValue, setCheckListValue] = useState("");
  const { phaseId, processId } = useParams();
  const { values } = useFormikContext();

  const handleStartButtonClick = (value) => {
    setFieldValue("syncupType", value);
    const currentList = checkList.find((item) => item.html?.value === value);

    setCurrentChecklist(currentList || {});
  };

  const handleCancleButtonClick = () => {
    values.syncupType = "";
    setCurrentChecklist({});
    resetForm();
  };

  const handleEndButtonClick = async () => {
    if (Object?.keys(errors)?.length < 1) {
      setShowDialog(true);
    } else {
      return ErrorDialog({ title: "Please enter valid email address" });
    }
  };

  const guidelinesClick = (value) => {
    setCheckListValue(value);
    setShowGuidelines(true);
  };

  return (
    <>
      <ConfirmModal
        onSuccess={handleSubmit}
        title="Are you sure you want to end this activity?"
        showDialog={showDialog}
        setShowDialog={setShowDialog}
      />
      <div className="update-section-wrapper">
        <div className="updateSection">
          <div className="flex align-center">
            <h4 className="updateTitle mt-32">Updates</h4>
            <div className="date mb-18 ml-16">{getCurrentTime()}</div>
          </div>
        </div>
        {showGuidelines ? (
          <GuidelineFiles
            styles={styles}
            subStageId={phaseId}
            substageName={processId}
            checkListValue={checkListValue}
            close={() => setShowGuidelines(false)}
          />
        ) : (
          <></>
        )}
        <div className="child-form-container w-98%">
          {Array.isArray(checkList) &&
            checkList.map(({ html: { text, value, clickable } }, index) => (
              <>
                <div className="row item-row" key={index}>
                  <div className="item-heading w-42%">
                    {!clickable && (
                      <img className="mr-8 w-18" src={completedIcon} />
                    )}
                    {text}
                  </div>
                  {values.syncupType === value ? (
                    <>
                      <div className="w-26%" />
                      <button
                        type="submit"
                        className="start-button col-2"
                        onClick={() => handleCancleButtonClick()}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="start-button col-2"
                        onClick={() => handleEndButtonClick()}
                      >
                        End
                      </button>
                    </>
                  ) : (
                    <>
                      <div className="w-16%" />
                      <button
                        className="guidelines-button col-2"
                        onClick={() => {
                          guidelinesClick(value);
                        }}
                      >
                        Guidelines
                      </button>
                      <Button
                        className="start-button col-2"
                        disabled={status === "closed"}
                        onClick={() => handleStartButtonClick(value)}
                      >
                        Start
                      </Button>
                    </>
                  )}
                </div>
                <div className="sub-form-wrapper">
                  <div>
                    {Object.keys(currentChecklist)?.length > 1 &&
                      values.syncupType === value && (
                        <>
                          {currentChecklist?.onClick?.map(
                            (formChildItem, index) => {
                              return (
                                <div key={`form-child-element-${index}`}>
                                  {formChildItem.inputType === "checkbox" ? (
                                    <label>
                                      <Field
                                        type="checkbox"
                                        name={
                                          currentChecklist?.html.value +
                                          "." +
                                          formChildItem.value
                                        }
                                      />
                                      <span className="field-label">
                                        {formChildItem.text}
                                      </span>
                                    </label>
                                  ) : (
                                    <TextareaToggle
                                      label={formChildItem.text}
                                      keyIdentifier={
                                        currentChecklist?.html.value +
                                        "." +
                                        formChildItem.value
                                      }
                                    />
                                  )}
                                </div>
                              );
                            }
                          )}
                          <label className="email-section-wrapper">
                            <span>Recipients email</span>
                            <FieldArray
                              name="sendTo"
                              type="text"
                              component={FieldArrayComponent}
                            />
                          </label>
                          <label className="comment-section-wrapper">
                            <span className="comments-label">
                              Meeting Points
                            </span>
                            <Field className="comment-section" name="comments">
                              {({ field, form }) => (
                                <RichTextEditor field={field} form={form} />
                              )}
                            </Field>
                          </label>
                        </>
                      )}
                  </div>
                </div>
              </>
            ))}
        </div>
      </div>
    </>
  );
};
