const getCurrentEnvironmentBaseUrl = () => {
  const currentDomain = window.location.host;
  if (currentDomain.includes("localhost")) {
    return "https://playbook-pm-dev.herokuapp.com";
  }
  if (currentDomain.includes("develop")) {
    return "https://playbook-pm-dev.herokuapp.com";
  }
  if (currentDomain.includes("staging")) {
    return "https://playbook-pm-staging.herokuapp.com";
  }
  return "https://playbook-pm.herokuapp.com";
};

export const CURRENT_ORIGIN = window.location.origin;
export const ENV_CONSTANTS = {
  SERVER_URL: getCurrentEnvironmentBaseUrl(),
  GCP_CLIENT_ID: process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID || "",
};

export const SOCKET_CONSTANTS = {
  JOIN_ROOM: "joinRoom",
  LEAVE_ROOM: "leaveRoom",
  UPDATE_OUTPUT_FILES: "updateOutputFiles",
  UPDATE_PROCESS_STATUS: "updateProcessStatus",
  GENERATE_POPUP: "generatePopup",
};
