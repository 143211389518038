import {
  GET_LOGIN_DETAIL,
  GET_LOGIN_DETAIL_SUCCESS,
  GET_LOGIN_DETAIL_ERROR,
} from "./types";
import axios from "axios";
import { ENV_CONSTANTS } from "../../constants/globalConstants";
const baseURL = ENV_CONSTANTS.SERVER_URL;

export const loginAuth = (loginToken) => async (dispatch) => {
  dispatch({ type: GET_LOGIN_DETAIL });
  try {
    axios
      .post(`${baseURL}/google-authentication/`, {
        token: loginToken,
      })
      .then((response) => {
        localStorage.setItem("userObj", JSON.stringify(response.data));
        dispatch({
          type: GET_LOGIN_DETAIL_SUCCESS,
          data: response.data,
          payload: response.data.accessToken,
          loading: false,
          isRegisteredWithGoogle: response.data.isRegisteredWithGoogle,
        });
        return response;
      })
      .catch(() => {
        dispatch({
          type: GET_LOGIN_DETAIL_ERROR,
          payload: {
            message: "Log In Error",
          },
        });
      });
  } catch (error) {
    throw new Error("Google Sign In");
  }
};
