import "./right-arrow.scss";
import React from "react";

function RightArrow({ width = 180 }) {
  return (
    <div className={`right-arrow-container width-${width}`}>
      <div className="right-arrow-line"></div>
      <i className="fa fa-caret-right" />
    </div>
  );
}

export default RightArrow;
