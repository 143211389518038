import React from "react";
function Intro(props) {
  return (
    <div className={"processIntro"}>
      <div className={"row"}>
        <div className={"col-6"}>
          <h1 className={"processHeading"}>Development</h1>
          <p className={"processRunning"}>
            The development phase begins after discovery is concluded and/or a
            Statement of Work (SOW) for the project is agreed upon with the
            client. It ends when a feature (set) is developed and ready for
            polishing. Tintash follows an agile approach to development which
            means it takes place in iterative cycles of design, coding, and QA.
          </p>
        </div>
        <div className={"col-6"}>
          <img className={"img-fluid"} src={props.mainImg} alt="process" />
        </div>
      </div>
    </div>
  );
}

export default Intro;
