import React from "react";
import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

export function ConfirmationDialog({
  title,
  message,
  onConfirm,
  yesText,
  onCancel = false,
}) {
  Modal.confirm({
    title: title,
    icon: <ExclamationCircleOutlined />,
    content: message,
    //okText: "Yes",
    okText: yesText,
    //okType: type,
    //cancelText: "No",
    onOk: onConfirm,
    maskClosable: true,
    onCancel: onCancel,
    centered: true,
  });
}
export function SuccessDialog({ title, message, type = "danger", onConfirm }) {
  Modal.success({
    title: title,
    content: message,
    okText: "Got it",
    onOk: onConfirm,
    centered: true,
    maskClosable: true,
    okType: type,
    cancelText: "No",
  });
}
export function ErrorDialog({ title = "", message, onConfirm }) {
  Modal.error({
    title: title,
    content: message,
    okText: "Got it",
    className: "error-dialog-custom",
    okButtonProps: { className: "btn-custom btn-danger-custom" },
    onOk: onConfirm,
    centered: true,
    maskClosable: true,
    cancelText: "No",
  });
}

export function Dialog({
  title,
  visible,
  onCancel,
  children,
  size,
  reset = true,
  className = "",
  closable = true,
}) {
  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={closable ? onCancel : undefined}
      footer={null}
      centered
      wrapClassName={`${size ? ` modal-${size}` : ""} ${className}`}
      destroyOnClose={reset}
      keyboard={closable}
    >
      {children}
    </Modal>
  );
}

export const ConfirmModal = ({
  children,
  visible,
  onCancel,
  content,
  okText,
  reset = true,
  closable = true,
}) => (
  <Modal
    content={content}
    centered
    onCancel={onCancel}
    visible={visible}
    okText={okText}
    footer={null}
    closable={false}
    maskClosable={true}
    destroyOnClose={reset}
    keyboard={closable}
  >
    {children}
  </Modal>
);
