import React from "react";
import { Dialog } from "./dialog";
import "./UpdateDialog.scss";

export default function UpdateDialog({ visible, hide, updateDetails }) {
  const getUpdateValue = (updateValue) => {
    if (typeof updateValue === "boolean") {
      return updateValue ? "Yes" : "No";
    }
    return updateValue;
  };
  return (
    <Dialog
      title={"View Details"}
      visible={visible}
      onCancel={hide}
      className="update-details-dialog"
    >
      {updateDetails &&
        updateDetails.checklist &&
        updateDetails.checklist.map((updateItem, index) => {
          return (
            <div
              className="mt-16 flex align-center justify-space-between"
              key={`update-item-` + index}
            >
              <h6>{updateItem.text}</h6>
              <h6 className="details-content-field">
                {getUpdateValue(updateItem.value)}
              </h6>
            </div>
          );
        })}
      <div className="mt-16 flex flex-column justify-space-between">
        <strong>
          <h6>{updateDetails && updateDetails.text} Comments</h6>
        </strong>
        <div
          className="comment-table-section"
          dangerouslySetInnerHTML={{
            __html: updateDetails && updateDetails.meetingPoints,
          }}
        />
      </div>
      <div className="mt-16 flex flex-column justify-space-between">
        <strong>
          <h6>{updateDetails && updateDetails.text} Recipients Email</h6>
        </strong>
        {updateDetails &&
          updateDetails.sendTo &&
          updateDetails.sendTo.map((item, index) => {
            return (
              <div
                key={index}
                className="email-table-section"
                dangerouslySetInnerHTML={{
                  __html: updateDetails && updateDetails.sendTo[index],
                }}
              />
            );
          })}
      </div>
    </Dialog>
  );
}
