import React from "react";
import { UpdateList } from "../../../../../UpdateSection/update-list/UpdateList";
import { LookingBackUpdateUpdateForm } from "./LookingBackUpdateForm/LookingBackUpdateForm";
import "../../../DiscoveryUpdateSection.scss";

export const LookingBackUpdateSection = () => {
  return (
    <div className="project-update-details-wrapper">
      <div className="update-form-wrapper">
        <LookingBackUpdateUpdateForm />
      </div>
      <div className="update-list-wrapper">
        <div className="side-update-title mt-32 ml-24">
          Project Management Events
        </div>
        <UpdateList />
      </div>
    </div>
  );
};
