export const SUB_ROUTE_KEY = {
  Discover: "discovery",
  "Starting a Discovery": "START_DISCOVERY_UPDATE",
  "During Discovery": "DURING_DISCOVERY_UPDATE",
  "Finishing a Discovery": "FINISH_DISCOVERY_UPDATE",
  "Provide an Estimate": "PROVIDE_ESTIMATE_UPDATE",
  "Handing Over to PM": "HANDING_OVER_PM_UPDATE",
  "Looping Back": "LOOKING_BACK_UPDATE",
  "Beginning Development": "BEGINNING_DEVELOPMENT_UPDATE",
  "During Development": "DURING_DEVELOPMENT_UPDATE",
  "Completing Development or Product":
    "COMPLETING_DEVELOPMENT_OR_PRODUCT_UPDATE",
  "Completing Development": "COMPLETING_DEVELOPMENT_OR_PRODUCT_UPDATE",
  "Dog Fooding": "DOG_FOODING_UPDATE",
  "Release Update": "RELEASE_UPDATE",
  "Looking Back": "LOOKING_BACK_UPDATE",
  Dogfooding: "DOG_FOODING_UPDATE",
  Release: "RELEASE_UPDATE",
};

export const ROUTES = {
  HOME_PAGE: "/",
  MAIN_PAGE: "/main-page",
  LEAD_CONVERSION: "/lead-conversion",
  DISCOVERY: "/discovery",
  DEVELOPMENT: "/development",
  POLISHING: "/polishing",
  RELEASE: "/release",
  MAINTAINANCE: "/maintainance",
  INTRODUCTION: "/introduction",
  HOW_TO_USE: "/how-to-use",
  SIGN_IN: "/sign-in",
  ADMIN_PANEL: "/admin-panel",
  WHY_THE_PLAYBOOK: "/why-the-playbook",
  PROJECTS: (projectId) => `/admin-panel/projects/${projectId}`,
  DASHBOARD: "/admin-panel/client-satisfaction",

  PROVIDE_ESTIMATE: (projectId, phaseId, processId) =>
    `/admin-panel/projects/${projectId}/phase/${phaseId}/provide-estimate/${processId}`,
  HANDING_OVER_PM: (projectId, phaseId, processId) =>
    `/admin-panel/projects/${projectId}/phase/${phaseId}/handing-over/${processId}`,
  LOOKING_BACK: (projectId, phaseId, processId) =>
    `/admin-panel/projects/${projectId}/phase/${phaseId}/looking-back/${processId}`,
  PROVIDE_ESTIMATE_UPDATE: (projectId, phaseId, processId) =>
    `/admin-panel/projects/${projectId}/phase/${phaseId}/provide-estimate/${processId}/update`,
  HANDING_OVER_PM_UPDATE: (projectId, phaseId, processId) =>
    `/admin-panel/projects/${projectId}/phase/${phaseId}/handing-over/${processId}/update`,
  LOOKING_BACK_UPDATE: (projectId, phaseId, processId) =>
    `/admin-panel/projects/${projectId}/phase/${phaseId}/looking-back/${processId}/update`,
  START_DISCOVERY: (projectId, phaseId, processId) =>
    `/admin-panel/projects/${projectId}/phase/${phaseId}/start-discovery/${processId}`,
  DURING_DISCOVERY: (projectId, phaseId, processId) =>
    `/admin-panel/projects/${projectId}/phase/${phaseId}/during-discovery/${processId}`,
  FINISH_DISCOVERY: (projectId, phaseId, processId) =>
    `/admin-panel/projects/${projectId}/phase/${phaseId}/finish-discovery/${processId}`,
  START_DISCOVERY_UPDATE: (projectId, phaseId, processId) =>
    `/admin-panel/projects/${projectId}/phase/${phaseId}/start-discovery/${processId}/update`,
  DURING_DISCOVERY_UPDATE: (projectId, phaseId, processId) =>
    `/admin-panel/projects/${projectId}/phase/${phaseId}/during-discovery/${processId}/update`,
  FINISH_DISCOVERY_UPDATE: (projectId, phaseId, processId) =>
    `/admin-panel/projects/${projectId}/phase/${phaseId}/finish-discovery/${processId}/update`,
  DURING_DISCOVERY_ARTIFACTS: (projectId, phaseId, processId) =>
    `/admin-panel/projects/${projectId}/phase/${phaseId}/during-discovery/${processId}/artifacts`,
  BEGINNING_DEVELOPMENT: (projectId, phaseId, processId) =>
    `/admin-panel/projects/${projectId}/phase/${phaseId}/beginning-development/${processId}`,
  BEGINNING_DEVELOPMENT_UPDATE: (projectId, phaseId, processId) =>
    `/admin-panel/projects/${projectId}/phase/${phaseId}/beginning-development/${processId}/update`,
  DURING_DEVELOPMENT: (projectId, phaseId, processId) =>
    `/admin-panel/projects/${projectId}/phase/${phaseId}/during-development/${processId}`,
  DURING_DEVELOPMENT_UPDATE: (projectId, phaseId, processId) =>
    `/admin-panel/projects/${projectId}/phase/${phaseId}/during-development/${processId}/update`,
  COMPLETING_DEVELOPMENT_OR_PRODUCT: (projectId, phaseId, processId) =>
    `/admin-panel/projects/${projectId}/phase/${phaseId}/completing-development-or-product/${processId}`,
  COMPLETING_DEVELOPMENT_OR_PRODUCT_UPDATE: (projectId, phaseId, processId) =>
    `/admin-panel/projects/${projectId}/phase/${phaseId}/completing-development-or-product/${processId}/update`,
  DOG_FOODING: (projectId, phaseId, processId) =>
    `/admin-panel/projects/${projectId}/phase/${phaseId}/dog-fooding/${processId}`,
  DOG_FOODING_UPDATE: (projectId, phaseId, processId) =>
    `/admin-panel/projects/${projectId}/phase/${phaseId}/dog-fooding/${processId}/update`,
  START_RELEASE: (projectId, phaseId, processId) =>
    `/admin-panel/projects/${projectId}/phase/${phaseId}/release-update/${processId}`,
  RELEASE_UPDATE: (projectId, phaseId, processId) =>
    `/admin-panel/projects/${projectId}/phase/${phaseId}/release-update/${processId}/update`,
};

export const NEXT_LEAD_CONVERSION_PROCESS_STEPS = {
  "provide-estimate": ROUTES.HANDING_OVER_PM_UPDATE,
  "handing-over": ROUTES.LOOKING_BACK_UPDATE,
  "looking-back": ROUTES.LOOKING_BACK_UPDATE,
};

export const NEXT_DISCOVERY_PROCESS_STEPS = {
  "start-discovery": ROUTES.DURING_DISCOVERY_UPDATE,
  "during-discovery": ROUTES.FINISH_DISCOVERY_UPDATE,
  "finish-discovery": ROUTES.FINISH_DISCOVERY_UPDATE,
};
export const NEXT_DEVELOPMENT_PROCESS_STEPS = {
  "beginning-development": ROUTES.DURING_DEVELOPMENT_UPDATE,
  "during-development": ROUTES.COMPLETING_DEVELOPMENT_OR_PRODUCT_UPDATE,
  "completing-development-or-product":
    ROUTES.COMPLETING_DEVELOPMENT_OR_PRODUCT_UPDATE,
};

export const NEXT_POLISHING_PROCESS_STEPS = {
  "dog-fooding": ROUTES.DOG_FOODING_UPDATE,
};

export const NEXT_RELEASE_STEPS = {
  release: ROUTES.RELEASE_UPDATE,
  "release-update": ROUTES.RELEASE_UPDATE,
};
