const BADGES_CONTENT = {
  DELIGHT_CUSTOMER:
    "If these are the types of updates that I will receive for the next six months, I want to work with this team.",
  PROFITABILITY:
    "Discuss the risks of the project very openly with the client. You may find solutions together. And if not, both parties walk away safe with their decision to not continue.",
  DISCOVERY_1: "It has important internal purposes too.",
  DISCOVERY_2:
    "Discovery is half about discovering the project and half about the client getting the experience of working with you. Remember the clients that we have converted based on experience alone despite changing our high-level estimates completely.",
  DISCOVERY_3:
    "Discovery is also about validating if the project is realistic and if we want to work on this project and with this client. If the answer is no to any of the above, do not continue with project.",
  ONBOARD:
    "This is more than just procedure. This is also about creating a safe space. The tone you set here will remain with the team for the entire duration of the project.",
  INTRODUCTIONS:
    "Plan icebreakers for team introductions! Some favourites used by PMs at Tintash are: favourite movie, favourite food, pitch a movie. There is also show and tell (grab an item close by, show us and tell us something about it), or a short quiz on Kahoot.it prepared in advance about the product or general trivia",
  PRODUCT:
    " Product thinking: encourage product thinking amongst the team from day 1. You can achieve this in different ways depending on your own individual style, type of project and the team. I make this as interactive as possible and always prepare in advance. AI start by asking the team members some questions before explaining the concept to them: why do you think the client wants to build this/what do they want to achieve. Who will be the users. Why would they choose this product.",
  TEAM_1:
    "One of our projects where we did not understand who the decision maker was. Didn’t understand their internal dynamics. We were in touch with the founder in the beginning who took to backstage during development when the daily manager became the focal person. Internally between them, the manager did not have much decision-making power. What do you think happened?",
  TEAM_2:
    " Game project. Client gave APIs, they were mostly wrong. It was frustrating for the team but the client didn’t learn and kept sending APIs. What to do?",
  SIMULATION_1:
    "Take one small feature that is complicated enough for a 15-minute discussion. Simulate the team actually working on it. Remember the meeting planning tips to be able to execute this in a useful way.",
  SIMULATION_2:
    " See how your team – these people, in this group – works together. Breakdown the ‘normal’ boundaries. Let engineers talk about design, QA talk about engineering. Talking about each other’s domains – within reason – is essential to the success of cross-functional teams. By doing this simulation, you have the opportunity to create that dynamic from the beginning – you solve a problem before it happens!",
  SIMULATION_3:
    "A UX Engineer on one of our projects is often upset - why is the engineer talking about my design, he says. This creates negativity in meetings and good ideas are missed due to resistance. What to do?",
  SCOPE_1:
    "Share with the team what has been agreed with the client. The deliverable and timeline may be adjusted to the client but keep the internal purpose in mind as well: risk mitigation and getting to know the client, make sure we want to work with the client and on this project. Do not shy away from a difficult conversation.",
  SCOPE_2:
    "Sometimes the best way to do that is UX, sometimes it is not. If you see that a Proof of Concept will bring down the budget by 50.000, discuss with the client and do it instead of sticking with the initial scope.",
  SCOPE_3:
    "Incremental experiments! Room builder: Murad gave a step by step plan to Amra. Start with one person who will do x, y. If that clears, we will go to the next experiment. If anytime this fails, we will stop.",
  MEETING:
    "Discuss the agenda of the discovery kick off meeting, agree on who is going to participate and who is going to present/ask what. Make sur ethe team is on the same page about what needs to be discussed with the client and why.",
  PRIORITISE_1:
    "Clients will have plans for their product. Launch related activities before, during and after the launch. Marketing and networking activities that they would undertake in parallel to development. Investor reach outs or other fundraising activities for which they might need demos or other information. This is the time to understand the product in its complete business context.",
  PRIORITISE_2:
    "Some clients would have an evolved business plan of which product development is one part, some clients will not have a complete plan for a number of reasons. Either way, remember that the product you are working on belongs in a larger business context. The more you know about it, the better you can advise the client. Tintash wants to act and be treated as partner, that begins with taking an interest in the larger picture. Ask questions, show interest.",
  PRIORITISE_3:
    "As an added bonus this will help you understand client’s expectations and reactions and stress factors. That understanding will come to save the day many times when there is friction.",
  BEFORE_MEETING:
    " When the project first starts, it’s great to set up a recurring invite to book the meeting time for the duration of the project. However, the key to a smooth meeting is to ensure that the agenda is clear to all participants. This allows them to add to the agenda as relevant. Editing the invite at least a couple of days before each meeting with a clear invitation to add agenda items is important.",
  CHECKLIST:
    "Do not go through the checklist like an interview questionnaire. Only use it for yourself to make sure you have asked everything that needs to be asked.",
  SHARE:
    " It’s important to note that a project can be highly successful and on track with the end customer but on many occasions the founding team members will not be active on Slack or other real-time tools, so using them as the de facto mechanism for information dissemination to all the relevant stakeholders is unreliable. We should use emails to capture the minutes of meetings and send them to all the project stakeholders.",
  PERSONAS_1:
    "Understand the client, know if they have gone through a design thinking exercise before. Ask about users, not user personas where you know a client does not have personas. The purpose is for you to know their target users.",
  PERSONAS_2:
    "If there are no personas: use this discussion to make an expert recommendation. E.g. your target is college students. Do girls and boys have different needs? Are they likely to use the product differently? Does it depend on where they live? What else about the college students is important to understand? We could help you make user personas.",
  COMPETITORS:
    "many of our clients did not have this information, we have had to research and tell clients. Even if they have some in mind: your purpose is to see and advise on which competitor is the most relevant. You should be able to explain to the client why this is important for you – so you know what look & feel, user experience and tech stack are they competing with and you can give advice on priorities based on that. ",
  LAWS_1:
    " You don’t have to implement any or all of it during the project – for example for an MVP if the client chooses it that way. However, you need to know what might come your way down the line and how it may fundamentally affect design, front or backend choices. ",
  LAWS_2:
    "Tell the clients that companies consider these at different stages. Do not make a recommendation that will implicate Tintash. If advised to wait, clients will appreciate the time and cost saving when all goes well. But if fined or in trouble otherwise, they will say Tintash told us to wait.",
  ESTIMATE_1:
    "A game project we all know well. Everyone including the client forgot that games have sounds too. Smart senior people were involved and yet we didn’t add to the estimates. What to do?",
  ESTIMATE_2:
    "We did not add a 3D artist to a game project because the client said they will provide the assets. What is wrong in this scenario? ",
};
export default BADGES_CONTENT;
