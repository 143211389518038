import React from "react";
import { ConfirmModal } from "../dialog";
import "./NextStageDialog.scss";
import { Button } from "../../Button/Button";

export const NextStageDialog = ({
  onSuccess,
  title,
  showDialog,
  setShowDialog,
  isDisabled = false,
}) => {
  const handleConfirm = async () => {
    await onSuccess();
    setShowDialog(false);
  };
  return (
    <ConfirmModal visible={showDialog} onCancel={() => setShowDialog(false)}>
      <div className="next-stage-dialog-content">
        <div className="next-stage-dialog-title">{title}</div>
        <div className="action-buttons-wrapper">
          <Button
            className="btn-primary-custom"
            onClick={() => setShowDialog(false)}
          >
            No
          </Button>
          <Button
            className="btn-success-custom"
            disabled={isDisabled}
            onClick={() => handleConfirm()}
          >
            Yes
          </Button>
        </div>
      </div>
    </ConfirmModal>
  );
};
