import React from "react";
function StatusReport(props) {
  const { styles } = props;

  return (
    <div className="guidancesContainer processRunning">
      <div className={styles.guidanceMainHeadingContainer}>
        <h2 className="processHeading2" style={{ margin: 0 }}>
          Status Report
        </h2>
      </div>
      <br />
      <div>
        <p>
          The Status Report is a key communication tool in connection to or
          instead of an update meeting. The purpose of a Status Report is to
          document and communicate the progress of a project, product, or
          release in terms of three key aspects: scope, budget, and
          schedule/timeline.
        </p>

        <p>
          Before drafting and sending a report, think through these 4 questions:
        </p>
        <ol>
          <li>Why are you reporting?</li>
          <li>What are you reporting?</li>
          <li>Who are you reporting to?</li>
          <li>When should you report?</li>
        </ol>

        <p>
          Use the following template but adjust the content and, even sections,
          based on the answers to the four questions above. Text in the template
          is example only.
        </p>
        <div className={styles.statusReport__statusContainer}>
          <b>Project / Product / Release status</b>
          <div className={styles.statusReport__labelContainer}>
            <div
              style={{
                width: "18px",
                height: "18px",
                background: "#6BDE92",
                marginRight: "5px",
                borderRadius: "20px",
              }}
            ></div>
            <div
              style={{
                width: "18px",
                height: "18px",
                background: "#FFEC3E",
                marginRight: "5px",
                borderRadius: "20px",
              }}
            ></div>
            <div
              style={{
                width: "18px",
                height: "18px",
                background: "#F16262",
                borderRadius: "20px",
              }}
            ></div>
          </div>
        </div>

        <div className="row">
          <div className="col-8">
            <div className="statusReport__container">
              <div className={styles.statusReport__budget}>
                <div className={styles.statusReport__budgetPurple}>
                  <div className={styles.budgetHeadingPurple}>
                    <b>Budget Spent</b>
                  </div>
                  <div className="budgetDescription">Extra hours due to...</div>
                </div>
                <div className={styles.statusReport__budgetRed}>
                  <div className={styles.budgetHeadingRed}>
                    <b>Scope</b>
                  </div>
                  <div className="budgetDescription">
                    Change request x, y / progress blocked by x issue
                  </div>
                </div>
                <div className={styles.statusReport__budgetYellow}>
                  <div className={styles.budgetHeadingYellow}>
                    <b>Schedule</b>
                  </div>
                  <div className="budgetDescription">
                    Delivery on schedule with adjusted scope as agreed…
                  </div>
                </div>
              </div>

              <div className={styles.statusReport__blueBox}>
                <div className={styles.blueBox__heading}>
                  <b>Items finished</b>
                </div>
                <div className={styles.blueBox__placeholder}>Add text here</div>
              </div>
              <div className={styles.statusReport__blueBox}>
                <div className={styles.blueBox__heading}>
                  <b>Items in Progress</b>
                </div>
                <div className={styles.blueBox__placeholder}>Add text here</div>
              </div>
              <div className={styles.statusReport__blueBox}>
                <div className={styles.blueBox__heading}>
                  <b>Risks &amp; Mitigation</b>
                </div>
                <div className={styles.blueBox__placeholder}>Add text here</div>
              </div>
            </div>
          </div>
          <div className="col-4">
            <div
              className={styles.statusReport__blueBox}
              style={{ display: "block" }}
            >
              <b>Summary</b>
              <br />
              <div className={styles.blueBox__placeholder}>Add text here</div>
            </div>
          </div>
        </div>
        <br />
        <ul>
          <li>Team velocity</li>
          <li>Sprint burndown graph</li>
          <li>Budget vs Actual graph + Planned vs Earned Value graph</li>
          <li>Scope change</li>
        </ul>
        <br />

        <b>Important Note: Daily Reports</b>
        <p>
          The status report above is meant to be a periodic report at minimum
          once per sprint. Some projects will require daily reports to the
          client or internally for the team (per communication &amp;
          documentation SOPs as agreed during Sprint 0.5 and Dev Kickoff
          meeting) the daily report does not follow the above template.
          <br />
          <br />
          The daily report should be structured based on features and have the
          following information:
          <ul>
            <li>
              Status: traffic light (green, yellow or red) to show if all went
              well and on track, some challenges and delays, or significant
              blockage requiring an escalation of the issue to the client and
              Tintash management.
            </li>
            <li>Planned Feature 1</li>
            <li>Planned feature ...</li>
            <li>
              Details to be found at: link to the place in project documentation
              where error reports etc. can be found for those who want detailed
              technical information.
            </li>
          </ul>
        </p>
      </div>
    </div>
  );
}

export default StatusReport;
