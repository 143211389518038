import { SET_PROJECT_DETAIL, SET_USER_ROLE } from "./ClientSatisfactionTypes";

export const setUserRole = (role) => ({
  type: SET_USER_ROLE,
  payload: role,
});

export const setProjectDetail = (data) => ({
  type: SET_PROJECT_DETAIL,
  payload: data,
});
