import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import ScrollArrow from "./Scrolltop";
import "antd/dist/antd.css";
import { RouteWithSubRoutes } from "./routes/routeWithSubRoutes";
import { routes } from "./routes/routes";

function App() {
  return (
    <>
      <Router>
        <RouteWithSubRoutes routes={routes} />
      </Router>
      <ScrollArrow />
    </>
  );
}

export default App;
