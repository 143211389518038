import React from "react";
import Pop from "../../Popup/popup";
import Badge from "../../../assets/images/badge.svg";
import BADGES_CONTENT from "./BadgesContent";

function Principles(props) {
  return (
    <div className="principlesContainer">
      <h1 className="processHeading2">Applying the Principles</h1>
      <div className="principles">
        <div className="row">
          <div className="col-12">
            <div className="principle">
              <div className="row">
                <div className="col-1">
                  <img
                    className="img-fluid"
                    src={props.principleone}
                    alt="principle"
                  />
                </div>
                <div className="col-11">
                  <h1 className="processHeading4">
                    Delight the Customer
                    <Pop
                      src={Badge}
                      heading="Client ke dil se:"
                      dataone={BADGES_CONTENT.DELIGHT_CUSTOMER}
                    />
                  </h1>
                  <ul>
                    <li>
                      You keep the client informed with meaningful updates
                    </li>
                    <li>
                      You demonstrate knowledge of the domain, an understanding
                      of the product and ask the right questions at the right
                      time
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="principle">
              <div className="row">
                <div className="col-1">
                  <img
                    className="img-fluid"
                    src={props.principletwo}
                    alt="principle"
                  />
                </div>
                <div className="col-11">
                  <h1 className="processHeading4">
                    Good working environment for the team
                  </h1>
                  <ul>
                    <li>PM is engaged throughout the process</li>
                    <li>
                      The team has a common understanding of the purpose and
                      scope of discovery
                    </li>
                    <li>
                      Design and QA teams feel heard and included as equals
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="principle" style={{ borderBottom: "none" }}>
              <div className="row">
                <div className="col-1">
                  <img
                    className="img-fluid"
                    src={props.principlethree}
                    alt="principle"
                  />
                </div>
                <div className="col-11">
                  <h1 className="processHeading4">
                    Profitability for the company
                    <Pop
                      src={Badge}
                      heading="Buzurg kehte hain"
                      dataone={BADGES_CONTENT.PROFITABILITY}
                    />
                  </h1>
                  <ul>
                    <li>
                      You have identified the risks and Tintash’s ability to
                      address them
                    </li>
                    <li>
                      Estimates
                      <Pop
                        src={Badge}
                        heading="Quiz"
                        dataone={BADGES_CONTENT.ESTIMATE_1}
                        headingOne="Quiz"
                        headingOneData={BADGES_CONTENT.ESTIMATE_2}
                      />
                      are done methodically and are based on the resources
                      likely to be assigned to the project
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Principles;
