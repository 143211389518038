import React from "react";
import { useEffect, useState } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { useSelector } from "react-redux";
import moment from "moment";
import { getFeedback } from "../../helper/getApis";
import sadImg from "../../assets/emojis/sad.svg";
import smileImg from "../../assets/emojis/smile.svg";
import happyImg from "../../assets/emojis/happy.svg";
import cryingImg from "../../assets/emojis/angry.svg";
import speechlessImg from "../../assets/emojis/speechless.svg";

const getIconMarkup = (value) => {
  // icon for happiness
  // if (graphType === GRAPH_TABS.HAPPINESS)
  {
    switch (value) {
      case 0:
        return ``;
      case 1:
        return `<img src=${cryingImg} alt="cryingImg" width="24px" height="24px"/>`;
      case 2:
        return `<img src=${sadImg} alt="sadImg" width="24px" height="24px"/>`;
      case 3:
        return `<img src=${speechlessImg} alt="speechlessImg" width="24px" height="24px"/>`;
      case 4:
        return `<img src=${smileImg} alt="smileImg" width="24px" height="24px"/>`;
      case 5:
        return `<img src=${happyImg} alt="happyImg" width="24px" height="24px"/>`;
      default:
        return "";
    }
  }
};

const getMarkupForYAxisLabel = (value) => {
  return `<div class="CompanyStatsGraph-yaxis-label">
    <div class="label">${getIconMarkup(value)}</div>
  </div>`;
};

const getLineGraphForHappiness = (option) => {
  return (
    <HighchartsReact
      containerProps={{ style: { width: "100%" } }}
      curve={true}
      highcharts={Highcharts}
      options={option}
    />
  );
};

const Graph = (props) => {
  const projectId = props.projectId;
  const pmId = props.pmId;
  const updateFeedback = props.updateFeedback;
  const arr = [];
  const arrBD = [];

  const userRoleId = useSelector(
    (state) => state.clientSatisfaction.userRole?._id
  );
  const [feedbackResponse, setFeedbackResponse] = useState([]);
  const [feedbackBD, setFeedbackBD] = useState([]);

  useEffect(() => {
    if (!userRoleId) return;
    setFeedbackResponse([]);
    setFeedbackBD([]);
    getFeedbackAveragePM(userRoleId, pmId, projectId);
  }, [userRoleId, projectId, pmId, updateFeedback]);

  const getFeedbackAveragePM = async (userRoleId, pmId, projectId) => {
    try {
      const response = await getFeedback(userRoleId, pmId, projectId);
      setFeedbackResponse(response.PM);
      setFeedbackBD(response.BD);
    } catch (error) {
      console.log("Error Fetching Get Feedback PM");
    }
  };

  const rangeFormat = (obj) => {
    const startDate = moment(obj.startDate).format("DD-MMM");
    const endDate = moment(obj.endDate).format("DD-MMM");
    return `${startDate} - ${endDate}`;
  };

  const getFeedbackBDRanges = () =>
    feedbackBD.map((obj) => {
      return rangeFormat(obj);
    });
  const getFeedbackPMRanges = () =>
    feedbackResponse.map((obj) => {
      return rangeFormat(obj);
    });

  feedbackResponse?.forEach((item) => {
    arr.push(item.average);
  });
  feedbackBD?.forEach((item) => {
    arrBD.push(item.average);
  });

  const PMData = arr.map((item, index) => {
    return {
      y: item,
      name: getFeedbackPMRanges()[index],
    };
  });
  const BDData = arrBD.map((item, index) => {
    return {
      y: item,
      name: getFeedbackBDRanges()[index],
    };
  });

  function toggleSeries() {
    var series = this.chart.series,
      seriesIndex = this.index,
      data = [PMData, BDData];

    series.forEach((item) => {
      if (item === this) {
        item.update({
          data: data[seriesIndex],
        });
      } else {
        item.update({
          data: [],
        });
        item.hide();
      }
    });
  }

  const option = {
    title: {
      text: "Mood",
    },
    chart: {
      type: "line",
    },
    xAxis: [
      {
        type: "category",
      },
    ],

    plotOptions: {
      series: {
        events: {
          legendItemClick: toggleSeries,
        },
      },
    },
    yAxis: {
      title: {
        text: "Mood Average",
      },
      gridLineWidth: 1,
      gridLineDashStyle: "longdash",
      labels: {
        useHTML: true,
        formatter: function () {
          return getMarkupForYAxisLabel(this.value);
        },
      },
      max: 5,
      min: 0,
    },
    tooltip: {
      //it shows the top value unit
      //valueSuffix: "°C",
    },
    series: [
      {
        visible: true,
        name: "PM",
        data: PMData,
      },
      {
        visible: false,
        name: "BD",
        data: BDData,
      },
    ],
  };
  return getLineGraphForHappiness(option);
};
export default Graph;
