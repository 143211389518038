import React from "react";
import { RouteWithSubRoutes } from "../../../../../../../routes/routeWithSubRoutes";
import "../../../ProjectDetails.scss";

export const StartReleaseDetails = ({ routes }) => {
  return (
    <>
      <div className="row updates-top-margin"></div>
      <div className="details-container">
        <RouteWithSubRoutes routes={routes} />
      </div>
    </>
  );
};
