import React from "react";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import { object, string, array } from "yup";

import { FormIkRadioDynamicContent } from "./FormIkRadioDynamicContent";

const validationSchema = object().shape({
  sendTo: array().of(string().email()),
});
export const FormikRadioDynamic = ({ initialValues, checkList, onSubmit }) => {
  const projectStatus = useSelector((state) => state.current.status);
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        onSubmit(values);
        resetForm();
      }}
      enableReinitialize={true}
    >
      {({ errors, handleSubmit, resetForm, setFieldValue }) => (
        <FormIkRadioDynamicContent
          initialValues={initialValues}
          checkList={checkList}
          errors={errors}
          setFieldValue={setFieldValue}
          handleSubmit={handleSubmit}
          resetForm={resetForm}
          status={projectStatus}
        />
      )}
    </Formik>
  );
};
