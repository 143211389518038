import React from "react";
import PropTypes from "prop-types";

export function ApplyingPrinciples(props) {
  const { principleone, principletwo, principlethree } = props;
  return (
    <div className="principlesContainer">
      <div>
        <div className="row">
          <div className="whitespace col-2"></div>
          <div className="col-6">
            <div className="row">
              <h1 className="processHeading2 ml-0">Applying the Principles</h1>
            </div>
            <div className="col-12">
              <div>
                <div className="row mt-24">
                  <div className="mobile-hidden col-1">
                    <img
                      className="img-fluid"
                      src={principleone}
                      alt="principle"
                    />
                  </div>
                  <div className="col-10">
                    <div className="mobile-flex">
                      <img
                        className="desktop-hidden img-fluid"
                        src={principleone}
                        alt="principle"
                      />
                      <h1 className="processHeading4">Delight the Customer</h1>
                    </div>
                    <ul className="processRunning">
                      <li>Elevate the product with finishing touches</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div>
                <div className="row mt-24">
                  <div className="mobile-hidden col-1">
                    <img
                      className="img-fluid"
                      src={principletwo}
                      alt="principle"
                    />
                  </div>
                  <div className="col-10">
                    <div className="mobile-flex">
                      <img
                        className="desktop-hidden img-fluid"
                        src={principletwo}
                        alt="principle"
                      />
                      <h1 className="processHeading4">
                        Good working environment for the team
                      </h1>
                    </div>

                    <ul className="processRunning">
                      <li>
                        PM creates an environment of trust and constructive
                        feedback, no criticism.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div>
                <div className="row mt-24">
                  <div className="mobile-hidden col-1">
                    <img
                      className="img-fluid"
                      src={principlethree}
                      alt="principle"
                    />
                  </div>
                  <div className="col-10">
                    <div className="mobile-flex">
                      <img
                        className="desktop-hidden img-fluid"
                        src={principlethree}
                        alt="principle"
                      />
                      <h1 className="processHeading4">
                        Profitability for the company
                      </h1>
                    </div>
                    <ul className="processRunning">
                      <li>
                        A product that earns Tintash pride in the delivery and
                        loyalty from the customer
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
ApplyingPrinciples.protoTypes = {
  principleone: PropTypes.string,
  principletwo: PropTypes.string,
  principlethree: PropTypes.string,
};
