import axios from "axios";
import { ENV_CONSTANTS } from "../constants/globalConstants";

const baseURL = ENV_CONSTANTS.SERVER_URL;

const getConfig = () => ({
  headers: {
    Authorization: `Bearer ${
      JSON.parse(localStorage.getItem("userObj"))?.accessToken
    }`,
  },
});

export const getCheckListService = (projectId, phaseId, processId) => {
  return axios.get(
    `${baseURL}/checklist/${projectId}/${phaseId}/${processId}`,
    getConfig()
  );
};

export const addUpdateService = (payload) => {
  return axios.post(`${baseURL}/update`, payload, getConfig());
};
