import React from "react";
import PropTypes from "prop-types";

import Badge from "../../../../assets/images/badge.svg";
import BADGES_CONTENT from "../../../../components/Processes/Discovery/BadgesContent";
import Tooltip from "../../../../components/Tooltip/Tooltip";

export const ApplyingPrinciples = (props) => {
  const { principleone, principletwo, principlethree } = props;
  return (
    <div className="principlesContainer">
      <div>
        <div className="row">
          <div className="col-2"></div>
          <div className="col-6">
            <div className="row">
              <h1 className="processHeading2 ml-0">Applying the Principles</h1>
            </div>
            <div className="col-12">
              <div>
                <div className="row mt-24">
                  <div className="mobile-hidden col-1">
                    <img
                      className="img-fluid"
                      src={principleone}
                      alt="principle"
                    />
                  </div>
                  <div className="col-10 tooltip-parent">
                    <div className="mobile-flex">
                      <img
                        className="desktop-hidden img-fluid"
                        src={principleone}
                        alt="principle"
                      />
                      <h1 className="processHeading4">
                        Delight the Customer
                        <Tooltip
                          paragraphOne={BADGES_CONTENT.DELIGHT_CUSTOMER}
                          direction="left"
                          headingOne="Client ke dil se:"
                        >
                          <img
                            src={Badge}
                            className="badgeIcon"
                            alt="delight-customer-tooltip"
                          />
                        </Tooltip>
                      </h1>
                    </div>

                    <ul className="processRunning">
                      <li>The product works as intended</li>
                      <li>
                        You provide high quality documentation (client facing +
                        technical).
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div>
                <div className="row mt-24">
                  <div className="mobile-hidden col-1">
                    <img
                      className="img-fluid"
                      src={principletwo}
                      alt="principle"
                    />
                  </div>
                  <div className="col-10">
                    <div className="mobile-flex">
                      <img
                        className="desktop-hidden img-fluid"
                        src={principletwo}
                        alt="principle"
                      />
                      <h1 className="processHeading4">
                        Good working environment for the team
                      </h1>
                    </div>
                    <ul className="processRunning">
                      <li>Feedback from the PM (positive and negative)</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div>
                <div className="row mt-24">
                  <div className="mobile-hidden col-1">
                    <img
                      className="img-fluid"
                      src={principlethree}
                      alt="principle"
                    />
                  </div>
                  <div className="col-10 tooltip-parent">
                    <div className="mobile-flex">
                      <img
                        className="desktop-hidden img-fluid"
                        src={principlethree}
                        alt="principle"
                      />
                      <h1 className="processHeading4">
                        Profitability for the company
                        <Tooltip
                          paragraphOne={BADGES_CONTENT.PROFITABILITY}
                          direction="left"
                          headingOne="Buzurg kehte hain"
                        >
                          <img
                            src={Badge}
                            className="badgeIcon"
                            alt="profitability-tooltip"
                          />
                        </Tooltip>
                      </h1>
                    </div>
                    <ul className="processRunning tooltip-parent">
                      <li>
                        Clear roles: QA driving the sprint, engineering team
                        setting up additional infrastructure as needed.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
ApplyingPrinciples.propTypes = {
  principleone: PropTypes.string,
  principletwo: PropTypes.string,
  principlethree: PropTypes.string,
};
