import React from "react";
import { useHistory } from "react-router";
import styles from "./../introduction.module.css";
import Badge from "../../../assets/images/badge.svg";
import "./intro.scss";
import Tooltip from "../../../components/Tooltip/Tooltip";

function Intro() {
  const history = useHistory();
  return (
    <div>
      <div className="mobile-hidden flex">
        <div className="back-btn" onClick={() => history.push("/")}>
          Back
        </div>
      </div>
      <div className="row mt-32">
        <div className="main-content-align">
          <h1 className={"processHeading2 mb-56"}>WHY THE PLAYBOOK?</h1>
          <h4 className={"processHeading2 mb-80"}>Introduction</h4>
        </div>

        <div className="whitespace col-2"></div>
        <div className={"col-4 mobile-w-90%"}>
          <p className={"lead-content"}>
            There exist in this world many a project management guide –
            different methodologies all evolving, various institutions and
            changing trends. Tintash’s PM playbook is not another method, we are
            not in the business of reinventing wheels.
            <br />
            <br />
            All companies apply existing methodologies to their work as
            relevant. This translation to a company’s reality implies difference
            in terminology used for different stages of a project. It also means
            a variation in obligatory requirements based on the needs and
            realities of the company. Project Management at Tintash – like at
            any growing company
          </p>
        </div>
        <div className="col-4 mobile-w-90%">
          <div className="row">
            <p
              className={`lead-content tooltip-parent ${styles.rightColumnIntro}`}
            >
              – has and continues to evolve. Engineers have risen through ranks
              to become successful Project Managers, PMs coming from outside
              have brought their expertise and experiences with them, the
              company has groomed and mentored individuals to deliver projects
              the Tintash way- with people at the centre, always.
              <br className="desktop-hidden" />
              <br className="desktop-hidden" />
              The PM Playbook is a documentation and further development of this
              - Project Management the Tintash way. It is meant to ensure
              consistency
              <Tooltip
                headingOne="Buzurg kehte hain"
                paragraphOne="In achieving consistency, some at Tintash may experience the playbook as close to existing practice and for some it might require changing, removing, or adding to existing practices to varying degrees. This is part of our journey together as we grow."
              >
                <img src={Badge} alt="" className="badgeIcon" />
              </Tooltip>
              in identity and quality in the way the organisation delivers
              projects to its clients as it grows rapidly and achieves its
              strategic ambitions. It lays out the minimum requirements for all
              projects, offers optional tools and provides expert tips.
            </p>
          </div>
        </div>
        <div className="col-2"></div>
      </div>
    </div>
  );
}

export default Intro;
