import React from "react";
import BADGES_CONTENT from "../BadgesContent";
import { Collapse } from "antd";
import Badge from "../../../../assets/images/badge.svg";
import Tooltip from "../../../Tooltip/Tooltip";
import prepTimeIcon from "../../../../assets/icons/prepTimeIcon.svg";
import runTimeIcon from "../../../../assets/icons/runTimeIcon.svg";
import followUpTimeIcon from "../../../../assets/icons/followUpTimeIcon.svg";
import peopleIcon from "../../../../assets/icons/peopleIcon.svg";

const { Panel } = Collapse;

function HandoverMeeting() {
  return (
    <div className="guidancesContainer handoverContainer">
      <h1 className="processHeading2" id="handOverMeeting">
        Handover to PM Meeting
      </h1>
      <br />
      <div className="row guidanceReqs">
        <div className="col-3">
          <div className="guidance guidance__brown">
            <img
              src={prepTimeIcon}
              width={40}
              className="img-fluid"
              alt="guidance"
            />
            <div>
              <div>Prep Time:</div>
              <div>15 mins</div>
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="guidance guidance__purple">
            <img
              src={runTimeIcon}
              width={40}
              className="img-fluid"
              alt="guidance"
            />
            <div>
              <div>Run Time:</div>
              <div>60 mins</div>
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="guidance guidance__red">
            <img
              src={followUpTimeIcon}
              width={40}
              className="img-fluid"
              alt="guidance"
            />
            <div>
              <div>Follow Up: </div>
              <div style={{ whiteSpace: "nowrap" }}>15-30 mins</div>
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="guidance guidance__green">
            <img
              src={peopleIcon}
              width={40}
              className="img-fluid"
              alt="guidance"
            />
            <span style={{ fontWeight: 400 }}>People:</span> 3-8
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="guidanceCollapse">
        <Collapse expandIconPosition={"right"}>
          <Panel header="Why the requirement: Setting up for Success" key="1">
            <p className="processRunning">
              You successfully converted the lead! The project will begin in 3
              days.
              <br />
              The deal driver has 3 other leads on the plate. The experts who
              provided the estimate are 120% booked. Almost always, the PM who
              will take on the project had no input to the estimate. Tintash is
              not set up to assign PMs before a lead is converted, that is not
              the most efficient use of resources.
              <br />
              But now that the lead is converted, a PM is assigned, and the
              project is handed over to him or her. The team is also identified
              (for discovery or development).
              <br />
              <br />
              Now is the time to remember that what you do here is a major
              factor in customer success.
              <Tooltip
                headingOne="Client ke dil se"
                paragraphOne={BADGES_CONTENT.MEETING_SUCCESS_1}
                paragraphTwo={BADGES_CONTENT.MEETING_SUCCESS_2}
                paragraphThree={BADGES_CONTENT.MEETING_SUCCESS_3}
              >
                <img src={Badge} alt="" className="badgeIcon" />
              </Tooltip>
              <br />
              <br />
              Let us assume: you documented all discussions thoroughly
              throughout lead conversion, including client’s thoughts and input
              and the internal discussions around the estimate. You send this to
              the PM. No documentation can replace a verbal explanation by those
              involved.
              <br />
              Let us be realistic: you documented the key points but not all
              discussions, especially internal, made it to the paper. You also
              can not map the minds of the experts involved in the estimate. The
              documentation is not sufficient.
              <br />
              <br />
              In either scenario – there is no substitute for people getting
              together. This is also the foundation of LEAN processes: bring as
              much information as possible to the beginning of the process (or
              sub process or a step within a process), best achieved by bringing
              people together.
            </p>
          </Panel>
        </Collapse>
        <br />
        <br />
        <Collapse expandIconPosition={"right"}>
          <Panel header="What is the requirement" key="1">
            <div className={"guidanceReqInnerBox"}>
              <p>
                <b>Mandatory participants:</b> Deal Driver, Project Manager,
                Experts who provided and reviewed the estimates, Team members
                (if assigned), Account Manager (if assigned)
              </p>
            </div>
            <p className="processRunning">
              The Deal Driver is responsible for sharing the agenda and
              conducting the meeting. You should draft and communicate it in
              your own style. However, it must include:
              <br />
              <br />
              <ul>
                <li>
                  Product brief including business concept to the extent shared
                  by the client
                </li>
                <li>
                  Brief on client: where did the lead originate, who is the team
                  on the client’s end, who has been involved so far, who will be
                  involved going forward. Their professional backgrounds (as
                  researched during lead qualification)
                </li>
                <li> Pitch / proposal presented to the client</li>
                <li>
                  Estimate
                  <Tooltip
                    headingOne="Buzurg kehte hain"
                    paragraphOne={BADGES_CONTENT.REQUIREMENT_ESTIMATE}
                    direction="right"
                  >
                    <img src={Badge} alt="" className="badgeIcon" />
                  </Tooltip>
                  : Beginning with the PMs
                  <Tooltip
                    headingOne="Champions ka tareeqa"
                    paragraphOne={BADGES_CONTENT.REQUIREMENT_PMS}
                    direction="right"
                  >
                    <img src={Badge} alt="" className="badgeIcon" />
                  </Tooltip>
                  estimate (see prep notes below), then the actual estimate
                  given to the client and it’s explanation
                </li>
                <li> Contract highlights (e.g. invoicing schedule)</li>
                <li>Q&amp;A</li>
              </ul>
            </p>
          </Panel>
        </Collapse>
        <br />
        <br />
        <Collapse expandIconPosition={"right"}>
          <Panel header="Meeting Prep" key="1">
            <p className="processRunning">
              You should book the calendars and send invites as soon as possible
              and attach your notes and the pitch.
              <br />
              Ask the PM to prepare their own high-level estimate before the
              meeting
            </p>
          </Panel>
        </Collapse>
        <br />
      </div>
    </div>
  );
}

export default HandoverMeeting;
