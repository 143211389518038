import React from "react";
import { useState } from "react";
import BADGES_CONTENT from "../BadgesContent";
import Pop from "../../../Popup/popup";
import Badge from "../../../../assets/images/badge.svg";
import nangaParbatImage from "../../../../assets/images/Lead Conversion/last.png";
import {
  ProductContent,
  TechContent,
  LawsRegulationsContent,
} from "../CheckListData";

function Checklist() {
  const [checkList, setCheckList] = useState("product");
  return (
    <>
      <div className="processChecklist">
        <h1 className="processHeading2">Lead Conversion Checklist</h1>
        <div className="row">
          <div className="col-4">
            <div
              className={
                checkList === "product" ? "checklistActive" : "checklist"
              }
              onClick={() => setCheckList("product")}
            >
              Product / Business Concept
            </div>
          </div>
          <div className="col-4">
            <div
              className={checkList === "tech" ? "checklistActive" : "checklist"}
              onClick={() => setCheckList("tech")}
            >
              Tech
            </div>
          </div>
          <div className="col-4">
            <div
              className={checkList === "law" ? "checklistActive" : "checklist"}
              onClick={() => setCheckList("law")}
            >
              Laws &amp; Regulations
            </div>
          </div>
        </div>

        <div className="checklistPointers">
          <h1 className="processHeading4">Pointers</h1>
          <ul style={{ color: "black" }}>
            {checkList === "product" ? (
              <ProductContent badgesContent={BADGES_CONTENT} />
            ) : checkList === "tech" ? (
              <TechContent />
            ) : checkList === "law" ? (
              <LawsRegulationsContent badgesContent={BADGES_CONTENT} />
            ) : (
              <></>
            )}
          </ul>
        </div>
      </div>
      <div className={"whatContainer parbatContainer"} id="parbatContainer">
        <div className={"row"}>
          <div className={"col-6"}>
            <h1 className={"processHeading2"}>
              What has <span style={{ color: "#0F9DFF" }}>Nanga Parbat </span>
              got to do with this!
            </h1>
            <p className="processRunning">
              Let’s forget apps for a few minutes. Also, web products.
              Blockchain too. And what are games. Let’s forget all development.
              <br />
              <br />
              Tintash is going to introduce new team building exercises
              combining socialising and adventure. The first of such exercises
              is a trip to the north. A group of Tintash colleagues will go to
              Fairy Meadows for a long weekend. People ops will take care of the
              overall planning and bookings etc. You have the simple and
              specific task of organising the purchase food and gear for the
              team. Mannan wants you to tell him how much it will cost and how
              long do you need. Then he will give you approval to have the
              purchasing done. Of course, this will be a high-level estimate
              right now, not precise figures expected. He needs to know the
              estimate tomorrow.
            </p>
          </div>
          <div className={"col-6"}>
            <img className={"img-fluid"} src={nangaParbatImage} alt="process" />
          </div>
        </div>
        <h1 className="processHeading4">This what you know:</h1>
        <ul className="processRunning">
          <li>It will be 8-10 people</li>
          <li>
            The trip will be 4 days but there are often landslides close to the
            camp site so it could be 6 days if the team is unable to hike down
            as planned.
          </li>
          <li>
            Some team members, approx. half, will do the complete trek to Nanga
            Parbat’s base camp from fairy meadows.
          </li>
          <li>
            They will get some food there but needs to bring own snacks and
            enough food for breakfasts and packed lunches.
          </li>
          <li>
            People in the team have different food preferences, some might have
            allergies.
          </li>
          <li>
            They will have nice, clean spring water there, but you should buy
            enough for travel and don’t forget chai. Some don’t drink chai but
            need coffee every morning.
          </li>
          <li>
            Tintash will buy hiking shoes and jackets for everyone. But some
            don’t own any wool – it’s Lahore after all - and will need extra
            gear.
          </li>
          <li>
            You should get the best possible quality for the lowest possible
            price.
          </li>
        </ul>
        <p className="processRunning">
          You eat, you buy clothes, this should be easy to estimate. At least a
          specific enough range of time and money.
        </p>
        <br />

        <h1 className="processHeading4">How would you do this?</h1>
        <ul className="processRunning">
          <li>
            Make a rough list in excel, add prices (Ami sends you grocery
            shopping every week, you know many items by heart + hello, this is
            Pakistan, there are no vegetarians here and allergies are a gora
            thing). Excel gives you the total, you add a buffer, et voila!
            <Pop
              src={Badge}
              heading=""
              dataone={BADGES_CONTENT.VOILA_1}
              datatwo={BADGES_CONTENT.VOILA_2}
            />
          </li>
          <li>
            Wasay has recently been to the north – ask him for an informed
            estimate.
            <Pop
              src={Badge}
              heading=""
              dataone={BADGES_CONTENT.INFORMED_ESTIMATE_1}
              datatwo={BADGES_CONTENT.INFORMED_ESTIMATE_2}
            />
          </li>
          <li>
            You have heard about LUMS Adventure Society doing these trips
            annually – contact them for advice and estimates.
            <Pop
              src={Badge}
              heading=""
              dataone={BADGES_CONTENT.ESTIMATES_1}
              datatwo={BADGES_CONTENT.ESTIMATES_2}
              datathree={BADGES_CONTENT.ESTIMATES_3}
            />
          </li>
        </ul>
        <br />
        <h1 className="processHeading4">You present the estimate</h1>
        <p className="processRunning">
          You heard Mannan loud and clear: well-being of the team. You presented
          the estimate to the management as such. With a good explanation of how
          you considered people, people and people.
          <br />
          <br /> Murad responded. He didn’t understand your process – did you
          get quotations and how did you calculate the best value for the
          budget? They were both keen to hear the response. You suddenly felt
          very
          <br />
          unprepared.
          <Pop
            src={Badge}
            heading="Imtehan"
            dataone={BADGES_CONTENT.UNPREPARED_1}
            datatwo={BADGES_CONTENT.UNPREPARED_2}
            datathree={BADGES_CONTENT.UNPREPARED_3}
            datafour={BADGES_CONTENT.UNPREPARED_4}
          />
          <br />
          <br /> What did you miss here?
        </p>
      </div>
    </>
  );
}
export default Checklist;
