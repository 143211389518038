import * as React from "react";
import { Switch, Route } from "react-router-dom";

export const RouteWithSubRoutes = ({ routes }) => (
  <Switch>
    {routes &&
      routes.map((route) => (
        <Route
          path={route.path}
          exact={route.exact}
          key={route.path}
          render={(props) => {
            if (!route.private) {
              // pass the sub-routes down as props
              return (
                <React.Suspense fallback={<>....</>}>
                  <route.component {...props} routes={route.routes} />
                </React.Suspense>
              );
            } else {
              // authenticate user and return component to render else return redirect
            }
          }}
        />
      ))}
  </Switch>
);
