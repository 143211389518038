import "../../../../styles/container.scss";

import React from "react";
import PropTypes from "prop-types";

function ApplyingPrinciples(props) {
  const { principleone, principletwo, principlethree } = props;

  return (
    <div className="principlesContainer">
      <div>
        <div className="row">
          <div className="whitespace col-2"></div>
          <div className="col-6">
            <div className="row">
              <h1 className="processHeading2 ml-0">Applying the Principles</h1>
            </div>
            <div className="col-12">
              <div>
                <div className="row mt-24">
                  <div className="mobile-hidden col-1">
                    <img
                      className="img-fluid"
                      src={principleone}
                      alt="principle"
                    />
                  </div>
                  <div className="col-10">
                    <div className="mobile-flex">
                      <img
                        className="desktop-hidden img-fluid"
                        src={principleone}
                        alt="principle"
                      />
                      <h1 className="processHeading4">Delight the Customer</h1>
                    </div>
                    <ul>
                      <li>
                        The team provides meaningful updates including visual
                        demos
                      </li>
                      <li>
                        The team raises concerns/issues as soon as they arise
                        and have suggestions for addressing them
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div>
                <div className="row mt-24">
                  <div className="mobile-hidden col-1">
                    <img
                      className="img-fluid"
                      src={principletwo}
                      alt="principle"
                    />
                  </div>
                  <div className="col-10">
                    <div className="mobile-flex">
                      <img
                        className="desktop-hidden img-fluid"
                        src={principletwo}
                        alt="principle"
                      />
                      <h1 className="processHeading4">
                        Good working environment for the team
                      </h1>
                    </div>
                    <ul>
                      <li>
                        PM is engaged and aware of progress, concerns and team
                        dynamics; gets support (e.g., from team managers) as
                        needed.
                      </li>
                      <li>PM gives frequent and clear feedback</li>
                      <li>
                        Design and QA teams feel heard and included as equals
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div>
                <div className="row mt-24">
                  <div className="col-1">
                    <img
                      className="mobile-hidden img-fluid"
                      src={principlethree}
                      alt="principle"
                    />
                  </div>
                  <div className="col-10">
                    <div className="mobile-flex">
                      <img
                        className="desktop-hidden img-fluid"
                        src={principlethree}
                        alt="principle"
                      />
                      <h1 className="processHeading4">
                        Profitability for the company
                      </h1>
                    </div>
                    <ul>
                      <li>
                        Designers, engineers and QA engineers work well together
                        in a smooth flow
                      </li>
                      <li>PM has ownership of the budget</li>
                      <li>Code quality is high (reputation &amp; loyalty)</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
ApplyingPrinciples.protoTypes = {
  principleone: PropTypes.string,
  principletwo: PropTypes.string,
  principlethree: PropTypes.string,
};

export default ApplyingPrinciples;
