import "./projects.scss";

import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import { Sidebar } from "../../../components/sidebar/Sidebar";
import { SidebarItem } from "../../../components/sidebar-item/SidebarItem";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getStatusProject,
  setCurrentPhase,
} from "../../../redux/actions/projectDetails";
import { ROUTES, SUB_ROUTE_KEY } from "../../../routes/routeConstants";
import { ProjectDetails } from "./ProjectDetails/ProjectDetails";
import { socket, SocketContext } from "../../../context/socket";
import { setCurrentSubStage } from "../../../redux/actions/projectDetails";

export const Projects = ({ routes }) => {
  const history = useHistory();
  const { projectId: projId } = useParams();
  const dispatch = useDispatch();

  const [projectId, setProjectId] = useState(projId);
  const allProjects = useSelector(
    (state) => state.clientSatisfaction.projectDetail
  );
  const currentPhaseName = useSelector((state) => state.phase.name);
  const accessToken = useSelector((state) => state.login.accessToken);

  const allPhases = useSelector((state) => state?.current.phases);
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  useEffect(() => {
    if (socket?.connected === false) {
      socket.connect();
    }
    dispatch(getStatusProject(config, projId, "1", currentPhaseName));
    return () => socket.disconnect();
  }, []);

  const goToProjectDetails = async ({
    _id: projectId,
    phase: { _id: phaseId, name: phaseName },
    process: { _id: processId, name: processName },
  }) => {
    setProjectId(projectId);
    if (phaseName === "Discovery") {
      await dispatch(await getStatusProject(config, projectId, "1", phaseName));
    } else {
      await dispatch(await getStatusProject(config, projectId, "2", phaseName));
    }

    dispatch(setCurrentPhase(phaseId, phaseName));
    dispatch(setCurrentSubStage(processId, processName));
    setTimeout(() => {
      history.push(
        ROUTES[SUB_ROUTE_KEY[processName]] &&
          ROUTES[SUB_ROUTE_KEY[processName]](projectId, phaseId, processId)
      );
    }, 1000);
  };
  return (
    <>
      <Layout>
        <div className="flex dashboard-container">
          <Sidebar>
            {allProjects?.map((obj) => (
              <SidebarItem
                key={obj._id}
                projectDetails={obj}
                isActive={projectId === obj._id}
                onClick={() => goToProjectDetails(obj)}
              />
            ))}
          </Sidebar>
          <SocketContext.Provider value={socket}>
            {allPhases && allPhases?.length && (
              <ProjectDetails routes={routes} projectId={projectId || projId} />
            )}
          </SocketContext.Provider>
        </div>
      </Layout>
    </>
  );
};
