import React from "react";
import { Link as SLink } from "react-scroll";

function wheredata(props) {
  return (
    <div className={"whatHappensContainer"}>
      <h3 className="glanceHeading">At a Glance</h3>
      <h1 className={"processHeading2"}>What Happens</h1>

      <p className={"processRunning"}>
        Concept/requirements are discussed in detail. Tintash provides design
        &amp; system architecture (first iteration) &amp; detailed estimates.
      </p>

      <div className={"whereContainer"}>
        <h1 className={"processHeading3 uppercase"}>Where are you today</h1>
        <div className={"whereBoxesContainer"}>
          {props.wheredata.map((where, index) => {
            return (
              <div className={"whereBoxContainer"} key={index}>
                <div
                  className={
                    where["name"] === props.activeWhere["name"]
                      ? "whereBoxActive"
                      : "whereBox"
                  }
                  onClick={() => props.setActiveWhere(props.wheredata[index])}
                >
                  {where["name"]}
                </div>
                {index < props.wheredata.length - 1 ? (
                  <img className="img-fluid" src={props.arrow} alt="arrow" />
                ) : (
                  ""
                )}
              </div>
            );
          })}
        </div>
        <div className={"whereDosContainer"}>
          <div className={"whereDos"}>
            <b>DO:</b>
            <ul>
              {props.activeWhere["dos"].map((dos, index) => {
                return (
                  <li key={index}>
                    <SLink
                      to={dos.ref}
                      style={{
                        textDecorationLine: "underline",
                      }}
                    >
                      {dos.name}
                    </SLink>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className={"whereDos"}>
            <b>USE:</b>
            <ul>
              {props.activeWhere["uses"].map((use, index) => {
                return <li key={index}>{use}</li>;
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default wheredata;
