import React from "react";
import { UpdateList } from "../../../../UpdateSection/update-list/UpdateList";
import "../../DiscoveryUpdateSection.scss";
import { DuringDiscoveryUpdateForm } from "../../DuringDiscoveryDetails/DuringDiscoveryUpdateSection/DuringDiscoveryUpdateForm/DuringDiscoveryUpdateForm";

export const DogFoodingUpdateSection = () => {
  return (
    <div className="project-update-details-wrapper">
      <div className="update-form-wrapper">
        <DuringDiscoveryUpdateForm />
      </div>

      <div className="update-list-wrapper">
        <div className="side-update-title mt-32 ml-24">
          Project Management Events
        </div>
        <UpdateList />
      </div>
    </div>
  );
};
