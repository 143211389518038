export const MOBILE_NAVIGATION_MENU_ITEMS = [
  {
    text: "Lead conversion",
    url: `/lead-conversion`,
  },
  {
    text: "Discovery",
    url: `/discovery`,
  },
  { text: "Development", url: `/development` },
  { text: "Polishing", url: `/polishing` },
  { text: "Release", url: `/release` },
];
