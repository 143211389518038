import React from "react";
import { useState } from "react";

function WorkPlan() {
  const [checklist, setChecklist] = useState("lead");
  return (
    <div className="processChecklist">
      <h1 className="processHeading2">Project Work Plan</h1>
      <p className={"processRunning"}>
        At Tintash, we work agile. This means we do not seek to create detailed
        plans for the entire project up front.
        <br />
        <br />
        However, agile does not mean a complete absence of planning. We plan at
        a high level, starting backwards from any deadlines or milestones raised
        by the client (e.g., feature release, investor deadline, marketing
        goal). We do this to:
        <ul>
          <li>Ensure that what are agreeing to is feasible</li>
          <li>
            We are able to connect the dots between the different lines of work
            and uncover dependencies (at the highest level).{" "}
          </li>
        </ul>
        <br />
        The Tintash Project Workplan is a high level, living document. Updated
        at different meeting points to guide the discussions, keep a feasibility
        check and inform detailed sprint/release plans.
        <br />
        Here is a summary of its use at different stages of a project’s life:
      </p>

      <div className="row">
        <div className="col-4">
          <div
            className={checklist === "lead" ? "checklistActive" : "checklist"}
            onClick={() => setChecklist("lead")}
          >
            During Lead Conversion
          </div>
        </div>
        <div className="col-4">
          <div
            className={
              checklist === "discovery" ? "checklistActive" : "checklist"
            }
            onClick={() => setChecklist("discovery")}
          >
            During Discovery
          </div>
        </div>
        <div className="col-4">
          <div
            className={
              checklist === "development" ? "checklistActive" : "checklist"
            }
            onClick={() => setChecklist("development")}
          >
            During Development
          </div>
        </div>
      </div>

      <div className="checklistPointers">
        <ul>
          {checklist === "lead" ? (
            <>
              <li>
                A project workplan is started with very few, biggest milestones.
              </li>
              <li>It helps confirm feasibility, scope, and time estimates.</li>
              <li>
                A project folder is created in the filing tree/intranet and the
                project workplan is filed there for easy reference.
              </li>
            </>
          ) : checklist === "discovery" ? (
            <li>
              The workplan is updated – both in terms of an adjustment of the
              timeline as needed and addition of some detail/some smaller
              milestones as things become clearer.
            </li>
          ) : checklist === "development" ? (
            <>
              <li>
                At kick-off, the workplan is updated to include a release
                schedule and feature flow for easy reference
              </li>
              <li>
                It is used to guide discussions with the client and check
                feasibility and impact of change requests or other requests.
              </li>
            </>
          ) : (
            <></>
          )}
        </ul>
      </div>
    </div>
  );
}

export default WorkPlan;
