import React, { useState, useRef, useEffect } from "react";
import { GuideLines } from "../../../components/GuideLines/GuideLines";
import { WhatHappensData } from "../../../components/Processes/LeadConversion/components/WhatHappensData";
import { ApplyingPrinciples } from "./components/ApplyingPrinciples";

import { DiscoveryContent } from "./components/DiscoveryContent";
import { DO_CONSTANTS } from "../../../constants/doConstants";
import arrow from "../../../assets/images/arrow.svg";
import "./discovery.scss";
import MainPageNav from "../../../components/MainPageNav/MainPageNav";
import leadConversionIcon from "../../../assets/icons/leadConversion.svg";
import dollar from "../../../assets/icons/dollar.svg";
import thumbsUp from "../../../assets/icons/thumbsUp.svg";
import heartIcon from "../../../assets/icons/heartIcon.svg";
import ScrollToTopButton from "../../../components/ScrollToTopButton/ScrollToTopButton";
import WhatHappensButton from "../../../components/WhatHappensButton/WhatHappensButton";

import { MOBILE_NAVIGATION_MENU_ITEMS } from "../../../constants/navigationMenuConstants";
import MobileNavbarTop from "../../../components/mobile/MobileNavbarTop/MobileNavbarTop";
import MobileNavbarBottom from "../../../components/mobile/MobileNavbarBottom/MobileNavbarBottom";
import useElementInView from "../../../utilities/useElementInView";
import { whatHappensObserverOptions } from "../LeadConversion/LeadConversion";

const WHEREDATA = [
  {
    name: "Starting a Discovery",
    dos: [
      {
        name: "Discovery Planning Meeting (internal)",
        ref: "discoveryPlanning",
      },
      {
        name: "Discovery Kickoff Meeting (with client)",
        ref: "discoveryKickOff",
      },
    ],
    uses: [
      { name: "Discovery checklist", link: "" },
      {
        name: "Discovery Plan template",
        url: "https://docs.google.com/document/d/19ibezTGMw57Fx1XhR9DG4Qp0FMe7htYHC8Smv-RUrVA/edit",
      },
      {
        name: "Project Work-Plan Template",
        url: "https://docs.google.com/spreadsheets/d/1G58VpG1JknaCSDP1gJygR_cuDJewYFX8/edit#gid=556443270",
      },
    ],
  },
  {
    name: "During Discovery",
    dos: [
      { name: "Daily standups and syncups" },
      { name: "Daily updates to clients" },
    ],
    uses: [
      {
        name: "Discovery Plan Template",
        url: "https://docs.google.com/document/d/19ibezTGMw57Fx1XhR9DG4Qp0FMe7htYHC8Smv-RUrVA/edit",
      },
      { name: "Project Documentation Skeleton", url: "" },
    ],
  },
  {
    name: "Finishing a Discovery",
    dos: [{ name: "Review of Estimates" }, { name: "Client Presentation" }],
    uses: [
      {
        name: "Discovery Plan Template",
        url: "https://docs.google.com/document/d/19ibezTGMw57Fx1XhR9DG4Qp0FMe7htYHC8Smv-RUrVA/edit",
      },
      { name: "Discovery Conclusion Document", url: "" },
      {
        name: "SOW template",
        url: "https://docs.google.com/document/d/19gkRqnpL7U4DACv6L6R5B0RaQBa-ziRxxOj27u_IIHg/edit",
      },
    ],
  },
];

export const Discovery = () => {
  const pageTopRef = useRef(null);
  const whatHappensSectionRef = useRef(null);

  const [whatHappensRef, whatHappensRefInView] = useElementInView(
    whatHappensObserverOptions
  );

  const [whatHappensEndRef, whatHappensEndRefInView] = useElementInView(
    whatHappensObserverOptions
  );

  const [activeWhere, setActiveWhere] = useState(WHEREDATA[0]);
  const [showGuideline, setShowGuideLine] = useState(false);
  const [selectedGuideline, setSelectedGuideline] = useState("");
  const [openNavigationMenu, setOpenNavigationMenu] = useState(false);

  const whatHappenStateHandler = (val) => {
    const guideLineDisplay = DO_CONSTANTS.find(
      (data) => val.target.innerText === data
    );
    if (guideLineDisplay) {
      setShowGuideLine(true);
      setSelectedGuideline(val.target.innerText);
    }
  };
  const mainDivFunction = () => {
    if (showGuideline) {
      setShowGuideLine(false);
    }
  };

  useEffect(() => {
    pageTopRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  return (
    <>
      <div ref={pageTopRef} />
      <MobileNavbarTop
        openNavigationMenu={openNavigationMenu}
        setOpenNavigationMenu={setOpenNavigationMenu}
        menuItems={MOBILE_NAVIGATION_MENU_ITEMS}
      />

      <MainPageNav />
      {showGuideline && (
        <GuideLines
          selectedGuideline={selectedGuideline}
          close={() => setShowGuideLine(false)}
        />
      )}

      <div
        onClick={mainDivFunction}
        className={showGuideline ? "row opacity" : "row"}
      >
        <div className="mt-88 whitespace"></div>
        <div id="intro-row" className="row">
          <div className="col-2 whitespace"></div>
          <div id="intro-container" className="col-5">
            <div className="main-heading-container">
              <img className="waterfall-chart" src={leadConversionIcon} />
              <h1 className="processHeading2 develop-heading ml-24">
                Discovery
              </h1>
            </div>
            <div className="lead-content">
              <p>
                The phase begins after a decision has been made to proceed with
                discovery. It is ideal that the contract for this purpose is
                signed and in place before work begins. In reality, however, it
                is natural that Tintash would begin with resource allocation in
                parallel to the contract process. It is also realistic that a PM
                when assigned would begin onboarding the team and initiate
                internal planning. Therefore, the decision to proceed must be
                documented by email along with the agreed scope, duration, and
                team composition. This decision marks the beginning of the
                discovery phase with its associated requirements.
              </p>
            </div>
          </div>
          <div className="col-5 mobile-hidden">
            <WhatHappensButton buttonRef={whatHappensSectionRef} />
          </div>
        </div>
        <DiscoveryContent />
        <ApplyingPrinciples
          principleone={heartIcon}
          principletwo={thumbsUp}
          principlethree={dollar}
        />
        <div className="row">
          <div
            className="main-content-align"
            id="linkWhatHappen"
            ref={whatHappensSectionRef}
          >
            <div className="happens">What Happens?</div>
            <div className="discovery-story mt-32" ref={whatHappensRef}>
              Concept/requirements are discussed in detail. Tintash provides
              design & system architecture (first iteration) & detailed
              estimates.
            </div>
          </div>
        </div>
        <div className="mt-88 whitespace"></div>
        <WhatHappensData
          wheredata={WHEREDATA}
          activeWhere={activeWhere}
          arrow={arrow}
          setActiveWhere={setActiveWhere}
          onChangeValue={whatHappenStateHandler}
        />
        <div ref={whatHappensEndRef}></div>
      </div>

      {!(whatHappensRefInView || whatHappensEndRefInView) ? (
        <MobileNavbarBottom>
          <WhatHappensButton buttonRef={whatHappensSectionRef} />
        </MobileNavbarBottom>
      ) : null}
      <ScrollToTopButton pageTopRef={pageTopRef} />
    </>
  );
};
