import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Modal, Form, Button, Input, message, Space } from "antd";
import { getOutputFiles } from "../../../../helper/getApis";
import { submitAttachLink, unlockSubStage } from "../../../../helper/putApis";
import { deleteFile } from "../../../../helper/deleteApis";
import { addOutputFile } from "../../../../helper/postApis";
import Loader from "../../../../components/Loader/loader";
import "./upload-files.scss";
import { SocketContext } from "../../../../context/socket";
import { SOCKET_CONSTANTS } from "../../../../constants/globalConstants";
import { NextStageDialog } from "../../../../components/Dialog/next-stage-dialog/NextStageDialog";
import { setCurrentSubStage } from "../../../../redux/actions/projectDetails";
import { getNextProcessUrl } from "../../../../utilities/utilities";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "../../../../assets/icons/add.svg";
import Artifact from "./Artifact";

export const ProjectArtifacts = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { phaseId, processId } = useParams();

  const { subStageId } = props;
  const [outputResponse, setOutputResponse] = useState([]);
  const [isAddDocModalVisible, setIsAddDocModalVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedDocId, setSelectedDocId] = useState(null);
  const [isLinkAttached, setIsLinkAttached] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [artifactsForm] = Form.useForm();
  const [additionalDocForm] = Form.useForm();

  const socket = useContext(SocketContext);

  const allSubStages = useSelector((state) => state?.current.data);
  const currentPhaseName = useSelector((state) => state.phase.name);
  const projectId = useSelector((state) => state.current.projectId);
  const isAddFile = JSON.parse(localStorage.getItem("isAdditionalFile"));

  useEffect(() => {
    localStorage.removeItem("isAdditionalFile");
  }, []);

  useEffect(() => {
    setIsAddDocModalVisible(isAddFile);
  }, [isAddFile]);

  const getOutput = async (projectID, stageID, subStageID) => {
    setLoading(true);
    try {
      const response = await getOutputFiles(projectID, stageID, subStageID);
      setOutputResponse(response);
    } catch (error) {
      console.log("Error Fetching GetOutput Detail");
      setOutputResponse([]);
    }
    setLoading(false);
  };
  const attachLink = async (url, docID) => {
    try {
      const response = await submitAttachLink(url, docID);
      setIsLinkAttached(response);
    } catch (error) {
      console.log("Error putting url in output");
    }
  };

  const deleteOutputFile = async (docID) => {
    try {
      const response = await deleteFile(docID);
      setIsLinkAttached(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("Error Deleting Output file");
    }
  };
  const addNewFile = async (
    projectID,
    stageID,
    subStageID,
    fileName,
    fileUrl
  ) => {
    try {
      const response = await addOutputFile(
        projectID,
        stageID,
        subStageID,
        fileName,
        fileUrl
      );
      localStorage.removeItem("isAdditionalFile");
      setLoading(false);
      setIsLinkAttached(response);
    } catch (error) {
      console.log("Error Deleting Output file");
    }
  };
  const isUrl = (url) => {
    const regexp =
      /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/;
    return regexp.test(url);
  };

  const handleUrlSubmit = (values) => {
    if (isUrl(values.url)) {
      attachLink(values, selectedDocId);
      setIsModalVisible(false);
      artifactsForm.resetFields();
    } else {
      message.error("Please enter valid Url");
    }
  };
  const handleAdditionalFiles = () => {
    setLoading(true);
    localStorage.setItem("isAdditionalFile", true);
  };
  const checkArtifacts = (fileName) => {
    return outputResponse.find(
      (item) =>
        item.file.name.toLowerCase().replace(/\s/g, "").trim() ===
        fileName.toLowerCase().replace(/\s/g, "").trim()
    );
  };
  const handleAddFileSubmit = (values) => {
    if (checkArtifacts(values.file)) {
      message.error("File with same name already exists");
    } else {
      if (isUrl(values.url)) {
        addNewFile(projectId, phaseId, processId, values.file, values.url);
        setIsAddDocModalVisible(false);
        additionalDocForm.resetFields();
      } else {
        message.error("Please enter valid Url");
      }
    }
  };
  const handleAttachUrl = (data) => {
    if (data.file.url === "") {
      setSelectedDocId(data._id);
      setIsModalVisible(true);
    } else {
      window.open(data.file.url, "_blank");
    }
  };
  message.config({
    duration: 2,
    maxCount: 1,
  });
  const handleDeleteFile = (docID, status, type) => {
    if (type === "mandatory" && status === "accepted") {
      message.error("File cannot be deleted after its approved");
    } else {
      setLoading(true);
      deleteOutputFile(docID);
    }
  };

  useEffect(() => {
    getOutput(projectId, phaseId, processId);
  }, [isLinkAttached, projectId, subStageId]);

  useEffect(() => {
    socket.on(SOCKET_CONSTANTS.UPDATE_OUTPUT_FILES, () =>
      getOutput(projectId, phaseId, processId)
    );
  }, []);

  const getNextProcessIndex = () => {
    const index = allSubStages.findIndex(({ _id }) => _id === processId);
    const newIndex = index + 1;
    return newIndex < allSubStages.length ? newIndex : allSubStages.length - 1;
  };
  const unlockNextStage = async () => {
    try {
      const currentProcessStatus = await unlockSubStage(
        projectId,
        phaseId,
        processId
      );
      if (currentProcessStatus.status === "completed") {
        const index = getNextProcessIndex();
        const nextProcess = allSubStages[index];
        dispatch(setCurrentSubStage(nextProcess._id, nextProcess.name));
        history.push(
          getNextProcessUrl({
            location,
            projectId,
            phaseId,
            processId: nextProcess._id,
            currentPhaseName,
          })
        );
        setShowDialog(false);
      }
    } catch (e) {
      console.log("Error Fetching Subscriptions Detail", e);
    }
  };
  const handleAdditionalFilesModal = () => {
    localStorage.setItem("isAdditionalFile", false);
    setIsAddDocModalVisible(false);
    setLoading(false);
    additionalDocForm.resetFields();
  };
  return (
    <>
      <NextStageDialog
        onSuccess={unlockNextStage}
        showDialog={showDialog}
        setShowDialog={setShowDialog}
      />
      {loading && <Loader />}
      <div className="project-artifacts-wrapper">
        <p className="artifacts-title">Artifacts</p>
        {outputResponse?.map((item) => (
          <Artifact
            key={item._id}
            item={item}
            handleDeleteFile={handleDeleteFile}
            handleAttachUrl={handleAttachUrl}
          />
        ))}
        <div className="help-text mt-8">
          * kindly click the respective file name to Upload the document
        </div>
        <div className="additionalDocuments" onClick={handleAdditionalFiles}>
          <img src={AddIcon} />
          <p>Add Additional Documents</p>
        </div>
      </div>
      {
        <Modal
          title="Add URL"
          visible={isModalVisible}
          footer={null}
          centered
          onCancel={() => {
            setIsModalVisible(false);
            artifactsForm.resetFields();
          }}
        >
          <Form
            className="tag-form"
            onFinish={handleUrlSubmit}
            layout="vertical"
            form={artifactsForm}
          >
            <Form.Item name="url">
              <Input placeholder="https://www.abc.com" type="text" />
            </Form.Item>
            {
              <div className="add-url-submit">
                <Space>
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    className="wide"
                  >
                    Save
                  </Button>
                </Space>
              </div>
            }
          </Form>
        </Modal>
      }
      {
        <Modal
          title="Add File"
          visible={isAddDocModalVisible}
          footer={null}
          centered
          onCancel={() => {
            handleAdditionalFilesModal();
          }}
        >
          <Form
            className="tag-form"
            onFinish={handleAddFileSubmit}
            layout="vertical"
            form={additionalDocForm}
          >
            <Form.Item
              name="file"
              rules={[
                {
                  required: true,
                  message: "Please enter file name",
                },
              ]}
            >
              <Input placeholder="Enter File Name" type="text" />
            </Form.Item>
            <Form.Item
              name="url"
              rules={[
                {
                  required: true,
                  message: "Please enter Url",
                },
              ]}
            >
              <Input placeholder="https://www.abc.com" type="text" />
            </Form.Item>

            {
              <div className="add-file-submit">
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  className="wide"
                >
                  Save
                </Button>
              </div>
            }
          </Form>
        </Modal>
      }
    </>
  );
};
