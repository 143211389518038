import "./whatHappensButton.scss";

import React from "react";

import whatHappenDefault from "../../assets/icons/general/whatHappenDefault.svg";

export default function WhatHappensButton({ buttonRef }) {
  const buttonClickHandler = () => {
    if (buttonRef) buttonRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div>
      <div className="whatHappenButtonContainer">
        <button onClick={buttonClickHandler} className="what-happens-button">
          <img height="100" src={whatHappenDefault} />
        </button>
      </div>
    </div>
  );
}
