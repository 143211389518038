import React, { useState } from "react";
import { FaArrowCircleUp } from "react-icons/fa";

const ScrollArrow = () => {
  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 1900) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 1900) {
      setShowScroll(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 850, behavior: "smooth" });
  };

  window.addEventListener("scroll", checkScrollTop);

  return (
    <div>
      {showScroll && (
        <div className="scrollTop" onClick={scrollTop}>
          <FaArrowCircleUp className="scrollTopBtn" size={40} />
        </div>
      )}
    </div>
  );
};

export default ScrollArrow;
