import React from "react";
import BADGES_CONTENT from "../../../../components/Processes/LeadConversion/BadgesContent";

import Badge from "../../../../assets/images/badge.svg";
import nangaParbatImage from "../../../../assets/images/Lead Conversion/last.png";
import school from "../../../../assets//icons/school.svg";
import Tooltip from "../../../../components/Tooltip/Tooltip";
export const NangaParbat = () => {
  return (
    <div className={"whatContainer parbatContainer"} id="parbatContainer">
      <div className={"row"}>
        <div className={"col-6"}>
          <h1 className={"processHeading2"}>
            What has <span style={{ color: "#0F9DFF" }}>Nanga Parbat </span>
            got to do with this!
          </h1>
          <img
            className={"img-fluid desktop-hidden"}
            src={nangaParbatImage}
            alt="process"
          />

          <p className="processRunning">
            Let’s forget apps for a few minutes. Also, web products. Blockchain
            too. And what are games. Let’s forget all development.
            <br />
            <br />
            Tintash is going to introduce new team building exercises combining
            socialising and adventure. The first of such exercises is a trip to
            the north. A group of Tintash colleagues will go to Fairy Meadows
            for a long weekend. People ops will take care of the overall
            planning and bookings etc. You have the simple and specific task of
            organising the purchase food and gear for the team. Mannan wants you
            to tell him how much it will cost and how long do you need. Then he
            will give you approval to have the purchasing done. Of course, this
            will be a high-level estimate right now, not precise figures
            expected. He needs to know the estimate tomorrow.
          </p>
        </div>
        <div className={"col-6 mobile-hidden"}>
          <img className={"img-fluid"} src={nangaParbatImage} alt="process" />
        </div>
      </div>
      <h1 className="processHeading4">This what you know:</h1>
      <ul className="processRunning">
        <li>It will be 8-10 people</li>
        <li>
          The trip will be 4 days but there are often landslides close to the
          camp site so it could be 6 days if the team is unable to hike down as
          planned.
        </li>
        <li>
          Some team members, approx. half, will do the complete trek to Nanga
          Parbat’s base camp from fairy meadows.
        </li>
        <li>
          They will get some food there but needs to bring own snacks and enough
          food for breakfasts and packed lunches.
        </li>
        <li>
          People in the team have different food preferences, some might have
          allergies.
        </li>
        <li>
          They will have nice, clean spring water there, but you should buy
          enough for travel and don’t forget chai. Some don’t drink chai but
          need coffee every morning.
        </li>
        <li>
          Tintash will buy hiking shoes and jackets for everyone. But some don’t
          own any wool – it’s Lahore after all - and will need extra gear.
        </li>
        <li>
          You should get the best possible quality for the lowest possible
          price.
        </li>
      </ul>
      <p className="processRunning">
        You eat, you buy clothes, this should be easy to estimate. At least a
        specific enough range of time and money.
      </p>
      <br />

      <h1 className="processHeading4">How would you do this?</h1>
      <ul className="processRunning">
        <li>
          Make a rough list in excel, add prices (Ami sends you grocery shopping
          every week, you know many items by heart + hello, this is Pakistan,
          there are no vegetarians here and allergies are a gora thing). Excel
          gives you the total, you add a buffer, et voila!
          <Tooltip
            headingOne=""
            paragraphOne={BADGES_CONTENT.VOILA_1}
            paragraphTwo={BADGES_CONTENT.VOILA_2}
          >
            <img src={Badge} className="badgeIcon" alt="" />
          </Tooltip>
        </li>
        <li>
          Wasay has recently been to the north – ask him for an informed
          estimate.
          <Tooltip
            headingOne=""
            paragraphOne={BADGES_CONTENT.INFORMED_ESTIMATE_1}
            paragraphTwo={BADGES_CONTENT.INFORMED_ESTIMATE_2}
          >
            <img src={Badge} className="badgeIcon" alt="" />
          </Tooltip>
        </li>
        <li>
          You have heard about LUMS Adventure Society doing these trips annually
          – contact them for advice and estimates.
          <Tooltip
            headingOne=""
            paragraphOne={BADGES_CONTENT.ESTIMATES_1}
            paragraphTwo={BADGES_CONTENT.ESTIMATES_2}
            paragraphThree={BADGES_CONTENT.ESTIMATES_3}
            direction={`${screen.width <= 1618 ? "right" : "left"}`}
          >
            <img src={Badge} className="badgeIcon" alt="" />
          </Tooltip>
        </li>
      </ul>
      <br />
      <h1 className="processHeading4">You present the estimate</h1>
      <p className="processRunning">
        You heard Mannan loud and clear: well-being of the team. You presented
        the estimate to the management as such. With a good explanation of how
        you considered people, people and people.
        <br />
        <br /> Murad responded. He didn’t understand your process – did you get
        quotations and how did you calculate the best value for the budget? They
        were both keen to hear the response. You suddenly felt very
        <br />
        unprepared.
        <Tooltip
          headingOne="Imtehan"
          paragraphOne={BADGES_CONTENT.UNPREPARED_1}
          direction="right"
          hasQuizOne={true}
          quizOneHeadingText="Think through your answer and click here for jawab"
          quizOneParagraphOne={BADGES_CONTENT.UNPREPARED_2}
          quizOneParagraphTwo={BADGES_CONTENT.UNPREPARED_3}
          quizOneParagraphThree={BADGES_CONTENT.UNPREPARED_4}
        >
          <img src={school} alt="" className="badgeIcon" />
        </Tooltip>
        <br />
        <br /> What did you miss here?
        <div className="mb-164"></div>
      </p>
    </div>
  );
};
