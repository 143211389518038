import React from "react";

function MobileProducts() {
  return (
    <>
      <h1 className="processRunningHeading">Pointers</h1>
      <ul className="processRunning">
        <li>Shrink application size, remove any unnecessary resources.</li>
        <li>Code freeze, acceptance criteria of all features should be met.</li>
        <li>Verify application version code/name.</li>
        <li>
          Thorough testing of the application across different OS versions.
        </li>
        <li>Documenting changes & publishing release notes.</li>
        <li>
          Security considerations:
          <ul>
            <li>
              Secrets/keys are secured properly in a vault or secret store/key
              store.
            </li>
            <li>SSL configuration on production environment.</li>
            <li>
              Disable logs which might provide sensitive information on the
              production environment.
            </li>
            <li>Disable debugging.</li>
            <li>Protect API calls against MITM attack if required.</li>
          </ul>
        </li>
        <li>
          Compliance: If there are any prevailing compliance requirements --
          like data encryption, HIPPA, GDPR etc..., they should be met before
          the release.
        </li>
        <li>
          Deployment:
          <ul>
            <li>
              Use alpha/beta/test tracks for testing your application. This
              ensures the level of readiness of your application and mocks the
              user experience of downloading the app from the App Store or the
              Play Store.
            </li>
            <li>Follow up on feedback from App Store and Play Store.</li>
            <li>
              Deployment strategy should be documented and CI/CD pipelines
              should be configured.
            </li>
            <li>
              Check possibilities for soft launch first or rollout in phases{" "}
            </li>
          </ul>
        </li>
        <li>
          Application performance: CPU usage, memory usage, network traffic,
          disk usage, and FPS should be checked for each screen.
        </li>
        <li>
          Application monitoring tools should be configured (for example,
          Firebase Crashlytics, Instabug etc...).
        </li>
      </ul>
    </>
  );
}

export default MobileProducts;
