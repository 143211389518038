import React from "react";

function Intro(props) {
  return (
    <div className={"processIntro"} id="discovery">
      <div className={"row"}>
        <div className={"col-6"}>
          <h1 className={"processHeading"}>Discovery</h1>
          <p className={"processRunning"}>
            The phase begins after a decision has been made to proceed with
            discovery. It is ideal that the contract for this purpose is signed
            and in place before work begins. In reality, however, it is natural
            that Tintash would begin with resource allocation in parallel to the
            contract process. It is also realistic that a PM when assigned would
            begin onboarding the team and initiate internal planning. Therefore,
            the decision to proceed must be documented by email along with the
            agreed scope, duration, and team composition. This decision marks
            the beginning of the discovery phase with its associated
            requirements.
          </p>
        </div>
        <div className={"col-6"}>
          <img
            className={"img-fluid"}
            src={props.nexttoprocess}
            alt="process"
          />
        </div>
      </div>
    </div>
  );
}

export default Intro;
