import React from "react";
import { UpdateList } from "../../../../UpdateSection/update-list/UpdateList";
import { DuringDiscoveryUpdateForm } from "../../DuringDiscoveryDetails/DuringDiscoveryUpdateSection/DuringDiscoveryUpdateForm/DuringDiscoveryUpdateForm";
import { ProjectArtifacts } from "../../../../ProjectArtifactsSection/ProjectArtifacts";

export const DuringDevelopmentUpdateSection = () => {
  return (
    <div className="project-update-details-wrapper">
      <div className="update-artifacts-wrapper">
        <div className="form-update-wrapper">
          <DuringDiscoveryUpdateForm />
        </div>
        <div className="project-artifacts">
          <ProjectArtifacts />
        </div>
      </div>
      <div className="update-list-wrapper">
        <div className="side-update-title mt-32 ml-24">
          Project Management Events
        </div>
        <UpdateList />
      </div>
    </div>
  );
};
