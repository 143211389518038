import React, { useState } from "react";
import { useHistory } from "react-router";
import "bootstrap/dist/css/bootstrap.min.css";

import "./whyThePlaybook.scss";
import styles from "./../introduction.module.css";
import LinkWithEllipse from "../../../components/LinkWithEllipse/LinkWithEllipse";
import { GuideLines } from "../../../components/GuideLines/GuideLines";
import timelapse from "../../../assets/icons/timelapse.svg";
import logo from "../../../assets/images/logo.png";
import MainPageNav from "../../../components/MainPageNav/MainPageNav";
import PersonAvatarWithName from "../../../components/GuideLines/PersonAvatarWithName/PersonAvatarWithName";
import ProjectMethodology from "./ProjectMethodology";
import Principles from "./Principles";
import Intro from "./Intro";
import WhatDoesThisDoButton from "../../../components/mobile/WhatDoesThisDoButton";
import MobileNavbarTop from "../../../components/mobile/MobileNavbarTop/MobileNavbarTop";
import { menuItems } from "../../LandingSite/MainPage/MainPage";

const persons = ["Umar", "Fatima", "Saima", "Hamza"];

function whyThePlaybook() {
  const history = useHistory();
  const [selectedValue, setSelectedValue] = useState("");
  const [showGuideline, setShowGuideLine] = useState(false);
  const [openNavigationMenu, setOpenNavigationMenu] = useState(false);

  const handleOnClick = (title) => {
    setSelectedValue(title);
    setShowGuideLine(true);
  };
  const mainDivFunction = () => {
    if (showGuideline) {
      setShowGuideLine(false);
    }
  };

  return (
    <>
      <MainPageNav />
      <MobileNavbarTop
        openNavigationMenu={openNavigationMenu}
        setOpenNavigationMenu={setOpenNavigationMenu}
        menuItems={menuItems}
      />
      {showGuideline && (
        <GuideLines
          selectedGuideline={selectedValue}
          close={() => setShowGuideLine(false)}
        />
      )}
      <div
        onClick={mainDivFunction}
        className={showGuideline ? "row opacity" : "row"}
      >
        <header className="mobile-hidden header mb-64">
          <div className="logo-container-main-page">
            <img
              src={logo}
              alt="logo"
              onClick={() => history.push("/")}
              className="img-fluid"
              width="150"
            />
          </div>
        </header>

        <Intro />
        <WhatDoesThisDoButton
          title="What does this do for me"
          clickHandler={handleOnClick}
        />
        <ProjectMethodology />

        <div className="mobile-hidden row mt-88">
          <div className="col-2"></div>
          <div className="col-8">
            <LinkWithEllipse
              title={"What does this do for me"}
              onClick={handleOnClick}
            />
          </div>
        </div>

        <Principles />

        <div>
          <div className="row mt-32">
            <div className="whitespace col-2"></div>
            <img
              className={`mobile-hidden col-2 ${styles.timelapseWhyThePlaybook}`}
              src={timelapse}
            />
            <h3
              id="usecase-heading"
              className="col-6 processHeading2 ml-0 mt-16"
            >
              Use Cases
            </h3>
          </div>
        </div>

        <div className="mobile-grid ml-44 mt-16">
          {persons.map((person) => (
            <div key={person} className="row">
              <div className="whitespace col-2"></div>
              <div className="col-6 mobile-w-full">
                <PersonAvatarWithName
                  key={person}
                  name={person}
                  onClick={handleOnClick}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default whyThePlaybook;
