import React from "react";

function Intro(props) {
  return (
    <div className={"processIntro"}>
      <div className={"row"}>
        <div className={"col-6"}>
          <h1 className={"processHeading"}>Polishing</h1>
          <p className={"processRunning"}>
            Polishing begins when a feature (set) is complete. It ends when the
            feature (set) is considered ready for release (deployment to
            production).
          </p>
        </div>
        <div className={"col-6"}>
          <img
            className={"img-fluid"}
            src={props.nexttoprocess}
            alt="process"
          />
        </div>
      </div>
    </div>
  );
}

export default Intro;
