import React from "react";
import PersonAvatarWithName from "../../../components/GuideLines/PersonAvatarWithName/PersonAvatarWithName";

const personName = "Hamza";
function Hamza() {
  return (
    <div className="guidancesContainer">
      <PersonAvatarWithName key={personName} name={personName} />
      <br />
      <br />
      <div className="guidanceReqInnerBox">
        <p className={"processRunning"}>
          <b>Use case</b>: The playbook gives him clarity on what Tintash
          expects of him as project manager. It helps him enforce expectations
          and requirements with his team members regardless of seniority. At the
          same time, it provides useful tips and resources on different methods
          and tools, enough for him to be able to have an informed conversation
          with clients.
        </p>
      </div>
      <p className={"processRunning"}>
        Hamza is a mid-level project manager by education and by professional
        experience including from outside of Tintash. He is a 29-year-old single
        man who likes to spend his time experimenting with solutions to
        different organisational problems that he would like to solve. He has
        brought good ideas to management and has helped develop and streamline
        some internal systems. His management style is a combination of
        traditional and modern practices. He prefers to deal with clients
        formally and have a casual atmosphere in the team with bilateral
        connection with each team member.
        <br />
        <br />
        His <b>pains</b> relate to not always being able to connect with senior
        team members who are more experienced and under different pressures due
        to different life situation than his own. He tries to solve that by
        asking them questions on their experiences but needs mentoring in
        establishing his leadership role as project manager. He also needs time
        to fully internalise the Tintash way of working. At the same time, his{" "}
        <b>pains</b> come from having experienced project management at a large
        organisation and with experienced clients who trained him on methods
        being used by modern tech companies in the US.
      </p>
    </div>
  );
}

export default Hamza;
