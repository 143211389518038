import React from "react";
import { Collapse } from "antd";
import PropTypes from "prop-types";

import BADGES_CONTENT from "../../../../components/Processes/Development/BadgesContent";
import Guidance from "../../../../components/Guidance/Guidance";
import elder from "../../../../assets/icons/elderly.svg";
import Tooltip from "../../../../components/Tooltip/Tooltip";
const { Panel } = Collapse;

const prepTime = "30mins";
const runTime = "60 mins";
const followUpTime = "30mins";
const people = "5-9";
function DevelopmentKickOff(props) {
  const { styles } = props;
  return (
    <div className="guidancesContainer">
      <div className={styles.guidanceMainHeadingContainer}>
        <h2 className="processHeading2" style={{ margin: 0 }}>
          Dev Kick-off Meeting
        </h2>
      </div>
      <br />

      <Guidance
        prepTime={prepTime}
        runTime={runTime}
        followUpTime={followUpTime}
        people={people}
      />

      <br />
      <br />
      <div className="guidanceCollapse processRunning">
        <Collapse expandIconPosition={"right"}>
          <Panel header="Why the requirement" key="1">
            <p className="processRunning">
              This is a meeting with the client. The purpose of the meeting is
              twofold:
            </p>
            <ol className="processRunning">
              <li>
                Ways of working
                <ul>
                  <li>Introduce the team and the client to each other</li>
                  <li>
                    Establish ways of working (communication tools and
                    frequency, input, change requests etc.)
                  </li>
                </ul>
              </li>
              <li>
                Product
                <ul>
                  <li>Questions and clarifications</li>
                  <li>
                    Milestones (discuss and agree on key milestones and
                    alignment between development and other business activities
                    e.g., investor meetings, launch, marketing etc.)
                  </li>
                </ul>
              </li>
            </ol>
          </Panel>
        </Collapse>
        <br />
        <br />
        <Collapse expandIconPosition={"right"}>
          <Panel header="What is the requirement" key="2">
            <div className="row">
              <div className="col-12">
                <div className="guidanceReqInnerBox">
                  <b className="mandatory-participants">
                    Mandatory participants:
                  </b>
                  &nbsp;PM, all team members, client
                </div>
                <p className="processRunning">
                  This meeting is with the client. Bringing the team along is a
                  good practice. The PM is responsible for drafting and sharing
                  the agenda of the meeting with the client and the team. It can
                  be shared in the body of an email or as a separate attachment
                  to the email. It is important that the PM follows their own
                  style of communication and logical flow. However, the
                  following items should be covered:
                </p>

                <ul className="processRunning">
                  <li>Introductions</li>
                  <li>Our understanding of the project goals</li>
                  <li>
                    Project workplan:
                    <Tooltip
                      headingOne="Buzurg kehte hein:"
                      paragraphOne={BADGES_CONTENT.WORKPLAN}
                      direction="right"
                    >
                      <img src={elder} alt="" className="badgeIcon" />
                    </Tooltip>
                    Key milestones
                  </li>
                  <li>
                    Ways of working: communication, feedback, change requests
                  </li>
                  <li>
                    Upcoming: what we will develop first, when will you receive
                    a demo
                  </li>
                </ul>

                <h1 className="processHeading4 italic">Before the Meeting</h1>
                <ul className="processRunning">
                  <li>Send the agenda at least a day in advance</li>
                  <li>
                    Share the Release Checklist with the team for reflection
                    before the meeting
                  </li>
                  <li>Assign a notetaker</li>
                </ul>

                <h1 className="processHeading4 italic">During the Meeting</h1>
                <ul className="processRunning">
                  <li>
                    Refer to the checklist to make sure all items that have
                    implications for the release plan are discussed.
                  </li>
                  <li>Make space for different points of view.</li>
                  <li>
                    Be prepared to prevent the discussion from spiralling out of
                    scope or go in circles without dismissing what people are
                    saying. Extract the main point people are making and repeat
                    that. Phrases: let’s park this and come back to it at the
                    end if we have time, let’s address that at point x, this is
                    an important discussion, I will book time for this during...
                  </li>
                  <li>
                    Show interest when a team member shares a thought or an idea
                    by asking questions about it.
                  </li>
                  <li>
                    If a team member is quiet, encourage them to contribute
                    without putting them on the spot. You can do this by raising
                    something that related to their area of
                    responsibility/expertise.
                  </li>
                </ul>

                <h1 className="processHeading4 italic">After the Meeting</h1>
                <ul className="processRunning">
                  <li>
                    Summarise the notes from the discussion and share with the
                    whole team with clear action items.
                  </li>
                  <li>Update the key milestones in the project workplan.</li>
                  <li>
                    Use the meeting conclusion to inform the kick-off meeting
                    prep and presentation to the client.
                  </li>
                </ul>
              </div>
            </div>
          </Panel>
        </Collapse>
      </div>
      <br />
    </div>
  );
}

DevelopmentKickOff.protoTypes = {
  styles: PropTypes.any,
};

export default DevelopmentKickOff;
