import React from "react";
import Pop from "../../Popup/popup";
import Badge from "../../../assets/images/badge.svg";

export function ProductContent(props) {
  return (
    <>
      <li>
        Would you like to share your business plan with us? Or other plans –
        launch, marketing, fundraising?
      </li>
      <li>
        Did you go through a design thinking exercise
        <Pop
          src={Badge}
          heading="Buzurg kehte hain"
          dataone={props.badgesContent.DESIGN_EXERCISE}
        />
        in making your business plan?
      </li>
    </>
  );
}
export function TechContent() {
  return (
    <li>
      Do you have any preference for technology to use while building out the
      product?
    </li>
  );
}
export function LawsRegulationsContent(props) {
  return (
    <>
      <li>
        Are there any laws related to the domain (e.g. health, use by minors)
        that are applicable to your product?
      </li>
      <li>
        Are there any privacy and data protection regulations to consider in the
        area you are trying to launch it in? At what stage
        <Pop
          src={Badge}
          heading="Lekin kion"
          dataone={props.badgesContent.LAWSREGULATIONS_1}
          headingOne="Buzurg kehte hain"
          headingOneData={props.badgesContent.LAWSREGULATIONS_2}
        />
        ?
      </li>
      <li>
        Are there universal access / accessibility of ICT solutions requirements
        that you need to comply with? At what stage?
      </li>
    </>
  );
}
