import React from "react";
import personBlue from "../../../assets/icons/personBlue.svg";
import styles from "../../../pages/Introduction/introduction.module.css";

function PersonAvatarWithName(props) {
  const { name, onClick } = props;
  return (
    <div className="row" onClick={onClick && (() => onClick(name))}>
      <img
        className={`mobile-hidden col-2 ${styles.personWhyThePlaybook}`}
        src={personBlue}
      />
      <h4
        className={`mobile-hidden col-6  ml-0 ${styles.personNameWhyThePlaybook}`}
      >
        {name}
      </h4>
      <div className="desktop-hidden mobile-flex avatar-container">
        <img
          className={`avatar ${styles.personWhyThePlaybook}`}
          src={personBlue}
        />
        <h4 className={`${styles.personNameWhyThePlaybook}`}>{name}</h4>
      </div>
    </div>
  );
}

export default PersonAvatarWithName;
