import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import ProgramsReducer from "./programs/ProgramsReducer";
import GetProjectReducer from "./GetProject/GetProjectReducer";
import {
  projectReducer,
  projectDetailReducer,
  subStageReducer,
} from "./reducers/projectDetails";
import loginReducer from "./reducers/login";
import { updateReducer } from "./reducers/dailyUpdate";
import { checkListReducer } from "./reducers/checkList";
import { phaseReducer } from "./reducers/projectDetails";
import { USER_LOGOUT } from "./actions/types";
import clientSatisfactionReducer from "./ClientSatisfaction/ClientSatisfactionReducer";
import getAllRolesReducer from "./GetAllRoles/GetAllRolesReducer";

const persistConfig = {
  key: "root",
  storage,
};

const appReducer = combineReducers({
  programs: ProgramsReducer,
  getProject: GetProjectReducer,
  projects: projectReducer,
  login: loginReducer,
  current: projectDetailReducer,
  update: updateReducer,
  phase: phaseReducer,
  subStage: subStageReducer,
  checkList: checkListReducer,
  clientSatisfaction: clientSatisfactionReducer,
  allRoles: getAllRolesReducer,
});

const rootReducer = (state = {}, action) => {
  if (action.type === USER_LOGOUT) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default persistReducer(persistConfig, rootReducer);
