import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { RouteWithSubRoutes } from "../../../../routes/routeWithSubRoutes";
import styles from "../../adminPanel.module.css";
import { Select } from "antd";
import { Option } from "antd/es/mentions";
import moment from "moment/moment";
import { ROUTES } from "../../../../routes/routeConstants";
import SubStages from "../SubStageSection/SubStages";
import { SocketContext } from "../../../../context/socket";
import { SOCKET_CONSTANTS } from "../../../../constants/globalConstants";
import { NextStageDialog as ConfirmModal } from "../../../../components/Dialog/next-stage-dialog/NextStageDialog";
import { ErrorDialog } from "../../../../components/Dialog/ErrorDialog";
import {
  setCurrentPhase,
  getStatusProject,
  setCurrentSubStage,
  getProjectsList,
} from "../../../../redux/actions/projectDetails";
import Loader from "../../../../components/Loader/loader";
import dateIcon from "../../../../assets/icons/date.svg";
import phaseIcon from "../../../../assets/icons/phaseIcon.svg";
import "./ProjectDetails.scss";
import ClientSatisfactionMeterImage from "../../../../assets/icons/client-satisfaction-meter.svg";
import NextProcess from "../../../../assets/icons/next-process.svg";
import { getProcessStatus } from "../../../../helper/getApis";
import { completeProject } from "../../../../helper/putApis";
import { ERROR_MESSAGE } from "../../../../constants/errorMessages";
import { PROJECT_STATUSES } from "../../../../redux/actions/types";
import ClientSatisfactionMeter from "./ProcessDetails/ClientSatisfactionMeter/ClientSatisfactionMeter";
import { getPmDetails } from "../../ClientSatisfaction/clientSatisfactionHelper";

const phasesValues = [
  {
    name: "Lead Conversion",
    processName: "Provide an Estimate",
    key: "PROVIDE_ESTIMATE_UPDATE",
  },
  {
    name: "Lead Conversion",
    processName: "Handing over to pm",
    key: "HANDING_OVER_PM_UPDATE",
  },
  {
    name: "Lead Conversion",
    processName: "Looking back",
    key: "LOOKING_BACK_UPDATE",
  },
  {
    name: "Discovery",
    processName: "Starting a Discovery",
    key: "START_DISCOVERY_UPDATE",
  },
  {
    name: "Discovery",
    processName: "During Discovery",
    key: "DURING_DISCOVERY_UPDATE",
  },
  {
    name: "Discovery",
    processName: "Finishing a Discovery",
    key: "FINISH_DISCOVERY_UPDATE",
  },
  {
    name: "Development",
    processName: "Beginning Development",
    key: "BEGINNING_DEVELOPMENT_UPDATE",
  },
  {
    name: "Development",
    processName: "During Development",
    key: "DURING_DEVELOPMENT_UPDATE",
  },
  {
    name: "Development",
    processName: "Completing Development",
    key: "COMPLETING_DEVELOPMENT_OR_PRODUCT_UPDATE",
  },
  {
    name: "Release",
    processName: "Release",
    key: "RELEASE_UPDATE",
  },
  {
    name: "Polishing",
    processName: "Dogfooding",
    key: "DOG_FOODING_UPDATE",
  },
];

export const ProjectDetails = ({ routes, projectId }) => {
  const history = useHistory();
  const socket = useContext(SocketContext);
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state.login.accessToken);
  const currentPhaseId = useSelector((state) => state.phase.id);
  const currentUserRole = useSelector(
    (state) => state.clientSatisfaction.userRole
  );
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  const currentUserObj = JSON.parse(localStorage.getItem("userObj"));
  const userId = currentUserObj?._id;
  const allSubStages = useSelector((state) => state?.current.data);
  const currentProjectId = useSelector((state) => state?.current.projectId);
  const currentSubStageId = useSelector((state) => state.subStage.id);
  const allPhases = useSelector((state) => state?.current.phases);
  const userRole = useSelector((state) => state?.clientSatisfaction.userRole);
  const userRoleId = userRole?._id;
  const projectStatus = useSelector((state) => state?.current.status);
  const projectEndDate = useSelector((state) => state?.current.endDate);
  const [showDialog, setShowDialog] = useState(false);
  const [closeProjectDialog, setCloseProjectDialog] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [currentPhaseName, setCurrentPhaseName] = useState("Discovery");
  const [phaseId, setPhaseId] = useState(null);
  const [phasesList, setPhasesList] = useState([]);
  const [showCheckinForm, setShowCheckinForm] = useState(false);
  const [updateFeedback, setUpdateFeedback] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [isRoleInValid, setIsRoleInValid] = useState(false);
  const [errorTitle, setErrorTitle] = useState("");

  const handlePhases = (phaseName) => {
    console.log({ phaseName });
    const phaseObj = currentPhaseObject(phaseName);
    dispatch(setCurrentPhase(phaseObj.phase._id, phaseObj.phase.name));
    setPhaseId(phaseObj.phase._id);
    dispatch(getStatusProject(config, projectId, "1", phaseName));
    setCurrentPhaseName(phaseName);

    const { phase = {}, processes = {} } =
      allPhases.find(({ phase }) => phase.name === phaseName) || {};

    const { _id, name } =
      processes.find(
        ({ status }) =>
          status === PROJECT_STATUSES.IN_PROGRESS ||
          status === PROJECT_STATUSES.PENDING_FOR_APPROVAL
      ) ||
      processes[0] ||
      {};
    dispatch(setCurrentSubStage(_id, name));
    const key = currentPhase(phaseName, name)?.key;
    if (key) {
      history.push(ROUTES[key](projectId, phase._id, _id));
    }
  };

  const setShowDialogValue = (value) => {
    setShowDialog(value);
  };

  const currentPhase = (phaseName, process) => {
    return phasesValues?.find(
      ({ name, processName }) => name === phaseName && processName === process
    );
  };
  const currentPhaseObject = (phaseName) => {
    return allPhases?.find((obj) => obj.phase.name === phaseName);
  };

  useEffect(() => {
    socket.emit(SOCKET_CONSTANTS.JOIN_ROOM, currentProjectId);
    return () => {
      socket.emit(SOCKET_CONSTANTS.LEAVE_ROOM, currentProjectId);
    };
  }, [currentProjectId]);

  useEffect(() => {
    if (loading === true) {
      putCompleteProject(currentProjectId);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  }, [loading]);

  useEffect(() => {
    handleSetCurrentPhase();
  }, [currentPhaseId, currentProjectId]);

  const handleSetCurrentPhase = () => {
    const phases = allPhases?.map(({ phase }) => phase);
    setPhasesList(phases);

    const phase = phases.find(({ _id }) => _id === currentPhaseId) || "";
    setCurrentPhaseName(phase?.name || "Discovery");
    setPhaseId(currentPhaseId);
  };

  useEffect(() => {
    const currentStage = allSubStages.find(
      (subStage) => subStage?._id == currentSubStageId
    );
    if (currentStage?.status === "completed") {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [currentSubStageId, projectId, allSubStages]);

  const handleNextProcess = async () =>
    submitSubStage(projectId, phaseId || currentPhaseId, currentSubStageId);

  const submitSubStage = async (projectId, projectPhaseId, processId) => {
    try {
      setIsDisabled(true);
      const completeProcess = await getProcessStatus(
        projectId,
        projectPhaseId,
        processId
      );
      if (completeProcess) {
        setIsDisabled(false);
        return setShowDialog(true);
      }
      setIsDisabled(false);
      setShowErrorDialog(true);
    } catch (e) {
      setIsDisabled(false);
      console.log("Error Fetching Subscriptions Detail", e);
    }
    setShowDialog(false);
  };
  const updateProjectDetail = async (userId, userRoleId) => {
    dispatch(getProjectsList(config, userRoleId));
    const id = userRole?.shortForm === "AD" ? "" : userId;
    await getPmDetails(id, userRoleId);
  };

  const putCompleteProject = async (projectId) => {
    try {
      setLoading(true);
      await completeProject(projectId);
      updateProjectDetail(userId, userRoleId);
      setLoading(false);
    } catch (e) {
      console.log("Error Completing Project", e);
    }
    setShowDialog(false);
  };

  const resetError = () => {
    setErrorTitle("");
    isRoleInValid ? setIsRoleInValid(false) : null;
  };

  const isProjectOpen = () => {
    if (projectStatus === "closed") {
      setErrorTitle(ERROR_MESSAGE.PROJECT_CLOSED);
      return false;
    }
    return true;
  };

  const isRoleValid = () => {
    if (
      currentUserRole.shortForm === "AD" ||
      currentUserRole.shortForm === "CL"
    ) {
      setErrorTitle(ERROR_MESSAGE.ROLE_INVALID);
      setIsRoleInValid(true);
      return false;
    }
    return true;
  };
  const clientSatisfactionMeterClick = () => {
    if (isProjectOpen() && isRoleValid()) {
      setShowCheckinForm(true);
      setUpdateFeedback(!updateFeedback);
    } else {
      setShowErrorDialog(true);
    }
  };
  const getEndDate = () => {
    return moment(projectEndDate).format("DD MMM YYYY");
  };
  return (
    <>
      <ErrorDialog
        title={errorTitle ? errorTitle : ERROR_MESSAGE.COMPLETE_STAGE}
        showDialog={showErrorDialog}
        setShowDialog={setShowErrorDialog}
        resetError={resetError}
      />
      <div className="project-details-wrapper">
        <div className="project-phase-wrapper">
          <div>
            <ClientSatisfactionMeter
              isVisible={showCheckinForm}
              updateFeedback={updateFeedback}
              setShowCheckinForm={setShowCheckinForm}
            />
            <div className={styles.main}>
              <div className="flex align-start stage-selector">
                <div>
                  <img
                    src={phaseIcon}
                    className="img-fluid w-28"
                    alt="guidance"
                  />
                </div>
                <Select
                  value={currentPhaseName}
                  onChange={handlePhases}
                  defaultValue={currentPhaseName}
                  className="phase-title"
                  bordered={false}
                >
                  {phasesList.map(({ name, status }) => (
                    <Option
                      key={name}
                      value={name}
                      disabled={status === "disable" || status === "locked"}
                    >
                      {name}
                    </Option>
                  ))}
                </Select>
              </div>

              <div>
                <SubStages
                  projectId={currentProjectId}
                  allSubStages={allSubStages}
                  currentStageId={phaseId || currentPhaseId}
                  showDialog={showDialog}
                  setShowDialog={setShowDialogValue}
                  handlePhases={handlePhases}
                  phasesList={phasesList}
                  setPhaseId={setPhaseId}
                  setShowErrorDialog={setShowErrorDialog}
                />
              </div>
              <div>
                <div className={styles.content}>Estimated Completion </div>
                <div className={styles.date}>
                  <img
                    src={dateIcon}
                    className="img-fluid w-28 mr-16 mb-8"
                    alt="guidance"
                  />
                  {getEndDate()}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ConfirmModal
          onSuccess={() => setLoading(true)}
          title="Are you sure you want to complete this project?"
          showDialog={closeProjectDialog}
          setShowDialog={setCloseProjectDialog}
        />
        {loading && <Loader />}
        <div className="process-details-wrapper row pb-16">
          <RouteWithSubRoutes routes={routes} />
        </div>
        <div className="row bottom-bar">
          <div className="client-satisfaction-meter col-4">
            <button
              className="client-satisfaction-meter-button"
              onClick={clientSatisfactionMeterClick}
            >
              <img
                className="satisfaction-icon"
                src={ClientSatisfactionMeterImage}
              ></img>
              <div className="client-satisfaction-meter-text">
                Client Satisfaction Meter
              </div>
            </button>
          </div>
          <div className="col-5"></div>
          <div className="next-process-button-container col-3">
            <button
              className={
                projectStatus === "closed"
                  ? "next-process-button-disable"
                  : "next-process-button"
              }
              onClick={() => setCloseProjectDialog(true)}
              disabled={projectStatus === "closed"}
            >
              <div className="next-process-text">Complete Project</div>
            </button>
            <button
              className={
                currentPhaseName === "Release"
                  ? "next-process-button-display"
                  : isDisabled || projectStatus === "closed"
                  ? "next-process-button-disable"
                  : "next-process-button"
              }
              onClick={handleNextProcess}
              disabled={isDisabled || projectStatus === "closed"}
            >
              <img src={NextProcess}></img>
              <div className="next-process-text">Next Process</div>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
