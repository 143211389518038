import React from "react";
import { useState } from "react";
import Tooltip from "../../../Tooltip/Tooltip";
import Badge from "../../../../assets/images/badge.svg";
import BADGES_CONTENT from "../BadgesContent";
function ReleasePlanning(props) {
  const { styles } = props;
  const [checklist, setChecklist] = useState("web");
  return (
    <div className="guidancesContainer Release_Checklist">
      <div className={styles.guidanceMainHeadingContainer}>
        <h2 className="processHeading2">Release Checklist</h2>
      </div>
      <br />
      <div className="row">
        <div className="col-4">
          <div
            className={checklist === "web" ? "checklistActive" : "checklist"}
            onClick={() => setChecklist("web")}
          >
            Web Products
          </div>
        </div>
        <div className="col-4">
          <div
            className={checklist === "mobile" ? "checklistActive" : "checklist"}
            onClick={() => setChecklist("mobile")}
          >
            Mobile Products
          </div>
        </div>
      </div>
      <br />
      <ul className="processRunning">
        {checklist === "web" ? (
          <>
            <li>Code freeze</li>
            <li>Thorough Testing of the application</li>
            <li>Document changes &amp; publishing Release Notes</li>
            <li>
              Security considerations:
              <ul>
                <li>
                  Proper authorization and authentication techniques, resistance
                  to common malicious activity.
                </li>
                <li>
                  Secrets/keys are secured properly in a vault or secret store.
                  Tools like git-secrets scan code to identify potential
                  secrets.
                </li>
                <li>
                  Data encryption (both at rest &amp; in flight — if needed).
                  SSL configuration on production environment both backend and
                  frontend.
                </li>
              </ul>
            </li>
            <li>
              Compliance: If there are any prevailing compliance requirements --
              like data encryption, HIPPA Guidelines, GDPR etc, they should be
              met before the release.
            </li>
            <li>
              Deployment:
              <ul>
                <li>
                  Prepare a good staging environment
                  <Tooltip
                    headingOne="Buzurg kehte hein:"
                    paragraphOne={BADGES_CONTENT.ENVIORNMENT}
                    direction="right"
                  >
                    <img src={Badge} alt="" className="badgeIcon" />
                  </Tooltip>
                  for your software.
                </li>
                <li>
                  Deployment strategy documented, and CI/CD pipelines configured
                  for staging and production environments.
                </li>
                <li>
                  Check possibilities for soft launch first or rollout in
                  phases.
                </li>
              </ul>
            </li>
            <li>
              Scalability: Test for application scalability, enable auto scaling
              or configure Load balancers for application to scale well.
            </li>
            <li>
              Application Performance: Load / Stress testing of the application
              using tools like Jmeter and Loader.io
            </li>
            <li>
              Application Monitoring Tools should be configured (Logging,
              tracing and general performance metrics)
            </li>
            <li>
              Fault Tolerance and Disaster Recovery mechanism should be outlined
            </li>
          </>
        ) : checklist === "mobile" ? (
          <>
            <li>Shrink application size, remove any unnecessary resources.</li>
            <li>
              Code freeze, acceptance criteria of all features should be met.
            </li>
            <li>Verify application version code/name.</li>
            <li>
              Thorough testing of the application across different OS versions.
            </li>
            <li>Documenting changes & publishing release notes.</li>
            <li>
              Security considerations:
              <ul>
                <li>
                  Secrets/keys are secured properly in a vault or secret
                  store/key store.
                </li>
                <li>SSL configuration on production environment.</li>
                <li>
                  Disable logs which might provide sensitive information on the
                  production environment.
                </li>
                <li>Disable debugging.</li>
                <li>Protect API calls against MITM attack if required.</li>
              </ul>
            </li>
            <li>
              Compliance: If there are any prevailing compliance requirements --
              like data encryption, HIPPA, GDPR etc..., they should be met
              before the release.
            </li>
            <li>
              Deployment:
              <ul>
                <li>
                  Use alpha/beta/test tracks for testing your application. This
                  ensures the level of readiness of your application and mocks
                  the user experience of downloading the app from the App Store
                  or the Play Store.
                </li>
                <li>Follow up on feedback from App Store and Play Store.</li>
                <li>
                  Deployment strategy should be documented and CI/CD pipelines
                  should be configured.
                </li>
                <li>
                  Check possibilities for soft launch first or rollout in phases{" "}
                </li>
              </ul>
            </li>
            <li>
              Application performance: CPU usage, memory usage, network traffic,
              disk usage, and FPS should be checked for each screen.
            </li>
            <li>
              Application monitoring tools should be configured (for example,
              Firebase Crashlytics, Instabug etc...).
            </li>
          </>
        ) : (
          <></>
        )}
      </ul>
    </div>
  );
}

export default ReleasePlanning;
