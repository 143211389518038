import "./Guidance.scss";

import React from "react";
import prepTimeIcon from "../.././assets/icons/prepTimeIcon.svg";
import runTimeIcon from "../.././assets/icons/runTimeIcon.svg";
import followUpTimeIcon from "../.././assets/icons/followUpTimeIcon.svg";
import peopleIcon from "../.././assets/icons/peopleIcon.svg";

function Guidance(props) {
  const { prepTime, runTime, followUpTime, people } = props;

  return (
    <div className="row guidanceReqs">
      <div className="col-3">
        <div className="guidance">
          <img
            src={prepTimeIcon}
            width={100}
            className="img-fluid"
            alt="guidance"
          />
          <div className="guidance-icon-container">
            <div className="guidance-key">Prep Time: </div>
            <div style={{ fontWeight: 400 }}>{prepTime}</div>
          </div>
        </div>
      </div>
      <div className="col-3">
        <div className="guidance">
          <img
            src={runTimeIcon}
            width={100}
            className="img-fluid"
            alt="guidance"
          />
          <div className="guidance-icon-container">
            <div className="guidance-key">Run Time: </div>
            <div className="guidance-values">{runTime}</div>
          </div>
        </div>
      </div>
      <div className="col-3">
        <div className="guidance">
          <img
            src={followUpTimeIcon}
            width={100}
            className="img-fluid"
            alt="guidance"
          />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="guidance-key">Follow Up:</div>
            <div className="guidance-values">{followUpTime}</div>
          </div>
        </div>
      </div>
      <div className="col-3">
        <div className="guidance">
          <img
            src={peopleIcon}
            width={100}
            className="img-fluid"
            alt="guidance"
          />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="guidance-key">People:</div>
            <div className="guidance-values">{people}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Guidance;
