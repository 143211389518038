import {
  ADD_SYNCUP_UPDATE,
  ADD_CLIENT_UPDATE,
  ADD_SYNCUP_UPDATE_SUCCESS,
  ADD_CLIENT_UPDATE_SUCCESS,
  ADD_SYNCUP_UPDATE_ERROR,
  ADD_CLIENT_UPDATE_ERROR,
  GET_ALL_UPDATES,
} from "../actions/types";

const INITIAL_STATE = {
  data: null,
  loading: false,
  error: null,
  status: null,
  allUpdates: [],
  //   pagination: {
  //     count: 0,
  //     next: null,
  //     prev: null,
  //   },
};

export const updateReducer = (state = INITIAL_STATE, action = { type: "" }) => {
  switch (action.type) {
    case ADD_SYNCUP_UPDATE:
      return { ...state, loading: true, error: null, status: null };
    case ADD_CLIENT_UPDATE:
      return { ...state, loading: true, error: null, status: null };
    case ADD_SYNCUP_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        status: null,
        allUpdates: [...state.allUpdates, action.payload],
      };
    case ADD_CLIENT_UPDATE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        status: "done",
        loading: action.loading,
      };
    case ADD_SYNCUP_UPDATE_ERROR:
    case ADD_CLIENT_UPDATE_ERROR:
      return {
        ...state,
        status: "done",
        loading: false,
        error: action.payload,
      };
    case GET_ALL_UPDATES:
      return {
        ...state,
        allUpdates: action.payload,
      };
    default:
      return state;
  }
};
