import React from "react";

function Intro(props) {
  return (
    <div className={"processIntro"}>
      <div className={"row"}>
        <div className={"col-6"}>
          <h1 className={"processHeading"}>Release</h1>
          <p className={"processRunning"}>
            Release begins when polishing is done, the stardust bowl is empty.
            It ends when the product/feature/feature set is live.
          </p>
        </div>
        <div className={"col-6"}>
          <img
            className={"img-fluid"}
            src={props.nexttoprocess}
            alt="process"
          />
        </div>
      </div>
    </div>
  );
}

export default Intro;
