import React from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Field, getIn } from "formik";
import { Button } from "antd";

const Input = ({ field, form }) => {
  const errorMessage = getIn(form.errors, field.name);

  return (
    <div className="input-field-container w-100%">
      <input
        placeholder={"abc@xyz.com"}
        className="input-field-email w-100%"
        {...field}
      />
      {errorMessage && (
        <div className="error-message">Enter a valid email address</div>
      )}
    </div>
  );
};

export const FieldArrayComponent = (arrayHelpers) => {
  const {
    form: { values },
  } = arrayHelpers;
  return (
    <div className="w-70%">
      {values?.sendTo?.map((email, index) => (
        <div className="field-container w-100%" key={index}>
          <div className="input-field w-38%">
            <Field
              name={`sendTo.${index}`}
              className="email-input"
              component={Input}
            />
          </div>

          {index > 0 && (
            <div className="minus-circle">
              <MinusCircleOutlined
                className="dynamic-delete-button"
                onClick={() => arrayHelpers.remove(index)}
              />
            </div>
          )}
        </div>
      ))}
      <Button
        type="dashed"
        onClick={() => arrayHelpers.push("")}
        className="plus-icon-dashed w-38%"
        icon={<PlusOutlined />}
      ></Button>
    </div>
  );
};
