import React from "react";
import { Collapse } from "antd";
import PropTypes from "prop-types";
import Guidance from "../../../../components/Guidance/Guidance";

const { Panel } = Collapse;

const prepTime = "45 min";
const runTime = "60 min";
const followUpTime = "15 min";
const people = "3-7";

function MilestoneDemo(props) {
  const { styles } = props;
  return (
    <div className="guidancesContainer">
      <div className={styles.guidanceMainHeadingContainer}>
        <h2 className="processHeading2" style={{ margin: 0 }}>
          Milestone Demo
        </h2>
      </div>
      <br />

      <Guidance
        prepTime={prepTime}
        runTime={runTime}
        followUpTime={followUpTime}
        people={people}
      />

      <br />
      <br />
      <div className="guidanceCollapse processRunning">
        <Collapse expandIconPosition={"right"}>
          <Panel
            header="Why the requirement"
            key="1"
            className="processRunning"
          >
            <p>
              This is a meeting with the client at the end of each sprint/block
              of development. The purpose of the meeting is twofold:
            </p>
            <ol>
              <li>Demo what has been developed</li>
              <li>
                Feedback:
                <ul>
                  <li>Receive immediate feedback</li>
                  <li>
                    Agree on how the client will receive a new or updated
                    feature in follow up to the meeting
                  </li>
                  <li>
                    How will that provide detailed feedback and how it will be
                    dealt with.
                  </li>
                </ul>
              </li>
            </ol>
          </Panel>
        </Collapse>
        <br />
        <br />
        <Collapse expandIconPosition={"right"}>
          <Panel
            header="What is the requirement"
            key="2"
            className="processRunning"
          >
            <div className="row">
              <div className="col-12">
                <div className="guidanceReqInnerBox">
                  <b className="mandatory-participants">
                    Mandatory participants:
                  </b>
                  &nbsp;PM, team members, client, Account Manager (if needed, at
                  key milestone demos)
                </div>

                <p className="processRunning">
                  This meeting is with the client. Bringing team members who are
                  the most relevant to the update is a good practice.
                  <br />
                  <br />
                  The PM is responsible for drafting and sharing the agenda of
                  the meeting with the client. It can be shared in the body of
                  an email or as a separate attachment to the email. It is
                  important that the PM follows their own style of communication
                  and logical flow. However, the following items should be
                  covered:
                </p>
                <ul>
                  <li>
                    Introduction (to the meeting i.e. what are we going to demo,
                    very brief, 3-5 minutes)
                  </li>
                  <li>Demo</li>
                  <li>Initial reaction</li>
                  <li>Q&amp;A</li>
                </ul>

                <h1 className="processHeading4 italic">Before the Meeting</h1>
                <ul>
                  <li>Send the agenda at least a day in advance</li>
                  <li>
                    Draft talking points and discuss with a senior/another PM
                    for tips if there are challenges to be discussed.
                  </li>
                  <li>Assign a notetaker</li>
                </ul>

                <h1 className="processHeading4 italic">During the Meeting</h1>
                <ul>
                  <li>
                    Do not read from the talking points – they were only meant
                    to clarify your thoughts
                  </li>
                  <li>
                    Do not prolong the introduction, the client is keen to see
                    the demo, get right to it.
                  </li>
                </ul>

                <h1 className="processHeading4 italic">After the Meeting</h1>
                <ul>
                  <li>
                    Summarise the notes from the discussion and share with the
                    client and the team with clear action items.
                  </li>
                  <li>
                    File the minutes in the project’s document tree /
                    documentation skeleton.
                  </li>
                </ul>
              </div>
            </div>
          </Panel>
        </Collapse>
      </div>
      <br />
    </div>
  );
}

MilestoneDemo.protoTypes = {
  styles: PropTypes.any,
};

export default MilestoneDemo;
