export const PROCESS_CHECKLIST_MAPPER = {
  discoveryPlanningMeeting: "Discovery Planning Meeting",
  discoveryKickoffMeeting: "Discovery Kickoff Meeting",
  clientPresentation: "Client Presentation",
  reviewEstimates: "Review of Estimates",
  syncUp: "Internal Syncup",
  clientUpdate: "Client Update",
  teamCharterMeet: "Team Charter Meet",
  sprintZero: "Sprint 0.5",
  devKickoffMeeting: "Dev Kickoff Meeting",
  releasePlanningMeeting: "Release Planning Meeting",
  milestoneDemo: "Milestone Demo",
  finalDemo: "Final Demo",
  reviewOfEstimates: "Review of Estimates",
  dogFooding: "Dogfooding",
  handingOverToPMMeeting: "Handing over to PM Meeting",
  conversionRetro: "Conversion Retro",
  releaseChecklist: "Release Checklist",
  releaseExecutionMeeting: "Release Execution Meeting",
  closureHandoverMeeting: "Closure/Handover Meeting",
};

export const PROCESS_CHECKLIST_POPUP_MAPPER = {
  "Discovery Planning Meeting": "discoveryPlanningMeeting",
  "Discovery Kickoff Meeting": "discoveryKickoffMeeting",
  "Client Presentation": "clientPresentation",
  "Review of Estimates": "reviewEstimates",
  "Internal Syncup": "syncUp",
  "Client Update": "clientUpdate",
};
