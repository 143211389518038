import {
  NEXT_LEAD_CONVERSION_PROCESS_STEPS,
  NEXT_DEVELOPMENT_PROCESS_STEPS,
  NEXT_DISCOVERY_PROCESS_STEPS,
  NEXT_RELEASE_STEPS,
  NEXT_POLISHING_PROCESS_STEPS,
} from "../routes/routeConstants";

export const getFormattedInitialValues = (checkList) => {
  return Array.isArray(checkList)
    ? checkList.reduce(
        (accumulator, currentValue) => {
          accumulator[currentValue.html.value] = currentValue.onClick.reduce(
            (childAccumulator, childCurrentValue) => {
              childAccumulator[childCurrentValue.value] =
                childCurrentValue?.inputType === "checkbox-textbox"
                  ? ""
                  : false;
              return childAccumulator;
            },
            {
              checkList: currentValue._id,
              isChecked: !currentValue.html.clickable,
              clickable: currentValue.html.clickable,
            }
          );
          return accumulator;
        },
        { comments: "", sendTo: [""] }
      )
    : [];
};

export const getPascalCase = (str) =>
  str
    .replace(/[-_]/g, " ")
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/\b([A-Z]+)([A-Z])([a-z])/, "$1 $2$3")
    .replace(/^./, (str) => str.toUpperCase())
    .replace(/\s+/g, " ")
    .trim();

export const getCurrentProcessRouteName = (location) =>
  location.pathname.split("/")[6];

export const getNextProcessUrl = ({
  location,
  projectId,
  phaseId,
  processId,
  currentPhaseName,
}) => {
  const currentProcessRouteMame = getCurrentProcessRouteName(location);
  if (currentPhaseName === "Lead Conversion") {
    return NEXT_LEAD_CONVERSION_PROCESS_STEPS[currentProcessRouteMame](
      projectId,
      phaseId,
      processId
    );
  } else if (currentPhaseName === "Discovery") {
    return NEXT_DISCOVERY_PROCESS_STEPS[currentProcessRouteMame](
      projectId,
      phaseId,
      processId
    );
  } else if (currentPhaseName === "Development") {
    return NEXT_DEVELOPMENT_PROCESS_STEPS[currentProcessRouteMame](
      projectId,
      phaseId,
      processId
    );
  } else if (currentPhaseName === "Release") {
    return NEXT_RELEASE_STEPS[currentProcessRouteMame](
      projectId,
      phaseId,
      processId
    );
  } else if (currentPhaseName === "Polishing") {
    return NEXT_POLISHING_PROCESS_STEPS[currentProcessRouteMame](
      projectId,
      phaseId,
      processId
    );
  }
};
