import React from "react";
import PropTypes from "prop-types";

import "./mobileNavbarBottom.scss";
const MobileNavbarBottom = ({ children }) => {
  return <nav id="mobile-navbar-bottom">{children}</nav>;
};

MobileNavbarBottom.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MobileNavbarBottom;
