import axios from "axios";
import { ENV_CONSTANTS } from "../constants/globalConstants";
const baseURL = ENV_CONSTANTS.SERVER_URL;

const getConfig = () => ({
  headers: {
    Authorization: `Bearer ${
      JSON.parse(localStorage.getItem("userObj"))?.accessToken
    }`,
  },
});

export const submitAttachLink = async (values, docID) => {
  try {
    const response = await axios.put(
      `
${baseURL}/output/submit-file/${docID}`,
      {
        url: values.url,
      },
      getConfig()
    );
    return response.data;
  } catch (error) {
    throw new Error("Error putting submit Attach Link");
  }
};

export const completeProject = async (projectID) => {
  try {
    const response = await axios.put(
      `${baseURL}/project/${projectID}`,
      { status: "closed" },
      getConfig()
    );
    return response.data;
  } catch (error) {
    throw new Error("Error putting unlockStage");
  }
};

export const unlockStage = async (projectID, stageID) => {
  try {
    const response = await axios.put(
      `${baseURL}/project-status/${projectID}/${stageID}`,
      {},
      getConfig()
    );
    return response.data;
  } catch (error) {
    throw new Error("Error putting unlockStage");
  }
};

export const unlockSubStage = async (projectID, stageID, subStageID) => {
  try {
    const response = await axios.put(
      `${baseURL}/process-status/${projectID}/${stageID}/${subStageID}`,
      {},
      getConfig()
    );
    return response.data;
  } catch (error) {
    throw new Error("Error putting unlock Sub Stage");
  }
};
