import React from "react";
import { useState } from "react";

import nexttoprocess from "../../../assets/images/Discovery/nexttodiscovery.png";
import arrow from "../../../assets/images/arrow.svg";
import badge from "../../../assets/images/badge.svg";
import whatis from "../../../assets/images/Discovery/whatis.png";
import principleone from "../../../assets/images/Discovery/principle-one.png";
import principletwo from "../../../assets/images/Discovery/principle-two.png";
import principlethree from "../../../assets/images/Discovery/principle-three.png";
import guidanceone from "../../../assets/images/Discovery/guidance-one.svg";
import guidancetwo from "../../../assets/images/Discovery/guidance-two.png";
import guidancethree from "../../../assets/images/Discovery/guidance-three.png";
import Intro from "./Intro";
import WhereData from "./WhereData";
import Principles from "./Principles";
import What from "./What";
import GuidancePlanning from "./GuidancePlanning";
import GuidanceMeeting from "./GuidanceMeeting";
import Checklist from "./Checklist";
import WorkPlan from "./WorkPlan";
import styles from "./discovery.module.css";

function Discovery() {
  const WHEREDATA = [
    {
      name: "Starting a Discovery",
      dos: [
        {
          name: "Discovery Planning Meeting (internal)",
          ref: "discoveryPlanning",
        },
        {
          name: "Discovery Kickoff Meeting (with client)",
          ref: "discoveryKickOff",
        },
      ],
      uses: [
        "Discovery checklist",
        "Discovery Plan template",
        "Project Work-Plan Template",
      ],
    },
    {
      name: "During Discovery",
      dos: [
        { name: "Daily standups and syncups" },
        { name: "Daily updates to clients" },
      ],
      uses: ["Discovery Plan Template", "Project Documentation Skeleton"],
    },
    {
      name: "Finishing a Discovery",
      dos: [{ name: "Review of Estimates" }, { name: "Client Presentation" }],
      uses: [
        "Discovery Plan Template",
        "Discovery Conclusion Document",
        "SOW template",
      ],
    },
  ];

  const [activeWhere, setActiveWhere] = useState(WHEREDATA[0]);

  return (
    <div className={styles.processContainer}>
      <Intro nexttoprocess={nexttoprocess} />

      <WhereData
        wheredata={WHEREDATA}
        activeWhere={activeWhere}
        arrow={arrow}
        badge={badge}
        setActiveWhere={setActiveWhere}
      />

      <Principles
        principleone={principleone}
        principletwo={principletwo}
        principlethree={principlethree}
      />

      <What whatis={whatis} />

      <GuidancePlanning
        guidanceone={guidanceone}
        guidancetwo={guidancetwo}
        guidancethree={guidancethree}
      />

      <GuidanceMeeting
        guidanceone={guidanceone}
        guidancetwo={guidancetwo}
        guidancethree={guidancethree}
      />

      <Checklist />
      <WorkPlan />
    </div>
  );
}

export default Discovery;
