import React from "react";
import PropTypes from "prop-types";

import "./mobileNavbarTop.scss";
import logo from "../../../assets/images/logo.png";
import hamburgerMenuIcon from "../../../assets/icons/hamburgerMenuIcon.svg";
import MobileNavigationMenu from "../MobileNavigationMenu/MobileNavigationMenu";

const MobileNavbarTop = ({
  openNavigationMenu,
  setOpenNavigationMenu,
  menuItems,
}) => {
  return (
    <>
      <nav id="mobile-navbar">
        <div className="main-page-mobile-logo-container">
          <a href="/">
            <img src={logo} alt="logo" />
          </a>
        </div>
        <div className="main-page-mobile-hamburger-menu">
          <img
            src={hamburgerMenuIcon}
            alt="mobile-hamburger-menu"
            onClick={() => setOpenNavigationMenu(true)}
          />
        </div>
      </nav>

      <MobileNavigationMenu
        openNavigationMenu={openNavigationMenu}
        setOpenNavigationMenu={setOpenNavigationMenu}
        menuItems={menuItems}
      />
    </>
  );
};

MobileNavbarTop.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      url: PropTypes.string,
      icon: PropTypes.string,
    })
  ).isRequired,
  openNavigationMenu: PropTypes.bool.isRequired,
  setOpenNavigationMenu: PropTypes.func.isRequired,
};

export default MobileNavbarTop;
