import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

import "./tooltip.scss";
import tooltipCloseIcon from "../../assets/icons/tooltipCloseIcon.svg";
import useClickOutsideTooltipDetector from "./useClickOutsideTooltipDetector";

const Tooltip = (props) => {
  const {
    children,
    hasQuizOne,
    hasQuizTwo,
    direction,
    yOffsetMobile,
    paragraphOne,
    headingOne,
    headingTwo,
    paragraphTwo,
    paragraphThree,
    paragraphFour,
    quizOneSectionHeading,
    quizOneContent,
    quizOneHeadingText,
    quizOneParagraphOne,
    quizOneParagraphTwo,
    quizOneParagraphThree,
    quizTwoSectionHeading,
    quizTwoContent,
    quizTwoHeadingText,
    quizTwoParagraphOne,
    quizTwoParagraphTwo,
    quizTwoParagraphThree,
  } = props;

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [showQuizOneAnswer, setShowQuizAnswer] = useState(false);
  const [showQuizTwoAnswer, setShowQuizTwoAnswer] = useState(false);

  const [quizOneHeading, setQuizOneHeading] = useState(
    quizOneHeadingText ? quizOneHeadingText : "Reveal answer"
  );

  const [quizTwoHeading, setQuizTwoHeading] = useState(
    quizTwoHeadingText ? quizTwoHeadingText : "Reveal answer"
  );

  const toolTipWrapperRef = useRef(null);

  useClickOutsideTooltipDetector(toolTipWrapperRef, setIsTooltipOpen);

  const handleClick = (e) => {
    if (
      e.target.className === "tooltip-wrapper" ||
      (e.target.parentNode?.className === "tooltip-wrapper" &&
        !e.target.className.includes("tooltip-tip"))
    )
      setIsTooltipOpen(!isTooltipOpen);
    else if (
      isTooltipOpen &&
      e.target.parentNode?.className === "tooltip-close-button"
    )
      setIsTooltipOpen(false);
    else if (e.target.className === "quiz-heading") {
      if (e.target.dataset.quizNumber === "quizOne") {
        if (quizOneHeading === quizOneHeadingText)
          setQuizOneHeading("Hide answer");
        else if (quizOneHeading === "Hide answer")
          setQuizOneHeading(quizOneHeadingText);
        setShowQuizAnswer(!showQuizOneAnswer);
      } else if (e.target.dataset.quizNumber === "quizTwo") {
        if (quizTwoHeading === quizTwoHeadingText)
          setQuizTwoHeading("Hide answer");
        else if (quizTwoHeading === "Hide answer")
          setQuizTwoHeading(quizTwoHeadingText);
        setShowQuizTwoAnswer(!showQuizTwoAnswer);
      }
    }
  };

  return (
    <div
      className="tooltip-wrapper"
      onClick={handleClick}
      ref={toolTipWrapperRef}
    >
      {children}
      {isTooltipOpen && (
        <div
          className={`tooltip-tip ${direction || "left"} ${
            yOffsetMobile || ""
          }`}
        >
          <div
            className={`close-icon-container ${
              headingOne ? "tooltip-heading" : ""
            }`}
          >
            {headingOne ? (
              <h4 className="tooltip-heading">{headingOne}</h4>
            ) : null}
            <div className="tooltip-close-button">
              <img src={tooltipCloseIcon} alt="tooltip-close-icon" />
            </div>
          </div>
          <div className="content">
            {paragraphOne ? <p>{paragraphOne}</p> : null}
            {headingTwo ? (
              <h4 className="tooltip-heading">{headingTwo}</h4>
            ) : null}
            {paragraphTwo ? <p>{paragraphTwo}</p> : null}
            {paragraphThree ? <p>{paragraphThree}</p> : null}
            {paragraphFour ? <p>{paragraphFour}</p> : null}

            {hasQuizOne ? (
              <div className="quiz-section">
                {quizOneSectionHeading ? (
                  <h4 className="tooltip-heading">{quizOneSectionHeading}</h4>
                ) : null}
                {quizOneContent ? <p>{quizOneContent}</p> : null}
                <p className="quiz-heading" data-quiz-number="quizOne">
                  {quizOneHeading}
                </p>
                {showQuizOneAnswer ? (
                  <>
                    {quizOneParagraphOne ? (
                      <p className="quiz-answer" data-quiz-number="quizOne">
                        {quizOneParagraphOne}
                      </p>
                    ) : null}
                    {quizOneParagraphTwo ? (
                      <p className="quiz-answer" data-quiz-number="quizOne">
                        {quizOneParagraphTwo}
                      </p>
                    ) : null}
                    {quizOneParagraphThree ? (
                      <p className="quiz-answer" data-quiz-number="quizOne">
                        {quizOneParagraphThree}
                      </p>
                    ) : null}
                  </>
                ) : null}
              </div>
            ) : null}
            {hasQuizTwo ? (
              <div className="quiz-section">
                {quizTwoSectionHeading ? (
                  <h4 className="tooltip-heading">{quizTwoSectionHeading}</h4>
                ) : null}
                {quizTwoContent ? <p>{quizTwoContent}</p> : null}
                <p className="quiz-heading" data-quiz-number="quizTwo">
                  {quizTwoHeading}
                </p>
                {showQuizTwoAnswer ? (
                  <>
                    {quizTwoParagraphOne ? (
                      <p className="quiz-answer" data-quiz-number="quizTwo">
                        {quizTwoParagraphOne}
                      </p>
                    ) : null}
                    {quizTwoParagraphTwo ? (
                      <p className="quiz-answer" data-quiz-number="quizTwo">
                        {quizTwoParagraphTwo}
                      </p>
                    ) : null}
                    {quizTwoParagraphThree ? (
                      <p className="quiz-answer" data-quiz-number="quizTwo">
                        {quizTwoParagraphThree}
                      </p>
                    ) : null}
                  </>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
};

Tooltip.propTypes = {
  children: PropTypes.element.isRequired,
  hasQuizOne: PropTypes.bool,
  hasQuizTwo: PropTypes.bool,
  direction: PropTypes.string,
  yOffsetMobile: PropTypes.string,
  paragraphOne: PropTypes.string,
  headingOne: PropTypes.string,
  headingTwo: PropTypes.string,
  paragraphTwo: PropTypes.string,
  paragraphThree: PropTypes.string,
  paragraphFour: PropTypes.string,
  quizOneSectionHeading: PropTypes.string,
  quizOneContent: PropTypes.string,
  quizOneHeadingText: PropTypes.string,
  quizOneParagraphOne: PropTypes.string,
  quizOneParagraphTwo: PropTypes.string,
  quizOneParagraphThree: PropTypes.string,
  quizTwoSectionHeading: PropTypes.string,
  quizTwoContent: PropTypes.string,
  quizTwoHeadingText: PropTypes.string,
  quizTwoParagraphOne: PropTypes.string,
  quizTwoParagraphTwo: PropTypes.string,
  quizTwoParagraphThree: PropTypes.string,
};

export default Tooltip;
