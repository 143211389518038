import React from "react";
import { Collapse } from "antd";
import Guidance from "../../../../components/Guidance/Guidance";
import Tooltip from "../../../../components/Tooltip/Tooltip";
const { Panel } = Collapse;

const prepTime = "15 min";
const runTime = "60 min";
const followUpTime = "15-30 min";
const people = "3-5";
export const ExecutionMeeting = (props) => {
  const { elder, victory, school } = props.badges;
  return (
    <div className="guidancesContainer ReleaseExecutionMeeting">
      <h1 className="processHeading2">Release Execution Meeting</h1>
      <br />
      <Guidance
        prepTime={prepTime}
        runTime={runTime}
        followUpTime={followUpTime}
        people={people}
      />
      <br />
      <br />
      <div className="guidanceCollapse">
        <Collapse expandIconPosition={"right"}>
          <Panel
            header="Why the requirement"
            key="1"
            className="processRunning"
          >
            <p>
              This is an internal meeting that happens in parallel to the end of
              Development or right after Polishing. A primary deployment would
              need more time than iterative releases which require very little,
              if any set up, and can happen almost automatically. Regardless
              there is a need to take a moment to consider what is about to be
              released and it’s possible implications. The purpose of this
              meeting is to:
            </p>
            <ul>
              <li>
                Ensure clear responsibility for different steps of the release
                is assigned and understood by each team member
              </li>
              <li>Agree on next steps and schedule for the release</li>
              <li>Have a clear contingency</li>
            </ul>
          </Panel>
        </Collapse>
        <br />
        <br />
        <Collapse expandIconPosition={"right"}>
          <Panel header="What is the requirement" key="2">
            <div className="row">
              <div className="col-12 processRunning">
                <div className="guidanceReqInnerBox">
                  <b className="mandatory-participants">
                    Mandatory participants:
                  </b>
                  &nbsp;PM, all team members
                </div>

                <p>
                  The PM is responsible for drafting and sharing the agenda of
                  the meeting with the team. It can be shared in the body of an
                  email or as a separate attachment to the email. It is
                  important that the PM follows their own style of communication
                  and logical flow. However, the following items should be
                  covered:
                </p>
                <ul>
                  <li>
                    Release checklist – responsibility and status of each item
                    on the list
                  </li>
                  <li>
                    Release manager to confirm if all features are release ready
                    <Tooltip
                      headingOne="Buzurg kehte hein:"
                      paragraphOne="Always prioritise releasing something, giving the client a build, be happy with the polish level but don’t try perfection. The balance between giving a build and perfection is important to manage. if there is any doubt on the release readiness of any feature, kill it. Release without it. Show a video demo to the client to show that we have it but we need to fix x and y, we will release it with the next batch/in x days."
                      paragraphTwo="If there are problems with more than one feature, delay the release by a day or two. It is better to ask for a small extension than to release something that would make a bad impression. A small bug that the team knows can be fixed in a day or two will overwhelm the client and create a bad impression or lack of trust that will last the rest of the project."
                    >
                      <img src={elder} className="badgeIcon" alt="" />
                    </Tooltip>
                  </li>
                  <li>
                    Release plan:
                    <Tooltip
                      headingOne="Buzurg kehte hein:"
                      paragraphOne="In general, never deploy on a Friday, not on Thursday either."
                      direction="right"
                    >
                      <img src={elder} className="badgeIcon" alt="" />
                    </Tooltip>
                    given the above, are there adjustments needed? Do we need to
                    limit the scope of the release or shift to a soft launch
                    with limited users/staging?
                  </li>
                  <li>
                    Contingency:
                    <Tooltip
                      headingOne="Champions ka tareeqa:"
                      paragraphOne="The most important thing here is the team’s buy in – they should know what kind of problems can arise. I ask the team to tell me how they would like to okay the release of their components/features. One of my teams agreed on a thumbs up from each member. That is how we did it. When people design the process themselves, you get the buy in automatically."
                      direction="right"
                    >
                      <img src={victory} className="badgeIcon" alt="" />
                    </Tooltip>
                    what happens if something goes wrong or app is rejected. The
                    ‘owner’ of each feature / subset of features should okay the
                    release.
                  </li>
                  <li>
                    Looking ahead: what happens when the client
                    <Tooltip
                      headingOne="Champions ka tareeqa:"
                      paragraphOne="I minimise surprises to the client and to the team by starting release early in the project and by taking polishing seriously before each release."
                    >
                      <img src={victory} className="badgeIcon" alt="" />
                    </Tooltip>
                    /users start using the build.
                    <Tooltip
                      headingOne="Imtehan"
                      paragraphOne="One current project started with client giving us complete wireframes. We developed a part and released it early. Then iterations started. Just on the design side alone, there were two months of iterations, not much further development could happen. They started changing their own wireframes. How would you handle this situation?"
                      hasQuizOne={true}
                      quizOneHeadingText="Click for Jawab"
                      quizOneParagraphOne="Clear communication on implications and expectations. We said we will keep 10%-20% of the time in the next release to incorporate your feedback. We can cover x and y in that. And that would reduce the overall scope in z way/extend the timeline. The client appreciated that communication, we moved into a flexible model. Team 2 saal chali thi. No ending date to the engagement even now. We continue to develop feature by feature."
                      quizOneParagraphTwo="It is good to be clear with the client from the beginning. Take this up to the Release Planning Meeting that is required during the Development Phase."
                      quizOneParagraphThree="Explain to the client that we will work with you on this feature set for two months, for example. Then we will work iteratively. For clients with a tight budget, agree that we will give you two iterations (for example). In either case, say that in our experience a release creates new feedback and understanding of your own product, we are happy to recalibrate our time estimates (and therefore budget) if your priorities change."
                      yOffsetMobile="translate-Y-neg-110%"
                    >
                      <img src={school} alt="" className="badgeIcon" />
                    </Tooltip>
                  </li>
                  <li>
                    Is there client feedback that has not been addressed for
                    this release? Move to maintenance and discuss talking points
                    for communication to the client.
                  </li>
                </ul>
              </div>
            </div>
          </Panel>
        </Collapse>
      </div>
      <br />
    </div>
  );
};
