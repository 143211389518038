import React, { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";

import GuidancePlanning from "./GuidancePlanning";
import GuidanceMeeting from "./GuidanceMeeting";

import DailyStandup from "../Development/Requirements/DailyStandup";
import UpdateMeetings from "../Development/Requirements/UpdateMeetings";

import prepTimeIcon from "../../../assets/icons/prepTimeIcon.svg";
import runTimeIcon from "../../../assets/icons/runTimeIcon.svg";
import followUpTimeIcon from "../../../assets/icons/followUpTimeIcon.svg";
import peopleIcon from "../../../assets/icons/peopleIcon.svg";

import dailyStandupImg from "../../../assets/images/Development/dailyStandupImg.png";
import updateMeetingsImg from "../../../assets/images/Development/updateMeetingsImg.png";
import styles from "../Development/development.module.css";

function DiscoveryGuidelines(props) {
  const location = useLocation();
  const { checkListValue, close } = props;
  const ref = useRef(null);
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      close && close();
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);
  return (
    <div>
      {location.pathname.includes("start-discovery") ? (
        <>
          {checkListValue === "discoveryPlanningMeeting" ? (
            <GuidancePlanning
              guidanceone={prepTimeIcon}
              guidancetwo={runTimeIcon}
              guidancethree={followUpTimeIcon}
            />
          ) : checkListValue === "discoveryKickoffMeeting" ? (
            <GuidanceMeeting
              guidanceone={prepTimeIcon}
              guidancetwo={runTimeIcon}
              guidancethree={followUpTimeIcon}
            />
          ) : null}
        </>
      ) : location.pathname.includes("during-discovery") ? (
        <>
          {checkListValue === "syncUp" ? (
            <DailyStandup
              reqImg={dailyStandupImg}
              styles={styles}
              guidanceone={prepTimeIcon}
              guidancetwo={runTimeIcon}
              guidancethree={followUpTimeIcon}
              guidancefour={peopleIcon}
            />
          ) : checkListValue === "clientUpdate" ? (
            <UpdateMeetings
              reqImg={updateMeetingsImg}
              styles={styles}
              guidanceone={prepTimeIcon}
              guidancetwo={runTimeIcon}
              guidancethree={followUpTimeIcon}
              guidancefour={peopleIcon}
            />
          ) : null}
        </>
      ) : (
        <div>No GuideLines</div>
      )}
    </div>
  );
}

export default DiscoveryGuidelines;
