import { SET_PROJECT_DETAIL, SET_USER_ROLE } from "./ClientSatisfactionTypes";

const INITIAL_STATE = {
  userRole: null,
  projectDetail: null,
};

const clientSatisfactionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_USER_ROLE:
      return { ...state, userRole: action.payload };

    case SET_PROJECT_DETAIL:
      return { ...state, projectDetail: action.payload };

    default:
      return state;
  }
};

export default clientSatisfactionReducer;
