import { ROUTES } from "./routeConstants";
import { Projects } from "../pages/AdminPanel/Projects/Projects.jsx";
import ClientSatisfaction from "../pages/AdminPanel/ClientSatisfaction/ClientSatisfaction";
import { StartingDiscoveryDetails } from "../pages/AdminPanel/Projects/ProjectDetails/ProcessDetails/StartingDiscoveryDetails/StartingDiscoveryDetails";
import { DuringDiscoveryDetails } from "../pages/AdminPanel/Projects/ProjectDetails/ProcessDetails/DuringDiscoveryDetails/DuringDiscoveryDetails";
import { FinishingDiscoveryDetails } from "../pages/AdminPanel/Projects/ProjectDetails/ProcessDetails/FinishingDiscoveryDetails/FinishingDiscoveryDetails";
import { StartingDiscoveryUpdateSection } from "../pages/AdminPanel/Projects/ProjectDetails/ProcessDetails/StartingDiscoveryDetails/StartingDiscoveryUpdateSection/StartingDiscoveryUpdateSection";
import { DuringDiscoveryUpdateSection } from "../pages/AdminPanel/Projects/ProjectDetails/ProcessDetails/DuringDiscoveryDetails/DuringDiscoveryUpdateSection/DuringDiscoveryUpdateSection";
import { ProjectArtifacts } from "../pages/AdminPanel/Projects/ProjectArtifactsSection/ProjectArtifacts";
import { FinishingDiscoveryUpdateSection } from "../pages/AdminPanel/Projects/ProjectDetails/ProcessDetails/FinishingDiscoveryDetails/FinishingDiscoveryUpdateSection/FinishingDiscoveryUpdateSection";
import { ProvideEstimate } from "../pages/AdminPanel/Projects/ProjectDetails/ProcessDetails/LeadConversion/ProvideEstimate/ProvideEstimate";
import { HandingOverDetails } from "../pages/AdminPanel/Projects/ProjectDetails/ProcessDetails/LeadConversion/HandingOverPM/HandingOverDetails";
import { HandingOverUpdateSection } from "../pages/AdminPanel/Projects/ProjectDetails/ProcessDetails/LeadConversion/HandingOverPM/HandingOverUpdateSection/HandingOverUpdateSection";
import { ProvideEstimateUpdateSection } from "../pages/AdminPanel/Projects/ProjectDetails/ProcessDetails/LeadConversion/ProvideEstimate/ProvideEstimateUpdateSection/ProvideEstimateUpdateSection";
import { LookingBackDetails } from "../pages/AdminPanel/Projects/ProjectDetails/ProcessDetails/LeadConversion/LookingBack/LookingBackDetails";
import { LookingBackUpdateSection } from "../pages/AdminPanel/Projects/ProjectDetails/ProcessDetails/LeadConversion/LookingBack/LookingBackUpdateSection/LookingBackUpdateSection";

import LandingPage from "../pages/LandingPage/LandingPage";
import { MainPage } from "../pages/LandingSite/MainPage/MainPage";
import { LeadConversion } from "../pages/LandingSite/LeadConversion/LeadConversion";
import { Discovery } from "../pages/LandingSite/Discovery/Discovey";
import { Development } from "../pages/LandingSite/Development/Development";
import { Release } from "../pages/LandingSite/Release/Release";
import { Polishing } from "../pages/LandingSite/Polishing/Polishing";
import Introduction from "../pages/Introduction";
//import HowToUse from "../pages/Howtouse";
import { HowToUse } from "../pages/LandingSite/HowToUse/HowToUse";
import GoogleSignIn from "../pages/GoogleSignIn/GoogleSignin";
import AdminPanel from "../pages/AdminPanel/AdminPanel";
import whyThePlaybook from "../pages/Introduction/whyThePlaybook/whyThePlaybook";
import { BeginningDevelopmentDetails } from "../pages/AdminPanel/Projects/ProjectDetails/ProcessDetails/BeginningDevelopmentDetails/BeginningDevelopmentDetails";
import { BeginningDevelopmentUpdateSection } from "../pages/AdminPanel/Projects/ProjectDetails/ProcessDetails/BeginningDevelopmentDetails/BeginningDevelopmentUpdateSection/BeginningDevelopmentUpdateSection";
import { DuringDevelopmentDetails } from "../pages/AdminPanel/Projects/ProjectDetails/ProcessDetails/DuringDevelopmentDetails/DuringDevelopmentDetails";
import { DuringDevelopmentUpdateSection } from "../pages/AdminPanel/Projects/ProjectDetails/ProcessDetails/DuringDevelopmentDetails/DuringDevelopmentUpdateSection/DuringDevelopmentUpdateSection";
import { CompletingDevelopmentOrProductDetails } from "../pages/AdminPanel/Projects/ProjectDetails/ProcessDetails/CompletingDevelopmentOrProductDetails/CompletingDevelopmentOrProductDetails";
import { CompletingDevelopmentOrProductUpdateSection } from "../pages/AdminPanel/Projects/ProjectDetails/ProcessDetails/CompletingDevelopmentOrProductDetails/CompletingDevelopmentOrProductUpdateSection/CompletingDevelopmentOrProductUpdateSection";
import { StartReleaseDetails } from "../pages/AdminPanel/Projects/ProjectDetails/ProcessDetails/Release/StartRelease/StartRelease";
import { DogFoodingDetails } from "../pages/AdminPanel/Projects/ProjectDetails/ProcessDetails/Polishing/DogFoodingDetails";
import { DogFoodingUpdateSection } from "../pages/AdminPanel/Projects/ProjectDetails/ProcessDetails/Polishing/DogFoodingUpdateSection/DogFoodingUpdateSection";
import { StartReleaseUpdateSection } from "../pages/AdminPanel/Projects/ProjectDetails/ProcessDetails/Release/StartRelease/StartReleaseUpdateSection/StartReleaseUpdateSection";
const {
  HOME_PAGE,
  MAIN_PAGE,
  LEAD_CONVERSION,
  DISCOVERY,
  DEVELOPMENT,
  POLISHING,
  RELEASE,
  INTRODUCTION,
  HOW_TO_USE,
  SIGN_IN,
  ADMIN_PANEL,
  WHY_THE_PLAYBOOK,
  PROJECTS,
  DASHBOARD,
  PROVIDE_ESTIMATE,
  PROVIDE_ESTIMATE_UPDATE,
  HANDING_OVER_PM,
  HANDING_OVER_PM_UPDATE,
  LOOKING_BACK,
  LOOKING_BACK_UPDATE,
  START_DISCOVERY,
  DURING_DISCOVERY,
  FINISH_DISCOVERY,
  START_DISCOVERY_UPDATE,
  DURING_DISCOVERY_UPDATE,
  FINISH_DISCOVERY_UPDATE,
  DURING_DISCOVERY_ARTIFACTS,
  BEGINNING_DEVELOPMENT,
  BEGINNING_DEVELOPMENT_UPDATE,
  DURING_DEVELOPMENT,
  DURING_DEVELOPMENT_UPDATE,
  COMPLETING_DEVELOPMENT_OR_PRODUCT,
  COMPLETING_DEVELOPMENT_OR_PRODUCT_UPDATE,
  DOG_FOODING,
  DOG_FOODING_UPDATE,
  START_RELEASE,
  RELEASE_UPDATE,
} = ROUTES;

export const routes = [
  {
    path: HOME_PAGE,
    exact: true,
    component: MainPage,
  },
  {
    path: MAIN_PAGE,
    exact: true,
    component: LandingPage,
  },
  {
    path: LEAD_CONVERSION,
    exact: true,
    component: LeadConversion,
  },
  {
    path: DISCOVERY,
    exact: true,
    component: Discovery,
  },
  {
    path: DEVELOPMENT,
    exact: true,
    component: Development,
  },
  {
    path: POLISHING,
    exact: true,
    component: Polishing,
  },
  {
    path: RELEASE,
    exact: true,
    component: Release,
  },
  {
    path: INTRODUCTION,
    exact: true,
    component: Introduction,
  },
  {
    path: WHY_THE_PLAYBOOK,
    exact: true,
    component: whyThePlaybook,
  },
  {
    path: HOW_TO_USE,
    exact: true,
    component: HowToUse,
  },
  {
    path: SIGN_IN,
    exact: true,
    component: GoogleSignIn,
  },
  {
    path: ADMIN_PANEL,
    component: AdminPanel,
    routes: [
      {
        path: PROJECTS(":projectId"),
        component: Projects,
        routes: [
          {
            path: PROVIDE_ESTIMATE(":projectId", ":phaseId", ":processId"),
            component: ProvideEstimate,
            routes: [
              {
                path: PROVIDE_ESTIMATE_UPDATE(
                  ":projectId",
                  ":phaseId",
                  ":processId"
                ),
                exact: true,
                key: "update",
                name: "update",
                component: ProvideEstimateUpdateSection,
              },
            ],
          },
          {
            path: HANDING_OVER_PM(":projectId", ":phaseId", ":processId"),
            component: HandingOverDetails,
            routes: [
              {
                path: HANDING_OVER_PM_UPDATE(
                  ":projectId",
                  ":phaseId",
                  ":processId"
                ),
                exact: true,
                key: "update",
                name: "update",
                component: HandingOverUpdateSection,
              },
            ],
          },
          {
            path: LOOKING_BACK(":projectId", ":phaseId", ":processId"),
            component: LookingBackDetails,
            routes: [
              {
                path: LOOKING_BACK_UPDATE(
                  ":projectId",
                  ":phaseId",
                  ":processId"
                ),
                exact: true,
                key: "update",
                name: "update",
                component: LookingBackUpdateSection,
              },
            ],
          },
          {
            path: START_DISCOVERY(":projectId", ":phaseId", ":processId"),
            component: StartingDiscoveryDetails,
            routes: [
              {
                path: START_DISCOVERY_UPDATE(
                  ":projectId",
                  ":phaseId",
                  ":processId"
                ),
                exact: true,
                key: "update",
                name: "update",
                component: StartingDiscoveryUpdateSection,
              },
            ],
          },
          {
            path: DURING_DISCOVERY(":projectId", ":phaseId", ":processId"),
            component: DuringDiscoveryDetails,
            routes: [
              {
                path: DURING_DISCOVERY_UPDATE(
                  ":projectId",
                  ":phaseId",
                  ":processId"
                ),
                exact: true,
                key: "DURING_DISCOVERY_UPDATE",
                name: "update",
                component: DuringDiscoveryUpdateSection,
              },
              {
                path: DURING_DISCOVERY_ARTIFACTS(
                  ":projectId",
                  ":phaseId",
                  ":processId"
                ),
                exact: true,
                key: "DURING_DISCOVERY_ARTIFACTS",
                name: "artifacts",
                component: ProjectArtifacts,
              },
            ],
          },
          {
            path: FINISH_DISCOVERY(":projectId", ":phaseId", ":processId"),
            component: FinishingDiscoveryDetails,
            routes: [
              {
                path: FINISH_DISCOVERY_UPDATE(
                  ":projectId",
                  ":phaseId",
                  ":processId"
                ),
                exact: true,
                key: "update",
                name: "update",
                component: FinishingDiscoveryUpdateSection,
              },
            ],
          },
          {
            path: BEGINNING_DEVELOPMENT(":projectId", ":phaseId", ":processId"),
            component: BeginningDevelopmentDetails,
            routes: [
              {
                path: BEGINNING_DEVELOPMENT_UPDATE(
                  ":projectId",
                  ":phaseId",
                  ":processId"
                ),
                exact: true,
                key: "update",
                name: "update",
                component: BeginningDevelopmentUpdateSection,
              },
            ],
          },
          {
            path: DURING_DEVELOPMENT(":projectId", ":phaseId", ":processId"),
            component: DuringDevelopmentDetails,
            routes: [
              {
                path: DURING_DEVELOPMENT_UPDATE(
                  ":projectId",
                  ":phaseId",
                  ":processId"
                ),
                exact: true,
                key: "update",
                name: "update",
                component: DuringDevelopmentUpdateSection,
              },
            ],
          },
          {
            path: COMPLETING_DEVELOPMENT_OR_PRODUCT(
              ":projectId",
              ":phaseId",
              ":processId"
            ),
            component: CompletingDevelopmentOrProductDetails,
            routes: [
              {
                path: COMPLETING_DEVELOPMENT_OR_PRODUCT_UPDATE(
                  ":projectId",
                  ":phaseId",
                  ":processId"
                ),
                exact: true,
                key: "update",
                name: "update",
                component: CompletingDevelopmentOrProductUpdateSection,
              },
            ],
          },
          {
            path: DOG_FOODING(":projectId", ":phaseId", ":processId"),
            component: DogFoodingDetails,
            routes: [
              {
                path: DOG_FOODING_UPDATE(
                  ":projectId",
                  ":phaseId",
                  ":processId"
                ),
                exact: true,
                key: "update",
                name: "update",
                component: DogFoodingUpdateSection,
              },
            ],
          },
          {
            path: START_RELEASE(":projectId", ":phaseId", ":processId"),
            component: StartReleaseDetails,
            routes: [
              {
                path: RELEASE_UPDATE(":projectId", ":phaseId", ":processId"),
                exact: true,
                key: "update",
                name: "update",
                component: StartReleaseUpdateSection,
              },
            ],
          },
        ],
      },
      {
        path: DASHBOARD,
        exact: true,
        component: ClientSatisfaction,
      },
    ],
  },
];
