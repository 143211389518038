import React from "react";
import Tooltip from "../../../Tooltip/Tooltip";
import Badge from "../../../../assets/images/badge.svg";
import BADGES_CONTENT from "../BadgesContent";

function ReviewEstimates() {
  return (
    <div className={"whatContainer estimatesContainer"}>
      <h1 className={"processHeading2"} id="reviewEstimates">
        Review of Estimates
      </h1>
      <div className={"row"}>
        <div className={"col-10"}>
          <p className={"processRunning"}>
            <b>What is the requirement</b>
            <br />
            <br />
            Tintash has moved away from fixed cost projects and towards
            agreements based on monthly burn rates. It is still natural that
            clients want to know at least an estimate
            <Tooltip
              headingOne="Buzurg kehte hein"
              paragraphOne={BADGES_CONTENT.ESTIMATE_REVIEW}
            >
              <img src={Badge} alt="" className="badgeIcon" />
            </Tooltip>
            of the time that a project will take. A client will take that time
            estimate, multiply it by the burn rate and reach an overall total.
            This will remain in the client’s mind as a point of comparison
            throughout the project. Even if they understand that it is a rough
            estimate, subject to change. Essentially, what you say here is a
            major part of whether the project is set up for success.
            <br />
            <br />
            The leads at this stage are qualified leads which means they have an
            appropriate budget; have a product we would like to work on and are
            a team we would like to work with. Basically, we want this client!
            <br />
            <br />
            <b>What is the requirement</b>
          </p>
          <ul>
            <li>
              There is an internal kick off period (3 days – 1 week) built into
              the estimate.
            </li>
            <li>
              The estimate is informed and quick. We see many variations in
              terms of clients’ profiles and how well defined the product is.
              The estimate effort
              <Tooltip
                headingOne="Client ke dil se"
                paragraphOne={BADGES_CONTENT.REQUIREMENT_REVIEW}
                direction={`${screen.width <= 1550 ? "left" : "right"}`}
              >
                <img src={Badge} alt="" className="badgeIcon" />
              </Tooltip>
              , including the required review, depends on those two factors.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default ReviewEstimates;
