import React from "react";
import { Collapse } from "antd";
import PropTypes from "prop-types";

import BADGES_CONTENT from "../../../../components/Processes/Discovery/BadgesContent";
import Guidance from "../../../../components/Guidance/Guidance";
import Tooltip from "../../../../components/Tooltip/Tooltip";
const { Panel } = Collapse;
const prepTime = "15 min";
const runTime = "60 min";
const people = "3-5";
export const DiscoveryKickOff = (props) => {
  const { elder } = props.badges;
  return (
    <>
      <div className="guidancesContainer discoveryKickOff">
        <h1 className="processHeading2" id="kickoffMeeting">
          Discovery Kick Off Meeting
        </h1>
        <br />
        <br />
        <Guidance prepTime={prepTime} runTime={runTime} people={people} />
        <br />
        <br />
        <div className="guidanceCollapse">
          <Collapse expandIconPosition={"right"}>
            <Panel header="Why the meeting: Purpose" key="1">
              <p className="processRunning">
                Discovery Kick-off Meeting is meeting with the client with a
                two-fold purpose:
                <br />
                <br />
                1. Product: Ask questions that will affect the choice of tech,
                features and timeline/prioritisation.
                <Tooltip
                  headingOne="Buzurg kehte hain"
                  paragraphOne={BADGES_CONTENT.PRIORITISE_1}
                  paragraphTwo={BADGES_CONTENT.PRIORITISE_2}
                  paragraphThree={BADGES_CONTENT.PRIORITISE_3}
                >
                  <img src={elder} alt="" className="badgeIcon" />
                </Tooltip>
                <br />
                2. Ways of working: set clear expectations
                <br />
                <ul>
                  <li>Introduce the team</li>
                  <li>
                    Establish ways of working (incl. roles and responsibilities,
                    SOPs for communication, tickets, and documentation)
                  </li>
                </ul>
              </p>
            </Panel>
          </Collapse>
          <br />
          <br />
          <Collapse expandIconPosition={"right"}>
            <Panel header="What is the requirement: Meeting Agenda" key="1">
              <p className="processRunning">
                The PM is responsible for drafting and sharing the agenda of the
                meeting with the client and the team. It can be shared in the
                body of an email or as a separate attachment to the email. It is
                important that the PM follows their own style of communication
                and logical flow. However, the following items should be
                covered:
                <ul>
                  <li>Introductions</li>
                  <li>Our understanding of the product</li>
                  <li>Brief recap: purpose and scope of discovery</li>
                  <li>Discovery Plan</li>
                  <li>SOPs for communication</li>
                </ul>
                <div className="guidanceTips">
                  <h1 className="processHeading4 italic">
                    Before the Meeting
                    <Tooltip
                      headingOne="Buzurg kehte hain"
                      paragraphOne={BADGES_CONTENT.BEFORE_MEETING}
                      direction="right"
                    >
                      <img src={elder} alt="" className="badgeIcon" />
                    </Tooltip>
                  </h1>
                  <p>
                    It is important that meetings are productive, useful to all
                    involved and provide tangible/actionable next steps. In
                    order to achieve this for the Discovery Kick off Meeting,
                    the PM should:
                  </p>
                  <ul>
                    <li>Send the agenda at least a day in advance</li>
                    <li>Share the discovery plan template with the client?</li>
                    <li>
                      Assign agenda items to individual team members as
                      applicable
                    </li>
                    <li>Assign a notetaker</li>
                  </ul>

                  <h1 className="processHeading4 italic">During the Meeting</h1>
                  <ul>
                    <li>
                      Ensure that the understanding of the product discussion
                      stays focused. It is meant to confirm you’re your overall
                      understanding is correct and for you to get information
                      <br />
                      (reference discovery checklist)
                      <Tooltip
                        headingOne="Buzurg kehte hain"
                        paragraphOne={BADGES_CONTENT.CHECKLIST}
                        direction="right"
                      >
                        <img src={elder} alt="" className="badgeIcon" />
                      </Tooltip>
                    </li>
                    <li>
                      Purpose and scope of discovery: this is not to open the
                      discussion on scope again. It is only a recap, present it
                      as such.
                    </li>
                  </ul>

                  <h1 className="processHeading4 italic">After the Meeting</h1>
                  <ul>
                    <li>
                      As a minimum, summarise the notes from the discussion and
                      share
                      <Tooltip
                        headingOne="Buzurg kehte hain"
                        paragraphOne={BADGES_CONTENT.SHARE}
                      >
                        <img src={elder} alt="" className="badgeIcon" />
                      </Tooltip>
                      with clear action items
                    </li>
                    <li>Summarise SOPs clearly</li>
                    <li>
                      Give the client the necessary access as agreed in the SOPs
                    </li>
                  </ul>
                </div>
              </p>
            </Panel>
          </Collapse>
        </div>
      </div>
      <br />
    </>
  );
};

DiscoveryKickOff.propTypes = {
  guidanceone: PropTypes.string,
  guidancetwo: PropTypes.string,
  guidancethree: PropTypes.string,
};
