import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getCheckList } from "../../../../../../../../redux/actions/projectDetails";
import { PROCESS_CHECKLIST_MAPPER } from "../../../processConstants";
import { FormikRadioDynamic } from "../../../../../../../../components/formik/dynamic-radio-form/FormikRadioDynamic";
import { addUpdateEffect } from "../../../../../../../../redux/actions/dailyUpdate";
import ClientSatisfactionMeter from "../../../ClientSatisfactionMeter/ClientSatisfactionMeter";

export const DuringDiscoveryUpdateForm = () => {
  const dispatch = useDispatch();
  const { projectId, phaseId, processId } = useParams();
  const { data: checkList } = useSelector((state) => state.checkList);
  const userRole = useSelector(
    (state) => state.clientSatisfaction.userRole.shortForm
  );
  const [showCheckinForm, setShowCheckinForm] = useState(false);
  const [updateFeedback, setUpdateFeedback] = useState(null);

  useEffect(() => {
    dispatch(getCheckList(projectId, phaseId, processId));
  }, [projectId]);

  const onSubmit = async (values) => {
    const { sendTo = [] } = values;
    const { checkList: checklist } = values[values.syncupType];
    const payload = {
      title: PROCESS_CHECKLIST_MAPPER[values.syncupType],
      syncupType: PROCESS_CHECKLIST_MAPPER[values.syncupType],
      meetingPoints: values.comments,
      process: processId,
      phase: phaseId,
      project: projectId,
      [values.syncupType]: values[values.syncupType],
      checklist,
      sendTo: sendTo.join(", "),
    };
    dispatch(addUpdateEffect(payload));
    const isRoleValid = userRole === "PM" || userRole === "CL";
    if (values.syncupType === "clientUpdate" && isRoleValid) {
      setTimeout(() => {
        setShowCheckinForm(true);
        setUpdateFeedback(!updateFeedback);
      }, 1000);
    }
  };

  const getFormattedInitialValues = (checkList) => {
    return Array.isArray(checkList)
      ? checkList.reduce(
          (accumulator, currentValue) => {
            accumulator[currentValue.html.value] = currentValue.onClick.reduce(
              (childAccumulator, childCurrentValue) => {
                childAccumulator[childCurrentValue.value] =
                  childCurrentValue.inputType === "checkbox" ? false : "";
                return childAccumulator;
              },
              {
                checkList: currentValue._id,
                isChecked: !currentValue.html.clickable,
                clickable: currentValue.html.clickable,
                sendTo: "",
              }
            );
            return accumulator;
          },
          { comments: "", sendTo: [""] }
        )
      : [];
  };

  return (
    <>
      <ClientSatisfactionMeter
        isVisible={showCheckinForm}
        updateFeedback={updateFeedback}
        setShowCheckinForm={setShowCheckinForm}
      />
      <FormikRadioDynamic
        checkList={checkList}
        onSubmit={onSubmit}
        initialValues={getFormattedInitialValues(checkList)}
      />
    </>
  );
};
