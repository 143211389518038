import React from "react";
import { useState } from "react";
import Pop from "../../Popup/popup";
import Badge from "../../../assets/images/badge.svg";
import BADGES_CONTENT from "./BadgesContent";

function Checklist() {
  const [checklist, setChecklist] = useState("product");
  return (
    <div className="processChecklist">
      <h1 className="processHeading2">Discovery Checklist</h1>

      <div className="row">
        <div className="col-4">
          <div
            className={
              checklist === "product" ? "checklistActive" : "checklist"
            }
            onClick={() => setChecklist("product")}
          >
            Product / Business Concept
          </div>
        </div>
        <div className="col-4">
          <div
            className={checklist === "tech" ? "checklistActive" : "checklist"}
            onClick={() => setChecklist("tech")}
          >
            Tech
          </div>
        </div>
        <div className="col-4">
          <div
            className={checklist === "law" ? "checklistActive" : "checklist"}
            onClick={() => setChecklist("law")}
          >
            Laws &amp; Regulations
          </div>
        </div>
      </div>

      <div className="checklistPointers">
        <h1 className="processHeading4">Pointers</h1>
        <ul>
          {checklist === "product" ? (
            <>
              <li>
                What is your target demographic, and can you share some sample
                personas
                <Pop
                  src={Badge}
                  heading="Buzurg kehte hain"
                  dataone={BADGES_CONTENT.PERSONAS_1}
                  datatwo={BADGES_CONTENT.PERSONAS_2}
                />
                of users who would be using your product?
              </li>
              <li>
                Do you currently have a style/theme guide for your product?
              </li>
              <li>
                What features are the foundation of your product and are
                primarily derived from your business model?
              </li>
              <li>
                Do you currently have any competitors
                <Pop
                  src={Badge}
                  heading="Buzurg kehte hain"
                  dataone={BADGES_CONTENT.COMPETITORS}
                />
                for your idea?
              </li>
              <li>Is this an investor or a market MVP?</li>
              <li>
                If features are already shared: How would you rank and
                prioritise your features based on the value they would deliver
                to your users?
              </li>
              <li>
                Would you like to share your business plan with us? Or other
                plans – launch, marketing, fundraising? How can we work together
                to make sure that our timelines remain connected?
              </li>
            </>
          ) : checklist === "tech" ? (
            <>
              <li>
                Do you have any preference for technology to use while building
                it out?
              </li>
              <li>
                What are the features that can be natively developed vs that can
                be used from third-party off the shelf components?
              </li>
              <li>What third party tools to use to build out the system</li>
            </>
          ) : checklist === "law" ? (
            <>
              <li>
                Are there any laws related to the domain (e.g. health, use by
                minors) that are applicable to your product?
              </li>
              <li>
                Are there any privacy and data protection regulations to
                consider in the area you are trying to launch it in? At what
                stage?
                <Pop
                  src={Badge}
                  heading="Lekin kion"
                  dataone={BADGES_CONTENT.LAWS_1}
                  headingOne="Buzurg kehte hain"
                  headingOneData={BADGES_CONTENT.LAWS_2}
                />
              </li>
              <li>
                Are there universal access / accessibility of ICT solutions
                requirements that you need to comply with? At what stage?
              </li>
            </>
          ) : (
            <></>
          )}
        </ul>
      </div>
    </div>
  );
}

export default Checklist;
