import React, { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";

import ReviewEstimates from "./ReviewEstimates";
import Badge from "../../../../assets/images/badge.svg";
import HandoverMeeting from "./HandoverMeeting";
import ConversionRetro from "./ConversionRetro";

function LeadConversionGuidelines(props) {
  const location = useLocation();
  const { close } = props;
  const ref = useRef(null);
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      close && close();
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);
  return (
    <div>
      {location.pathname.includes("provide-estimate") ? (
        <>
          <ReviewEstimates badge={Badge} />
        </>
      ) : location.pathname.includes("handing-over") ? (
        <>
          <HandoverMeeting />
        </>
      ) : location.pathname.includes("looking-back") ? (
        <>
          <ConversionRetro />
        </>
      ) : (
        <div>No GuideLines</div>
      )}
    </div>
  );
}

export default LeadConversionGuidelines;
