import React from "react";

import WhatDoesThisDoDefault from "../../assets/icons/general/whatDoesThisDoDefault.svg";

const WhatDoesThisDoButton = ({ title, clickHandler }) => {
  return (
    <div id="what-does-this-do-button-container" className="desktop-hidden">
      <img
        src={WhatDoesThisDoDefault}
        alt="what does this do button"
        onClick={() => clickHandler(title)}
      />
    </div>
  );
};

export default WhatDoesThisDoButton;
