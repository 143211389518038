import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getProjectsList,
  getStatusProject,
  setCurrentSubStage,
} from "../../../../redux/actions/projectDetails";
import { useHistory, useLocation } from "react-router-dom";
import { unlockSubStage } from "../../../../helper/putApis";
import { ROUTES, SUB_ROUTE_KEY } from "../../../../routes/routeConstants";
import Icon from "../../../../components/Icon/Icon";
import "./sub-stages.scss";
import completed from "../../../../assets/icons/processCompletIcon.svg";
import inProgress from "../../../../assets/icons/currentPhase.svg";
import pending from "../../../../assets/icons/pendingProcess.svg";
import rejected from "../../../../assets/icons/rejected.svg";
import locked from "../../../../assets/icons/unfinished.svg";
import { getNextProcessUrl } from "../../../../utilities/utilities";

import { SocketContext } from "../../../../context/socket";
import { SOCKET_CONSTANTS } from "../../../../constants/globalConstants";
import { NextStageDialog } from "../../../../components/Dialog/next-stage-dialog/NextStageDialog";
import { PROJECT_STATUSES } from "../../../../redux/actions/types";
import { useState } from "react";
import { getPmDetails } from "../../ClientSatisfaction/clientSatisfactionHelper";
import { COMPLETE_STAGE } from "../../../../constants/completeStageMessages";

function SubStages(props) {
  const {
    setShowDialog,
    showDialog,
    handlePhases,
    phasesList,
    setPhaseId,
    setShowErrorDialog,
  } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const socket = useContext(SocketContext);
  const currentSubStageId = useSelector((state) => state.subStage.id);
  const projectId = useSelector((state) => state.current.projectId);
  const accessToken = useSelector((state) => state.login.accessToken);
  const { allSubStages, currentStageId } = props;
  const currentPhaseName = useSelector((state) => state.phase.name);
  const processId = useSelector((state) => state?.subStage.id);
  const substageName = useSelector((state) => state?.subStage.name);
  const userRole = useSelector((state) => state.clientSatisfaction.userRole);
  const userRoleId = userRole?._id;
  const projectStatus = useSelector((state) => state.current.status);
  const [isDisabled, setIsDisabled] = useState(false);

  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };

  const getProcessId = () =>
    location.pathname.split("/")[location.pathname.split("/").length - 2];

  const PROCESSES_STAGE = {
    "in progress": inProgress,
    completed: completed,
    rejected: rejected,
    "pending for approval": pending,
    locked: locked,
  };
  const checkStatus = () => {
    const index = allSubStages.findIndex(({ _id }) => _id === processId);
    if (allSubStages[index].status === "completed") {
      return false;
    }
    return true;
  };
  const getNextProcessIndex = () => {
    const index = allSubStages.findIndex(({ _id }) => _id === processId);
    const newIndex = index + 1;
    return newIndex < allSubStages.length ? newIndex : allSubStages.length - 1;
  };
  const goToNextProcess = async () => {
    setIsDisabled(true);
    await unlockSubStage(projectId, currentStageId, currentSubStageId);
    const index = getNextProcessIndex();
    const nextProcess = allSubStages[index];
    dispatch(setCurrentSubStage(nextProcess._id, nextProcess.name));
    await dispatch(
      await getStatusProject(config, projectId, "1", currentPhaseName)
    );
    dispatch(getProjectsList(config, userRoleId));
    getPmDetails("", userRoleId);

    const url = getNextProcessUrl({
      location,
      projectId,
      phaseId: currentStageId,
      processId: nextProcess._id,
      currentPhaseName,
    });

    const processIndex = allSubStages.findIndex(
      ({ _id }) => _id === currentSubStageId
    );

    setShowDialog(false);
    if (processIndex >= allSubStages.length - 1) {
      const phaseIndex = phasesList.findIndex(
        ({ _id }) => _id === currentStageId
      );
      const nextPhaseIndex = phaseIndex + 1 < 5 ? phaseIndex + 1 : 0;
      const nextPhase = phasesList[nextPhaseIndex];
      if (nextPhase.status !== PROJECT_STATUSES.DISABLE) {
        handlePhases(nextPhase.name);
        setPhaseId(nextPhase._id);
      }
      setIsDisabled(false);
      return;
    }
    history.push(url);
    setIsDisabled(false);
  };

  const setCurrentSubStageId = (id, name) => {
    dispatch(setCurrentSubStage(id, name));
  };

  const goToProcess = (data) => {
    if (data.status === "locked") {
      return setShowErrorDialog(true);
    }
    setCurrentSubStageId(data._id, data.name);
    history.push(
      ROUTES[SUB_ROUTE_KEY[data.name]](projectId, currentStageId, data._id)
    );
  };

  useEffect(() => {
    socket.on(SOCKET_CONSTANTS.GENERATE_POPUP, () => {
      closePreviousPopUps();
      checkStatus() ? setShowDialog(true) : setShowDialog(false);
    });

    return () => {
      socket.off(SOCKET_CONSTANTS.GENERATE_POPUP);
    };
  }, [processId, allSubStages]);

  useEffect(() => {
    socket.on(SOCKET_CONSTANTS.UPDATE_PROCESS_STATUS, () => {
      dispatch(getStatusProject(config, projectId, "1", currentPhaseName));
    });

    return () => {
      socket.off(SOCKET_CONSTANTS.UPDATE_PROCESS_STATUS);
    };
  }, [currentPhaseName, projectId]);

  const closePreviousPopUps = () => {
    setShowErrorDialog(false);
    const isAdditionalFileDialog = localStorage.getItem("isAdditionalFile");
    isAdditionalFileDialog
      ? localStorage.setItem("isAdditionalFile", false)
      : null;
  };

  const getLineColor = (index) => {
    if (index > 2) {
      return "";
    }
    const nextIndex = index + 1;

    if (allSubStages[nextIndex]?.status !== "locked") {
      return "color-red";
    }
    return "color-black";
  };

  const getLeftMargin = (data) => {
    const length = data?.name?.length;
    let margin = 0;
    margin = length * 2 + 9;
    if (length < 8) {
      margin = length * 2;
    }
    return margin;
  };

  return (
    <>
      <div className="flex justify-center">
        <NextStageDialog
          title={
            substageName === "Release"
              ? COMPLETE_STAGE.RELEASE_SUCCESS
              : COMPLETE_STAGE.SUCCESS
          }
          onSuccess={goToNextProcess}
          showDialog={showDialog}
          setShowDialog={setShowDialog}
          isDisabled={isDisabled}
        />
        <div className="flex justify-center mb-16">
          {allSubStages?.map((data, count) => (
            <div
              className="flex justify-center pb-16"
              key={`sub-stage-item` + count}
            >
              <div
                className={
                  projectStatus === "closed" && data.status === "locked"
                    ? "substage-item-wrapper-disable"
                    : "substage-item-wrapper"
                }
                onClick={() => goToProcess(data)}
              >
                <div className="flex align-center">
                  <Icon processState={PROCESSES_STAGE[data.status]} />
                  {count < 2 && allSubStages.length > 1 && (
                    <div className={`line ${getLineColor(count)} `}></div>
                  )}
                </div>

                <div
                  style={{ marginLeft: `-${getLeftMargin(data)}px` }}
                  className={`subStageItem  ${
                    data?._id === getProcessId() ? "active-substage" : ""
                  }`}
                  key={data?._id}
                >
                  {data?.name}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default SubStages;
