import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCheckList,
  getProjectsList,
  setCurrentSubStage,
} from "../../../../../../../../redux/actions/projectDetails";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { FormikCheckboxDynamic } from "../../../../../../../../components/formik/dynamic-form/FormikCheckboxDynamic";
import { addUpdateEffect } from "../../../../../../../../redux/actions/dailyUpdate";
import { PROCESS_CHECKLIST_MAPPER } from "../../../processConstants";
import {
  getFormattedInitialValues,
  getNextProcessUrl,
} from "../../../../../../../../utilities/utilities";
import { unlockSubStage } from "../../../../../../../../helper/putApis";
import { NextStageDialog } from "../../../../../../../../components/Dialog/next-stage-dialog/NextStageDialog";

export const CompletingDevelopmentOrProductDetailsUpdateForm = () => {
  const currentPhaseName = useSelector((state) => state.phase.name);
  const dispatch = useDispatch();
  const { projectId, phaseId, processId } = useParams();
  const { data: checkList } = useSelector((state) => state.checkList);
  const allSubStages = useSelector((state) => state?.current.data);
  const history = useHistory();
  const location = useLocation();
  const userRole = useSelector((state) => state.clientSatisfaction.userRole);
  const userRoleId = userRole?._id;
  const accessToken = useSelector((state) => state.login.accessToken);

  const [showDialog, setShowDialog] = useState(false);

  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };

  useEffect(() => {
    dispatch(getCheckList(projectId, phaseId, processId));
  }, [projectId]);

  const unlockNextStage = async () => {
    try {
      unlockSubStage(projectId, phaseId, processId);
      const nextProcess = allSubStages[1];
      dispatch(getProjectsList(config, userRoleId));
      dispatch(setCurrentSubStage(nextProcess._id, nextProcess.name));
      setShowDialog(false);

      history.push(
        getNextProcessUrl({
          location,
          projectId,
          phaseId,
          processId: nextProcess._id,
          currentPhaseName,
        })
      );
    } catch (e) {
      setShowDialog(false);

      console.log("Error Fetching Subscriptions Detail", e);
    }
  };

  const onSubmit = async (values) => {
    let startDiscoveryCompleted = false;
    const { sendTo = [] } = values;
    const {
      checkList: checklist,
      isChecked,
      comments,
    } = values[values.syncupType];
    startDiscoveryCompleted = isChecked;
    const payload = {
      title: PROCESS_CHECKLIST_MAPPER[values.syncupType],
      syncupType: PROCESS_CHECKLIST_MAPPER[values.syncupType],
      process: processId,
      phase: phaseId,
      project: projectId,
      [values.syncupType]: values[values.syncupType],
      checklist,
      sendTo: sendTo.join(","),
      meetingPoints: comments,
    };
    dispatch(addUpdateEffect(payload));
    if (startDiscoveryCompleted) {
      setShowDialog(true);
    }
    setTimeout(() => {
      dispatch(getCheckList(projectId, phaseId, processId));
    }, 1000);
  };

  return (
    <>
      <NextStageDialog
        onSuccess={unlockNextStage}
        showDialog={showDialog}
        setShowDialog={setShowDialog}
      />
      <FormikCheckboxDynamic
        checkList={checkList}
        onSubmit={onSubmit}
        initialValues={getFormattedInitialValues(checkList)}
      />
    </>
  );
};
