import React from "react";
import styles from "./../introduction.module.css";
import timelapse from "../../../assets/icons/timelapse.svg";
import principleone from "../../../assets/images/Development/Group 22269.svg";
import principletwo from "../../../assets/images/Development/Group 22268.svg";
import principlethree from "../../../assets/images/Development/Group 22267.svg";

function Principles() {
  return (
    <div>
      <div className="row mt-64">
        <div className="whitespace col-2"></div>
        <img
          className={`mobile-hidden col-2 ${styles.timelapseWhyThePlaybook}`}
          src={timelapse}
        />
        <h3 className="col-6 processHeading2 ml-0 mt-16">Principles</h3>
      </div>
      <div className="row mt-32">
        <div className="whitespace col-2"></div>
        <div className="col-4 mobile-w-90% principle-container">
          <p className={"lead-content ml-24"}>
            A brief note on the principles that guide the content of the
            playbook– in translating project management to Tintash’s reality,
            the playbook takes guidance from the company ethos. These reflect
            the human focus at the organisation and, as described uniformly by
            Tintash leadership, are:
            <br />
            <br />
            <br />
            <div className="mobile-hidden">
              In this playbook, you will find what each of these mean
              specifically at different stages of a project’s life cycle
            </div>
          </p>
        </div>
        <div className="col-4 mobile-w-90%">
          <div className="row mb-16">
            <img
              className={`mobile-hidden col-3 ${styles.principleIcon}`}
              src={principleone}
            />
            <h5 className={`mobile-hidden col-8 ${styles.principleItem}`}>
              Delight the customer
            </h5>
            <div className="desktop-hidden mobile-flex">
              <img className={`${styles.principleIcon}`} src={principleone} />
              <h5 className={`${styles.principleItem}`}>
                Delight the customer
              </h5>
            </div>
          </div>
          <div className="row mb-16">
            <img
              className={`mobile-hidden col-3 ${styles.principleIcon}`}
              src={principletwo}
            />
            <h5 className={`mobile-hidden col-9 ${styles.principleItem}`}>
              Good working environment for the team
            </h5>
            <div className="desktop-hidden mobile-flex">
              <img className={`${styles.principleIcon}`} src={principletwo} />
              <h5 className={`${styles.principleItem}`}>
                Good working environment for the team
              </h5>
            </div>
          </div>
          <div className="row mb-16">
            <img
              className={`mobile-hidden col-3 ${styles.principleIcon}`}
              src={principlethree}
            />
            <h5 className={`mobile-hidden col-8 ${styles.principleItem}`}>
              Profitability for the company
            </h5>
            <div className="desktop-hidden mobile-flex">
              <img className={`${styles.principleIcon}`} src={principlethree} />
              <h5 className={`${styles.principleItem}`}>
                Profitability for the company
              </h5>
            </div>
            <div className="desktop-hidden guidanceReqInnerBox mt-4r">
              In this playbook, you will find what each of these mean
              specifically at different stages of a project’s life cycle
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Principles;
