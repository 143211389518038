import { useState, useEffect, useRef } from "react";

const useElementInView = (options) => {
  const containerRef = useRef(null);

  const [showElement, setShowElement] = useState(false);

  const callbackFunction = (entries) => {
    const [entry] = entries;
    setShowElement(entry.isIntersecting);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, options);

    containerRef.current && observer.observe(containerRef.current);

    return () =>
      containerRef.current && observer.unobserve(containerRef.current);
  }, [containerRef, options]);

  return [containerRef, showElement];
};

export default useElementInView;
