import React from "react";
import PropTypes from "prop-types";
import { Space } from "antd";
import PaperClipIcon from "../../../../assets/icons/paperClip.svg";
import Trash from "../../../../assets/icons/trash.svg";

const discoveryPlanTemplate =
  "https://docs.google.com/document/d/19ibezTGMw57Fx1XhR9DG4Qp0FMe7htYHC8Smv-RUrVA/edit?usp=sharing";
const discoveryEffortTemplate =
  "https://docs.google.com/document/d/10lCnfy9uwOVA7e8fV7eBjhclxjtLO6y1zX5MjCOXw2M/edit?usp=sharing";
const sow =
  "https://docs.google.com/document/d/1IrRww8jwYH89ML795jUqOHz1tlLSgPKR2nkI9A7fRMQ/edit?usp=sharing";

const URL = {
  "Discovery Plan": discoveryPlanTemplate,
  "Discovery Effort Estimates": discoveryEffortTemplate,
  SOW: sow,
};

function Artifact({ item, handleDeleteFile, handleAttachUrl }) {
  const {
    _id,
    status,
    isUserDefined,
    file: { name: itemFileName, url } = {},
  } = item;

  const handleOpenTemplateClick = (fileName) => {
    window.open(URL[fileName], "_blank");
  };

  return (
    <div>
      <div className="files  ">
        <div className="row file-row">
          <div className="file-container col-4">
            <img className="paper-clip-icon" src={PaperClipIcon} />

            <div
              className="file-name-wrapper"
              onClick={() => handleAttachUrl(item)}
            >
              {itemFileName}
            </div>
          </div>
          <div
            className="file-content-project-artifacts col-4"
            onClick={() => {
              handleOpenTemplateClick(itemFileName);
            }}
          >
            {URL[itemFileName] && "Open Template"}
          </div>

          <div className="col-4">
            {isUserDefined === false ? (
              <div className="row flex flex-no-wrap">
                <div className="col-9 flex justify-center">
                  {url !== "" &&
                  status !== "accepted" &&
                  status !== "rejected" ? (
                    <div className="pending-for-approval">
                      Pending For Approval
                    </div>
                  ) : (
                    <></>
                  )}
                  {status === "accepted" && url !== "" ? (
                    <div className="accepted">Approved</div>
                  ) : status === "rejected" && url !== "" ? (
                    <div className="rejected"> Rejected</div>
                  ) : status === "missing document" ? (
                    <div
                      className="upload"
                      onClick={() => handleAttachUrl(item)}
                    >
                      Upload
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <Space>
                  <div
                    className={
                      url !== ""
                        ? "col-2 delete-button"
                        : "disable-delete-button"
                    }
                  >
                    {url !== "" ? (
                      <img
                        src={Trash}
                        onClick={() =>
                          handleDeleteFile(_id, status, "mandatory")
                        }
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </Space>
              </div>
            ) : (
              <div className="row">
                <div className="col-9"></div>
                <div className="col-2 delete-button">
                  <img
                    src={Trash}
                    onClick={() => handleDeleteFile(_id, status, "optional")}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

Artifact.propTypes = {
  item: PropTypes.any.isRequired,
  handleDeleteFile: PropTypes.func.isRequired,
  handleAttachUrl: PropTypes.func.isRequired,
};

export default Artifact;
