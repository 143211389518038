import { GET_ROLES } from "./GetAllRolesTypes";

export const setAllRoles = (data) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ROLES,
      payload: data,
    });
  } catch (error) {
    throw new Error("Error Fetching roles");
  }
};
